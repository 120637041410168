var ICD10Description = function ($) {
  var icd_service_url = '';
  function setup() {
    $(document).on('table_updated', function (event, data) {
      if (data) {
        icd_service_url = $('.icd-service-url').data('service-url');
        update(data.table.find('span.icd10-indicator'));
      }
    });
  }
  setup();
  function prepared_service_url(codes) {
    var unique_codes = [];
    $.each(codes, function(i, el){
      if($.inArray(el, unique_codes) === -1) unique_codes.push(el);
    });
    return icd_service_url + '?ids[]=' + unique_codes.join('&ids[]=');
  }
  function update_description(element, description) {
    element.attr('data-content', description);
    element.data('content', description);
    element.popover('hide').popover();
  }
  function update(elements) {
    if (typeof(icd_service_url) === 'undefined') {
      return;
    }
    if (icd_service_url.length < 1) {
      return;
    }
    var codes = [], 
      deferred_ajax_update = $.Deferred();

    elements.each(function () {
      var elem = $(this),
        icd = elem.data('icd');

      codes.push(icd);
      deferred_ajax_update.done(function (data) {
        update_description(elem, data[icd]);
      }).fail(function (data) {
        update_description(elem, data);
      });
    });
    if (codes.length > 0) {
      $.ajax({
        url: prepared_service_url(codes),
        dataType: 'json'
      }).done(function (data) {
        deferred_ajax_update.resolve(data);
      }).fail(function (obj) {
        console.log(obj);
        deferred_ajax_update.reject('Could not get description');
      });
    }
  }
}(jQuery);