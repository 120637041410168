import { Application } from '../application';

var UserImport = (function($) {
  function ajax_complete(event) {
    $('.indeterminate_progress').hide();
  }

  function ajax_success(event, xhr, settings) {
    if (settings.url.match(/user_imports/)) {
      var json = JSON.parse(xhr.responseText),
        error_count = parseInt(json['error_count']);
      reset_responses();
      if (error_count > 0) {
        display_errors(json);
      } else {
        display_success(json);
      }
    }
  }

  function reset_responses() {
    $('#error-count-dialog').hide();
    $('#success-dialog').hide();
  }

  function display_success(json) {
    var commit_form = $('#user_import_commit_form'),
      commit_url = commit_form.data('url').replace(':id', json['id']);
    let show_uit_link = $('#success-dialog').find('a');
    let show_uit_url = show_uit_link.data('url').replace(':id', json['id']);
    $('#success-dialog').show();
    commit_form.attr('action', commit_url);
    show_uit_link.attr('href', show_uit_url);
  }

  function error_list_html(errors) {
    let html = '<ol>';
    for (let type in errors) {
      if (errors.hasOwnProperty(type)) {
        html = html + '<li>' + type;
        html = html + '<ol>';
        for (var i = 0; i < errors[type].length; i++) {
          var error = errors[type][i];
          html = html + '<li>' + error + '</li>';
        }

        html = html + '</ol></li>';
      }
    }
    html = html + '</ol>';
    return html;
  }

  function display_errors(json) {
    var error_dialog = $('#error-count-dialog'),
      error_count = parseInt(json['error_count']),
      download_annotated_csv_link = error_dialog.find('a'),
      annotated_csv_url = download_annotated_csv_link.data('url').replace(':id', json['id']),
      error_list = $('#error-list');

    error_dialog.show();
    $('#error-count').html(error_count);
    download_annotated_csv_link.attr('href', annotated_csv_url);
    if (typeof(json['errors']) !== 'undefined') {
      if (json['errors']['base'].length > 0) {
        error_list.show();
        error_list.html(error_list_html(json['errors']));
      } else {
        error_list.hide();
      }
    }

    if (typeof(json['id']) === 'undefined') {
      download_annotated_csv_link.hide();
    } else {
      download_annotated_csv_link.show();
    }
  }

  function ajax_error(event, xhr, settings, thrown_error) {
    alert('Could not process the file. Review the file for data errors and retry.');
    console.log(event, xhr, settings, thrown_error);
  }

  function ajax_start(event) {
    $('#error-count-dialog').hide();
    $('.indeterminate_progress').show();
  }

  function choose_csv_button_clicked(event) {
    $(event.target).closest('form').find('input[type="file"]').trigger('click');
  }

  function file_selected(event) {
    var input = $(event.target),
      filename = input.val().split('\\').pop(),
      linked_text_field = input.data('pretty-text-field');
    $('#' + linked_text_field).val(filename);
  }

  function init() {
    $(document).on('ajaxComplete', ajax_complete);
    $(document).on('ajaxSuccess', ajax_success);
    $(document).on('ajaxError', ajax_error);
    $(document).on('ajaxStart', ajax_start);
    $(document).on('click', '#user_import_file_input_button', choose_csv_button_clicked);
    $(document).on('click', '#staged_validation_file_input_button', choose_csv_button_clicked);
    $(document).on('change', 'input[type="file"]', file_selected);
  }

  function show_init() {
    $(document).on('click', '#user_import_file_input_button', choose_csv_button_clicked);
    $(document).on('click', '#staged_validation_file_input_button', choose_csv_button_clicked);
    $(document).on('change', 'input[type="file"]', file_selected);
  }

  function setup() {
    if (Application.current({ controller: 'user_imports', action: 'new' })) {
      init();
    }
    if (Application.current({ controller: 'user_imports', action: 'show' })) {
      show_init();
    }
  }

  return {
    setup: setup
  };
}(jQuery));

$(function(){
  UserImport.setup();
});