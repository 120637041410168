(function($) {
  $.fn.ants = function(command) {
    var my = this;
    function halt_all () {
      $('div.ants').children().ants().halt();
    }
    function march () {
      halt_all();
      var ants_wrapper = $('<div class=\'ants\' />');
      my.wrap(ants_wrapper);
      ants_wrapper = my.parents('div.ants');
      var styles = my.getStyleObject();
      if (styles) {
        var saved_styles = {};
        $.each(styles, function(key, value) {
          if (key.match(/radius/i)) {
            ants_wrapper.get(0).style[key] = value;
          }
          if (key.match(/margin/i)) {
            ants_wrapper.get(0).style[key] = value;
            saved_styles[key] = value;
            my.get(0).style['margin'] = '0px';
          }
        });
        my.data('saved_styles', saved_styles);
      }
      if (my.css('display') === 'inline-block') {
        ants_wrapper.css({display:'inline-block'});
      }

      // ants_wrapper.css({
      //   'border-radius':my.css("border-radius"),
      //   '-webkit-border-radius':my.css('-webkit-border-radius'),
      //   '-moz-border-radius':my.css('-moz-border-radius')
      //
      //   // float:my.css('float')
      // });

    }
    function halt () {
      my.unwrap();
      var saved_styles = my.data('saved_styles');
      if (saved_styles) {
        my.css(saved_styles);
      }

    }
    function toggle () {
      if (my.parents('div.ants').length > 0) {
        halt();
      } else {
        march();
      }
    }
    if (command === 'halt_all') {
      halt_all();
    }
    return {
      march:march,
      halt:halt,
      toggle:toggle
    };
  };
})(jQuery);