//#checked_all
function sync_batch_generate_button_activation() {
  var threshold_report_controls = $('div#threshold_report_controls');
  if (threshold_report_controls.length > 0) {
    var activate_controls = $('input:checkbox').is(':checked');
    if (activate_controls) {
      threshold_report_controls.find('input, button').attr('disabled', false);
    } else {
      threshold_report_controls.find('input, button').attr('disabled', 'disabled');
    }
  }
}

$(function() {
  sync_batch_generate_button_activation();
});

$(document).on('change', 'input:checkbox', function(event) {
  var input = $(event.target);
  if (input.parents('*[data-ignore-check-all]').length > 0) {
    return false;
  }
  if (input.attr('data-group-id')) {
    var related_checkboxes = $('input:checkbox[data-group-id=' + input.data('group-id') + ']');
    if (input.attr('data-group-key') !== undefined) {
      //Master checkbox is clicked at top of list
      var check_all = true;
      if (!input.is(':checked')) {
        check_all = false;
      }
      related_checkboxes.prop('checked', check_all);
    } else {
      var key = $('input:checkbox[data-group-id=' + input.data('group-id') + '][data-group-key]'),
        all_checked = true;
      related_checkboxes.each(function() {
        var box = $(this);
        if (box.get(0) !== key.get(0)){
          if (!box.attr('checked')) {
            all_checked = false;
          }
        }
      });
      key.prop('checked', all_checked);

    }
  }
  sync_batch_generate_button_activation();
});

var ThresholdReport = (function () {
  var klass = new Object(),
    timer_id = null;
  klass.poll_for_finished_report = function (url, options) {
    timer_id = setInterval(function () {
      klass.check_report_completion(url, options.success, options.error);
    }, 1000);
  };
  klass.check_report_completion = function (url, success, error) {
    $.ajax({
      url:url,
      dataType:'json',
      success: function (data, status, xhr) {
        // console.log(data);
        if (String(data) === 'complete') {
          clearInterval(timer_id);
          success();
        }
        if (String(data) === 'error') {
          clearInterval(timer_id);
          // alert("Could not complete your report. Please contact your administrator.")
          error();
        }

      }
    });
  };
  return klass;
}());
