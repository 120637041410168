/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   calendar-button.js
 */

import { DatePicker } from './datepicker';
import { DatePickerDateRange } from './daterange';

export const CalendarButtonInput = function (inputNode, buttonNode, datepicker) {
  this.inputNode = inputNode;
  this.buttonNode = buttonNode;
  this.imageNode = false;

  this.datepicker = datepicker;

  this.defaultLabel = 'Choose Date';

  this.keyCode = Object.freeze({
    'ENTER': 13,
    'SPACE': 32
  });
};

CalendarButtonInput.prototype.init = function () {
  this.buttonNode.addEventListener('click', this.handleClick.bind(this));
  this.buttonNode.addEventListener('keydown', this.handleKeyDown.bind(this));
  this.buttonNode.addEventListener('focus', this.handleFocus.bind(this));
};

CalendarButtonInput.prototype.handleKeyDown = function (event) {
  var flag = false;

  switch (event.keyCode) {

  case this.keyCode.SPACE:
    case this.keyCode.ENTER:
    this.datepicker.show();
    this.datepicker.setFocusDay();
    
    flag = true;
    break;

  default:
    break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

CalendarButtonInput.prototype.handleClick = function (event) {
  if (!this.datepicker.isOpen()) {
    this.datepicker.show();
    this.datepicker.setFocusDay();
  } else {
    this.datepicker.hide();
  }

  event.stopPropagation();
  event.preventDefault();
  return false;
};

CalendarButtonInput.prototype.setLabel = function (str) {
  if (typeof str === 'string' && str.length) {
    str = ', ' + str;
  }
  this.buttonNode.setAttribute('aria-label', this.defaultLabel + str);
};

CalendarButtonInput.prototype.setFocus = function () {
  this.buttonNode.focus();
};

CalendarButtonInput.prototype.setDate = function (day) {
  var d = day.getDate().toString();
  var m = day.getMonth() + 1;

  this.datepicker.saveDate(m + '/' + d + '/' + day.getFullYear());
  this.datepicker.updateInputNodeValue();
};

CalendarButtonInput.prototype.getDate = function () {
  return this.inputNode.value;
};

CalendarButtonInput.prototype.getDateLabel = function (dateValue) {
  var label = '';

  var parts = dateValue.split('/');

  if ((parts.length === 3) &&
        Number.isInteger(parseInt(parts[0])) &&
        Number.isInteger(parseInt(parts[1])) &&
        Number.isInteger(parseInt(parts[2]))) {
    var month = parseInt(parts[0]) - 1;
    var day = parseInt(parts[1]);
    var year = parseInt(parts[2]);

    label = this.datepicker.getDateForButtonLabel(year, month, day);
  }

  return label;
};

CalendarButtonInput.prototype.handleFocus = function () {
  var dateLabel = this.getDateLabel(this.inputNode.value);
  if (dateLabel) {
    this.setLabel('selected date is ' + dateLabel + '. Note: Activating this button will automatically move navigation focus to the open calendar application.');
  } else {
    this.setLabel('');
  }
};

// Initialize menu button date picker
export const AccessibleDatePickerSetUp = (function() {
  let initialize = function() {
    let datePickerContainers = document.querySelectorAll(':not(.date_range_field).accessible-calendar-datepicker.uninitialized');

    (function() {
      for (let i = 0; i < datePickerContainers.length; i++) {
        let theCurrentContainer = datePickerContainers[i];
        let inputNode = theCurrentContainer.querySelector('input');
        let buttonNode = theCurrentContainer.querySelector('button');
        let dialogNode = theCurrentContainer.querySelector('.datepickerDialog');

        let datePicker = new DatePicker(inputNode, buttonNode, dialogNode);
        datePicker.init();
        theCurrentContainer.classList.remove('uninitialized');
      }
    })();
    
    let datePickerRangeContainers = document.querySelectorAll('.accessible-datepicker-date-range');
    
    (function () {
      for (let j = 0; j < datePickerRangeContainers.length; j++) {
        let theCurrentDateRangeContainer = datePickerRangeContainers[j];
        let dateRange = new DatePickerDateRange(
          theCurrentDateRangeContainer
        );
        dateRange.init();
        theCurrentDateRangeContainer.classList.remove('uninitialized');
      }
    })();
   
  };
  

  return {
    initialize: initialize
  };
})();


window.addEventListener('load', function () {
  AccessibleDatePickerSetUp.initialize();
});
