import { Flash } from '../app/flash';

var OrganizationApproval = (function ($) {
  function setup() {
    $(document).ajaxSuccess(function (event, xhr, settings) {
      var match = settings.url.match(/\/organizations\/organizations\/(\d+)\/approval/);
      if ( match !== null ) {
        var org_id = match[1];
        $('*[data-row-id="' + org_id + '"]').hide('fade');
        // hide any popovers
        $('[data-toggle="popover"], [data-auto-popover-form], [data-auto-popover]').each(function () {
          $(this).popover('hide');
        });
      }
    });
    $(document).ajaxError(function (event, xhr, settings) {
      var match = settings.url.match(/\/organizations\/organizations\/(\d+)\/approval/);
      if ( match !== null ) {
        var org_id = match[1];
        if (xhr.statusText !== 'validatorian:validation:failed') {
          Flash.error('Could not perform the requested action for the Organization. Please contact your administrator.');
        }
      }
    });
  }
  return {
    setup: setup
  };
}(jQuery));

OrganizationApproval.setup();