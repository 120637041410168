var FormAttributeHelper = (function($) {
  function check_for_clear(event) {
    var node = $(event.target),
      field_to_clear = node.data('clear-field');
    if (field_to_clear !== undefined) {
      $(field_to_clear).val('');
      $(field_to_clear).trigger('change');
    }
  }

  function setup() {
    $(function () {
      $('*[data-sync-form-field]').each(function () {
        var node = $(this),
          field_selector = node.data('sync-form-field'),
          field = $(field_selector);
        field.on('change', function (event) {
          var value = $(event.target).val();
          node.html($.find(value));
        });
      });
    });
    $(document).on('click', check_for_clear);
  }

  return {
    setup: setup
  };
}(jQuery));

FormAttributeHelper.setup();