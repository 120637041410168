import { x508 } from '../app/x508';
import { Flash } from '../app/flash';
import { InputsHelper } from '../lib/inputs_helper';
import { FileUploadHelper } from '../lib/file_upload_helper';
import { StaticTableHeaders } from '../app/static_table_headers';
import { tmpl } from '../blueimp';

$.fn.hasParent = function (objs) {
  var found = false;
  $(this[0]).parents().addBack().each(function () {
    if ($.inArray(this, $.find(objs)) != -1) {
      found = true;
      return false;
    }
  });
  return found;
};

$(document).on('click', function (event) {
  //  var source_element = $(event.srcElement) // this didn't work for certain browsers, and failed for IE 8.
  //  var source_element = $(event.srcElement || event.target) // a suggested x-browser solution that doesn't work.
  var source_element = $(event.target); // this apparently works for IE 8.

  // If *not* in the search_options drop down form and *not* part of the toggle control (which already fires a toggle)
  if (!source_element.hasParent('#search_options') && !source_element.hasParent('#search_option_toggle')) {
    // if statement is necessary because...
    if ($('#search_options').hasClass('show')) {
      // if you use .collapse('hide') on an object that isn't showing and has never been toggled it shows! %$@#%@#%
      if (source_element.parents().addBack().filter('html').length > 0) {
        // Okay... the reasoning behind this madness is I believe the calendar widget is removing it's nodes from the dom before we get the event bubbled up this far, so we are essentally looking for orphaned nodes...
        if (!source_element.hasParent('.datepicker')){
          // In case we haven't closed the datepicker (by selecting a day), the datepicker still exists, so we need to make sure we aren't clicking inside of it.
          $('#search_options').collapse('hide');
        }
      }
    }
  }
});

$(function() {
  /* The Advanced Options Toggle Button */
  $('#search_option_toggle').on('keyup', function (event) {
    var key = event.key || event.keyCode || event.which;

    if ($('#search_options').hasClass('show') && key === 'Escape') {
      $('#search_options').collapse('hide');
    }
  });

  function handle_table_selection(event) {
    var object = $(event.target).closest('tr');
    $('.selected-row-sr-only').remove();
    $('.selected-row-accessibility-control').remove();
    $(event.target).append('<div class="sr-only selected-row-sr-only"> selected row.</div>');
    $('table#registration_search_results tr').removeClass('info');
    $('table#registration_search_results tr').find('a[role=\'button\']').attr('aria-pressed', 'false');
    if (!object.hasClass('not-info')) {
      object.addClass('info');
    }

    update_address_card(object);
  }

  $(document).on('click', 'div#results_with_pagination tbody tr', function (event) {
    handle_table_selection(event);
  });

  // if we conduct a search, blank the address card.
  $('.form-search')
    .on('ajax:success', function (evt, data, status, xhr) {
      // TODO: Perhaps a blank address card route would be better? I don't know.
      blank_address_card(this.id);
    }).on('submit', function(){
      Flash.clear();
    });

  $('#registration_search_form')
    .on('ajax:beforeSend', function (evt, xhr, settings) {
      //todo: spinner
    })
    .on('ajax:success', function (evt, data, status, xhr) {
      try {
        eval(xhr.responseText);
      } catch (error) {
        $('#results').html(xhr.responseText);
        //console.log('Could not eval response. ', xhr);
      }

    })
    .on('ajax:complete', function (evt, xhr, status) {

    })
    .on('ajax:error', function (evt, xhr, status, error) {


      $('.scrolltable').html(xhr.responseText);

      alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
      //console.log('Ajax error: ', error);
      $(document).trigger('search:failed', {error: error});
    });

  // Update user row when the modal is dismissed
  $(document).on('hide.bs.modal', function (event) {
    var node = $(event.target);
    if ($.inArray(node.attr('id'), ['validation_management_modal', 'edit_roles_modal', 'edit_demographics_modal', 'user_notes-modal']) > -1) {
      var user_id = node.attr('data-user-id');
      if (user_id == null) { //try to automagically determine user id
        var selected_row = $('tr.info');
        user_id = selected_row.attr('id').split('_').slice(-1)[0]; // I miss you prototype! ;_;

      }

      if (user_id != null) {
        update_user_with_id(user_id);
      }

    }

  });
});

$(document)
  .on('ajax:success', '.modal-form', function (evt, data, status, xhr) {
    // console.log(evt, data, status, xhr);
    eval(xhr.responseText);
  })
  .on('ajax:error', '.modal-form', function (evt, xhr, status, error) {

    // throw(error);
    // console.log("we have an error!", error, status, xhr);
    if (evt.currentTarget.id === 'approve_registration_unverified') {
      //      console.log(xhr.responseText);
      return;
    }
    // console.log(xhr.responseText);
    var errors = JSON.parse(xhr.responseText);
    // try {
    //
    // } catch (err) {
    //   throw(error);
    //   // console.log(err, xhr.responseText);
    // }

    var error_list = $('<ul></ul>');
    $('span.error_for_field').remove();
    $('input.field_with_errors').removeClass('field_with_errors');
    $.each(errors, function (key, value) {
      if (key == 'base') {
        error_list.append($('<li>' + value.join(', ') + '</li>'));
      } else {
        $(value).each(function () {
          var errString = '' + $.humanizeString(key) + ' ' + this;
          error_list.append($('<li>' + errString + '</li>'));
          var offending_field = $('#user_' + key);
          offending_field.addClass('field_with_errors');
          var error_field = $('span.error_for_field.' + key);
          if (error_field.length > 0) {
            error_field.html(value.join(', '));
          } else {
            if (offending_field.parent('label').length > 0) {
              // This fixes check boxes and radio buttons within a label.
              // Append to the end of the label instead of after the input field, in this case, a checkbox.
              offending_field.parent('label').append($('<span class=\'error_for_field ' + key + '\'>' + value.join(', ') + '</span>'));
            } else {
              offending_field.after($('<span class=\'error_for_field ' + key + '\'>' + value.join(', ') + '</span>'));
            }
          }
        });
      }
    });
    var element = $(evt.target);
    var scroll_parent = element.find('div.modal-body');
    if (errors) {
      scroll_parent.animate({ scrollTop: 0 }, 400);
    }
    var html = '<div id="alert-error" class="flash-error alert flash modal_flash_padding">';
    html = html + '<div class="flash-left"><i class="icon-error large"></i></div>';
    html = html + '<div class="flash-right"><strong>Error</strong><br>'+ error_list.html() +'</div>';
    html = html + '<a href="#" data-dismiss="alert" class="alert-dismiss">DISMISS</a>';
    html = html + '<div class="clearfix"></div></div>';
    $('#alert-error').remove();
    scroll_parent.prepend(html);
    $('.flash').each(function () {
      Flash.new_with_element(this);
    });
  });


export const blank_address_card = function (id) {
  var card_name = 'Address Card';
  if (id != undefined && id == 'pharmacy_search_form') {
    card_name = 'Pharmacy';
  }

  var card = $('div[data-detail-card]');
  if (card.attr('data-blank-template')) {
    card_name = card.data('blank-template');
  }

  $('div#address_card').html('<div id="blank_card">' + card_name + '</div>');
  // card.html('<div class="blank">' + card_name + '</div>');
};

window.blank_address_card = blank_address_card;

export const update_address_card = function(row_object) {
  var url = row_object.attr('data-address-card-url');
  var promise = $.Deferred();

  if(url == null) return;

  $('#registration-spinnah').show();
  $('div#address_card, div[data-detail-card]').load(url, function (response, status, request) {
    $('#registration-spinnah').hide();
    $('div#address_card, div[data-detail-card]').attr('data-refresh-url', url);
    $.event.trigger({type: 'address_card:updated', card: $('div#address_card, div[data-detail-card]')});

    let cardSelected = $('#card-header__heading').text();
    x508.say(cardSelected + ' details listed below table');

    InputsHelper.mask_country_code_of_mobile_phone();
    InputsHelper.mask_ssn_number();

    $('*[data-has-popover]').popover();

    FileUploadHelper.event_listeners();
    FileUploadHelper.plug_upload_event();

    // Set Option for uploading files remotely after clicking on view link
    $(document).find('.uploading_multiple_documentation_files').fileupload({
      url: '/request_documentations',
      autoUpload: true,
      formData: {
        'request_documentation_type': $(document).find('.request_documentation_type').val(),
        'request_documentation_id': $(document).find('.request_documentation_id').val(),
      },
      add: function(e, data) {
        $(document).find('.uploading_multiple_documentation_files').value = '';
        var file;
        file = data.files[0];
        data.context = $(tmpl('template-upload', file));
        $('.upload_documentations').append(data.context);
        return data.submit();
      },
      done: function (e, data) {
        $(document).find('.uploading_multiple_documentation_files').value = '';
        $(document).find('.template-upload').remove();

        var file;
        file = data.files[0];
        file['errors'] = data.result.documentation_errors;

        if (file['errors'] == null) {
          file['created_date'] = data.result.DoC;
          file['documentation_url'] = data.result.documentation_url;
          file['documentation_id'] = data.result.documentation_id;
        }

        data.context = $(tmpl('template-download', file));
        $('.upload_documentations').append(data.context);
      }
    });

    promise.resolve();
  });

  return promise.promise();
};

export const refresh_address_card = function () {
  var url = $('div#address_card, div[data-detail-card]').attr('data-refresh-url');
  $('div#address_card, div[data-detail-card]').load(url, function (response, status, request) {
    $.event.trigger({ type: 'address_card:updated', card: $('div#address_card, div[data-detail-card]') });
  });
};

window.refresh_address_card = refresh_address_card;

export const adulterate_user_row = function (id, class_name) {
  // jquery will only animate the row blinding up if I do the cells and the row... I wish we were using scripty.
  var row = $('#search_result_row_user_id_' + id);
  var cells = row.children('td');
  $(row).animate({
    height: '0px'
  }, {
    duration: '1500',
    complete: function () {
      row.remove();
    }
  });
  $(cells).slideUp(150, function () {
    StaticTableHeaders.tables_need_update();
  });
};

export const adulterate_address_card = function(class_name) {
  var card_toolbar = $('#address_card_toolbar');
  card_toolbar.addClass(class_name);
};

export const approve_user = function (id) {
  adulterate_user_row(id, 'approved');
  adulterate_address_card('approved');
  $('#approve_user_button').popover('hide');
};

window.approve_user = approve_user;

export const fail_to_approve_user = function (errors) {
  var content = '';
  $.each(errors, function (key, value) {
    content = content + value + ' ';
  });

  var html = '<div class=\'popover_error\'>' + content + '</div>';
  $('#approve_user_button').popover({
    // html:"OH HAI",
    placement: 'top',
    trigger: 'manual',
    title: 'Approval Failed',
    content: html,
    html: true
  }).popover('show');
  // alert("The user '"+user.first_name+"' could not be approved.");
};

window.fail_to_approve_user = fail_to_approve_user;

export const reject_user = function (id) {
  adulterate_user_row(id, 'rejected');
  adulterate_address_card('rejected');
  $('#approve_user_button').popover('hide');
};

window.reject_user = reject_user;

export const update_user_with_id = function (id) {
  var row_to_replace = $('#search_result_row_user_id_' + id);
  var refresh_url = row_to_replace.attr('data-refresh-url');
  $.get(refresh_url, function (data) {
    row_to_replace.replaceWith(data);
    var replaced_row = $('#search_result_row_user_id_' + id);
    update_address_card(replaced_row);
    replaced_row.addClass('info');
  });
};
