export const InputsHelper = {
  mask_country_code_of_mobile_phone: function() {
    var mobile_phone_input;
    mobile_phone_input = $(document).find('.mask-mobile-phone-number');
    if (mobile_phone_input === null) {
      return;
    }
    if (mobile_phone_input.data('country-code') === 'US') {
      return mobile_phone_input.mask('(999) 999-9999');
    } else if (mobile_phone_input.data('country-code') === 'PL') {
      return mobile_phone_input.mask('999-999-999');
    }
  },

  mask_ssn_number: function() {
    var ssn_number_input;
    ssn_number_input = $(document).find('.mask-ssn-number');
    if (ssn_number_input === null) {
      return;
    }
    ssn_number_input.mask('****');
  }
};
