var FilterableNodes = (function () {
  var DISPOSAL_METHOD_CLONE = 'clone';
  function initialize (options) {
    var instance = new Object();
    instance.filter_control_node = $(options.filter_control);
    instance.button_node = instance.filter_control_node.find('.btn');
    instance.input_node = instance.filter_control_node.find('input');
    instance.filter_group_name = instance.filter_control_node.data('filter-control');
    instance.filterable_nodes_container = $('*[data-filterable="'+instance.filter_group_name+'"]');
    instance.filterable_nodes = instance.filterable_nodes_container.find('*[data-filterable-node]');
    instance.disposal_method = instance.filter_control_node.data('filterable-disposal-method');
    instance.filter_mode = instance.filter_control_node.data('filter-mode');
    instance.disposal_method_is_clone = function () {
      return (instance.disposal_method === DISPOSAL_METHOD_CLONE);
    };

    if (instance.disposal_method_is_clone()) {
      instance.cloned_container = instance.filterable_nodes_container.clone(false, false);
      instance.cloned_container.empty();
      $('body').append(instance.cloned_container);
      instance.cloned_container.hide();
      // console.log(instance);
    }


    instance.filter = function (string) {
      if (instance.disposal_method_is_clone()) {
        instance.reset_nodes();
        instance.filterable_nodes.each(function () {
          var node = $(this);
          if (instance.filter_mode == 'starts-with') {
            var regex = new RegExp('^' + string, 'mi');
          } else {
            var regex = new RegExp(string, 'mi');
          }
          if (node.parent().data('filterable') ) {
            if (node.text().match(regex)) {
              instance.filterable_nodes_container.append(node);
            } else {
              instance.cloned_container.append(node);
            }
          }

        });
      } else {
        instance.filterable_nodes.each(function () {
          var node = $(this), regex = new RegExp(string, 'mi');
          if (node.text().match(regex)) {
            node.show();
          } else {
            node.hide();
          }
        });
      }

      return;
    };

    instance.reset_nodes = function () {
      // console.log('resetting');
      if (instance.disposal_method_is_clone()) {
        // var clone = instance.cloned_container.clone(true, true).show(0);
        // instance.filterable_nodes_container.replaceWith(clone);
        // instance.filterable_nodes_container = clone;
        // instance.filterable_nodes.empty();
        instance.cloned_container.find('*[data-filterable-node]').each(function () {
          var item = $(this);
          instance.filterable_nodes_container.append(item);
        });
      } else {
        instance.filterable_nodes.each(function () {
          var node =$(this);
          node.show();
        });
      }

    };

    instance.is_active = function () {
      return instance.button_node.hasClass('active');
    };

    instance.button_node.on('click', function (event) {
      event.preventDefault();
      //timeout needed because otherwise we query the state of the button before it changes!
      setTimeout(function () {
        // console.log('is active?', instance.is_active());
        if (instance.is_active()) {
          instance.filter(instance.input_node.val());
          instance.input_node.trigger('focus');
        } else {
          instance.reset_nodes();
        }

      },1);
    });

    instance.input_node.on('keyup', function (event) {
      if (event.keyCode === 13) {
        instance.button_node.addClass('active');
      }
      if (instance.is_active()) {
        instance.filter(instance.input_node.val());
      }
    });

    $(document).on('Multilist:added_to_destination', function () {
      if (instance.is_active()) {
        instance.filter(instance.input_node.val());
      }
    });

    return instance;
  }

  return {
    initialize: initialize,
  };
}());
var test;
$(function () {
  setTimeout(function () {
    $('*[data-filter-control]').each(function () {
      test = FilterableNodes.initialize({filter_control:this});
    });
  }, 10);

});
