var MultiList = (function ($) {

  function instantiate(object, key) {
    var client = $(object['client']),
      server = $(object['server']),
      key = key.trim();

    function clear_blank_items_from_list(object) {
      object.find('option').each(function () {
        var option = $(this);
        if (option.val() === '') {
          option.remove();
        }
      });
    }
    //just in case.
    clear_blank_items_from_list(client);

    function move_roles(source,destination) {
      var items = source.find('option');
      destination.append(items);
      $(destination).trigger('Multilist:added_to_destination', {items:items, source:source, destination:destination});
    }

    function move_selected_roles(source,destination) {
      var items = source.find('option').filter(':selected');
      destination.append(items);
      $(destination).trigger('Multilist:added_to_destination', {items:items,source:source, destination:destination});
    }

    function add_all_roles() {
      move_roles(server, client);
    }

    function remove_all_roles() {
      move_roles(client, server);
    }

    function add_selected_roles() {
      move_selected_roles(server, client);
    }

    function remove_selected_roles() {
      move_selected_roles(client, server);
    }

    function theRoleActionSelector(roleAction) {
      return '[data-selector="' + key + roleAction + '"]';
    }

    $(document).on('click', theRoleActionSelector('.add'), add_all_roles);
    $(document).on('click', theRoleActionSelector('.remove'), remove_all_roles);
    $(document).on('click', theRoleActionSelector('.selected.add'), add_selected_roles);
    $(document).on('click', theRoleActionSelector('.selected.remove'), remove_selected_roles);

    client.closest('form').on('submit', function () {
      client.find('option').prop('selected', true);
    });
  }

  function initialize(id, key) {
    var server, client;
    $('*[data-multilist-id='+id+']').each(function () {
      var node = $(this);
      if (node.data('multilist-role') === 'client') {
        client = node;
      } else {
        server = node;
      }
    });
    instantiate({server:server, client:client}, key);
  }
  return {initialize:initialize};
})($);

$(function () {
  MultiList.initialize('announcements', 'roles');
  MultiList.initialize('announcements_area', 'area');
});

