'use strict';

var flagForInvestigationSwitch = (function($, undefined){

  var locator = 'input#switch_flagged_for_investigation';
  var switch_wraper_id = '#casper_report_title_flag_for_investigation_switch';

  var changeFlagOnServer = function(event, state) {
    disableSwitch();
    var control = $(this);
    var report_id = control.data('report-id');
    var report_type = control.data('report-type');

    var uri = '/flag_for_investigation/'+ report_type + '/' + report_id + '/' + state.toString();
    $.ajax(
      {
        type:'POST',
        url: uri,
        success: function() {
          enableSwitch();
        },
        error: function(xhr) {
          if(xhr.status == 422) {
            alert(xhr.responseText);
          } else {
            alert('Unable to change flag report at this time.\nPlease reload page and try again.\nError Info: '+xhr.responseText+'');
          }
        }
      }
    );
  };


  var enableSwitch = function() {
    $(locator).bootstrapSwitch('disabled',false);
  };

  var disableSwitch = function() {
    $(locator).bootstrapSwitch('disabled',true);
  };

  var createSwitch = function() {
    $(locator).bootstrapSwitch({
      'onText': 'Active',
      'offText': 'Off',
      'labelText': 'Investigation',
      'onColor': 'danger'
    });
  };

  var init = function() {
    createSwitch();
    $(switch_wraper_id).attr('title','Setting this report flag to "ACTIVE" Investigation will exclude it (including its contents) from scheduled automatic removal of data from the system. These flagged reports will display in Reports History with the Investigation column marked as "Active".');
    $(switch_wraper_id).tooltip();
    $(locator).on('switchChange.bootstrapSwitch', changeFlagOnServer);
  };

  return {
    init: init
  };
})(jQuery);


$(function() {
  flagForInvestigationSwitch.init();
});
