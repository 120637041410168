$(function(){
  $('#case_management_search')
    .on('ajax:beforeSend', function(evt, xhr, settings){
      $('#spinnah').show(100);
    })
    .on('ajax:success', function(evt, data, status, xhr){
      $('#results').html(xhr.responseText);
    })
    .on('ajax:complete', function(evt, xhr, status){
      $('#spinnah').hide(100);
    })
    .on('ajax:error', function(evt, xhr, status, error){
      $('.scrolltable').html(xhr.responseText);

      alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
      $(document).trigger('search:failed');
    });

  $(function() {
    $('#case-document-upload').on('click', function() {
      $('#case-document-upload-field').trigger('click');
    });

    $(document).on('click', '.remove-case-document', function(){
      $(this).siblings('.case-document-destroy-gif').removeClass('hidden');
      $(this).remove();
    });

    $('form').on('change', '#case-document-upload-field', function() {
      var form = $('#new-case-document');
      var formData = new FormData();
      formData.append('document', $('#case-document-upload-field')[0].files[0]);
      $.ajax({
        type: 'POST',
        url: form.attr('action'),
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(){
          $('#case-document-upload').attr('disabled', true);
          $('#case-document-upload-gif').removeClass('hidden');
        },
        complete: function(){
          $('#case-document-upload').attr('disabled', false);
          $('#case-document-upload-gif').addClass('hidden');
        }
      });
    });
  });

  $(document).on('click', '.cm-reset-btn', function(e) {
    e.preventDefault();
    var form = $('#case_management_search');
    form.trigger('reset');
    $('#case_management_search input[type=\'text\'], input[type=\'checkbox\'], select').each(function() {
      this.value = '';
    });
    form.trigger('submit');
    return false;
  });

  $(document).on('click', '.cm-show-filters a', function(e) {
    e.preventDefault();
    $(this).find('i').toggleClass('cm-arrow-up').toggleClass('cm-arrow-down');
    var filter_div = $('#case_management_search_options');
    var filter_text = $(this).find('.cm-filters-text');
    var show_filters_input = $('#show_filters');
    if(filter_div.hasClass('collapse')) {
      filter_text.text('Hide Filters');
      show_filters_input.val('1');
    } else {
      filter_text.text('View Filters');
      show_filters_input.val('');
    }
    filter_div.collapse('toggle');
    return false;
  });

  $(document).on('click', 'input[type=\'submit\'].submit-new-case', function(e) {
    $('form#new_prescriber_activity_case_report, form#new_dispenser_activity_case_report, form#new_investigative_patient_case_report').submit();
    $('.activity-spinner .indeterminate_progress').show();
    $('input[type=\'submit\'].submit-new-case').prop('disabled', true);
  });
});
