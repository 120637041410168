
$(document).on('click', '#add_npi_number', function(){
  button = $(this);
  uri = button.data('url');
  npi_number = $('#user_national_provider_id').val();
  data = { npi_number: npi_number };

  $.ajax({url: uri, data: data}).done();
});

$(document).on('click', '.remove_npi_number', function(){
  $('#npi-number-table').html('');
  $('#user_national_provider_id').val('');
});
