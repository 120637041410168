var TrainingModal = (function() {
  function setup() {
    // Don't bother running if it's not on the page with the training modal
    if ($('#training-modal')[0] === undefined) { return; }

    $('#training-modal').modal('show');

    // Don't cycle the slides automatically
    $('.carousel').carousel({interval: false});

    // For some reason, the carousel indicators aren't changing their active status on their own, so I'm changing it manually.
    $('.carousel').on('slid.bs.carousel', function() {
      var to_slide = $('.carousel-inner .carousel-item.active').data('slide-no');
      $('.carousel-indicators').children().removeClass('active');
      $('.carousel-indicators [data-slide-to=' + to_slide + ']').addClass('active');

      // If the current slide is a final slide, change the Next button to Finish
      if (to_slide === $('[data-final-slide]').data('final-slide')) {
        $('#next-training-page').html('Continue to AWARxE');
        $('#next-training-page-wrapper').removeClass('col-4');
        $('#skip-training-page-wrapper').hide();
      } else {
        $('#next-training-page-wrapper').addClass('col-4');
        $('#next-training-page').html('Next');
        $('#skip-training-page-wrapper').show();
      }

      if (to_slide > 0) {
        $('#prev-training-page').show();
      } else {
        $('#prev-training-page').hide();
      }
    });

    // If the button is a Finish button, close the modal. If it's a next button, slide to next
    $('#next-training-page').on('click', function(evt) {
      if (evt.target.innerHTML === 'Continue to AWARxE') {
        evt.preventDefault();
        var finish_path = $(evt.target).data('finish-path');
        $.ajax(finish_path).done(function() {
          $('#training-modal').modal('hide');
        });
        return;
      }
      $('.carousel').carousel('next');
    });
    $('#prev-training-page').on('click', function() { $('.carousel').carousel('prev'); });
    $('#skip-training-page').on('click', function(evt) {
      evt.preventDefault();
      var finish_path = $(evt.target).data('finish-path');
      $.ajax(finish_path).done(function() {
        $('#training-modal').modal('hide');
      });
    });

    // Load the content
    $('.training-page').each(function(idx, elem) {
      $.ajax($(elem).data('path')).done(function(data) {
        $(elem).html(data);
      });
    });
  }

  return {
    setup:setup
  };
})();

$(function() {
  TrainingModal.setup();
});
