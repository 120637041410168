$(function() {
  // Set some options for the drug categories picker
  $('#drug_categories').selectpicker({
    size: 4,
    showTick: true,
    tickIcon: 'icon-ok',
    noneSelectedText: 'Select to add multiples',
    selectedTextFormat: 'static',
    title: 'Select to add multiples',
    iconBase: 'fa',
    tickIcon: 'fa-check'
  });

  $('#drug_categories').on('changed.bs.select', function(e, clickedIndex, optionIsSelected, oldValue) {
    // Variables for handling a new option being selected from the dropdown
    let selectedOptionText;
    let selectedOptionValue;
    let allSelectedValues;

    // Variable for handling deleting the customRemoveElement of a previously selected option
    let theOptionBeingDeselected = e.currentTarget[clickedIndex] ? e.currentTarget[clickedIndex].value : null;

    if (optionIsSelected) {
      selectedOptionText = this.options[clickedIndex].text;
      selectedOptionValue = this.options[clickedIndex].value;
      var customRemoveElement = $('<div class="remove_drug_category" data-option-value="' + selectedOptionValue + '">' + selectedOptionText + '<i class="fa fa-remove float-right"></i></div>');
      $('#selected_drug_categories').append(customRemoveElement);

      customRemoveElement.on('click', function() {
        allSelectedValues = $('#drug_categories').selectpicker('val');
        allSelectedValues.remove(allSelectedValues.indexOf(customRemoveElement.data('option-value')));
        $('#drug_categories').selectpicker('val', allSelectedValues);
        customRemoveElement.remove();
      });

    } else if (theOptionBeingDeselected) {
      $('.remove_drug_category[data-option-value=' + theOptionBeingDeselected + ']').remove();
    }
  });
});
