import { x508 } from '../app/x508';

function ArrayWithAdditions() {
  var arr = Array.apply(null, arguments);
  arr.each_pair = function (iteration) {
    var one = arr[0], two = arr[1];
    for (var i = one.length - 1; i >= 0; i--) {
      var item = one[i], item2 = two[i];
      iteration(item, item2);
    }
  };

  return arr;
}

export const StaticTableHeaders = (function ($) {
  var table_timeout_id = null;
  // If this method is called rapidly, it will coalesce the requests and
  // only perform a single update once, since updates are expensive.
  function tables_need_update(table) {
    clearTimeout(table_timeout_id);
    table_timeout_id = setTimeout(function () {
      $(document).trigger('table_updated');
    }, 300);
  }

  var init = function (table) {
    var table_head = table.find('thead'),
      y_scroll_container = table.parent(),
      relative_container = y_scroll_container.parent(), // holds cloned header
      x_scroll_container = relative_container.parent(),
      cloned_header = null,
      table_id = Math.floor((Math.random() * 1000000000) + 1);

    // Copies the appearance of one element to another
    function sync_style() {
      table_head.css('visibility', 'visible');

      two_dimensional_cell_matrix.each_pair(function (original_cell, cloned_cell) {
        var $original_cell = $(original_cell), $cloned_cell = $(cloned_cell);
        $cloned_cell.data('original-cell', $original_cell);
      });
      table_head.css('display', 'none');

    }

    // setup the vertical scroll container
    function setup_y_scroll_container(kontainer) {

      var proper_cloned_width = [];
      table_head.find('th').each(function (index) {
        proper_cloned_width[index] = $(this).outerWidth();
      });

      cloned_header.find('th').each(function(index) {
        $(this).css({
          'width': proper_cloned_width[index]
        });
      });

    }

    // Setup the horizontal scroll container
    function setup_x_scroll_container(kontainer) {

      kontainer.css({
        'overflow-x':'auto',
        'overflow-y':'hidden'
      });
    }

    // I know this is drrrrrty
    function is_not_ie_8() {
      if (/MSIE 8/.test(navigator.userAgent)) {
        return false;
      } else {
        return true;
      }
    }

    function should_fix_headers() {
      var contains_scroll_area = (table.data('sticky-table-headers') === true),
        has_y_scroll = y_scroll_container.prop('offsetHeight') < y_scroll_container.prop('scrollHeight'),
        already_sticky = y_scroll_container.parent().hasClass('static_table_header_wrapper');


      return contains_scroll_area && has_y_scroll && is_not_ie_8() && !already_sticky;
    }

    if (should_fix_headers()) {
      // Let's ensure that we have the table wrapped in some divs so we
      // can control horizontal and vertical scrolling seperately, and
      // to give the static header a place to live
      y_scroll_container.wrap('<div class=\'static_table_header_wrapper\' />');
      relative_container = y_scroll_container.parent();

      relative_container.wrap('<div class=\'x_scroll_table_wrapper\' />');
      x_scroll_container = relative_container.parent();

      // We clone the table header and it's events
      cloned_header = table_head.clone(true, true);

      // We need to copy over some values that aren't copied over
      // in the deep clone for whatever reasone. Tablesorter breaks
      // without these defined.
      cloned_header.children().children().toArray().forEach( (item, idx) => {
        item.column = table_head.children().children()[idx].column;
        item.count = table_head.children().children()[idx].count;
        item.order = table_head.children().children()[idx].order;
        item.sortDisabled = table_head.children().children()[idx].sortDisabled;
      });

      // table_head.css('visibility', 'hidden');
      // We assign the clone a unique id and append it to the container to anchor it
      cloned_header
        .attr('id', 'clone_' + table_id)
        .addClass('cloned-header');

      /*
      * Ideally, we should be prepending the cloned thead element
      * within the table itself. However, this is a legacy decision that
      * will need to be solved at a later time. - Tyler Akin, 5/20
      */

      relative_container.css({
        'position': 'relative'
      });

      table.prepend(cloned_header); // -- TODO, implement this line

      // relative_container.prepend(cloned_header);

      // We need to set the container's position relative so that the absolutely
      // positioned clone can float and appear static
      cloned_header = relative_container.find('#clone_' + table_id);

      // Let's grab all the cells for further manipulation
      var cloned_cells = cloned_header.find('th'),
        original_cells = table_head.find('th'),
        two_dimensional_cell_matrix = new ArrayWithAdditions(original_cells, cloned_cells);

      // Setup the x and y scroll containers so we can have the static header
      // scroll horizontally but not vertically
      setup_x_scroll_container(x_scroll_container);
      setup_y_scroll_container(y_scroll_container);

      // We copy the style object of the original header to the clone
      // This ensures that it always has the same appearance, cell widths, etc
      sync_style();


      // for tables that use tablesorter
      table.on('sortEnd', function (e, element) {
        sync_style();
      });

      // Finally, absolutely position the cloned header
      var rightOffset = y_scroll_container.width() - y_scroll_container.prop('scrollWidth');

      // Whenever a table updates it's data, either from sorting or infinite
      // pagination, we need to rejigger the clone's appearance incase the
      // table reflows. This also means we need to do any math to the scroll
      // containers to make sure they still fit(this needs to happen every time
      // the table width changes)

      var sync_header_with_changed_table = function () {
        table.trigger('update');
        sync_style();
        setup_x_scroll_container(x_scroll_container);
        setup_y_scroll_container(y_scroll_container);
      };

      $(document).on('table_updated', sync_header_with_changed_table);
      //this *should* work for every browser except IE10- but we aren't supporting that anymore right?
      //... RIGHT?
      if (typeof(window.MutationObserver) === 'function') {
        var table_change_observer = new MutationObserver(sync_header_with_changed_table);
        table_change_observer.observe(table[0], {
          childList: true,
          subtree: true
        });
      }
    }


  };

  return {
    setup: init,
    tables_need_update: tables_need_update
  };
})(jQuery);


$(document).on('ready, ajaxSuccess', function () {
  $('table').each(function () {
    var table = $(this);
    StaticTableHeaders.setup(table);
    x508.handle_button_role_down(table);
  });
});
