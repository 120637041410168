import { Flash } from '../app/flash';

$(function() {
  $(function() {
    $('.select2-bootstrap-group-select').select2({ theme: 'bootstrap', placeholder: 'Select Roles or Role Types', width: '85%' });

    setup_mapping_table();
    register_field_mapping_refresh('sftp_validation');
    register_field_mapping_refresh('web_service_validation', 'request');
    register_field_mapping_refresh('web_service_validation', 'response');

    check_selected_mappings('license_status', '.license_active_tokens');
    check_selected_mappings('license_expires_on', '.license_allow_expired');
    check_selected_mappings('disciplinary_action_status', '.disciplinary_action_input');
    show_send_last_name_to_pending();

    $(document).on('change', '.mapped-aware-field-select select', function(){
      check_selected_mappings('license_status', '.license_active_tokens');
      check_selected_mappings('disciplinary_action_status', '.disciplinary_action_input');
      check_selected_mappings('license_expires_on', '.license_allow_expired');
    });

    $(document).on('click', '.add-active-token', function(e){
      e.preventDefault();
      const attr_klass = this.dataset.attrKlass;
      $('.license_active_tokens .active-tokens').append(`<div class='active-token form-row'><div class='col-3'><div class='form-group'><div class='input-group'><input class='form-control form-control-sm' type='text' name='validation_configuration[${attr_klass}_attributes][active_tokens][]' value='' /><div class='input-group-append'><a class='remove-active-token btn btn-sm btn-link font-weight-normal' rel='nofollow'>Remove</a></div></div></div></div>`);
    });

    $(document).on('click', '.remove-active-token', function(e){
      e.preventDefault();
      $(this).parents('.active-token').remove();
    });

    $(document).on('click', '.add-disciplinary-status', function(e){
      e.preventDefault();
      const attr_klass = this.dataset.attrKlass;
      $('.disciplinary_action_input .disciplinary-status-pass-tokens').append(`<div class='disciplinary-status-pass-token form-row'><div class='col-3'><div class='form-group'><div class='input-group'><input class='form-control form-control-sm' type='text' name='validation_configuration[${attr_klass}_attributes][disciplinary_status_pass_tokens][]' value='' /><div class='input-group-append'><a class='remove-disciplinary-status btn btn-sm btn-link font-weight-normal' rel='nofollow'>Remove</a></div></div></div></div>`);
    });

    $(document).on('click', '.remove-disciplinary-status', function(e){
      e.preventDefault();
      $(this).parents('.disciplinary-status-pass-token').remove();
    });

    $(function() {
      $('#validation_configuration_web_service_validation_attributes_web_service_configuration_id').on('change', function() {
        $('#base_url').text($('#base_urls').data(this.value));
      }).trigger('change');
    });

    $('#step-one #review_configuration').on('click', function() {
      if (valid_form()) { return submit_for_review(); }
    });

    $('input[name="validation_configuration[validation_type]"]').on('change', function() {
      toggle_validation_components(this.value);
    });

    $('#validation_configuration_sftp_validation_attributes_file_format').on('change', function() {
      if (this.value === 'delimited') {
        $('#validation_configuration_sftp_validation_attributes_data_separator').val('comma');
        $('.sftp-data-separator').toggleClass('hidden');
      } else {
        $('.sftp-data-separator').addClass('hidden');
      }
    });


    $('#option_group_four').on('change', function() {
      show_send_last_name_to_pending();
    });

    $('input[name="validation_configuration[sftp_validation_attributes][create_shell_account]"]').on('change', function() {
      let option_fields;
      const selects = $('[id^="validation_configuration_sftp_validation_attributes_field_mappings_attributes_"][id$="_user_field"]');
      const reuse_sftp_dir = $('#mapping_configurations').data('reuse-sftp-dir');

      if (this.value === 'true') {
        option_fields = $('#mapping_configurations').data('user-fields');
        for (let select of selects) {
          (function() {
            if ($(`#${select.id} option:disabled`).length < 1) {
              add_select_options_to(select, option_fields, true);
            }
          })();
        }
        if (!reuse_sftp_dir) {
          $('#sftp_validation_field_mappings td:nth-child(5), #sftp_validation_field_mappings th:nth-child(5)').show();
        }
        $('#shell_account_create_note').show();
      } else {
        option_fields = $('#mapping_configurations').data('validated-fields');
        for (let select of selects) {
          (function() {
            if ($(`#${select.id} option:disabled`).length < 1) {
              add_select_options_to(select, option_fields, true);
            }
          })();
        }
        if (!reuse_sftp_dir) {
          $('#sftp_validation_field_mappings td:nth-child(5), #sftp_validation_field_mappings th:nth-child(5)').hide();
        }
        $('#shell_account_create_note').hide();
      }
    });

    $('#license_file').on('change', function() {
      $('.license-file-preview').hide();
      if (!this.files) { return; }
      if (!this.files[0]) { return; }
      let file_type = this.files[0].type;
      if (!file_type) { file_type = this.files[0].name.split('.')[1]; }
      if (!is_a_supported_type(file_type)) {
        Flash.error('<ul><li>Only csv, txt, dat and excel(.xlsx) files are allowed</li></ul>');
      }
    });

    $('#validation_configuration_sftp_validation_attributes_license_file_attributes_name').on('focusout', () => prompt_for_upload_dir_reuse());

    $(document).on('click', '#web_service_request_sample_submit', () => submit_ws_data_sample('request'));

    $(document).on('click', '#web_service_response_sample_submit', () => submit_ws_data_sample('response'));
  });


  // Sets up the options in the field mappings table selects after initial file upload and show or hide validated column
  var setup_mapping_table = function() {
    let option_fields;
    const selects = $('[id^="validation_configuration_"][id$="_user_field"]');
    const reuse_sftp_dir = $('#mapping_configurations').data('reuse-sftp-dir');

    if ($('#create_shell_account_true:checked').val() === 'true') {
      option_fields = $('#mapping_configurations').data('user-fields');
      if (!reuse_sftp_dir) {
        $('#sftp_validation_field_mappings td:nth-child(5), #sftp_validation_field_mappings th:nth-child(5)').show();
        $('#web_service_validation_request_field_mappings td:nth-child(5), #web_service_validation_request_field_mappings th:nth-child(5)').show();
        $('#web_service_validation_response_field_mappings td:nth-child(5), #web_service_validation_response_field_mappings th:nth-child(5)').show();
      }
    } else {
      option_fields = $('#mapping_configurations').data('validated-fields');
      if (!reuse_sftp_dir) {
        $('#sftp_validation_field_mappings td:nth-child(5), #sftp_validation_field_mappings th:nth-child(5)').hide();
        $('#web_service_validation_request_field_mappings td:nth-child(5), #web_service_validation_request_field_mappings th:nth-child(5)').hide();
        $('#web_service_validation_response_field_mappings td:nth-child(5), #web_service_validation_response_field_mappings th:nth-child(5)').hide();
      }
    }

    for (let select of selects) {
      (function() {
        setup_select_listener(select);
        if ($(`#${select.id} option:disabled`).length < 1) {
          add_select_options_to(select, option_fields, true);
        } else {
          $(select).trigger('change');
        }
      })();
    }
  };

  var setup_select_listener = function(select) {
    const validated_fields = $('#mapping_configurations').data('validated-fields');
    $(select).on('change', function() {
      let check_box = $(select).closest('tr').children('td:nth-child(5)').children('[id^="validated_indicator_"]');
      if (check_box.length > 0) {
        $(check_box).prop('checked', validated_fields.includes(this.value));
      } else {
        check_box = $(select).closest('tr').children('td:nth-child(5)').children('[id$="_validated"]');
        check_box.prop('disabled', !validated_fields.includes($(select).val()));
      }
    });
  };

  var show_send_last_name_to_pending = function() {
    if ($('#option_group_five').length > 0) {
      let selected = $('#option_group_four input[type=\'radio\']:checked').val();
      if (selected === 'true') {
        $('#option_group_five').show();
      } else {
        $('#option_group_five').hide();
      }
    }
  }

  var submit_ws_data_sample = function(sample_type) {
    const formData = new FormData();
    formData.append('sample_data', $(`#validation_configuration_web_service_validation_attributes_${sample_type}_sample`).val());
    formData.append('sample_type', sample_type);
    $.ajax({
      url: '/system/admin/process_web_service_sample_data',
      method: 'POST',
      data: formData,
      contentType: false,
      processData: false
    }).done(() => register_field_mapping_refresh('web_service_validation', sample_type));
  };

  var check_selected_mappings = function(search, selector) {
    const vals = $('.mapped-aware-field-select select').map(function() {
      return this.value;
    });

    if ($.inArray(search, vals) >= 0) {
      $(selector).show();
    } else {
      $(selector).hide();
    }
  };

  var is_a_supported_type =function(file_type) {
    const iterable = ['csv', '(.)+.ms-excel', 'txt', 'text', 'dat', '(.)+.spreadsheetml.sheet'];
    for (let i = 0; i < iterable.length; i++) {
      const type = iterable[i];
      if (file_type.match(type)) { return true; }
    }
    return false;
  };

  var toggle_validation_components = function(validation_type) {
    const current_component_id = `#${validation_type}_validation`;
    const all_components = ['#dea_validation', '#employer_dea_validation', '#npi_validation', '#sftp_validation', '#web_service_validation'];
    const components_to_disable = all_components.filter(x => x !== current_component_id);
    $(components_to_disable.join(',')).hide();
    for (let component_id of components_to_disable) {
      $(`${component_id} *`).attr('disabled', true);
    }

    $(current_component_id).fadeIn(500);
    $(`${current_component_id} *`).attr('disabled', false);
  };

  var register_field_mapping_refresh = function(validation_type, mapping_type) {
    let mapping_table_id = validation_type;
    if ((mapping_type != null) && (mapping_type !== '')) { mapping_table_id += `_${mapping_type}`; }
    mapping_table_id += '_field_mappings';
    $(`#${mapping_table_id} select[id*='_user_field']`).on('change', function() {
      const table_id = this.closest('table').id;
      const base_path = this.id.match(/([^.]+)_user_field$/)[1];

      const selected_user_field = this.options[this.selectedIndex].value;
      if (!selected_user_field) {
        refresh_field_mapping(table_id, base_path, { data_type: '', formats: '' });
        return;
      }
      const user_field_config = $('#mapping_configurations').data('by-user-field')[selected_user_field];
      refresh_field_mapping(table_id, base_path, user_field_config);
    });
    setup_mapping_table();
  };

  // using table_id namespace to account for two sets of field mappings visible simultaneously
  // web_service_validation_request[response]_field_mappings
  var refresh_field_mapping = function(table_id, base_path, user_field_config) {
    $(`#${table_id} #${base_path}_data_type`).val(user_field_config.data_type);
    const row_id = base_path.match(/.*attributes_([^.]+)$/)[1];
    $(`#${table_id} #data_type_${row_id}`).html(user_field_config.data_type);
    add_select_options_to(`#${table_id} #${base_path}_format`, user_field_config.formats);
    update_validated_flag(table_id, base_path);
  };

  var update_validated_flag = function(table_id, base_path) {
    const user_field_val = $(`#${table_id} #${base_path}_user_field option:selected`).val();
    const validated_fields = $('#mapping_configurations').data('validated-fields');
    if ((user_field_val != null) && (user_field_val !== '') && validated_fields.includes(user_field_val)) {
      $('input:hidden[id="' + base_path + '_validated"]').val('1');
    } else {
      $('input:hidden[id="' + base_path + '_validated"]').val('0');
    }
  };

  const valid_license_upload = function() {
    if ($('#license_file').val() === '') {
      Flash.error('License File is required to present file preview');
      return false;
    } else if (!$('input[name="validation_configuration[sftp_validation_attributes][license_file_attributes][file_has_headers]"]').is(':checked')) {
      Flash.error('Must answer if SFTP file has headers');
      return false;
    }
    return true;
  };

  this.handle_file_submit = function() {
    prompt_for_upload_dir_reuse();
    if (!valid_license_upload()) { return; }

    const formData = new FormData();
    formData.append('license_file', $('#license_file')[0].files[0]);
    formData.append('file_format', $('#validation_configuration_sftp_validation_attributes_license_file_attributes_file_format').val());
    formData.append('has_headers', $('input[name="validation_configuration[sftp_validation_attributes][license_file_attributes][file_has_headers]"]:checked').val());
    formData.append('separator', $('#validation_configuration_sftp_validation_attributes_license_file_attributes_data_separator').val());

    $.ajax({
      url: '/system/admin/process_sample_sftp_file',
      method: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      beforeSend() {
        $('#license_file_submit').off('click');
      }
    }).done(function() {
      $('#license_file_submit').on('click');
      register_field_mapping_refresh('sftp_validation');
      setup_mapping_table();
    });
  };

  this.clear_file_select = function() {
    $('#license_file').val('');
    $('.file_upload_file_name').val('');
    $('#sftp_license_file_preview').fadeOut(500);
    $('#sftp_validation_field_mappings').fadeOut(500);
    $('.sftp-field-mappings').hide();
  };

  var add_select_options_to = function(select_element, option_values, empty_option) {
    if (empty_option == null) { empty_option = false; }
    let options = [];
    if (empty_option) { options = ['<option/>']; }
    let selected_option = $(select_element).val();
    if (!option_values.includes(selected_option)) { selected_option = ''; }

    for (let i = 0; i < option_values.length; i++) {
      const option_val = option_values[i];
      options.push(`<option value='${option_val}'>${option_val}</option>`);
    }
    $(select_element).empty().append(options.join(''));
    $(select_element).val(selected_option).trigger('change');
  };

  // eslint-disable-next-line no-unused-vars
  const move_to_step =function(name) {
    $('.progress-circle').removeClass('in-progress-circle');
    $('.progress-label').removeClass('in-progress-label');
    $('.step-' + name + '.progress-circle').addClass('in-progress-circle');
    $('.step-' + name + '.progress-label').addClass('in-progress-label');
    $('[id^=\'step-\']').addClass('hidden');
    $('#step-' + name).removeClass('hidden');
  };

  // front-end validation for form fields
  const input_criteria_met =function(selector) {
    if (!$(selector).is(':visible')) { return true; }
    let valid_input = false;
    switch (selector) {
    case '#configuration_role_ids':
      if ($(selector).siblings('.select2-container').find('li.select2-selection__choice').length) { valid_input = true; }
      break;
    case '#license_file_name':
      if ($('#file_preview_validation_conf').is(':visible') || $('#sftp_validation_field_mappings').is(':visible')) { valid_input = true; }
      break;
    case '#sftp_validation_field_mappings':
      $('#sftp_validation_field_mappings tbody tr').each(function() {
        const aware_field_col = $(this.children[1]).find('select')[0];
        if (aware_field_col.options[aware_field_col.selectedIndex].value !== '') {
          valid_input = true;
          return false;
        }
      });
      break;
    case '#validation_configuration_sftp_validation_attributes_name':
      if ($(selector).val()) { valid_input = true; }
      break;
    case '#create_shell_account_true':
      var selects = $('[id^="validation_configuration_sftp_validation_attributes_field_mappings_attributes_"][id$="_user_field"]');
      if ($(`${selector}:checked`).val() === 'true') {
        for (let select of selects) {
          if ($(select).find(':selected').text() === 'email') {
            valid_input = true;
          }
        }
      } else {
        valid_input = true;
      }
      break;
    default:
      $(`${selector} input`).each(function() {
        if (this.checked) { return valid_input = true; }
      });
    }
    return valid_input;
  };

  const error_message_for =function(selector) {
    switch (selector) {
    case '#validation_types': return 'must select at least one type of Validation Process.';
    case '#configuration_role_ids': return 'must select at least one User Role';
    case '#license_file_name': return 'Please Submit the Test License File';
    case '#sftp_validation_field_mappings': return 'must complete at least one Field Mapping';
    case '#validation_configuration_sftp_validation_attributes_name': return 'Please provide a unique name for the SFTP rule';
    case '#create_shell_account_true': return 'must map email aware field when import creates shell accounts';
    case '.sftp-file-header-input': return 'must answer if SFTP file has headers';
    case '.sftp-append-records-input': return 'must answer if SFTP file append records';
    default: return 'must select at least one option from each Validation question.';
    }
  };

  // needed for dynamically updated form submit reliably as JS unlike form.submit()
  const submit_configuration =function(form) {
    let {
      method
    } = form[0];
    const method_override = $(`#${form[0].id} input:hidden[name='_method']`).val();
    if ((method_override !== undefined) && (method_override !== method)) { method = method_override; }
    $.ajax({
      type: method,
      url: form[0].action,
      data: form.serialize(),
      dataType: 'script'
    });
  };

  var submit_for_review = function() {
    prompt_for_upload_dir_reuse();
    $('#alert-error').hide();
    $('#submit_type').val('review');
    submit_configuration($('#step-one').closest('form'));
  };

  this.submit_form = function() {
    $('#alert-error').hide();
    $('#submit_type').val('save');
    submit_configuration($('#step-one').closest('form'));
  };

  var valid_form = () => validate_non_sftp_fields() && validate_sftp_fields();

  var validate_non_sftp_fields = () => validate(['#validation_types', '#option_group_one', '#option_group_two', '#option_group_three',
    '#option_group_four', '#option_group_five', '#configuration_role_ids']);

  var validate_sftp_fields = () => validate(['#license_file_name', '#create_shell_account_true', '#sftp_validation_field_mappings',
    '#validation_configuration_sftp_validation_attributes_name',
    '.sftp-file-header-input', '.sftp-append-records-input']);

  var validate =function(selectors) {
    let all_valid = true;
    for (let i = 0; i < selectors.length; i++) {
      const selector = selectors[i];
      if (!input_criteria_met(selector)) {
        console.log(`FAILED VALIDATION FOR ${selector}`);
        all_valid = false;
        Flash.error(`<ul><li>${error_message_for(selector)}</li></ul>`);
        break;
      }
    }
    return all_valid;
  };

  var prompt_for_upload_dir_reuse = function() {
    const el = $('#validation_configuration_sftp_validation_attributes_license_file_attributes_name');
    if (el.length == 0) { return; }
    if (el.attr('readOnly')) { return; }

    const file_names_in_use = $('#sftp_validation #reuse_sftp_dir').data('used-names');
    Object.keys(file_names_in_use).forEach(function(key) {
      if (el.val() === key && !file_names_in_use[key]) {
        $('#reuse-license-file .license-file-name-in-use').text(el.val());
        $('#alert-error').hide();
        $('#reuse-license-file').modal('show');
      } else if (el.val() === key && file_names_in_use[key]) {
        $('#cannot-reuse-license-file .license-file-name-in-use').text(el.val());
        $('#alert-error').hide();
        $('#cannot-reuse-license-file').modal('show');
      }
    });
  };

  this.submit_for_license_file_reuse = function() {
    $('#alert-error').hide();
    $('#reuse-license-file').modal('hide');
    const form = $('#step-one').closest('form');
    form.data('remote', false);
    form.attr('action', '/system/admin/validation_configurations/copy_field_mappings').trigger('submit');
  };
});
