import { RxSearchFormAssistant } from './rx_search_requests';

$(document).on('PMPi:complete', function (event, rx_request) {
  //if nothing found in pmpi
  RxSearchFormAssistant.form_processing_complete();
  $('#searchResultsDialogModal').addClass('hidden');

  if (rx_request.patient_results_empty && rx_request.patient_found_no_results) {
    $('#searchResultsDialogModal').find('.modal-title').html('Search Results');
    $('#searchResultsDialogModal').find('.modal-body').html('Patients found but no prescriptions found.');
    $('#searchResultsDialogModal').find('.btn').html('OK');
    $('#searchResultsDialogModal').modal('show');
    return;
  }

  if (rx_request.patient_results_empty && !rx_request.show_external_indicators) {
    $('#searchResultsDialogModal').find('.modal-title').html('Search Results');
    $('#searchResultsDialogModal').find('.modal-body').html('No matching patient found.');
    $('#searchResultsDialogModal').find('.btn').html('OK');
    $('#searchResultsDialogModal').modal('show');
  } else {
    if (rx_request.approval_status_nil) {
      document.location.href = rx_request.redirect_url;
    } else {
      if (rx_request.needs_approval) {
        $('#searchResultsDialogModal').find('.modal-title').html('Search Results');
        $('#searchResultsDialogModal').find('.modal-body').html('The request has been forwarded to your admin for approval.');
        $('#searchResultsDialogModal').find('.btn').html('OK');
        $('#searchResultsDialogModal').modal('show');
        $('body').on('click', '.btn', function() {
          document.location.href = rx_request.new_search_url;
        });
      }
    }
  }
});

$(document).on('PMPi:timeout', function () {
  RxSearchFormAssistant.form_processing_complete();
  $('#searchResultsDialogModal').find('.modal-title').html('Search Results');
  $('#searchResultsDialogModal').find('.modal-body').html('No Matching Patient found');
  $('#searchResultsDialogModal').find('.btn').html('OK');
});
