var FrameBuster = (function () {
  function bust() {
    if (top.location != location) {
      top.location.href = document.location.href ;
    }
  }

  return {
    bust:bust
  };
})();

FrameBuster.bust();