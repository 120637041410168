$(function() {
  $('#mm_search_form')
    .on('ajax:beforeSend', function(evt, xhr, settings){
      $('.mm_request_spinner .indeterminate_progress').show(100);
    })
    .on('ajax:complete', function(evt, xhr, status){
      $('.mm_request_spinner .indeterminate_progress').hide(100);
    })
    .on('ajax:error', function(evt, xhr, status, error){
      $('.scrolltable').html(xhr.responseText);
      alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
      $(document).trigger('search:failed');
    });
});
