$(function () {
  $('*[data-bootstrap-switch]').bootstrapSwitch();

  $('*[data-bootstrap-switch]').each(function(){
    if($(this).data('state')){
      $(this).bootstrapSwitch('state', true);
    }else{
      $(this).bootstrapSwitch('state', false);
    }
  });

  setTimeout(function () {
    $('.bootstrap-switch-container').each(function () {
      var container = $(this),
        old_width = container.width();
      container.width(old_width + 1);
    });

  }, 200);

});

//Append alert disclaimer to modal body
$(function () { $('#alert_preview_modal').on('show.bs.modal', function () {
  var alertDisclaimerValue = $('textarea#disclaimer').val();
  if (alertDisclaimerValue === undefined) {
    alertDisclaimerValue = $('*[data-alert-disclaimer-value]').val();
  }
  $('#alert_preview_modal').find('#alert_disclaimer').append('<div id="disclaimer_text" class="disclaimer_text"><br></div>');
  $('#alert_preview_modal').find('#disclaimer_text').text(alertDisclaimerValue);
});
});
//Empty out disclaimer information from modal so fresh legal info can be appended later
$(function () { $('#alert_preview_modal').on('hidden.bs.modal', function () {
  $('#alert_preview_modal').find('#alert_disclaimer div').empty();
});
});
