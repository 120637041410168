var SamAdminList = (function ($) {
  function setup() {
    $('#sam_admin_search')
      .on('ajax:beforeSend', function(evt, xhr, settings){
        $('#spinnah').show(100);
      })
      .on('ajax:success', function(evt, data, status, xhr){
        $('#results').html(xhr.responseText);
      })
      .on('ajax:complete', function(evt, xhr, status){
        $('#spinnah').hide(100);
      })
      .on('ajax:error', function(evt, xhr, status, error){
        $('.scrolltable').html(xhr.responseText);

        alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
        $(document).trigger('search:failed');
      });

  }

  $(function() {
    setup();
  });
})(jQuery);
