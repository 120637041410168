var ContestedRecordFlagController = (function ($) {
  function row_clicked(event) {
    var target = $(event.target);
    if (typeof(target.data('contest-url')) === 'undefined') {
      target = target.closest('[data-contest-url]');
    }
    var url = target.data('contest-url'),
      classname = target.data('contest-model'),
      dispensation_id = target.data('dispensation-id'),
      result_id = target.data('result-id');


    var promise = $.ajax({
      url: url,
      type: 'GET',
      data: { 'result_id' : result_id, 'dispensation_id' : dispensation_id, 'class' : classname }
    }).promise();

    promise.done(function (modal_content) {
      $('#contested_dialog_modal').html(modal_content);
      $('#contested_dialog_modal').modal();
    });
  }
  $(document).on('click', 'tr[data-contest-url]', row_clicked);
})(jQuery);

$(document).on('click', '#submit_contested', function(event) {
  checkComment(event);
});

$(document).on('focusout input', '.contested_record_message_area', function(event) {
  checkComment(event);
});

function checkComment(event) {
  if ($('.contested_record_message_area').val() == '' && $('#delete_').is(':checked') == false) {
    $('.contested_record_message_area').css('border-color', 'red');
    $('.comment_error').text('Comment can\'t be blank.');
    $('.comment_error').css('color', 'red');
    event.preventDefault(); 
  } else {
    $('.contested_record_message_area').css('border-color', '');
    $('.comment_error').text('');
  }
}
