/* TAB DATA-API
  * ============ */

var tabbedNavAssistiveText = (function ($) {

  // A function run once, when the DOM is 'ready.' Used to detect the initial active tab
  function check_active_tab(element) {
    if ($(element).hasClass('active')) {
      $(element).find('a, button').append('<span class="sr-only"> Currently Selected</span>');
    }
  }

  // A function run each time a tab is , when the DOM is 'ready.' Used to detect the initial active tab
  function change_active_tabs(element) {
    var other_tabs = $('.nav-tabs li a, .nav-tabs li button').not(element);
    other_tabs.find('.sr-only').remove();

    var the_assistive_text = $(element).find('.sr-only').length;
    if (the_assistive_text == 0) {
      $(element).append('<span class="sr-only" aria-live="polite"> Currently Selected</span>');
    }
  }

  // Calls the `check_active_tab` function declared above, looking over each tab
  function get_the_active_tab_on_load() {
    $('.nav-tabs li').each(function () {
      check_active_tab(this);
    });
  }

  // Setup. Function runs when the DOM is `ready`.
  function setup() {
    get_the_active_tab_on_load();

    // When a tab is clicked
    var a_tab_link = $('.nav-tabs li a, .nav-tabs li button');
    $(a_tab_link).on('click', function() {
      change_active_tabs(this);
    });
  }

  return {
    setup: setup
  };
})($);

$(function() {
  tabbedNavAssistiveText.setup();
});