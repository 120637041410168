var NpiLookupField = (function ($) {
  function enable_npi_validate(node) {
    var input = node.find('input'),
      dea = $('[dea-with-npi=true]'),
      dea_input = dea.find('input'),
      dea_label = get_dea_label();

    function get_dea_label(){
      if (node.children('span').length === 0) return '';

      if (node.children('span').get(0).innerText === 'Pharmacy NPI #') {
        return dea.find('span:contains(\'Pharmacy DEA #\')');
      } else if (node.children('span').get(0).innerText === 'Prescriber NPI #'){
        return dea.find('span:contains(\'Prescriber DEA #\')');
      }
    }

    function is_valid(text) {
      if (text.match(/^\d{10}$/i)) {
        return true;
      } else {
        return false;
      }
    }

    function clear_requirement() {
      dea_label.removeClass('required');
      dea_input.removeClass('validation_error');
      dea_input.attr('data-validate', 'false');
      dea.removeClass('validation_error');
      dea.attr('aria-invalid', 'false');
      dea.find('span.help-block').remove();
      dea.trigger('validatorian.error_cleared');
    }

    function return_requirement() {
      dea_label.addClass('required');
      dea_input.attr('data-validate', 'true');
    }

    function change_dea_requirement(event) {
      var text = $(this).val();
      if (is_valid(text)) {
        clear_requirement();
      } else {
        return_requirement();
      }
    }

    input.on('keyup', change_dea_requirement);
    input.on('change', change_dea_requirement);
  }

  $(function () {
    $('*[data-npi-validate]').each(function () {
      enable_npi_validate($(this));
    });
  });
})($);
