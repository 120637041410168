/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   datepicker.js
 * 
 * Adapted for for the PMP AWARxE platform by Bamboo Health on 06/20
 * 
 * Statement of changes:
 * All references to cancel and submit buttons have been removed,
 * including the initial declaration of `this.[button-type] = ...`
 * and subsequent functions.
 * 
 * Implementation
 * 
 * Start and end dates
 * 
 * By default, enddate is the current date and startdate is 1/1/1900.
 * Both can be set/either or. Start date must be equal to or earlier than the enddate.
 * API
 * 
 */

import { CalendarButtonInput } from './datepicker_button';
import { AccessibleDateConstraints, accessibleDatePickerHelper } from '../accessible_date_helpers';
import { DatePickerFeatures } from './datepicker_features';

export const DatePicker = function (inputNode, buttonNode, dialogNode) {
  this.datePickerFeatures = new DatePickerFeatures(this);
  this.accessibleDatePickerHelper = accessibleDatePickerHelper;
  
  this.inputNode = inputNode;
  this.buttonNode = buttonNode;
  this.dialogNode = dialogNode;
  this.messageNode = dialogNode.querySelector('.message');

  this.earliestYear = String(new Date().getFullYear() - 121);
  this.latestYear = String(new Date().getFullYear() + 100);
 
  this.dateInput = new CalendarButtonInput(this.inputNode, this.buttonNode, this);

  this.endDate = this.setEndDate(inputNode);
  this.startDate = this.setStartDate(inputNode);
  this.defaultCalendarDate = new Date();
  this.defaultDate = this.defaultCalendarDate;

  this.accessibleDateConstraints = new AccessibleDateConstraints('MM/DD/YYYY', this.startDate, this.endDate);


  this.headerRow = this.dialogNode.querySelector('.header');
  this.headerRowElements = this.headerRow.querySelectorAll('button');

  this.MonthYearNode = this.dialogNode.querySelector('.monthYear');

  this.prevYearNode = this.dialogNode.querySelector('.prevYear');
  this.prevMonthNode = this.dialogNode.querySelector('.prevMonth');
  this.nextMonthNode = this.dialogNode.querySelector('.nextMonth');
  this.nextYearNode = this.dialogNode.querySelector('.nextYear');

  this.tbodyNode = this.dialogNode.querySelector('table.dates tbody');

  this.focusDay = new Date();
  this.selectedDay = new Date(0, 0, 1);
  
  // if (this.inputNode.value) {
  //   this.focusDay = new Date(this.inputNode.value);
  // } else {
  //   this.focusDay = this.endDate || new Date(this.latestYear);
  // }

  // this.selectedDay = this.focusDay;
  // this.savedDate = this.selectedDay;
};

DatePicker.prototype.init = function () {
  this.dateInput.init();

  this.datePickerFeatures.init();

  this.inputNode.addEventListener('blur', this.handleInputNodeBlur.bind(this));
};

DatePicker.prototype.updateGrid = function () {
  this.datePickerFeatures.updateGrid();
};

DatePicker.prototype.updateLastRowVisibility = function (
  dayOfWeek,
  daysInMonth
) {
  this.datePickerFeatures.updateLastRowVisibility(dayOfWeek, daysInMonth);
};

DatePicker.prototype.setPlaceholder = function () {
  this.datePickerFeatures.setPlaceholder();
};

DatePicker.prototype.setMask = function () {
  this.datePickerFeatures.setMask();
};

DatePicker.prototype.updateNavigationArrowVisibility = function (d) {
  this.datePickerFeatures.updateNavigationArrowVisibility(d);
};

DatePicker.prototype.limitArrowsPerLatestAllowedDate = function (
  visibleCalendarMonth
) {
  this.datePickerFeatures.limitArrowsPerLatestAllowedDate(
    visibleCalendarMonth
  );
};

DatePicker.prototype.limitArrowsPerEarliestAllowedDate = function (
  visibleCalendarMonth
) {
  this.datePickerFeatures.limitArrowsPerEarliestAllowedDate(
    visibleCalendarMonth
  );
};

DatePicker.prototype.hideLastRow = function () {
  this.datePickerFeatures.hideLastRow();
};

DatePicker.prototype.showLastRow = function () {
  this.datePickerFeatures.showLastRow();
};

DatePicker.prototype.hideNextYear = function () {
  this.datePickerFeatures.hideNextYear();
};

DatePicker.prototype.showNextYear = function () {
  this.datePickerFeatures.showNextYear();
};

DatePicker.prototype.hideNextMonth = function () {
  this.datePickerFeatures.hideNextMonth();
};

DatePicker.prototype.showNextMonth = function () {
  this.datePickerFeatures.showNextMonth();
};

DatePicker.prototype.hidePrevMonth = function () {
  this.datePickerFeatures.hidePrevMonth();
};

DatePicker.prototype.showPrevMonth = function () {
  this.datePickerFeatures.showPrevMonth();
};

DatePicker.prototype.hidePrevYear = function () {
  this.datePickerFeatures.hidePrevYear();
};

DatePicker.prototype.showPrevYear = function () {
  this.datePickerFeatures.showPrevYear();
};

DatePicker.prototype.setFocusDay = function (flag) {
  this.datePickerFeatures.setFocusDay(flag);
};

DatePicker.prototype.updateDay = function (day) {
  this.datePickerFeatures.updateDay(day);
};

DatePicker.prototype.getDaysInLastMonth = function () {
  return this.datePickerFeatures.getDaysInLastMonth();
};

DatePicker.prototype.getDaysInMonth = function () {
  return this.datePickerFeatures.getDaysInMonth();
};

DatePicker.prototype.show = function () {
  this.datePickerFeatures.show();
};

DatePicker.prototype.isOpen = function () {
  return this.datePickerFeatures.isOpen();
};

DatePicker.prototype.hide = function () {
  this.datePickerFeatures.hide();
};

DatePicker.prototype.handleBackgroundMouseDown = function (event) {
  this.datePickerFeatures.handleBackgroundMouseDown(event);
};

DatePicker.prototype.handleBackgroundMouseUp = function () {
  this.datePickerFeatures.handleBackgroundMouseUp();
};

DatePicker.prototype.handleInputNodeBlur = function () {
  this.datePickerFeatures.handleInputNodeBlur();
};

DatePicker.prototype.saveDate = function (newDate) {
  this.datePickerFeatures.saveDate(newDate);
};

DatePicker.prototype.updateInputNodeValue = function () {
  this.datePickerFeatures.updateInputNodeValue();
};

DatePicker.prototype.handleNextYearButton = function (event) {
  this.datePickerFeatures.handleNextYearButton(event);
};

DatePicker.prototype.handlePreviousYearButton = function (event) {
  this.datePickerFeatures.handlePreviousYearButton(event);
};

DatePicker.prototype.handleNextMonthButton = function (event) {
  this.datePickerFeatures.handleNextMonthButton(event);
};

DatePicker.prototype.handlePreviousMonthButton = function (event) {
  this.datePickerFeatures.handlePreviousMonthButton(event);
};

DatePicker.prototype.moveToNextYear = function () {
  this.datePickerFeatures.moveToNextYear();
};

DatePicker.prototype.moveToPreviousYear = function () {
  this.datePickerFeatures.moveToPreviousYear();
};

DatePicker.prototype.moveToNextMonth = function () {
  this.datePickerFeatures.moveToNextMonth();
};

DatePicker.prototype.moveToPreviousMonth = function () {
  this.datePickerFeatures.moveToPreviousMonth();
};

DatePicker.prototype.moveFocusToDay = function (day) {
  this.datePickerFeatures.moveFocusToDay(day);
};

DatePicker.prototype.moveFocusToNextDay = function () {
  this.datePickerFeatures.moveFocusToNextDay();
};


DatePicker.prototype.moveFocusToNextWeek = function () {
  this.datePickerFeatures.moveFocusToNextWeek();
};

DatePicker.prototype.moveFocusToPreviousDay = function () {
  this.datePickerFeatures.moveFocusToPreviousDay();
};

DatePicker.prototype.moveFocusToPreviousWeek = function () {
  this.datePickerFeatures.moveFocusToPreviousWeek();
};

DatePicker.prototype.moveFocusToFirstDayOfWeek = function () {
  this.datePickerFeatures.moveFocusToFirstDayOfWeek();
};

DatePicker.prototype.moveFocusToLastDayOfWeek = function () {
  this.datePickerFeatures.moveFocusToLastDayOfWeek();
};

DatePicker.prototype.setTextboxDate = function (day) {
  this.datePickerFeatures.setTextboxDate(day);
};

DatePicker.prototype.setAriaCurrentDate = function (day) {
  this.datePickerFeatures.setAriaCurrentDate(day);
};

DatePicker.prototype.getDateInput = function () {
  this.datePickerFeatures.getDateInput();
};

DatePicker.prototype.updateDateDialogCoordinates = function () {
  this.datePickerFeatures.updateDateDialogCoordinates();
};

DatePicker.prototype.updateDateDialogCoordinatesOnScroll = function () {
  this.datePickerFeatures.updateDateDialogCoordinatesOnScroll();
};

DatePicker.prototype.reupdateDateDialogCoordinates = function () {
  this.datePickerFeatures.reupdateDateDialogCoordinates();
};

DatePicker.prototype.getDateForButtonLabel = function (year, month, day) {
  return this.datePickerFeatures.getDateForButtonLabel(year, month, day);
};

DatePicker.prototype.setEndDate = function (inputNode) {
  return this.datePickerFeatures.setEndDate(inputNode);
};

DatePicker.prototype.setStartDate = function (dataNode) {
  return this.datePickerFeatures.setStartDate(dataNode);
};

DatePicker.prototype.setMessage = function (str) {
  this.datePickerFeatures.setMessage(str);
};

DatePicker.prototype.setUniqueIdRelationship = function () {
  this.datePickerFeatures.setUniqueIdRelationship();
};
