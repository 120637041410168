$(document)
  .on('ajax:success', '#reports_history_search_form', function(evt, data, status, xhr) {
    try {
      eval(xhr.responseText);
    } catch (error) {
      $('#report_search_results').html(xhr.responseText);
    }
  })
  .on('ajax:error', '#reports_history_search_form', function(evt, data, status, xhr) {
    alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
    $(document).trigger('search:failed', {
      error: error
    });
  });
