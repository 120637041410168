import { IndeterminateProgress } from '../app/indeterminate_progress';

$(document).on('submit', 'form[data-enforce-single-submit-with-message]', function (event) {
  var form = $(this), button = form.find('button.btn-primary'), message = form.data('enforce-single-submit-with-message'), old_message = '';

  function alter_button() {
    old_message = button.html;
    button.prop('disabled', true);
    button.html(message + ' ' + IndeterminateProgress.init({white:true}));
  }

  if (form.data('remote') === true) {
    form.on('ajax:beforeSend', function (evt, xhr, message) {
      alter_button();
    })
      .on('ajax:complete', function (evt, xhr, status) {
        button.prop('disabled', false);
        button.html(old_message);
      });
  } else {
    alter_button();
  }
});
