export const XssSanitizer = {
  sanitize: function(str) {
    if (!str || !str.length) {
      return '';
    }

    return str.replace(/<[^>]*>/g, ' ');
  },
  santize_js: function(str) {
    if (!str || !str.length) {
      return '';
    }

    return str.replace(/<script>|<\/script>|<a[^>]*>|<\/a>/gi, ' ');
  },
  sanitize_dom_text: function(text) {
    return text.replace(/[&<>"']/g, function (match) {
      switch (match) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '"':
        return '&quot;';
      case "'":
        return '&#39;';
      default:
        return match;
      }
    });
  },
  sanitize_disallowed_tags: function(str) {
    if (!str || !str.length) return '';
    const disallowed = [  // a tags are not included here because some stories allow them. Use sanitize_anchor_tags()
      'script',
      'iframe',
      'img',
      'object',
      'form',
      'frameset',
      'meta',
      'math'
    ];

    const regex = new RegExp(`<(${disallowed.join('|')})[^>]*>`, 'gi');
    return str.replace(regex, '');
  },
  sanitize_anchor_tags: function(str) {
    if (!str || !str.length) return '';
    return str.replace(/<a[^>]*>/gi, '');
  }
};
