import { XssSanitizer } from './xss_sanitizer';

export const Casper = (function ($) {
  function casperize(element) {
    var the_friendly_ghost = new Object();
    the_friendly_ghost.element = element;

    function get_attr(name) {
      return element.attr('data-casper-' + name);
    }
    function set_attr(name, object) {
      return element.attr('data-casper-' + name, object);
    }
    // console.log(get_attr('model'));
    function get_results(args) {
      var report = get_attr('report'),
        model = get_attr('model'),
        id = get_attr('id'),
        method = get_attr('method'),
        page = get_attr('page'),
        sort_column = get_attr('sort-column'),
        sort_direction = get_attr('sort-direction'),
        plugin = get_attr('plugin'),
        nodepath = get_attr('node-path'),
        component_id = get_attr('component-id'),
        success = args.success;


      var url = window.location.protocol + '//' + window.location.host;

      if (plugin !== undefined) {
        url = url + '/casper/:report/:plugin/:nodepath/:model/:id/method/:method/page/:page/sort/:sort/:direction';
      } else {
        url = url + '/casper/:report/results/:model/:id/method/:method/page/:page/sort/:sort/:direction';
      }

      url =   url.replace(':report', report)
        .replace(':model', model)
        .replace(':id', ''+id)
        .replace(':method', method)
        .replace(':page', ''+page)
        .replace(':sort', sort_column)
        .replace(':direction', sort_direction)
        .replace(':plugin',plugin)
        .replace(':nodepath', nodepath);

      if (typeof(component_id) !== 'undefined') {
        url = url + '?component_id=' + component_id;
      }
      return $.get(url, function (data) {
        success(data);
      });
    }

    the_friendly_ghost.initialize = function () {
      var results_promise = get_results({
        success: function (data) {
          var tbody = element.find('tbody');
          tbody.html(data);
          element.find('.progress_container').hide();
          $(document).trigger('table_updated', {table: element});
        }
      });

      function enable_scroll() {
        var table_scroll = element.find('.tablescroll');
        table_scroll.off('scroll');
        table_scroll.scroll(function (event) {
          let lol = table_scroll;

          var height, scroll_top, autopaginate_fetching = false;
          height = table_scroll.prop('scrollHeight');
          scroll_top = table_scroll.scrollTop();
          if (table_scroll.attr('data-autopaginate-fetching') === 'true') {
            autopaginate_fetching = true;
          }
          if (((height - table_scroll.height()) <= scroll_top + 1) && !autopaginate_fetching) {
            table_scroll.attr('data-autopaginate-fetching', true);
            element.find('.progress_container').show();

            let page = get_attr('page');
            page = parseInt(page) + 1;
            set_attr('page', page);
            get_results({
              success: function (data) {
                var tbody = element.find('tbody');
                tbody.append(data);
                element.find('.progress_container').hide();
                table_scroll.attr('data-autopaginate-fetching', false);
                $(document).trigger('table_updated', {table: element});
                if (data.length < 2) {
                  table_scroll.off('scroll');
                }

              }
            });
          }

        });
      }

      enable_scroll();

      element.find('th.sortable').on('click', function (event) {
        var target = $(event.target), sort_selector = target.data('casper-sort-selector');
        set_attr('page', 1);
        set_attr('sort-column', sort_selector);
        var d = 'desc';
        if (target.hasClass('desc')) {
          // console.log('classy!');
          d = 'asc';
        }
        element.find('.progress_container').show();
        element.find('th').removeClass('sort').removeClass('asc').removeClass('desc');
        target.addClass(d).addClass('sort');
        set_attr('sort-direction', d);

        get_results({
          success: function (data) {
            var tbody = element.find('tbody');
            tbody.html(data);
            element.find('.progress_container').hide();
            $(document).trigger('table_updated', {table: element});
            if (data.length < 2) {
              var table_scroll = element.find('.tablescroll');
              table_scroll.off('scroll');
            }
            enable_scroll();
          }
        });

      });
    };

    the_friendly_ghost.initialize();
  }
  $(function () {

    $('div[data-casper-id]').each(function () {
      casperize($(this));
    });
    $('a[data-casper-pdf-link], a[data-casper-csv-link]').on('click', function (event) {
      // event.preventDefault();
      //:title/:selector/:direction
      var link = $(this), href = link.attr('href');
      var query = '?sort=';
      var components = $('*[data-casper-component-header-id]');
      if (components.length > 0) {
        components.each(function () {
          var component_div = $(this), component_id = component_div.data('casper-component-header-id');

          component_div.find('div.casper').each(function () {
            var results_div = $(this);
            query = query + component_id + '!';
            query = query + results_div.attr('data-casper-method').replace(' ', '+')+'/';
            query = query + results_div.attr('data-casper-sort-column')+'/';
            query = query + results_div.attr('data-casper-sort-direction')+'|';
          });
        });
      } else {
        $('div.casper').each(function () {
          var results_div = $(this);
          query = query + results_div.attr('data-casper-method').replace(' ', '+')+'/';
          query = query + results_div.attr('data-casper-sort-column')+'/';
          query = query + results_div.attr('data-casper-sort-direction')+'|';
        });
      }

      query = query.substring(0, query.length - 1);
      link.attr('href', XssSanitizer.sanitize_dom_text(`${href.split('?')[0]}${query}`) );
    });
  });
})($);
