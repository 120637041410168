var PermissionButton = (function () {
  // var button, icon, related_field;
  function new_with_element(element) {
    var instance = new Object();
    instance.button = null;
    instance.icon = null;
    instance.related_field = null;
    instance.new_with_element = function(element) {
      instance.button = $(element);
      // if (instance.button.data('perm-button')) {
      //   instance = instance.button.data('perm-button');
      //   return;
      // } else {
      //   instance.button.data('perm-button', instance);
      // }

      instance.icon = instance.button.children('i').first();
      instance.related_field = $('input[data-hidden-field-id="' + instance.button.data('related-hidden-field') + '"]');

      if (instance.icon.length < 1) {
        instance.button = instance.button.parent(), instance.related_field = $('input[data-hidden-field-id="' + instance.button.data('related-hidden-field') + '"]'), instance.icon = instance.button.children('i').first();
      }

      // Determine Parent
      var parent_id = instance.related_field.data('parent-id');
      var access_type = instance.related_field.data('access-type');
      var parent_field = $('input[data-self-id="' + parent_id + '"][data-access-type="'+access_type+'"]');

      var parent_button = $('span[data-related-hidden-field="'+ parent_field.data('hidden-field-id') +'"]');

      instance.has_parent = false;
      if (parent_button.length > 0) {
        instance.has_parent = true;
        instance.parent = PermissionButton.new_with_element(parent_button);
      }


      // console.log(instance.has_children);
    };
    instance.check_siblings = function() {
      // var node = $(node)
      // var self_id = $(node).attr("data-self-id");
      var siblings = $(instance.siblings());
      //console.log("siblings:", siblings);
      if (instance.related_field.data('access-type') === 'access') {
        if (instance.is_denied()) {
          siblings.each(function() {
            this.set_default();
          });
        }
      } else { // We are not an access click
        if (instance.is_allowed()) {
          // ensure the access node is checked
          var related_access_node = null;
          siblings.each(function () {
            if (this.related_field.data('access-type') === 'access'){
              related_access_node = this;
              return;
            }
          });
          if (related_access_node !== null) {
            if (!related_access_node.is_allowed()){
              related_access_node.set_allow();
            }
          }

          // if (related_access_node.is(":checked") === false) {
          //   $(related_access_node).prop("checked", true);
          //   $(related_access_node).prop("indeterminate", false);
          //   check_parent(related_access_node);
          // };


        }
      }
    };

    instance.siblings = function () {
      var self_id = instance.related_field.data('self-id');
      var related_fields = $('input[data-self-id="'+self_id+'"]');
      var siblings = new Array();
      var sibling_buttons = related_fields.map(function () {
        var field = $(this);
        return $('span[data-related-hidden-field="'+field.data('hidden-field-id')+'"]').get(0);
        // console.log(button);
        // instance.children.push(PermissionButton.new_with_element(button));
      }).get();
      if (sibling_buttons.length > 0) {
        for (var i = 0; i < sibling_buttons.length; i++) {
          var button = sibling_buttons[i];
          if (typeof button != undefined) {
            var p_button = PermissionButton.new_with_element(button);
            if (button !== instance.button.get(0)) {
              siblings.push(p_button);
            }

          }

        }
      }
      return siblings;
    };
    instance.default_siblings = function () {
      var siblings = $(instance.siblings());
      siblings.push(instance);
      siblings.each(function () {
        this.set_default();
        for (var i = 0; i < this.children().length; i++) {
          this.children()[i].default_siblings();
        }
      });

    };
    instance.children = function () {
      // Determine Children
      var self_id = instance.related_field.data('self-id');
      var access_type = instance.related_field.data('access-type');
      var child_fields = $('input[data-parent-id="'+self_id+'"][data-access-type="'+access_type+'"]');
      var children = new Array();
      var child_buttons = child_fields.map(function () {
        var field = $(this);
        return $('span[data-related-hidden-field="'+field.data('hidden-field-id')+'"]').get(0);
        // console.log(button);
        // instance.children.push(PermissionButton.new_with_element(button));
      }).get();
      // console.log(instance.children);
      if (child_buttons.length > 0) {
        for (var i = 0; i < child_buttons.length; i++) {
          var button = child_buttons[i];
          if (typeof button != undefined) {
            var p_button = PermissionButton.new_with_element(button);

            children.push(p_button);
          }

        }
      }
      return children;
    };
    var minusIcon = 'fa fa-minus';
    var banCircleIcon = 'fa fa-ban';
    var checkmarkIcon = 'fa fa-check';
    instance.set_allow = function (element) {
      instance.button.removeClass('deny').addClass('allow');
      instance.related_field.val('allow');
      instance.icon.removeClass(minusIcon).removeClass(banCircleIcon).addClass(checkmarkIcon);

      if (instance.has_parent) {
        instance.parent.set_allow();
      }
      instance.check_siblings();
    };
    instance.set_deny = function (element) {
      instance.button.removeClass('allow').addClass('deny');
      instance.related_field.val('deny');
      instance.icon.removeClass(minusIcon).removeClass(checkmarkIcon).addClass(banCircleIcon);
      var children = instance.children();
      if (children.length > 0) {
        for (var i = 0; i < children.length; i++) {
          children[i].set_deny();
        }
      }
      instance.check_siblings();
    };
    instance.set_default = function (element) {
      instance.button.removeClass('allow').removeClass('deny');
      instance.related_field.val('nil');
      instance.icon.removeClass(banCircleIcon).removeClass(checkmarkIcon).addClass(minusIcon);
    };
    instance.is_allowed = function (element) {
      return instance.button.hasClass('allow');
    };
    instance.is_denied = function (element) {
      return instance.button.hasClass('deny');
    };
    instance.is_default = function (element) {
      return !(instance.button.hasClass('allow') || instance.button.hasClass('deny'));
    };
    instance.next_state = function () {
      if (instance.is_allowed()) {
        instance.set_deny();
      } else {
        if (instance.is_denied()) {
          instance.set_default();
        } else {
          instance.set_allow();
        }
      }
    };
    instance.new_with_element(element);
    return instance;
  }
  return {
    new_with_element:new_with_element
  };
})();

var AdminPermissionItemToggle = (function ($) {
  $(document).on('click', '#admin-permissions-toggle', function (event) {
    var checkbox = $(event.target);
    var targets = $(checkbox.data('toggle-elements-with-selector'));
    targets.toggle(checkbox.is(':checked'));
  });
})(jQuery);

$(function() {
  $(document).on('change', '#dropdown', function(event) {
    var permissions_div = $('#permissions');
    var dropdown = event.target;
    var role_id = $(dropdown).val();
    var role_type = $(dropdown).siblings('input#type').val();
    var selected_org_type_tab = $(dropdown).siblings('input#selected_org_type_tab').val();
    permissions_div.load(permissions_div.attr('data-refresh-url') + '/?id=' + role_id + '&role_type=' + role_type + '&selected_org_type_tab=' + selected_org_type_tab);
  });
});
$(document).on('address_card:updated', function(event){
  $('.permissions input[type=checkbox]').each(function() {
    var check = $(this);
    if (check.attr('data-indeterminate') == 'true') {
      check.prop('checked', false);
      check.prop('indeterminate', true);
    }
  });
});
$(document).on('ajax:before', 'form.permissions', function(evt, data, status, xhr){
  $('form.permissions input[type=checkbox]').each(function() {
    var related_hidden_field = $('input[data-hidden-field-id=\''+$(this).attr('data-related-hidden-field')+'\']');
    if ($(this).prop('indeterminate')) {
      related_hidden_field.prop('value', 'default');
    }
  });
});
$(document).on('click', 'form.permissions .permission_button', function(event) {
  var button = PermissionButton.new_with_element($(event.target));

  button.next_state();
});
$(document).on('click', 'form.permissions li.for_user a.check_defaults', function(event) {
  var input_node = $(event.target);
  //console.log(input_node.is(":checked"));
  // var check_parent = function(node) {
  //   var node = $(node);
  //   if (node.is(":checked")) {
  //     var parent_id = node.attr("data-parent-id");
  //     var parent_node = $("input[data-self-id="+parent_id+"]");
  //     parent_node.prop("checked", true);
  //     parent_node.prop("indeterminate", false);
  //     if (parent_node.attr('data-parent-id')) {
  //       check_parent(parent_node);
  //     };
  //   };
  // };
  // var uncheck_children = function(node) {
  //   var node = $(node);
  //   if (node.is(":checked") === false) {
  //     if (node.attr("data-has-children")) {
  //       var self_id = node.attr("data-self-id");
  //       $("input[data-parent-id="+self_id+"]").each(function() {
  //         $(this).prop("checked", false);
  //         $(this).prop("indeterminate", false);
  //         uncheck_children(this);
  //         check_sibling_permissions(this);
  //       });
  //     };
  //   };
  // };
  var force_toggle_children = function(node, toggle) {
    var node = $(node);
    if (node.attr('data-has-children')) {
      var self_id = node.attr('data-self-id');
      $('input[data-parent-id='+self_id+']').each(function() {
        $(this).prop('checked', toggle);
        $(this).prop('indeterminate', false);
        force_toggle_children(this,toggle);
      });
    }
  };
  var check_defaults = function(button) {
    var self_id = $(button).attr('data-self-id');
    var related_hidden_field = $('input[data-self-id='+self_id+']');
    var signature = related_hidden_field.data('hidden-field-id');
    var elem = $('span[data-related-hidden-field="' + signature + '"]');
    //console.log(self_id, related_hidden_field, signature, elem);
    var permissions_button = PermissionButton.new_with_element(elem);
    permissions_button.default_siblings();
    // var self_id = $(button).attr("data-self-id");
    // $(button).parents('.controls').first().find('.permission_button').each(function () {
    //   var perm_button = $(this);
    //   var button = PermissionButton.new_with_element(perm_button);
    //
    //   button.set_default();
    // });
    // $("input[data-self-id="+self_id+"]").each(function() {
    //   if ($(this).attr("data-checked-on-default") == "true") {
    //     $(this).prop("checked", true);
    //     $(this).prop("indeterminate", false);
    //     check_parent(this);
    //   } else {
    //     $(this).prop("checked", false);
    //     $(this).prop("indeterminate", false);
    //   };
    //
    //
    // });
  };
  var check_sibling_permissions = function(node) {
    var node = $(node);
    var self_id = $(node).attr('data-self-id');
    var siblings = $('input[data-self-id='+self_id+']');

    if (node.attr('data-is-access')) {
      if (node.is(':checked') === false) {
        siblings.each(function() {
          if (!(this === node)) {
            $(this).prop('checked', false);
            $(this).prop('indeterminate', false);
          }

        });
      }
    } else { // We are not an access click
      if (node.is(':checked')) {
        // ensure the access node is checked
        var related_access_node = $('input[data-is-access=true][data-self-id='+self_id+']');
        if (related_access_node.is(':checked') === false) {
          $(related_access_node).prop('checked', true);
          $(related_access_node).prop('indeterminate', false);
          check_parent(related_access_node);
        }


      }
    }
  };
  if (input_node.get(0).nodeName.toLowerCase() === 'input') {
    // check_parent(input_node);
    // uncheck_children(input_node);
    if (event.altKey) {
      force_toggle_children(input_node, input_node.is(':checked'));
    }
    check_sibling_permissions(input_node);
  }

  if (input_node.get(0).nodeName.toLowerCase() === 'a') {
    check_defaults(input_node);

  }

});

$(document).on('click', 'form.permissions span.permission_button, form.permissions a.check_defaults, form.permissions input[type=checkbox]', function(event) {
  var input_node = $(event.target);
  // console.log(input_node.is(":checked"));
  var check_parent = function(node) {
    var node = $(node);
    var hidden_field = $('input[data-hidden-field-id="' + node.data('related-hidden-field') + '"]');
    var parent_node = $('#' + hidden_field.data('parent-id'));
    if (node.hasClass('allow')) {
      parent_node.prop('checked', true);
      parent_node.prop('indeterminate', false);
      if (parent_node.attr('data-parent-id')) {
        check_parent(parent_node);
      }
    }
  };
  var check_parent_without_allow = function(node) {
    var node = $(node);
    var hidden_field = $('input[data-hidden-field-id="' + node.data('related-hidden-field') + '"]');
    var parent_node = $('#' + node.data('parent-id')).parent().find('input[data-is-access=true]');

    parent_node.prop('checked', true);
    parent_node.prop('indeterminate', false);
    if (parent_node.attr('data-parent-id')) {
      check_parent_without_allow(parent_node);
    }

  };
  var uncheck_children = function(node) {
    var node = $(node);
    if (node.is(':checked') === false) {
      if (node.attr('data-has-children')) {
        var self_id = node.attr('data-self-id');
        $('input[data-parent-id='+self_id+']').each(function() {
          $(this).prop('checked', false);
          $(this).prop('indeterminate', false);
          uncheck_children(this);
          check_sibling_permissions(this);
        });
      }
    }
  };
  var force_toggle_children = function(node, toggle) {
    var node = $(node);
    if (node.attr('data-has-children')) {
      var self_id = node.attr('data-self-id');
      $('input[data-parent-id='+self_id+']').each(function() {
        $(this).prop('checked', toggle);
        $(this).prop('indeterminate', false);
        force_toggle_children(this,toggle);
      });
    }
  };
  var check_defaults = function(button) {
    var self_id = $(button).attr('data-self-id');
    $('input[data-self-id='+self_id+']').each(function() {
      if ($(this).attr('data-checked-on-default') == 'true') {
        $(this).prop('checked', true);
        $(this).prop('indeterminate', false);
        check_parent(this);
      } else {
        $(this).prop('checked', false);
        $(this).prop('indeterminate', false);
      }


    });
  };
  var check_sibling_permissions = function(node) {
    var node = $(node);
    var self_id = $(node).attr('data-self-id');
    var siblings = $('input[data-self-id='+self_id+']');

    if (node.attr('data-is-access')) {
      if (node.is(':checked') === false) {
        siblings.each(function() {
          if (!(this === node)) {
            $(this).prop('checked', false);
            $(this).prop('indeterminate', false);
          }

        });
      }
    } else { // We are not an access click
      if (node.is(':checked')) {
        // ensure the access node is checked
        var related_access_node = $('input[data-is-access=true][data-self-id='+self_id+']');
        if (related_access_node.is(':checked') === false) {
          $(related_access_node).prop('checked', true);
          $(related_access_node).prop('indeterminate', false);
          check_parent(related_access_node);
          check_parent_without_allow(related_access_node);
        }


      }
    }
  };
  if (input_node.get(0).nodeName.toLowerCase() === 'span') {
    check_parent(input_node);
    uncheck_children(input_node);
    if (event.altKey) {
      force_toggle_children(input_node, input_node.is(':checked'));
    }
    check_sibling_permissions(input_node);
  }

  if (input_node.get(0).nodeName.toLowerCase() === 'input') {
    check_parent_without_allow(input_node);
    uncheck_children(input_node);
    if (event.altKey) {
      force_toggle_children(input_node, input_node.is(':checked'));
    }
    check_sibling_permissions(input_node);
  }

  if (input_node.get(0).nodeName.toLowerCase() === 'a') {
    check_defaults(input_node);
  }

});
