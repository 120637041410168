var SlideToggle = (function($) {
  function setup() {
    $(document).on('click', '*[data-slide-toggle]', function(event) {
      $($(this).data('slide-toggle')).slideToggle('slow', function() {
        // Animation complete.
      });
    });
  }
  return {
    setup: setup
  };
})(jQuery);
SlideToggle.setup();
