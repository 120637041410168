$(function() {
  $('form').on('click', '#logo-upload-button', function(){
    $('#application_preference_logo').trigger('click');
  });
  $('form').on('change', '#application_preference_logo', function(){
    $('#logo_upload').val($(this).val());
  });
  $('#application_preference_color_theme_id, #preferences_color_theme_id').on('change', function() {
    $('.color-theme').hide();
    $('.color-theme-' + $(this).val()).show();
    ThemePreview.changeColor('.color-theme-preview');
  });
  ThemePreview.changeColor('.color-theme-preview');
});

var ThemePreview = (function ($) {
  function changeColor(element) {
    var navColor = $('.color-theme:visible .primary-color span').html();
    var buttonColor = $('.color-theme:visible .secondary-color span').html();
    var navDropdownColor = $('.color-theme:visible .tertiary-color span').html();
    var navFocusColor = $('.color-theme:visible .quaternary-color span').html();
    $(element + ' .preview-navbar').css('background', navColor);
    $(element + ' .preview-navbar-inner').css('background', navColor);
    $(element + ' .preview-navbar-inner li.active').css('background', navDropdownColor);
    $(element + ' .nav-dropdown').css('background', navDropdownColor);
    $(element + ' .tabbed-nav ul').css('border-bottom', '1px solid ' + buttonColor);
    $(element + ' .tabbed-nav ul li a.active').attr('style', 'background: ' + buttonColor + ' !important');
  }
  return {changeColor:changeColor};
})($);
