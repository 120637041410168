export const RegistrationHelper = (function () {
  var dea = {map:function(data){}};
  var ncpdp = {map:function(data){}};
  var npi = {};

  var expectedFormat = {
    'DEA' : 'DEA number not found. Expected something similar to "AB1234567"',
    'NCPDP' : 'NCPDP number not found. Expected something similar to "1234567"',
    'NPI' : 'NPI number not found. Expected something similar to "1234567890"'
  };

  var addedDelegate = false;
  dea.url = null;
  let searchFunction = function(object, searchingFor) {
    return function(input, callback) {
      var number = input.val();

      var spinner = input.parent().find('.indeterminate_progress');
      var button = input.siblings('.btn');
      var tooltip_id = 'tooltip_for_' + input.attr('id');

      // var theCloseButton = "<button type='button' class='close' aria-label='close' tabindex='0'>x</button>";
      var html = '<div class=\'popover_error d-flex align-items-center justify-content-between\'><span class=\'popover_error_tooltip_message\' id=\'' + tooltip_id + '\'>' + expectedFormat[searchingFor] + '</span><button type=\'button\' class=\'close\' aria-label=\'close\' tabindex=\'0\'>x</button></div>';

      // Puts it on the body unless the parent has an id then it puts the container on the parent.
      // This is needed to get proper tab order.
      var container = false;

      var parent_container_id = $($(input).parent()).attr('id');
      if(parent_container_id !== undefined){
        container = '#' + parent_container_id;
      }

      input.popover({
        html:true,
        content:html,
        // change the delegate to garbage so we have control over when it is shown and hidden
        selector:'dlkflakdsjkfajksfdshfadhflhdsifuckbootstrapha87gwugd7ygduysgfauydglguydguyduyaifdghiufdgi',
        placement:'bottom',
        template:'<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"><p></p></div></div>',
        container: container
      });

      // Tell screen readers about this popup.
      input.attr('aria-describedby', 'tooltip_for_'+input.attr('id') );
      button.attr('aria-describedby', 'tooltip_for_'+input.attr('id') );

      function invokeTheCloseEventListener(eventListenerStatus) {
        let theCloseButton = $('#' + tooltip_id).parent().find('.close');
        if (eventListenerStatus === 'off') {
          $(theCloseButton).off('click');
          return;
        }
        $(theCloseButton).on('click', function() {
          input.popover('hide');
        });
      }

      spinner.css({display:'inline-block'});
      $.getJSON(object.url + '/' + number + '.json', function (data, status, xhr) {
        if(jQuery.isEmptyObject(data)){
          input.popover('show');
          invokeTheCloseEventListener();
          $('div.popover').css({'z-index':99999999999999});
        } else {
          callback(data);
          input.popover('hide');
          invokeTheCloseEventListener('off');
        }
      }).always(function() {
        spinner.hide();
      }).fail(function() {
        input.popover('show');
        invokeTheCloseEventListener();
        $('div.popover').css({'z-index':99999999999999});
      });
    };
  };
  dea.search = searchFunction(dea, 'DEA');

  ncpdp.url = null;
  ncpdp.search = searchFunction(ncpdp, 'NCPDP');

  npi.url = null;
  npi.search = searchFunction(npi, 'NPI');


  function matching_identifier(autofill_button) {
    return $(autofill_button).closest('td').find('.employer-identifier-label').text();
  }

  function autofill_fields_with_selectors (map) {
    $.each(map, function(key, value) {
      var node = $(key);
      if (node.prop('tagName') === 'SELECT') {
        // console.log(value);
        node.children('option').filter(function(){return $(this).val()==value;}).attr('selected', true);
      } else {
        if(node.val(value).length > 0){
          node.val(value).addClass('border-warning');
        }
      }

    });
  }
  function user_dea_search (event) {
    $('input[data-id-search-key="user_dea"]').val($(event.target).closest('tr').data('dea-number'));
    var input = $('input[data-id-search-key="user_dea"]');
    RegistrationHelper.dea.search(input, function(data) {
      var map = {
        '#user_first_name':data.first_name,
        '#user_middle_name':data.middle_name,
        '#user_last_name':data.last_name
      };
      $.each(data, function (key, value) {
        var linked_node = $('input[data-search-and-autofill-key='+key+']');
        if (linked_node.count > 0) {
          map[linked_node] = value;
        }
      });
      autofill_fields_with_selectors(map);
    });
  }

  function user_npi_search (event) {
    var input = $('input[data-id-search-key="user_npi"]');
    RegistrationHelper.npi.search(input, function(data) {
      var map = {
        '#user_first_name':data.first_name,
        '#user_last_name':data.last_name,
        '#user_middle_name':data.middle_name
      };
      autofill_fields_with_selectors(map);
    });
  }

  function employer_dea_search(event) {
    $('input[data-id-search-key="employer_dea"]').val(matching_identifier($(this))).trigger('keyup');
    var input = $('input[data-id-search-key="employer_dea"]');
    RegistrationHelper.dea.search(input, function(data) {
      var map = {
        '#user_employer_name':data.name,
        '#user_address1':data.address_line1,
        '#user_address2':data.address_line2,
        '#user_city':data.city,
        '#user_state':data.state,
        '#user_zip_code':data.postal_code
      };
      $.each(data, function (key, value) {
        var linked_node = $('*[data-search-and-autofill-key='+key+']');
        // console.log(linked_node);
        if (linked_node.length > 0) {
          var rand = String(Math.random()).replace('.', '');
          linked_node.attr('data-unique-token', rand);
          map['*[data-unique-token='+rand+']'] = value;
        }
      });
      autofill_fields_with_selectors(map);
    });
  }

  function employer_npi_search (event) {
    var input = $('input[data-id-search-key="employer_npi"]');
    RegistrationHelper.npi.search(input, function(data) {
      var map = {
        '#user_employer_name':data.organization_name,
        '#user_address1':data.business_address_line1,
        '#user_address2':data.business_address_line2,
        '#user_city':data.business_city,
        '#user_state':data.business_state,
        '#user_zip_code':data.business_postal_code
      };
      autofill_fields_with_selectors(map);
    });
  }

  function ncpdp_search(event) {
    var input = $('input[data-id-search-key$="ncpdp"]');
    RegistrationHelper.ncpdp.search(input, function(data) {
      var map = {
        '#user_employer_name':data.name,
        '#user_address1':data.mailing_address1,
        '#user_address2':data.mailing_address2,
        '#user_city':data.mailing_city,
        '#user_state':data.mailing_state_code,
        '#user_zip_code':data.mailing_zip_code,
        '#user_phone':data.location_phone,
        '#user_fax':data.location_fax
      };
      map = $.extend(map, RegistrationHelper.ncpdp.map(data));
      autofill_fields_with_selectors(map);
    });
  }

  function dea_search (event) {
    var input = $('input[data-id-search-key$="dea"]');

    RegistrationHelper.dea.search(input, function(data) {

      var map = {
        '#user_first_name':data.first_name,
        '#user_middle_name':data.middle_name,
        '#user_last_name':data.last_name
      };
      map = $.extend(map, RegistrationHelper.dea.map(data));
      $.each(data, function (key, value) {
        var linked_node = $('input[data-search-and-autofill-key='+key+']');
        if (linked_node.length > 0) {
          // console.log(linked_node);
          map[linked_node] = value;
        }
      });
      autofill_fields_with_selectors(map);
    });
  }

  function remove_address_card_with_id (id) {
    $('div.mini_addresscard[data-id=' + id + ']').remove();
  }

  function remove_dea_number_with_id (id) {
    remove_table_with_identifiers(id, '#dea_numbers');
  }

  function remove_employer_identifier_dea_with_id (id) {
    remove_table_with_identifiers(id, '#employer_identifier_dea_numbers');
  }

  function remove_employer_identifier_npi_with_id (id) {
    remove_table_with_identifiers(id, '#employer_identifier_npi_numbers');
  }

  function remove_employer_identifier_ncpdp_with_id (id) {
    remove_table_with_identifiers(id, '#employer_identifier_ncpdp_numbers');
  }

  function remove_table_with_identifiers (id, container) {
    var $container = $(container);
    $container.find('tr[data-id=' + id + ']').slideUp({complete: function(event) {
      $(this).remove();
    }});
    $container.children().length == 1 && $container.parent().slideUp();
  }

  function show_email_error (error_msg) {
    var input = $('.email_search');
    input.popover('dispose');

    add_popover(input, error_msg);
    input.popover('show');
    console.log(error_msg);
  }

  function agency_fill(event){
    var id = this.value;
    var uri = $(this).data('url');

    if (typeof uri === 'undefined') {
      return false;
    }
    uri = uri + '/' + id;
    $.getJSON(uri)
      .done(function(data) {
        var map = {
          '#user_employer_name':data.name,
          '#user_address1':data.address_one,
          '#user_address2':data.address_two,
          '#user_city':data.city,
          '#user_state':data.state,
          '#user_zip_code':data.postal_code
        };
        autofill_fields_with_selectors(map);
      });
  }

  return {
    dea:dea,
    ncpdp:ncpdp,
    npi:npi,
    user_dea_search:user_dea_search,
    employer_dea_search:employer_dea_search,
    ncpdp_search:ncpdp_search,
    user_npi_search:user_npi_search,
    employer_npi_search:employer_npi_search,
    dea_search:dea_search,
    remove_address_card_with_id:remove_address_card_with_id,
    remove_dea_number_with_id:remove_dea_number_with_id,
    remove_employer_identifier_dea_with_id:remove_employer_identifier_dea_with_id,
    remove_employer_identifier_npi_with_id:remove_employer_identifier_npi_with_id,
    remove_employer_identifier_ncpdp_with_id:remove_employer_identifier_ncpdp_with_id,
    added_delegate:addedDelegate,
    show_email_error:show_email_error,
    agency_fill:agency_fill
  };


})();

window.RegistrationHelper = RegistrationHelper;


$(document).on('click', 'input#user_finalize_registration_submit[type=\'submit\']', function(event) {
  if (RegistrationHelper.added_delegate) {
    $('#delegateAlert').modal('show');
    $('#delegateAlert').on('shown.bs.modal', function(){
      $('#delegateAlertButton').trigger('focus');
    });
    return false;
  }


});


$(document).on('hidden.bs.modal', '#delegateAlert', function () {
  $('.loading-overlay').addClass('hidden');
  $('form.edit_user').trigger('submit');
});
$(document).on('click', '#user_dea_search', RegistrationHelper.user_dea_search);
$(document).on('click', '#user_employer_dea_search', RegistrationHelper.employer_dea_search);
$(document).on('click', '#ncpdp_search', RegistrationHelper.ncpdp_search);
$(document).on('click', '#user_npi_search', RegistrationHelper.user_npi_search);
$(document).on('click', '#user_employer_npi_search', RegistrationHelper.employer_npi_search);
$(document).on('change', '#user_agency_id', RegistrationHelper.agency_fill);

$(document).on('click', '#user_peer_reviewee', function() {
  $('#user_peer_reviewer').prop('checked', false);
  toggle_email_field();
});

$(document).on('click', '#user_delegate', function() {
  toggle_delegate_email_field();
});

function toggle_email_field() {
  if ($('#user_peer_reviewee').is(':checked')) {
    $('div.peer_reviewers').slideDown('blind');
  } else {
    $('div.peer_reviewers').find('#email_search_peer_review').popover('hide');
    $('div.peer_reviewers').slideUp('blind');
  }
}

function toggle_delegate_email_field() {
  if ($('#user_delegate').is(':checked')) {
    $('div.peer_reviewers').find('#email_search_peer_review').popover('update');
    $('div.delegates').slideDown('blind');
  } else {
    $('div.peer_reviewers').find('#email_search').popover('hide');
    $('div.delegates').slideUp('blind');
  }
}

$(document).on('click', '#pharmacy_dea_search', RegistrationHelper.dea_search);
// $(document).on('click', '#ncpdp_search', RegistrationHelper.employer_dea_search);


$(document).on('click', '.popover-inner>button.close', function(event) {
  $(this).parents('.popover').hide();
});

function handlePopoverHidingOnClick(popoverElement) {
  $(document).on('shown.bs.popover', function() {
    $(document).on('click.outsidepopover', function() {
      popoverElement.popover('hide');
    });
  });

  $(document).on('hidden.bs.popover', function() {
    $(document).off('click.outsidepopover');
  });
}

function add_popover(input, text) {
  input.popover({
    html:true,
    content:'<div class=\'popover_error\'><span>' + text + '</span></div>',
    // change the delegate to garbage so we have control over when it is shown and hidden
    selector:'dlkflakdsjkfajksfdshfadhflhdsifuckbootstrapha87gwugd7ygduysgfauydglguydguyduyaifdghiufdgi',
    placement:'bottom',
    template:'<div class="popover"><div class="arrow"></div><div class="popover-body"><button type="button" class="close" aria-label="close" tabindex="0">x</button></div></div>'
  });

  handlePopoverHidingOnClick(input);
}

$(document).on('hide.bs.modal', '#edit_roles_modal, #edit_demographics_modal', function(event) { // do something
  var modal = $(this), form = modal.find('form'),
    delegate_count = form.find('.delegates .mini_addresscard').length,
    delegates_visible = form.find('.delegates').is(':visible');

  if ($(event.target).prop('tagName') === 'DIV'){
    if (delegates_visible && (delegate_count < 1)) {
      event.stopPropagation();
      alert('You cannot close this dialog without having at least one supervisor added.');
      return false;
    }
  }
});

function is_valid(text) {
  if (text.match(/^([A-Z]{2}|[A-Z]{1}9)\d{7}$/i)) {
    return true;
  } else {
    return false;
  }
}

$(document).on('keyup', '#user_dea_number', function(event)  {
  var text = $(this).val();

  if (is_valid(text)) {
    $('#add_dea_number').removeAttr('disabled');
  } else {
    $('#add_dea_number').attr('disabled', 'true');
  }
});

$(document).on('keyup', '#user_employer_dea_number', function(event)  {
  var text = $(this).val();

  if (is_valid(text)) {
    $('#add_employer_identifier_dea').removeAttr('disabled');
  } else {
    $('#add_employer_identifier_dea').attr('disabled', 'true');
  }
});

$(document).on('click', '#add_delegate', function() {
  RegistrationHelper.added_delegate = true;
  // var button = $(event.target);
  var button = $(this);
  var uri = button.data('url');
  if (typeof uri === 'undefined') {
    return false;
  }
  var input = button.parents('.delegates').find('.email_search');

  var data = {email:input.val().replace(/^\s+|\s+$/g, '')};
  var spinner = $('#delegate_add_spinner');
  var div_selector = 'div.delegates';

  send_superior_request(uri, data, div_selector, spinner, input, button);

  return false;
});

$(document).on('click', '#add_reviewer', function() {
  RegistrationHelper.added_delegate = true;
  var button = $(this);
  var uri = button.data('url');
  if (typeof uri === 'undefined') {
    return false;
  }
  var input = button.parents('.peer_reviewers').find('.email_search');

  var data = {email: input.val().replace(/^\s+|\s+$/g, '')};
  var spinner = $('#reviewer_add_spinner');
  var div_selector = 'div.peer_reviewers';

  send_superior_request(uri, data, div_selector, spinner, input, button);

  return false;
});

function send_superior_request(uri, data, div_selector, spinner, input, button) {
  spinner.css({display:'inline-block'});
  $.ajax({type:'POST', url: uri, data:data}).done(function(card) {
    var html = $(card);
    var data_id = html.attr('data-id');
    var existing_elem = $('.mini_addresscard[data-id=' + data_id + ']');
    if (existing_elem.length < 1) {

      input.popover('hide');
      // this is in a seperate timeout because IE8 is so damn stupid; it crashes if run directly
      setTimeout(function () {
        var address_cards = button.closest(div_selector).find('#address_cards');
        address_cards.append(html);
        var last_card = address_cards.find('div.mini_addresscard').last();
        last_card.css({display:'none'}).show('blind');
      }, 10);

    } else {

      var background = existing_elem.css('background');
      existing_elem.css({'background':'#FFFFAC'});
      existing_elem.animate({
        'background-color':'#EBEDF1'
      }, {
        complete: function() {
          existing_elem.css({background:background});
        }
      });
    }

  }).always(function(data) {
    spinner.hide();
    var input = $(div_selector + ' ' + '.email_search');
    if(data.status == undefined ){
      input.val('');
    }
  }).fail(function(data) {
    var input = $(div_selector + ' ' + '.email_search');
    input.popover('dispose');

    var error = 'Please try again later'; //incase something crazy happens
    var add_delegate = /delegate\/add/.test(uri);

    if(data.status == '400' ){
      if (add_delegate == true) {
        error = 'The e-mail address is either empty or its format is incorrect.<br />Use the email address in the format of <b>username@example.com</b>';
      }
    }
    if(data.status == '404' ){
      if (add_delegate == true) {
        error = 'The e-mail address provided cannot be specified as a delegate.<br />Use the email address belonging to an existing and active user.';
      } else {
        error = 'The address provided must be for a user with prescriptive authority as a supervisor';
      }
    }
    if(data.status == '406' ){
      if (add_delegate == true) {
        error = 'The address provided cannot be specified as a delegate.';
      } else {
        error = 'Cannot add a delegate as a supervisor.';
      }
    }
    if(data.status == '409') {
      if (add_delegate == true) {
        error = 'Delegate already added.';
      } else {
        error = 'Supervisor already added.';
      }
    }
    if(data.status == '422'){
      if (add_delegate == true) {
        error = 'You cannot add yourself as a delegate.';
      } else {
        error = 'You cannot add yourself as a supervisor.';
      }
    }
    if(data.status == '429') {
      if (add_delegate == true) {
        error = 'No more delegates can be added.';
      } else {
        error = 'No more delegators can be added.';
      }
    }
    add_popover(input, error);
    input.popover('show');
  });
}

function clearDeaInputAndIconsOnAdd(inputNode, suffixInputNode) {
  inputNode.val('');

  if (suffixInputNode) {
    suffixInputNode.val('');
  }

  var status_icon_check = $(inputNode).parent().find('img.check');
  var status_icon_x = $(inputNode).parent().find('img.x');

  status_icon_check.hide();
  status_icon_x.hide();
}

$(document).on('click', '#add_dea_number', function add_dea_number_handler(event) {
  $('#add_dea_number').attr('disabled', 'true');
  var spinner = $('.indeterminate_progress');

  if ($('#edit-dea').data('edit-dea') === true) {
    spinner.show();
  }

  var button = $(this);
  var uri = button.data('url');

  if (typeof uri === 'undefined') {
    return false;
  }
  var input = $('input#user_dea_number');
  var suffix_input = $('input#user_institution_number');

  var data = {dea_number:input.val().replace(/^\s+|\s+$/g, ''),institution_number:suffix_input.val()};
  var is_suffix = $('#suffix-number').attr('data-suffix-required');
  if (is_suffix == 'true'){
    if (suffix_input.val() == '' || suffix_input.val().length == 0 || suffix_input.val() == null){
      $('#add_dea_number').removeAttr('disabled');
      spinner.hide();
      return false;
    }
  }

  $.ajax({type:'POST', url: uri, data:data}).done(function(card) {
    clearDeaInputAndIconsOnAdd(input, suffix_input);

  }).always(function(data) {
    input.popover('show');
    spinner.hide();
  }).fail(function(data) {
    input.popover('show');
    spinner.hide();
  });

  setTimeout(function() {
    // delay long enough to not process second click from onchange + click event firing
    $('#add_dea_number').removeAttr('disabled');
  }, 200);
  return false;
});

$(document).on('click', '#add_employer_identifier_dea', function add_employer_identifier_dea_handler(event) {
  $('#add_employer_identifier_dea').attr('disabled', 'true');

  var button = $(this);
  var uri = button.data('url');

  if (typeof uri === 'undefined') {
    return false;
  }

  var input = $('input#user_employer_dea_number');
  var data = {employer_dea_number:input.val().replace(/^\s+|\s+$/g, '')};

  $.ajax({type:'POST', url: uri, data:data}).done(function(card) {
    var html = $(card);
    var data_id = html.attr('data-id');
    var existing_elem = $('.employer_dea_number[data-id=' + data_id + ']');

    clearDeaInputAndIconsOnAdd(input);

    if (existing_elem.length < 1) {
      add_new_identifiers_to_table_body('#employer_identifier_dea_numbers', html);
      var reload_url = $('#available_identifers').data('refresh-url');
      $('#available_identifers').load(reload_url, function(){
        if($('#available_identifers').html() != '') {
          $('#rx_management').removeClass('hidden');
        }
      });
    }
  }).always(function(data) {
    input.popover('show');
  }).fail(function(data) {
    input.popover('show');
  });

  setTimeout(function() {
    // delay long enough to not process second click from onchange + click event firing
    $('#add_employer_identifier_dea').removeAttr('disabled');
  }, 500);
  return false;
});

$(document).on('click', '#add_employer_identifier_npi', function add_employer_identifier_npi_handler(event) {
  $('#add_employer_identifier_npi').attr('disabled', 'true');

  var button = $(this);
  var uri = button.data('url');

  if (typeof uri === 'undefined') {
    return false;
  }
  var input = $('input#user_employer_npi_number');
  var data = {employer_npi_number:input.val().replace(/^\s+|\s+$/g, '')};

  $.ajax({type:'POST', url: uri, data:data}).done(function(card) {
    var html = $(card);
    var data_id = html.attr('data-id');
    var existing_elem = $('.employer_npi_number[data-id=' + data_id + ']');
    input.val('');
    if (existing_elem.length < 1) {
      add_new_identifiers_to_table_body('#employer_identifier_npi_numbers', html);
      if($('#available_identifers').length) {
        var reload_url = $('#available_identifers').data('refresh-url');
        $('#available_identifers').load(reload_url, function() {
          if ($('#available_identifers').html() != '') {
            $('#rx_management').removeClass('hidden');
          }
        });
      }
    }
  }).always(function(data) {
    input.popover('show');
    $('#add_employer_identifier_npi').removeAttr('disabled');
  }).fail(function(data) {
    input.popover('show');
  });

  return false;
});

$(document).on('click', '#add_employer_identifier_ncpdp', function add_employer_identifier_ncpdp_handler(event) {
  $('#add_employer_identifier_ncpdp').attr('disabled', 'true');

  var button = $(this);
  var uri = button.data('url');

  if (typeof uri === 'undefined') {
    return false;
  }
  var input = $('#user_ncpdp_nabp_number');
  var data = {employer_ncpdp_number:input.val().replace(/^\s+|\s+$/g, '')};

  $.ajax({type:'POST', url: uri, data:data}).done(function(card) {
    var html = $(card);
    var data_id = html.attr('data-id');
    var existing_elem = $('.employer_ncpdp_number[data-id=' + data_id + ']');
    input.val('');
    if (existing_elem.length < 1) {
      add_new_identifiers_to_table_body('#employer_identifier_ncpdp_numbers', html);
      var reload_url = $('#available_identifers').data('refresh-url');
      $('#available_identifers').load(reload_url, function(){
        if($('#available_identifers').html() != '') {
          $('#rx_management').removeClass('hidden');
        }
      });
    }
  }).always(function(data) {
    input.popover('show');
    $('#add_employer_identifier_ncpdp').removeAttr('disabled');
  }).fail(function(data) {
    input.popover('show');
  });

  return false;
});

function add_new_identifiers_to_table_body (bodyIdenticator, html) {
  var $employer_identifiers_body = $(bodyIdenticator);
  $employer_identifiers_body.parent().show('blind');
  $employer_identifiers_body.append(html);
}

$(document).on('click', '.remove_dea_number', function(event) {
  event.preventDefault();
  var button = $(this);
  var uri = button.data('url');
  if (typeof uri === 'undefined') {
    return false;
  }

  $.ajax({type:'POST', url: uri}).done();
  return false;
});

$(document).on('click', '.remove_employer_identifier_dea', function(event) {
  event.preventDefault();
  var button = $(this);
  var uri = button.data('url');
  if (typeof uri === 'undefined') {
    return false;
  }

  $.ajax({type:'POST', url: uri}).done(function() {
    var reload_url = $('#available_identifers').data('refresh-url');
    $('#available_identifers').load(reload_url, function(){
      if($('#available_identifers').html() == '') {
        $('#rx_management').addClass('hidden');
      }
    });
  });

  return false;
});

$(document).on('click', '.remove_employer_identifier_npi', function(event) {
  event.preventDefault();
  var button = $(this);
  var uri = button.data('url');

  if (typeof uri === 'undefined') {
    return false;
  }

  $.ajax({type:'POST', url: uri}).done(function() {
    var reload_url = $('#available_identifers').data('refresh-url');
    $('#available_identifers').load(reload_url, function(){
      if($('#available_identifers').html() == '') {
        $('#rx_management').addClass('hidden');
      }
    });
  });

  return false;
});

$(document).on('click', '.remove_employer_identifier_ncpdp', function(event) {
  event.preventDefault();
  var button = $(this);
  var uri = button.data('url');
  if (typeof uri === 'undefined') {
    return false;
  }

  $.ajax({type:'POST', url: uri}).done(function() {
    var reload_url = $('#available_identifers').data('refresh-url');
    $('#available_identifers').load(reload_url, function(){
      if($('#available_identifers').html() == '') {
        $('#rx_management').addClass('hidden');
      }
    });
  });

  return false;
});

$(function() {
  if($('#employer input').length == 0){
    $('#employer_header').hide();
  }

  if ($('#professional input').length == 0){
    $('#professional').hide();
  }
});

['#email', '#password'].forEach(mountFieldConfirmationMatchValidator);

function mountFieldConfirmationMatchValidator(parentId) {
  var element = parentId + '_confirmation';
  $(document).on('change', element, function(){
    var $this = $(this);
    var $parent = $(parentId);
    compareValueAndToggleErrorMessage($this, $parent);
    $parent.on('change', function(){
      compareValueAndToggleErrorMessage($this, $parent);
    });
  });
}

function compareValueAndToggleErrorMessage($this, $parent) {
  if($this.val() == $parent.val()) {
    $this.next().css('display', 'none');
  }else {
    $this.next().css('display', '');
  }
}
