/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   datepicker.js
 * 
 * Adapted for for the PMP AWARxE platform by Bamboo Health on 06/20
 * 
 * Statement of changes:
 * All references to cancel and submit buttons have been removed,
 * including the initial declaration of `this.[button-type] = ...`
 * and subsequent functions.
 * 
 * Implementation
 * 
 * Start and end dates
 * 
 * By default, enddate is the current date and startdate is 1/1/1900.
 * Both can be set/either or. Start date must be equal to or earlier than the enddate.
 * API
 * 
 */

import { CalendarButtonInput } from './datepicker_button';
import { accessibleDatePickerHelper } from '../accessible_date_helpers';
import { DatePickerFeatures } from './datepicker_features';

export const DateRangeDatePicker = function (dateRange, inputType) {
  this.datePickerFeatures = new DatePickerFeatures(this);
  this.accessibleDatePickerHelper = accessibleDatePickerHelper;

  if (inputType === 'start') {
    this.inputNode = dateRange.dateRangeBeginInputNode;
    this.buttonNode = dateRange.dateRangeBeginInputNodeButton;
    this.dialogNode = dateRange.dateRangeBeginInputDialog;
  }

  if (inputType === 'end') {
    this.inputNode = dateRange.dateRangeEndInputNode;
    this.buttonNode = dateRange.dateRangeEndInputNodeButton;
    this.dialogNode = dateRange.dateRangeEndInputDialog;
  }
  
  this.messageNode = this.dialogNode.querySelector('.message');

  this.dateInput = new CalendarButtonInput(this.inputNode, this.buttonNode, this);
  
  // Inherit dates and constraints from date range component
  this.dateRange = dateRange;
  this.endDate = this.dateRange.endDate;
  this.startDate = this.dateRange.startDate;
  this.accessibleDateConstraints = this.dateRange.dateConstraints;
  this.earliestYear = this.dateRange.earliestYear;
  this.latestYear = this.dateRange.latestYear;
  this.defaultCalendarDate = new Date();
  this.defaultDate = this.defaultCalendarDate;

  this.headerRow = this.dialogNode.querySelector('.header');
  this.headerRowElements = this.headerRow.querySelectorAll('button');

  this.MonthYearNode = this.dialogNode.querySelector('.monthYear');

  this.prevYearNode = this.dialogNode.querySelector('.prevYear');
  this.prevMonthNode = this.dialogNode.querySelector('.prevMonth');
  this.nextMonthNode = this.dialogNode.querySelector('.nextMonth');
  this.nextYearNode = this.dialogNode.querySelector('.nextYear');

  this.tbodyNode = this.dialogNode.querySelector('table.dates tbody');

  if (inputType === 'start') {
    this.focusDay = this.startDate || new Date(this.earliestYear);
    this.selectedDay = this.startDate || new Date(this.earliestYear);
    // if (this.inputNode.value) {
    //   this.focusDay = new Date(this.inputNode.value);
    // } else {
    //   this.focusDay = this.endDate || new Date(this.earliestYear);
    // }
    // this.selectedDay = this.focusDay || new Date(this.earliestYear);
  }
 
  if (inputType === 'end') {
    this.focusDay = this.endDate || new Date(this.latestYear);
    this.selectedDay = this.endDate || new Date(this.latestYear);
    //  if (this.inputNode.value) {
    //      this.focusDay = new Date(this.inputNode.value);
    //  } else {
    //      this.focusDay = this.endDate || new Date(this.latestYear);
    //  }
    // this.selectedDay = this.focusDay || new Date(this.latestYear);
  }

  this.savedDate = this.selectedDay;
};

DateRangeDatePicker.prototype.init = function () {
  this.dateInput.init();
  this.datePickerFeatures.init();
};

DateRangeDatePicker.prototype.updateGrid = function () {
  this.datePickerFeatures.updateGrid();
};

DateRangeDatePicker.prototype.updateLastRowVisibility = function (dayOfWeek, daysInMonth) {
  this.datePickerFeatures.updateLastRowVisibility(dayOfWeek, daysInMonth);
};

DateRangeDatePicker.prototype.setPlaceholder = function () {
  this.datePickerFeatures.setPlaceholder();
};

DateRangeDatePicker.prototype.setMask = function () {
  this.datePickerFeatures.setMask();
};

DateRangeDatePicker.prototype.updateNavigationArrowVisibility = function (d) {
  this.datePickerFeatures.updateNavigationArrowVisibility(d);
};

DateRangeDatePicker.prototype.limitArrowsPerLatestAllowedDate = function (visibleCalendarMonth) {
  this.datePickerFeatures.limitArrowsPerLatestAllowedDate(visibleCalendarMonth);
};

DateRangeDatePicker.prototype.limitArrowsPerEarliestAllowedDate = function (visibleCalendarMonth) {
  this.datePickerFeatures.limitArrowsPerEarliestAllowedDate(visibleCalendarMonth);
};

DateRangeDatePicker.prototype.hideLastRow = function () {
  this.datePickerFeatures.hideLastRow();
};

DateRangeDatePicker.prototype.showLastRow = function () {
  this.datePickerFeatures.showLastRow();
};

DateRangeDatePicker.prototype.hideNextYear = function () {
  this.datePickerFeatures.hideNextYear();
};

DateRangeDatePicker.prototype.showNextYear = function () {
  this.datePickerFeatures.showNextYear();
};

DateRangeDatePicker.prototype.hideNextMonth = function () {
  this.datePickerFeatures.hideNextMonth();
};

DateRangeDatePicker.prototype.showNextMonth = function () {
  this.datePickerFeatures.showNextMonth();
};

DateRangeDatePicker.prototype.hidePrevMonth = function () {
  this.datePickerFeatures.hidePrevMonth();
};

DateRangeDatePicker.prototype.showPrevMonth = function () {
  this.datePickerFeatures.showPrevMonth();
};

DateRangeDatePicker.prototype.hidePrevYear = function () {
  this.datePickerFeatures.hidePrevYear();
};

DateRangeDatePicker.prototype.showPrevYear = function () {
  this.datePickerFeatures.showPrevYear();
};


DateRangeDatePicker.prototype.setFocusDay = function (flag) {
  this.datePickerFeatures.setFocusDay(flag);
};

DateRangeDatePicker.prototype.updateDay = function (day) {
  this.datePickerFeatures.updateDay(day);
};

DateRangeDatePicker.prototype.getDaysInLastMonth = function () {
  return this.datePickerFeatures.getDaysInLastMonth();
};

DateRangeDatePicker.prototype.getDaysInMonth = function () {
  return this.datePickerFeatures.getDaysInMonth();
};

DateRangeDatePicker.prototype.show = function () {
  this.datePickerFeatures.show();
};

DateRangeDatePicker.prototype.isOpen = function () {
  return this.datePickerFeatures.isOpen();
};

DateRangeDatePicker.prototype.hide = function () {
  this.datePickerFeatures.hide();
};

DateRangeDatePicker.prototype.handleBackgroundMouseDown = function (event) {
  this.datePickerFeatures.handleBackgroundMouseDown(event);
};

DateRangeDatePicker.prototype.handleBackgroundMouseUp = function () {
  this.datePickerFeatures.handleBackgroundMouseUp();
};

DateRangeDatePicker.prototype.handleInputNodeBlur = function () {
  this.datePickerFeatures.handleInputNodeBlur();
};

DateRangeDatePicker.prototype.saveDate = function (newDate) {
  this.datePickerFeatures.saveDate(newDate);
};

DateRangeDatePicker.prototype.updateInputNodeValue = function () {
  this.datePickerFeatures.updateInputNodeValue();
};

DateRangeDatePicker.prototype.handleNextYearButton = function (event) {
  this.datePickerFeatures.handleNextYearButton(event);
};

DateRangeDatePicker.prototype.handlePreviousYearButton = function (event) {
  this.datePickerFeatures.handlePreviousYearButton(event);
};

DateRangeDatePicker.prototype.handleNextMonthButton = function (event) {
  this.datePickerFeatures.handleNextMonthButton(event);
};

DateRangeDatePicker.prototype.handlePreviousMonthButton = function (event) {
  this.datePickerFeatures.handlePreviousMonthButton(event);
};

DateRangeDatePicker.prototype.moveToNextYear = function () {
  this.datePickerFeatures.moveToNextYear();
};

DateRangeDatePicker.prototype.moveToPreviousYear = function () {
  this.datePickerFeatures.moveToPreviousYear();
};

DateRangeDatePicker.prototype.moveToNextMonth = function () {
  this.datePickerFeatures.moveToNextMonth();
};

DateRangeDatePicker.prototype.moveToPreviousMonth = function () {
  this.datePickerFeatures.moveToPreviousMonth();

};

DateRangeDatePicker.prototype.moveFocusToDay = function (day) {
  this.datePickerFeatures.moveFocusToDay(day);
};

DateRangeDatePicker.prototype.moveFocusToNextDay = function () {
  this.datePickerFeatures.moveFocusToNextDay();
};

DateRangeDatePicker.prototype.moveFocusToNextWeek = function () {
  this.datePickerFeatures.moveFocusToNextWeek();
};

DateRangeDatePicker.prototype.moveFocusToPreviousDay = function () {
  this.datePickerFeatures.moveFocusToPreviousDay();
};

DateRangeDatePicker.prototype.moveFocusToPreviousWeek = function () {
  this.datePickerFeatures.moveFocusToPreviousWeek();
};

DateRangeDatePicker.prototype.moveFocusToFirstDayOfWeek = function () {
  this.datePickerFeatures.moveFocusToFirstDayOfWeek();
};

DateRangeDatePicker.prototype.moveFocusToLastDayOfWeek = function () {
  this.datePickerFeatures.moveFocusToLastDayOfWeek();
};

DateRangeDatePicker.prototype.setTextboxDate = function (day) {
  this.datePickerFeatures.setTextboxDate(day);
};

DateRangeDatePicker.prototype.setAriaCurrentDate = function (day) {
  this.datePickerFeatures.setAriaCurrentDate(day);
};

DateRangeDatePicker.prototype.getDateInput = function () {
  this.datePickerFeatures.getDateInput();
};

DateRangeDatePicker.prototype.updateDateDialogCoordinates = function () {
  this.datePickerFeatures.updateDateDialogCoordinates();
};

DateRangeDatePicker.prototype.updateDateDialogCoordinatesOnScroll = function () {
  this.datePickerFeatures.updateDateDialogCoordinatesOnScroll();
};

DateRangeDatePicker.prototype.reupdateDateDialogCoordinates = function () {
  this.datePickerFeatures.reupdateDateDialogCoordinates();
};

DateRangeDatePicker.prototype.getDateForButtonLabel = function (year, month, day) {
  return this.datePickerFeatures.getDateForButtonLabel(year, month, day);
};

DateRangeDatePicker.prototype.setEndDate = function (inputNode) {
  return this.datePickerFeatures.setEndDate(inputNode);
};

DateRangeDatePicker.prototype.setStartDate = function (dataNode) {
  return this.datePickerFeatures.setStartDate(dataNode);
};

DateRangeDatePicker.prototype.setMessage = function (str) {
  this.datePickerFeatures.setMessage(str);
};

DateRangeDatePicker.prototype.setUniqueIdRelationship = function () {
  this.datePickerFeatures.setUniqueIdRelationship();
};
