$(function () {

  $('tr.alert_request_row').on('click', function (event) {
    var object = $(event.target).closest('tr');
    load_patients(object);
  });

  $(document).on('click', 'a.letter-template-link', function () {
    $('*[data-modal-auto-show]').modal('hide');
    activate_letter_tab();
  });
});

function activate_patient_tab() {
  $('#select_patients_tab').trigger('click');
}

function activate_letter_tab() {
  $('#letter_template_tab').trigger('click');
}

function load_patients(clicked_report) {
  var url = clicked_report.first().attr('data-patient-list-url');

  $('div#patients_pick_list, div[data-detail-card]').load(url, function () {
    $('div#patients_pick_list, div[data-detail-card]').attr('data-refresh-url', url);
    activate_patient_tab();
    $('*[data-modal-auto-show]').modal('show');
  });
}
