var ModernNavigation = (function($){

  function initialize() {
    deactivateOtherMenuElementsOnClick();
    collapseAllWhenClickedOutside();
  }


  function deactivateOtherMenuElementsOnClick() {
    $('nav.navbar').on('click', 'a[data-toggle]', function(event){
      $(this).addClass('active');
      deactivateUnclickedMenu($(this));
    });
  }

  function deactivateUnclickedMenu(clickedElement) {
    $.each($('nav.navbar a[data-toggle=\'collapse\']'), function(i, obj) {
      if ($(this).data('target') !== clickedElement.data('target')) {
        $(this).removeClass('active');
        collapseUnclickedMenuDataTarget($($(this).data('target')));
      }
    });
  }

  function collapseUnclickedMenuDataTarget(dataTarget) {
    dataTarget.collapse('hide');
  }

  function collapseAllWhenClickedOutside() {
    $(document).on('click', function(event){
      if (!$(event.target).hasClass('nav-item')) {
        $.each($('nav.navbar a[data-toggle=\'collapse\']'), function(i, obj) {
          collapseUnclickedMenuDataTarget($($(this).data('target')));
        });
      }
    });
  }

  return {initialize:initialize};
})($);


$(function(){
  ModernNavigation.initialize();
});