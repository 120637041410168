export const DateParser = {
  dateFromString: function (string) {

    const date = {
      getFullYear: function () {
        return parseInt(string['year']);
      },
      getMonth: function () {
        return parseInt(string['month']);
      },
      getDate: function () {
        return parseInt(string['day']);
      }
    };

    return date;
  },
  getQuarter: function(d) {
    switch (d.getMonth()) {
    case 1:
      return 0;
      break;
    case 4:
      return 1;
      break;
    case 7:
      return 2;
      break;
    case 10:
      return 3;
      break;
    default:
      return 0;
    }
  }
};

var DashboardPanel = (function ($) {

  function instantiate(element) {
    var me = new Object();
    me.element = $(element);
    me.initialize = function () {
      me.type = me.element.data('dashboard-panel');
      if (me.type === 'pending_tasks') {
        var rows = me.element.find('tr');
        rows.on('click', function (event) {
          var selected_row = $(event.delegateTarget);
          rows.removeClass('selected');
          selected_row.addClass('selected');
          $('#detail_column').load(selected_row.data('dashboard-url'));
        });
      }
    };

    me.initialize();
  }
  $(function () {
    $('*[data-dashboard-panel]').each(function (index, item) {
      instantiate(item);
    });

  });


})($);

export const DashboardGraph = (function ($) {

  function instantiate() {
    var me = new Object();
    me.chart_settings = function(settings) {
      return $.extend(true, settings, {
        xaxes:[{mode:'categories', tickLength:0}],
        series: {
          // stack: true,
          // highlightColor:'rgba(255,255,255,0.5)',
          bars: {

            show: true,
            barWidth: 0.6,
            align:'center',
            lineWidth:0,
            fill:0.7
          }
        },
        // colors:['rgba(255,0,0,1.0)','rgba(0,0,255,1.0)'],
        grid:{
          hoverable:true,
          borderWidth:1,
          borderColor:'#999999',
          color:'#000000', //somehow this renders way lighter :(
          backgroundColor:null
        },
        legend:{
          container:me.legend_node
        }
      });
    };
    return me;
  }
  return {instantiate:instantiate};
})($);

var ResponseTimeMeter = (function ($) {
  var node, bar_width, pixel_delta_value, update_timer, saved_value;
  var MAX_VALUE = 15;
  function supportsTransitions() {
    var b = document.body || document.documentElement,
      s = b.style,
      p = 'transition';

    if (typeof s[p] == 'string') { return true; }

    // Tests for vendor specific prop
    var v = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];
    p = p.charAt(0).toUpperCase() + p.substr(1);

    for (var i=0; i<v.length; i++) {
      if (typeof s[v[i] + p] == 'string') { return true; }
    }

    return false;
  }

  function initialize(nde) {
    node = $(nde);
    // console.log(node);
    bar_width = node.width();
    pixel_delta_value = bar_width / MAX_VALUE;

    node.on('transitionend webkitTransitionEnd', function(){
      animation_finished();
    });
    $(document).on('ResponseTime:Returned', function (event, json) {
      set_value(json.combined.toFixed(1));
    });

    $( document ).ajaxComplete(function( event, xhr, settings ) {
      if ( settings.url.match('dashboard/response_time')) {
        try {
          var json = JSON.parse(xhr.responseText);
          $(document).trigger('ResponseTime:Returned', json.speedometer);
        } catch (error) {
          //silently fail.
        }
      }
    });
  }

  function animation_finished() {
    clearInterval(update_timer);
    set_value_node(parseFloat(saved_value).toFixed(1));
  }

  function value_to_left_delta(value) {
    return value * pixel_delta_value;
  }

  function needle_node() {
    return node.find('.needle');
  }

  function set_value(value) {
    if (value < 0) {
      value = 0;
    }
    if (value > 15) {
      value = 15;
    }
    saved_value = value;
    var delta = value_to_left_delta(value);
    if (supportsTransitions()) {
      update_value_node_with_value_over_time(value);
    } else {
      set_value_node(value);
    }

    needle_node().css({left:delta});
  }

  function set_value_node(string) {
    node.find('span').html(string);
  }

  function update_value_node_with_value_over_time(value) {
    var current_value = parseFloat(node.text()),
      animation_began_at = new Date().getTime(),
      millisecond_duration = 1000, //make sure to match this in the css transition
      animation_ends_at = animation_began_at + millisecond_duration,
      interframe_delay = 10,
      frame = 0;

    clearInterval(update_timer);
    update_timer = setInterval(function () {
      var now = new Date().getTime(),
        time_passed = (now - animation_began_at),
        time_delta = animation_ends_at - animation_began_at,
        percent = (time_passed * 100) / time_delta,
        percent_float = $.easing.swing(percent * 0.01), //closely matches ease-in-out for css transitions
        new_value = ((value - current_value) * percent_float) + current_value;

      frame = frame + 1;

      set_value_node(new_value.toFixed(1));
      if (parseFloat(new_value).toFixed(1) === parseFloat(value).toFixed(1)) {
        animation_finished();
      }
    }, interframe_delay);
  }
  return {initialize:initialize, set_value:set_value};
})($);

$(function () {
  $('body').append('<div id="dates"></div>');
  // meter = Speedometer.initialize('#speedometer');
  ResponseTimeMeter.initialize('div#response_time_bar');
  $('#user_form_chart').trigger('submit');

  //Init graphs
  $('#form_chart_patterns').trigger('submit');
  $('#form_chart').trigger('submit');
  $('#response_time_form').trigger('submit');
});
