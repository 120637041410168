var PasswordRequirementDisplay = (function() {
  function attach_behavior_to_node(n) {
    var myself = new Object();

    myself.markRequirementsAs = function(checked, element) {
      var $element = $(element);
      $element.removeClass('checked');
      checked && $element.addClass('checked');
    };

    myself.validate_password = function() {
      var password = myself.node.val();

      myself.markRequirementsAs(password.length >= myself.node.data('passwordMinLength'), '.min-chars-js');
      myself.markRequirementsAs(password.match(/[A-Z]/), '.upper-case-js');
      myself.markRequirementsAs(password.match(/[a-z]/), '.lower-case-js');
      myself.markRequirementsAs(password.replace(/\s/g, '').match(/\W/), '.special-char-js');
      myself.markRequirementsAs(password.length <= 72 && password.length != 0, '.max-chars-js');
    };

    myself.initialize = function(node) {
      $(node).data('password_requirement_display', myself);
      myself.node = node;
    };

    myself.initialize(n);

    return myself;
  }
  return {
    attach_behavior_to_node: attach_behavior_to_node
  };

})();

$(function(event) {
  $('input[data-password-requirements-display]').each(function() {
    var input_field = $(this);
    PasswordRequirementDisplay.attach_behavior_to_node(input_field);
  });
});

$(document).on('blur keyup', 'input[data-password-requirements-display]', function (event) {
  var node = $(event.target);
  $(node).data('password_requirement_display').validate_password();
});
