// This is needed for all IE versions
var IESRCDOCShim = (function ($) {
  $(function (event) {
    $('iframe#gateway_report_with_srcdoc').each(function () {
      var iframe = $(this),
        inner_source = iframe.attr('srcdoc'),
        iframe_document = iframe[0].contentWindow.document;
      if (iframe_document.body.innerHTML === '') {
        // This means the content of the iframe is blank, and it is likely internet explorer!
        iframe_document.open('text/html', 'replace');
        iframe_document.write(inner_source);
        iframe_document.close();
      }
    });
  });
})(jQuery);
