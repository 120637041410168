(function() {
  $(function() {
    // Delegate the click to form so we don't insert a click handler on every radio button
    return $('#new_pharmacy_rx_request').on('click', 'input[type=radio]', function() {
      // Enable Search button only after they have selected an identifier
      $('#pharmacy_rx_search_button').removeAttr('disabled');
      // When an indentifier is selected, make sure to set the identifier type on the value
      // of the hidden identifier_type element, so that it gets inserted into the correct
      // part of the model in the controller.
      return $('#pharmacy_rx_request_identifier_type').val($(this).data('id-type'));
    });
  });

}).call(this);
