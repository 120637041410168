import { Flash } from '../app/flash';

class MedicalMarijuanaProductsController {
  constructor() {
    this.dragHandler = this.dragHandler.bind(this);
    this.dropHandler = this.dropHandler.bind(this);
    this.startUpload = this.startUpload.bind(this);
    $(document).on('click', '.file-chooser', this.showUploadDialog);
    $(document).on('change', '#medical_marijuana_product_csv', this.startUpload);
    const selected = $('.add-medical-marijuana input[name=method][checked=checked]').prop('value');

    $('.add-marijuana-product').hide();
    $('.add-marijuana-product.' + selected).show();

    $(document).on('change', 'input[name=method]', function() {
      $('.add-marijuana-product').hide();
      return $('.' + $(this).val()).show();
    });

    const dropArea = document.getElementById('mm-file-upload-box');

    if (dropArea) {
      dropArea.addEventListener('dragenter', this.dragHandler);
      dropArea.addEventListener('dragover', this.dragHandler);
      dropArea.addEventListener('drop', this.dropHandler);
    }
  }

  dragHandler(e) {
    e.stopPropagation();
    return e.preventDefault();
  }

  dropHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    const formData = new FormData();

    formData.append('medical_marijuana_product[csv]', e.dataTransfer.files[0]);
    this.uploadFile(formData, $('#mm-csv-upload-form')[0].action);
    return false;
  }

  toggleUI() {
    $('.add-marijuana-product').hide();
    const target = `.${$(this).val()}`;
    $(target).show();
    return false;
  }

  showUploadDialog() {
    $('#medical_marijuana_product_csv').val(null);
    $('#medical_marijuana_product_csv').trigger('click');
    return false;
  }

  startUpload() {
    const form = $('#mm-csv-upload-form')[0];
    const formData = new FormData(form);
    this.uploadFile(formData, form.action);
    return false;
  }

  uploadFile(formData, action) {
    $('#mm-file-upload-box').hide();
    $('#mm-upload').show();
    return $.ajax({
      url: action,
      data: formData,
      processData: false,
      contentType: false,
      type: 'POST',
      dataType: 'json',
      success: this.handleResponse,
      error: this.handleError,
      xhr: this.performRequest,
      complete: this.resetForm
    });
  }

  // Create a custom XHR object with a callback to handle the progress bar.
  performRequest(e) {
    const xhr = new window.XMLHttpRequest();
    xhr.upload.addEventListener('progress', this.updateProgressBar);
    return xhr;
  }

  // update the progress bar as the file is uploaded
  updateProgressBar(e) {
    // lengthComputable must be set to true for this to work.
    if (e.lengthComputable) {
      const percentComplete = (e.loaded / e.total) * 100;
      return $('#mm-upload progress').val(percentComplete);
    }
  }

  // Handle the successful result
  handleResponse(data) {
    $('#mm-upload progress').val(100);
    if (data['status'] === 'error') {
      return Flash.error(data['message']);
    } else {
      // Flash.notice(data['message'])
      return $('#mm-body').html(data['message']);
    }
  }

  // Reset the form
  resetForm() {
    $('#mm-file-upload-box').show();
    $('#mm-upload').hide();
    return $('#mm-upload progress').val(0);
  }

  // Raise an error if there is a problem.
  handleError(e) {
    console.log(e);
    return Flash.error('An error occurred while attempting to upload your file. Please try again later or contact customer support.');
  }
}

document.addEventListener('DOMContentLoaded', function() {
  let mmp;
  return mmp = new MedicalMarijuanaProductsController();
});
  