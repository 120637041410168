$(function() {
  $(() => show_selected_form());

  var show_selected_form = () => $('.new_state_licensee input[name="process_type"], .edit_state_licensee input[name="process_type"]').each(function() {
    if ((this.value === 'bulk_upload') && this.checked) {
      return $('#bulk_state_licensee_form').removeClass('hidden');
    } else if ((this.value === 'manual') && this.checked) {
      return $('#manual_state_licensee_form').removeClass('hidden');
    }
  });

  return $(document).on('click', '.add_user_as_state_licensee_button', function(e) {
    return $('#add_user_as_state_licensee_modal').load($(this).data('modal-path'), () => $('#add_user_as_state_licensee_modal').modal('show'));
  });
});