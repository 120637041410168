// Note- this works best for non-ajax forms.
// If you with to enforce a single form submit for ajax forms, please
// see unique_form_submission_motivator.js
var UniqueFormSubmissionEnforcer = (function ($) {
  var instance_methods = {
      construct: function (form) {
        this.form = form;
        this.submit_count = 0;
      }, 
      submit_button: function () {
        return this.form.find(':submit');
      },
      enforce: function (event) {
        if (this.submit_count > 0) {
          event.preventDefault();
        }
        this.submit_count += 1;
      }
    },
    class_methods = {
      instantiate: function (arg) {
        var instance = new Object();
        for (let method in instance_methods) {
          if (instance_methods.hasOwnProperty(method)) {
            instance[method] = instance_methods[method];
          }
        }
        instance.construct(arg);
        return instance;
      },
      setup: function () {
        $(document).on('submit', 'form[data-enforce-unique-submissions]', function (submit_event) {
          var form = $(submit_event.target).closest('form'),
            enforcer = form.data('enforcer');
          if (typeof(enforcer) === 'undefined') {
            enforcer = UniqueFormSubmissionEnforcer.instantiate(form);
            form.data('enforcer', enforcer);
          }
          enforcer.enforce(submit_event); 
        });
      }
    };
  return class_methods;
})(jQuery);

UniqueFormSubmissionEnforcer.setup();