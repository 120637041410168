var DeaLookupField = (function ($) {
  function enable_dea_validate(node) {

    var input = node.find('input.dea'),
      optional_placement = node.data('placement'),
      status_icon_check = node.find('img.check'),
      status_icon_x = node.find('img.x'),
      status_container = status_icon_x.parent(),
      default_button_on_input_change = node.find('*[data-default-on-change]');
    if (status_container.length === 0) {
      status_container = input.parent();
    }
    function set_status(valid) {
      status_icon_check.hide();
      status_icon_x.hide();
      if (valid) {
        status_icon_check.show();
        $('#add_dea_number').removeAttr('disabled');
      } else {
        status_icon_x.show();
        $('#add_dea_number').attr('disabled', 'true');
      }
    }
    function is_valid(text) {
      if (text.match(/^([A-Z]{2}|[A-Z]{1}9)\d{7}$/i)) {
        return true;
      } else {
        return false;
      }
    }
    function validate_input(event) {
      var text = $(this).val();
      if (is_valid(text)) {
        set_status(true);
        $(status_container).popover('hide');
      } else {
        set_status(false);
      }
    }
    //This function was added because people would put dea numbers in the box and forget to add them before submitting, assuming putting them in the box was good enough. I forget which story number this is. Sorry! -Seth
    function display_conditional_validation_message_or_automatically_add(event) {
      var text = $(this).val();
      if (is_valid(text) || text == '') {
        if (default_button_on_input_change.length > 0) {
          // We want to disable automatically sending if there are any kind of optional suffix/prefix indicators added.
          if (input.length <= 1) {
            default_button_on_input_change.trigger('click');
          }
        }
        $(status_container).popover('hide');
      } else{

        var options = {
          placement:'top',
          trigger:'manual',
          content:'DEA Number is invalid.'
        };
        if (typeof(optional_placement) !== 'undefined') {
          options['placement'] = optional_placement;
        }
        $(status_container).popover(options).popover('show');
      }
    }

    input.on('keyup', validate_input);
    input.on('change', display_conditional_validation_message_or_automatically_add);
  }

  $(function () {
    $('*[data-dea-validate]').each(function () {
      enable_dea_validate($(this));
    });

  });
})($);

var DeaValidateForAutofill = (function ($) {
  function autofill_fields_with_selectors (map) {
    $.each(map, function(key, value) {
      var node = $(key);
      if (node.prop('tagName') === 'SELECT') {
        node.children('option').filter(function(){return $(this).val()==value;}).attr('selected', true);
      } else {
        node.val(value).effect('highlight');
      }

    });
  }
  function enable_dea_autofill(node) {
    var container = $(node),
      dea = node.data('id'),
      autofill_button = node.find('button.autofill-button'),
      validation_url = container.data('validate-dea-when-created');

    container.removeAttr('data-validate-dea-when-created');
    $.getJSON(validation_url, function(data, status, xhr) {
      if (data && data.status === 'valid') {
        autofill_button.show();
        autofill_button.on('click', function (event) {
          event.preventDefault();

          var map = {
            '#user_first_name':data.registration.first_name,
            '#user_middle_name':data.registration.middle_name,
            '#user_last_name':data.registration.last_name
          };
          $.each(data.registration, function (key, value) {
            var linked_node = $('input[data-search-and-autofill-key='+key+']');
            if (linked_node.count > 0) {
              map[linked_node] = value;
            }
          });
        });
      }
    });
  }

  function enable_employer_dea_autofill(node) {
    var container = $(node),
      dea = node.data('id'),
      autofill_button = node.find('button.autofill-button'),
      validation_url = container.data('validate-dea-when-created');
    container.removeAttr('data-validate-dea-when-created');
    $.getJSON(validation_url, function(data, status, xhr) {
      if (data && data.status === 'valid') {
        autofill_button.show();
        autofill_button.on('click', function (event) {
          event.preventDefault();

          var map = {
            '#user_employer_name': data.registration.name,
            '#user_address1': data.registration.clean_address_one,
            '#user_address2': data.registration.clean_address_two,
            '#user_city': data.registration.clean_city,
            '#user_state': data.registration.clean_state,
            '#user_postal_code': data.registration.clean_postal_code
          };
          $.each(data.registration, function (key, value) {
            var linked_node = $('input[data-search-and-autofill-key='+key+']');
            if (linked_node.count > 0) {
              map[linked_node] = value;
            }
          });
          autofill_fields_with_selectors(map);
        });
      }
    });
  }

  $(function (evt, xhr, status) {
    $('*[data-validate-dea-when-created]').each(function () {
      if(this.id.indexOf('employer') != -1){
        enable_employer_dea_autofill($(this));
      }else{
        enable_dea_autofill($(this));
      }
    });
  });
  $(document).ajaxComplete(function (evt, xhr, status) {
    setTimeout(function () {
      $('*[data-validate-dea-when-created]').each(function () {
        if(this.id.indexOf('employer') != -1){
          enable_employer_dea_autofill($(this));
        }else{
          enable_dea_autofill($(this));
        }
      });
    }, 100);

  });
})($);
