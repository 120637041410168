var MenuAdornments = (function ($) {
  function setup(json) {
    $(function () {
      adorn_items(json);
    });
  }
  // In the future, if we want to update the adornments dynamically,
  // we can just call this method with new json.
  // The format looks like this:
  // {
  //   "/Home/Notices": {
  //     "before_content": null,
  //     "after_content": "(3)",
  //     "css_class": "admin-notice-adornment",
  //     "style": null
  //   }
  // }
  function adorn_items(json) {
    var adornments_hash = JSON.parse(json);
    for (var path in adornments_hash) {
      if (adornments_hash.hasOwnProperty(path)) {
        var adornment_data = adornments_hash[path],
          menu_item = $('*[data-menu-path="' + path + '"] a');
        adorn_item(menu_item, adornment_data);
      }
    }
  }

  function adorn_item(node, data) {
    node.addClass(data.css_class);
    node.attr('data-before-content', data.before_content);
    node.attr('data-after-content', data.after_content);
    if (data.style !== null) {
      node.css(data.style);
    }
  }

  return {
    setup: setup
  };
}(jQuery));

window.MenuAdornments = MenuAdornments;
