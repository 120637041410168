var uncheck_btn = (function() {
  function setup() {
    $('.myrx_radiobtn').on('change', function() {
      if ($(this).is(':checked')) {
        $('.myrx_checkbox').prop('checked', false);
      }
    });
    $('.myrx_checkbox').on('change', function() {
      if ($(this).is(':checked')) {
        $('.myrx_radiobtn').prop('checked', false);
      }
    });
  }
  return {
    setup: setup
  };
})();

$(function() {
  uncheck_btn.setup();
});