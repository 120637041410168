var Agreement = (function ($) {
  var agreement_text_area = null, button_group_one = null, button_group_two = null;
  function enable_editing_mode(event) {
    event.preventDefault();
    agreement_text_area.prop('disabled', false);
    agreement_text_area.attr('data-original-value', agreement_text_area.val());
    button_group_one.hide();
    button_group_two.show();
  }
  function disable_editing_mode(event) {
    event.preventDefault();
    agreement_text_area.val(agreement_text_area.attr('data-original-value'));
    agreement_text_area.prop('disabled', true);
    button_group_two.hide();
    button_group_one.show();
  }
  function setup() {
    var update_agreement_button = $('#update_agreement_button'),
      discard_changes_button = $('#discard_changes_button');
    button_group_one = $('#stage_1_button_group');
    button_group_two = $('#stage_2_button_group');


    agreement_text_area = $('#eula_box');
    update_agreement_button.on('click', enable_editing_mode);
    discard_changes_button.on('click', disable_editing_mode);
    button_group_two.hide();

    if(navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1){
      agreement_text_area.resizable();
    }
  }

  return {
    setup:setup
  };
})($);

$(function() {
  Agreement.setup();
});