import { AccessibleDatePickerSetUp } from '../module/datepickers/accessible/datepicker_button';

$(function () {
  const dynamic_table=$('table[data-blank-row]');
  let count_rows = dynamic_table.children('tbody').first().children('tr');

  const add_row_count_information =elements => $.each(elements, function(element) {
    const current_row = element + 1;
    const total_rows = elements.length;

    $(this).find('.row-assistive-text').remove();
    $(this).find('label')
      .append('<span class="sr-only row-assistive-text"> '+ 
      '. entry ' + current_row + ' of ' + total_rows + '</span>');

    $(this).find('*[data-single-nest-row="add"]')
      .append('<span class="sr-only row-assistive-text"> '+ 
      '. entry ' + current_row + ' of ' + total_rows + '</span>');

    return $(this).find('*[data-single-nest-row="remove"]')
      .append('<span class="sr-only row-assistive-text"> '+ 
      '. entry ' + current_row + ' of ' + total_rows + '</span>');
  });

  add_row_count_information(count_rows);

  $('form').on('click', '*[data-single-nest-row="add"]', function(event) {
    const time = new Date().getTime();
    const regexp = new RegExp('REPLACE', 'g');

    dynamic_table.children('tbody').first().children('tr:last').after(dynamic_table.data('blank-row').replace(regexp,time));
    count_rows = dynamic_table.children('tbody').first().children('tr');

    $('td[data-action]:not(:last)').html(dynamic_table.data('remove-button'));
    $(document).trigger('single_nest_row:row_added');

    count_rows = dynamic_table.children('tbody').first().children('tr');
    add_row_count_information(count_rows);

    AccessibleDatePickerSetUp.initialize();
    return event.preventDefault();
  });

  return $('form').on('click', '*[data-single-nest-row="remove"]', function(event) {
    const grouping = $(this).closest('tr').data('grouping');
    $('tr[data-grouping="'+grouping+'"]').remove();
    count_rows = dynamic_table.children('tbody').first().children('tr');
    return add_row_count_information(count_rows);
  });
});
  