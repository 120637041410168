import { x508 } from '../app/x508';

// The following code is used to make our search term bold.
// http://stackoverflow.com/a/9795091
$.fn.wrapInTag = function (opts) {
  // http://stackoverflow.com/a/1646618
  function getText(obj) {
    return obj.textContent ? obj.textContent : obj.innerText;
  }

  var tag = opts.tag || 'strong',
    words = opts.words || [],
    regex = RegExp(words.join('|'), 'gi'),
    replacement = '<' + tag + '>$&</' + tag + '>';

  // http://stackoverflow.com/a/298758
  $(this).contents().each(function () {
    if (this.nodeType === 3) //Node.TEXT_NODE
    {
      $(this).empty().replaceWith(getText(this).replace(regex, replacement));
    }
    else if (!opts.ignoreChildNodes) {
      $(this).wrapInTag(opts);
    }
  });
};

// This bit of sadness helps prevent unexpected openings of the searchbox. 
let keysThatShouldNotOpenSearch = [
  'Meta',
  'Enter',
  'Shift',
  'Alt',
  'Tab',
  'Escape',
  'Control',
  'CapsLock',
  'ContextMenu',
  'NumLock',
  'ScrollLock',
  'AudioVolumeMute',
  'AudioVolumeDown',
  'AudioVolumeUp',
  'LaunchMediaPlayer',
  'LaunchApplication1',
  'LaunchApplication2',
  'Print',
  'Insert',
  'Home',
  'End',
  'Paused',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
];

var DynamicSearch = (function ($) {
  var dynamic_menu_height = 250;
  function instantiate() {
    var me = new Object();
    function strengthen_tokens_in_html(token, html) {
      var regex = new RegExp(token, 'gi');
      return html.replace(regex, '<strong>$&</strong>');
    }
    me.init = function (node) {
      if ($(node).data('already-dynamic') !== undefined) {
        return;
      }
      me.node = $(node);
      me.ornament_node = $(me.node.siblings('.input-group-prepend').find('.input-group-text'));
      me.node.data('already-dynamic', true);
      me.hover_menu_container = null;
      me.hover_menu_is_open = false;
      me.hover_menu_id = `health-specialties_hover_menu_${Math.floor(Math.random() * 100)}`;
      me.url = me.node.data('dynamic-search-url');
      me.xhr_type = me.node.data('dynamic-search-type');
      if (me.xhr_type === undefined) {
        me.xhr_type = 'json';
      }
      me.search_callback_timer = null;
      me.search_callback = function (data, textStatus, jqXHR) {
        if (me.xhr_type !== 'json') {
          //render template!
          var offset = me.node.offset();
          offset.top = offset.top + me.node.height() + 10;
          me.create_hover_menu_at_position(offset);
          me.hover_menu_container.append(data);
          me.hover_menu_is_open = true;
          me.node.attr('aria-expanded', true);
          me.hover_menu_container.css({'height':'auto'});
          var menu_height = me.hover_menu_container.height();
          if (menu_height > dynamic_menu_height) {
            me.hover_menu_container.css({'height':dynamic_menu_height});
          }
        } else {
          offset = me.node.offset();
          me.hover_menu_is_open = true;
          me.node.attr('aria-expanded', true);
          offset.top = offset.top + me.node.height() + 10;
          me.create_hover_menu_at_position(offset);
          //Here be dragons!
          let html;
          for (var parent_group_label_key in data) {
            let item_array = data[parent_group_label_key];
            let item_array_key_title_id = parent_group_label_key
              .replaceAll(" ", "")
              .replaceAll("&", "")
              .toLowerCase();

              if (item_array.length > 0) {
                html = `<li><span id='section_${item_array_key_title_id}'>${parent_group_label_key}</span><ul>`;
                item_array.forEach((item) => {
                  const item_html = `
                    <li id='healthcare_specialty_item_${item.id}' class='healthcare_specialty_item' aria-describedby="section_${item_array_key_title_id}">
                      <a href='${item.assignment_url}' data-remote='true' data-method='post' role="option">${item.title}</a>
                    </li>
                  `;
              
                  html += item_html;
                })

              html = html + "</ul></li>";

              me.append_list_items_to_menu(menu_height, html);
            }
          }

          if (Object.keys(data).length === 0) {
            html = '<li><span id="section_no_results">No results found</span><ul>';

            me.append_list_items_to_menu(menu_height, html);
          }
        }
      };

      me.adjust_menu_height = function (menu_height) {
        me.hover_menu_container.css({ 'height': 'auto' });
        menu_height = me.hover_menu_container.height();
        if (menu_height > dynamic_menu_height) {
          me.hover_menu_container.css({ 'height': dynamic_menu_height });
        }
      };

      me.append_list_items_to_menu = function (menu_height, html) {
        me.hover_menu_container.append(html);
        if (me.node.val().length > 0) {
          me.hover_menu_container.wrapInTag({ words: [me.node.val()] });
        }

        me.adjust_menu_height(menu_height);
      };

      me.remove_hover_menu = function () {
        if (me.hover_menu_container !== null) {
          me.hover_menu_container.remove();
          me.hover_menu_container = null;
          me.hover_menu_is_open = false;
          me.node.attr('aria-expanded', false);
        }
      };
      me.create_hover_menu_at_position = function (point) {
        me.remove_hover_menu();
        me.hover_menu_container = $(
          `<ul id='${me.hover_menu_id}' class='hover_menu' role='listbox' aria-label='Healthcare Specialties. Press Tab to traverse these list items' tabindex="-1"/>`
        );
        me.node.parent().append(me.hover_menu_container);
        me.node.attr('aria-controls', me.hover_menu_id);
        me.hover_menu_container.css(
          {
            'position':'absolute',
            'top':'100%',
            'left':0,
            'width':'100%',
            'height':dynamic_menu_height + 'px'
          }
        );
        me.hover_menu_is_open = true;
        me.node.attr('aria-expanded', true);
        me.setup_menu_removal_button();
        // set the focus to the hover_menu_container ONLY IF the use is not typing
        const inputField = document.getElementById('input-health-care-specialties');
        if (document.activeElement !== inputField) {
          me.hover_menu_container.focus();
        }
      };
      me.setup_menu_removal_button = function () {
        var html = '<button type="button" class="border-0 px-0" aria-label="Close popup"><i class="fa fa-times-circle" aria-hidden="true"></i></button>';
        me.ornament_node.html(html);
        me.ornament_node.on('click', function (event) {
          me.close_search(event);
        });
      };

      me.close_search = function (event) {
        event.preventDefault();
        me.ornament_node.html('<i class="fa fa-search" aria-hidden="true"></i>');
        me.remove_hover_menu();
        me.hover_menu_is_open = false;
        me.node.attr('aria-expanded', false);
        me.node.val('');
      };

      //cycle forwards
      me.next_listitem = function() {
        if(me.hover_menu_container != null){
          var listitems = me.hover_menu_container.find('li.healthcare_specialty_item');
          me.cycle_next(listitems);
        }
      };

      //cycle backwards.
      me.previous_listitem = function() {
        if(me.hover_menu_container != null){
          var listitems = me.hover_menu_container.find('li.healthcare_specialty_item');
          me.cycle_next(listitems.toArray().reverse());
        }
      };
      me.click_simulated_hover = function() {
        if(me.hover_menu_container != null){
          var items = me.hover_menu_container.find('li.healthcare_specialty_item.simulate_hover');
          if (items.length > 0) {
            let relatedLink = $(items).find('a');
            relatedLink.trigger('click');
            x508.say(`${relatedLink.text()} added as a selected healthcare specialty`);
          }
        }
      };

      me.cycle_next = function (listitems) {
        for (let i = 0; i <= listitems.length; i++){
          
          if ($(listitems[i]).hasClass('simulate_hover')) {
            if (i < listitems.length) {
              $(listitems[i]).removeClass('simulate_hover');
              $(listitems[i]).removeAttr('aria-selected');

              let next_item;
              if (listitems[i + 1]) {
                next_item = listitems[i + 1];
              } else {
                next_item = listitems[0];
              }

              if (next_item) {
                //scroll in list box.
                me.update_hover_menu_animation(next_item);
              }
              return;
            }
          }
        }

        //couldn't find simulate_hover so it to the first item.
        if (listitems.length >= 0) {
          me.update_hover_menu_animation(listitems[0]);
        }
      };

      me.update_hover_menu_animation = function (focusedMenuItem) {
        focusedMenuItem = $(focusedMenuItem);
        focusedMenuItem.addClass('simulate_hover');
        focusedMenuItem.attr('aria-selected', true);
        me.node.attr('aria-activedescendant', focusedMenuItem.attr('id'));

        me.hover_menu_container.animate({
          scrollTop: focusedMenuItem.offset().top - me.hover_menu_container.offset().top + me.hover_menu_container.scrollTop() - 20
        });
      };

      $(me.node).on('keydown', function (event) {
        // Handle most events in keyup but some you have to bind to key down.
        if(event.code == 'Enter') { //prevent form submit while trying to add item.
          event.stopPropagation();
          event.preventDefault();
          return false;
        }

      });

      $(me.node).on('keyup', function (event) {
        if ($(event.target).data('dynamic-search-url') === undefined) {
          return;
        }

        if (!me.hover_menu_is_open) {
          
          if (event.code == 'Escape') { //escape key close the menu.
            me.node.val('');
          }

          if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
            $.post(me.url, { browse_all: 'true' }, me.search_callback, 'json').done(function () {
              if (event.altKey) {
                return;
              }
              
              if (event.code == 'ArrowUp') {
                me.previous_listitem();
              } else {
                me.next_listitem();
              }
            });
          }
        }

        if (event.code == 'Escape') { //escape key close the menu.
          me.node.trigger('dynamic_menu:close');
          return;
        }

        if (event.code == 'ArrowDown') { //down arrow
          me.next_listitem();
          return;
        }

        if (event.code == 'ArrowUp') { //up arrow
          me.previous_listitem();
          return;
        }

        if(event.code == 'Enter') { //enter key
          if (me.hover_menu_is_open) {
            event.stopPropagation();
            event.preventDefault();
            me.click_simulated_hover();

            me.close_search(event);
            return false;
          }
        }

        // We want to delay the search long enough that it only searches when there is a pause in the typing, but we want it to still be responsive.
        // two-tenths of a second is about 60 wpm, anyone typing below that is going to generate hella network requests. (up to 6 per second).
        if (keysThatShouldNotOpenSearch.indexOf(event.key) !== -1) {
          return;
        }

        clearTimeout(me.search_callback_timer);
        me.search_callback_timer = setTimeout(function () {
          $.post(me.url, {'search_token': event.target.value}, me.search_callback, me.xhr_type);
        }, 200);
      });

      $('.healthcare_specialty_browse_all').on('click', function (event) {
        event.preventDefault();
        me.node.val('');

        $(this).attr('aria-expanded', !me.hover_menu_is_open);

        if (!me.hover_menu_is_open) {
          $.post(me.url, { browse_all: 'true' }, me.search_callback, 'json');
        } else {
          me.close_search(event);
        }
      });

      $(document).on('dynamic_menu:refocus_on_input', function () {
        me.node.focus();
      });

      $(document).on('dynamic_menu:close', function (event) {
        if (!me.node.is(':visible')) {
          return;
        }

        me.close_search(event);
      });
      $(document).on('hide', function () {
        $(document).trigger('dynamic_menu:close');
      });
    };
    return me;
  }
  return {
    instantiate:instantiate
  };
})($);

function setUpTheDynamicSearchInstance() {
  $('[data-dynamic-search-url]').each(function () {
    DynamicSearch.instantiate().init($(this));
  });

  $('li.healthcare_specialty_item a').on('click', function (event) {
    event.preventDefault();
    $(document).trigger('dynamic_menu:close');
    $(document).trigger('dynamic_menu:refocus_on_input');
  });

  $('li.healthcare_specialty_item a').on('keyup', function (event) {
    if (event.key === 'Escape') {
      $(document).trigger('dynamic_menu:close');
      $(document).trigger('dynamic_menu:refocus_on_input');
    }
  });

  $(document).on('click', 'a.pp_person', function (event) {
    $(document).trigger('dynamic_menu:close');
  });
}

$(function () {
  setUpTheDynamicSearchInstance();
});

$(document).on('ajaxComplete', function () {
  setUpTheDynamicSearchInstance();
});
