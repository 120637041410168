Function.prototype.bound = function(scope){
  var _this = this;
  return function() {
    return _this.apply(scope);
  };
};

var DisclosureTriangleAnimation = {
  class_names: ['closed', 'kinda-open', 'mostly-open', 'open'],
  reversed_class_names: ['closed', 'kinda-open', 'mostly-open', 'open'].reverse(),
  frame_delay: 50,
  open: function(triangle){
    var tri = $(triangle);
    for (var i = 0; i <= DisclosureTriangleAnimation.class_names.length -1; i++){
      setTimeout(function() {
        tri.removeClass(DisclosureTriangleAnimation.class_names.join(' '));
        tri.addClass(this.toString());
      }.bound(DisclosureTriangleAnimation.class_names[i]), DisclosureTriangleAnimation.frame_delay * i);

    }
  },

  close: function(triangle){
    var tri = $(triangle);
    var reversed_class_names = DisclosureTriangleAnimation.reversed_class_names;
    for (var i = 0; i <= DisclosureTriangleAnimation.class_names.length -1; i++){
      setTimeout(function() {
        tri.removeClass(DisclosureTriangleAnimation.class_names.join(' '));
        tri.addClass(this.toString());
      }.bound(reversed_class_names[i]), DisclosureTriangleAnimation.frame_delay * i);

    }
  }
};

$(function() {
  $(document).on('hide.bs.collapse','.collapse', function(event) {
    var collapse_id = event.target.id;
    var tri = $('i[for="' + collapse_id + '"]'); // non-standard to use 'for' attribute in this manner. Retool to use data attribute when there is time.
    if (tri.length === 0) {
      var classes = String($(event.target).attr('class')).replace(/ +(?= )/g,'').split(' ');
      $(classes).each(function () {
        var class_name = '.' + this;
        tri = $('i[for="' + class_name + '"]');
        if (tri.length > 0) {
          DisclosureTriangleAnimation.close(tri);
          return false;
        }
      });

    } else {
      DisclosureTriangleAnimation.close(tri);
    }
  });

  $(document).on('show.bs.collapse', '.collapse', function(event) {
    var collapse_id = event.target.id;
    var tri = $('i[for="' + collapse_id + '"]');

    if (tri.length === 0) {

      var classes = String($(event.target).attr('class')).replace(/ +(?= )/g,'').split(' ');
      $(classes).each(function () {
        var class_name = '.' + this;
        tri = $('i[for="' + class_name + '"]');
        if (tri.length > 0) {
          DisclosureTriangleAnimation.open(tri);
          return false;
        }
      });

    } else {
      DisclosureTriangleAnimation.open(tri);
    }
  });

  $(document).on('click', '.icon-disclosure', function(event) {
    var collapse_target = $(event.target).attr('for');
    var target;
    try {
      target = $('#'+collapse_target);
    } catch (error) {
      target = $(collapse_target);
    }

    if (target.length > 0) {
      target.collapse('toggle');
    }
  });
});
