import { Flash } from '../app/flash';
import { IndeterminateProgress } from '../app/indeterminate_progress';
import { XssSanitizer } from './xss_sanitizer';

Array.prototype.remove = function (from, to) {
  var rest = this.slice((to || from) + 1 || this.length);
  this.length = from < 0 ? this.length + from : from;
  return this.push.apply(this, rest);
};
var PatientConsolidator = function () {
  var Patient = function () {
    function blueprint(o) {
      o.capture_origin = function () {
        var origin = o.node.closest('.user_group');
        o.origin_node = origin;
      };
      o.className = 'Patient';
      o.swap_node = function (node) {
        o.node = node;
        node.data('linked-object', o);
        o.setup_drag();
      };
      o.setup_drag = function (){
        o.node.draggable({
          start: function( event, ui ) {
            $(document).trigger('patient:drag:start', o);
            // console.log(event, ui);
            // current_drag_element = $(event.currentTarget);
            //             current_drag_element.addClass('selected_drag');
          },
          stop: function (event, ui) {
            $(document).trigger('patient:drag:stop', o);
            // current_drag_element.removeClass('selected_drag');
            //             current_drag_element = null;
          },
          revert:'invalid',
          scroll:'true',
          helper: function (obj) {
            // I find the offset of scrolling ruins the drag effect, so let's fix that...
            var node;
            node = $(o.node).closest('.ui-draggable').clone();
            var top = $(document).scrollTop() * -1;
            node.css({'margin-top': top + 'px'});
            return node;
          },
          // opacity:0.8,
          appendTo:o.node.closest('#person_consolidator'),
          cursorAt:{top:-2, left:-2}
        });
      };
      return o;
    }
    function create_from_node(node) {
      var instance = new Object();
      instance = blueprint(instance);

      instance.node = node;
      instance.node.data('linked-object', instance);

      instance.user_id = instance.node.data('user-id');
      instance.original_group_id = instance.node.data('original-group-id');
      instance.hash_with_column_index = instance.node.data('user-hash') +
        'col_+' + node.parents('.column').index();

      instance.setup_drag();

      return instance;
    }

    return {create_from_node:create_from_node};

  }();
  var ColumnContainer = function () {
    function setup_listeners(instance) {
      $(document).on('column:willBeDestroyed', function (event, data) {
        for (var i = 0; i < instance.columns.length; i++) {
          var col = instance.columns[i];
          if (col === data.column) {
            if (col.safe_to_remove()) {
              instance.columns.remove(i);
              $(document).trigger('column:wasDestroyed', {column:data.column});
              col.destroy();
            }

          }
        }
      });
      $(document).on('column:wasDestroyed', function (event, data) {
        instance.rebalance_column_width();
      });
      $(document).on('group:create', function (event, data) {
        var group_id = data.group_id, column_id = data.column_id;

        if (column_id === '_new') {
          // It's us! Let's add that new group holmes
          // var pending_columns = instance.pendingColumns();
          // var column = $(pending_columns).first()[0];
          var group = Group.create({group_id:group_id});
          // column.node.append(group.node);
          // column.groups.push(group);
          instance.new_column_from_node(event, {node:group.node, title:'New Group'});
        }
      });
      return instance;
    }
    function setup_instance(instance) {
      instance = setup_listeners(instance);
      instance.pendingColumns = function () {
        var pending = [];
        for (var i = 0; i < instance.columns.length; i++) {
          var col = instance.columns[i];
          if (col.status !== 'done') {
            pending.push(col);
          }
        }

        return pending;
      };
      instance.rebalance_column_width = function () {
        for (var i = 0; i < instance.columns.length; i++) {
          var col = instance.columns[i];
          col.node.width(instance.column_width);
        }
      };
      instance.prepare_column_from_node = function (event) {
        if (instance.columns.length < instance.max_column_count) {
          var column = Column.create_with_parent(instance.node);
          column.set_in_progress();

          instance.columns.push(column);

          instance.rebalance_column_width();
          column.scroll_in_view();
        } else {
          Flash.alert('You cannot create more than ' + instance.max_column_count + ' columns.');
        }

      };
      instance.abort_new_column = function (event, data) {
        var pending_columns = instance.pendingColumns();
        var column = $(pending_columns).first()[0];

        if (typeof column === 'undefined') {
          return;
        }

        column.destroy();
        for (var i = 0; i < instance.columns.length; i++) {
          var col = instance.columns[i];
          if (col === column) {
            instance.columns.remove(i);
          }
        }
        instance.rebalance_column_width();

        if (data !== undefined) {
          var errors = data.errors;

          var error_list = $('<ul />');
          $.each(errors, function (key, value) {
            var error_string = '';
            var k = $(`label[for='${XssSanitizer.sanitize_dom_text(key)}']`).text().replace(':', ' ');
            error_string = error_string + k + value.join(', ');
            error_list.append($(`<li>${XssSanitizer.sanitize_dom_text(error_string)}</li>`));
          });
          Flash.error('Could not create a new column because of the following errors: ' + error_list.html());
        }


      };
      instance.new_column_from_node = function(event, data){
        if (instance.columns.length <= instance.max_column_count) {
          var node = data.node;

          // instance.node.append(node);
          var pending_columns = instance.pendingColumns();
          var column = $(pending_columns).first()[0];//Column.create_with_parent_and_children(instance.node, node);
          if (typeof column !== 'undefined') {
            column.set_done();
            if (data.search_id !== undefined) {
              column.search_id = data.search_id;
            }
            column.title = data.title;
            column.install_controls();
            column.append_children_groups(node);
            // instance.columns.push(column);

            // instance.rebalance_column_width();
          }
        }

      };

      return instance;
    }
    function create_from_node(node) {
      var instance = new Object();
      instance.node = $(node);
      instance.column_width = instance.node.data('column-width');
      instance.node.data('linked-object', instance);
      instance.columns = [];
      instance.max_column_count = instance.node.data('max-column-count');

      instance = setup_instance(instance);

      instance.node.find('.column').each(function () {
        var column_node = $(this);
        var column = Column.create_from_node(column_node);
        instance.columns.push(column);
      });
      instance.rebalance_column_width();
      $(document).ajaxSend(function(evt, request, settings) {
        try {
          if (settings.data.match('ajaxColumnOnCreate=true') !== null) {
            $(document).trigger('column:willBeCreated');
          }
        } catch (variable) {

        } finally {

        }


      });

      $(document).on('column:willBeCreated', instance.prepare_column_from_node);
      $(document).on('column:createFromNode', instance.new_column_from_node);
      $(document).on('column:failedToCreateNode', instance.abort_new_column);

      return instance;
    }

    return {create_from_node:create_from_node};
  }();
  var Group = function () {
    function blueprint(o) {
      o.className = 'Group';
      o.title = function () {
        return 'Group ' + o.group_id;
      };
      o.failed_patient_id_move = function (event, data) {
        var failed_patient_id = data.patient_id;
        $('.user[data-user-id='+failed_patient_id+']').each(function () {
          var failed_node = $(this);
          var failed_patient = failed_node.data('linked-object');
          var origin_node = failed_patient.origin_node.find('.collapse').first();

          failed_node.appendTo(origin_node);
          failed_node.removeClass('moved');

        });
      };
      o.failed_group_id_move = function (event, data) { 
        var failed_group_id = data.group_id, failed_group_node = $('.user_group[data-group-id='+failed_group_id+']');
        $('.user[data-original-group-id="'+failed_group_id+'"]').each(function () {
          var failed_node = $(this);
          // var failed_patient = failed_node.data('linked-object');
          // var origin_node = failed_patient.origin_node.find('.collapse').first();

          failed_node.appendTo(failed_group_node.find('.collapse'));
          failed_node.removeClass('moved');
          failed_node.removeAttr('data-original-group-id');
        });
      };
      o.select_group = function(event, data) {

        var id = data.group.group_id;
        //
        o.node.removeClass('selected');
        if (o.group_id === id) {
          o.node.addClass('selected');
          o.node.find('a.select_action').text('selected');
        } else {
          o.node.find('a.select_action').text('select');
        }


        // var title = o.node.find('.controls .canhaztoggle').first().text();
      };
      o.create_node = function (group_id) {
        o.group_id = group_id;
        var group_name = 'Group ' + group_id,
          unique_token = new Date().getTime();

        var src = '<div class="user_group" data-group-id='+group_id+'>';
        src = src + '  <div class="controls">';
        src = src + '    <i class="icon-disclosure closed" for="group_id_' + group_id + '_'+unique_token+'"></i>';
        src = src + '    <a id="' + group_id + '" data-toggle="collapse" data-target="#group_id_' + group_id + '_'+unique_token+'" class="canhaztoggle">';
        src = src + '      ' + group_name;
        src = src + '    </a>';
        src = src + '  </div>';
        src = src + '<div class="collapse out" id="group_id_' + group_id + '_'+unique_token+'"></div>';
        var element = $(src);
        return element;
      };
      o.users = [];
      o.group_id = null;
      o.setup_drag = function () {
        $(document).on('group:dropped', o.group_dropped);
        let current_drag_element;

        var options = {
          start: function( event, ui ) {
            current_drag_element = $(event.currentTarget);
            current_drag_element.addClass('selected_drag');
            $(document).trigger('group:drag:start', o);
          },
          stop: function (event, ui) {
            current_drag_element.removeClass('selected_drag');
            current_drag_element = null;
            $(document).trigger('group:drag:stop', o);
          },
          revert:'invalid',
          scroll:'true',
          helper: function (obj) {
            // I find the offset of scrolling ruins the drag effect, so let's fix that...
            var node;
            node = $(this).closest('.ui-draggable').clone();
            var top = $(document).scrollTop() * -1;
            node.css({'margin-top': top + 'px'});
            return node;
          },
          // opacity:0.8,
          // appendTo:container_selector,
          cursorAt:{top:-2, left:-2}
        };
        o.node.draggable(options);
      };

      o.setup_drop = function () {
        $(document).on('patient:dropped', o.patient_dropped);
        // $(document).on('group:dropped', o.group_dropped);
        o.listeners_setup = true;
        var options = {
          accept: function (element) {
            var parent_group_id = element.closest('.user_group').data('group-id');
            if (o.group_id !== parent_group_id) {
              return true;
            } else {
              return false;
            }
          },
          activeClass: 'active_dropzone',
          hoverClass: 'hover_dropzone',
          tolerance: 'pointer',
          drop: o.dropped
        };
        o.node.droppable(options);
      };
      o.patient_dropped = function (event, data) {
        var source = data.source,
          destination = data.destination,
          obj = source.node.clone();
        if (destination.group_id === o.group_id) {
          let dest = o.node.find('.collapse').first();
          // We received a drop. Clone object into us if it hasn't happened yet.
          // this check is necessary because sometimes jquery sends more than one drop event as it bubbles up?
          if (o.node.find('.user[data-user-id=' + source.node.data('user-id') + ']').length < 1) {
            dest.append(obj);
          }
          if(o.group_id !== source.original_group_id){
            obj.addClass('moved');
          } else {
            obj.removeClass('moved');
          }

          // Add object to our array;
          o.users.push(source);
          // add cloned node into related object
          source.swap_node(obj);
        } else {
          o.node.find('div.user[data-user-id='+ source.user_id +']').each(function () {
            var remove_this_node = $(this);
            var remove_this_object = remove_this_node.data('linked-object');
            for (var i = 0; i < o.users.length; i++) {
              var user = o.users[i];
              if (user.user_id === remove_this_object.user_id) {
                // remove object from array
                o.users.remove(i);
              }
            }
            // remove node from dom
            remove_this_node.remove();
          });


        }
      };

      o.group_dropped = function (event, data) {
        var source = data.source,
          destination = data.destination;
        if (destination.group_id !== o.group_id) {
          return;
        }

        var destination_user_container = $(destination.node).find('.collapse');
        $(source.node).find('.user').each(function () {
          var user_node = $(this);
          destination_user_container.append(user_node);
          user_node.data('original-group-id', source.group_id);
          if(destination.group_id !== source.original_group_id){
            user_node.addClass('moved');
          } else {
            user_node.removeClass('moved');
          }
        });
        $(document).trigger('group:dropFinishedMovingNodes', data);
        // obj = source.node.clone();
        // if (destination.group_id === o.group_id) {
        //           dest = o.node.find('.collapse').first();
        //           // We received a drop. Clone object into us.
        //           dest.append(obj);
        //           if(o.group_id !== source.original_group_id){
        //             obj.addClass('moved');
        //           } else {
        //             obj.removeClass('moved');
        //           }
        //
        //           // Add object to our array;
        //           o.users.push(source);
        //           // add cloned node into related object
        //           source.swap_node(obj);
        //         } else {
        //           o.node.find('div.user[data-user-id='+ source.user_id +']').each(function () {
        //             var remove_this_node = $(this);
        //             var remove_this_object = remove_this_node.data('linked-object');
        //             for (var i = 0; i < o.users.length; i++) {
        //               var user = o.users[i];
        //               if (user.user_id === remove_this_object.user_id) {
        //                 // remove object from array
        //                 o.users.remove(i);
        //               }
        //             }
        //             // remove node from dom
        //             remove_this_node.remove();
        //           })
        //
        //
        //         }
      };

      o.dropped = function (event) {
        var source = o.current_drag_object, 
          original_destination = $(event.toElement); 

        if (original_destination.length === 0 ) {
          original_destination = $(event.target);
        }

        var top = original_destination.closest('.user_group');
        let destination = top.data('linked-object');

        if (source.className == 'Patient') {
          $(document).trigger('patient:dropped', {
            source: source, destination: destination, origin: source.origin_node
          });
        }

        if (source.className == 'Group') {
          $(document).trigger('group:dropped', {
            source: source, destination: destination
          });
        }
      }; // end dropped

      return o;
    }
    function setup_listeners(instance) {
      $(document).on('patient:drag:start', function (event, patient) {
        patient.capture_origin();
        instance.current_drag_object = patient;
      });
      $(document).on('patient:drag:stop', function (event, patient) {
        instance.current_drag_object = null;
      });
      $(document).on('group:drag:start', function (event, group) {
        // patient.capture_origin();
        instance.current_drag_object = group;
      });
      $(document).on('group:drag:stop', function (event, group) {
        instance.current_drag_object = null;
      });
      $(document).on('group:selected', instance.select_group);
      $(document).on('patient:failedToMovePatientID', instance.failed_patient_id_move);
      $(document).on('group:failedToMoveGroupID', instance.failed_group_id_move);
      instance.setup_drop();
      instance.setup_drag();
    }
    function create_from_node(node) {

      var instance = new Object();
      instance = blueprint(instance);
      instance.node = $(node);
      instance.node.data('linked-object', instance);
      instance.group_id = instance.node.data('group-id');
      instance.node.find('div.user').each(function () {
        var user_node = $(this),
          user = Patient.create_from_node(user_node);
        instance.users.push(user);
      });
      $(document).trigger('column:createFromNode:done');
      setup_listeners(instance);
      return instance;
    }
    function create(options) {
      var instance = new Object();
      instance = blueprint(instance);
      instance.node = instance.create_node(options.group_id);
      instance.node.data('linked-object', instance);
      instance.group_id = options.group_id;
      setup_listeners(instance);


      return instance;
    }

    return {create:create, create_from_node:create_from_node};
  }();
  var Column = function () {
    function blueprint(o) {
      o.search_id = null;
      o.record_event = function (object) {
        var event = object.event,
          data = event.data;
        o.action_history.push(object);
        o.node.find('a.close i').removeClass('icon-remove-sign').addClass('icon-remove-circle');
      };
      o.action_history = [];
      o.safe_to_remove = function () {
        if (o.action_history.length < 1) {
          return true;
        }
        return false;
      };
      o.className = 'Column';
      o.title = 'New Column';
      o.create_control_node = function () {
        var html = '';
        html = html + '<div class=\'column_controls\'>';
        html = html + '  <a href=\'#\' class=\'close\'><i class=\'fa fa-times-circle\'></i></a>';
        html = html + '  <span class=\'title\'>'+ o.title +'</span>';
        html = html + '</div>';

        return $(html);
      };
      o.create_node = function () {
        return $('<div class="column"></div>');
      };
      o.status = 'done';
      o.set_in_progress = function () {
        o.status = 'indeterminate';
        var spinner = IndeterminateProgress.init({
          size:'large'
        });
        var html = '<div style="background:#eee;border-radius:12px;height:100%;overflow:hidden;"><div style="left:50%;margin-left:-16px;top:50%;margin-top:-16px;position:relative;">' + spinner + '</div></div>';
        o.node.html(html);
      };
      o.set_done = function () {
        o.status = 'done';
        o.node.html('');
        $('form[data-encourage-reenable-submit]').each(function() { $(this).trigger('change'); });
      };
      o.scan_for_groups = function () {
        o.node.find('div.user_group').each(function () {
          var group_node = $(this),
            group = group_node.data('linked-object');
          if (group === undefined) {
            group = Group.create_from_node(group_node);
          }
          o.groups.push(group);

        });
        return o;
      };
      o.destroy = function () {
        //todo
        o.node.remove();
        $('form[data-encourage-reenable-submit]').each(function() { $(this).trigger('change'); });
        // $(document).trigger('column:destroyed', {column:o});
      };
      o.append_children_groups = function (groups) {
        groups.appendTo(o.node);
        scan_for_groups(o);
      };
      o.scroll_in_view = function () {
        var scroll_container = o.node.parent(),
          width = scroll_container.data('column-width'),
          scroll_offset = (scroll_container.find('.column').length * width);
        scroll_container.animate({
          scrollLeft:scroll_offset + width
        }, 900);
      };
      o.install_controls = function () {
        o.node.prepend(o.create_control_node());
        o.node.find('a.close').on('click', function (event) {
          event.preventDefault();
          if (o.safe_to_remove()) {
            var x = o.node.offset().left, y = o.node.offset().top;
            x = o.node.width() / 2 + x;
            y = o.node.height() / 4 + y;
            setTimeout(function () {
              // If we don't wrap this in a function, o doesn't get preserved, and firefox uses the node's coordinates after it's been destroyed/resized...
              $(document).trigger('column:willBeDestroyed', {column:o});
            }, 20);
          } else {
            Flash.alert('You\'ve made changes to this column. Please save your work to start new searches.');
          }


        });
      };
      return o;
    }
    function scan_for_groups(instance) {
      return instance.scan_for_groups();
    }
    function setup_listeners(instance) {
      $(document).on('group:create', function (event, data) {
        var group_id = data.group_id, column_id = data.column_id;

        if (column_id === instance.identifier) {
          // It's us! Let's add that new group holmes
          var group = Group.create({group_id:group_id});
          instance.node.append(group.node);
          instance.groups.push(group);
        }
      });

      $(document).on('patient:dropped', function (event, data) {
        var patient_id = data.source.user_id,
          group_id = data.destination.group_id;
        // $.ajax(move_patient_uri, {type:'POST', data:{patient_id:patient_id, group_id: group_id}, dataType:'json', error: function () {
        //   alert('Patient "' + patient_id +'" cannot be moved to Group "' + group_id + '"');
        //
        //   $(document).trigger('patient:failedToMovePatientID', {patient_id:patient_id});
        // }});
        if (instance.node.find('*[data-group-id='+group_id+']').length > 0) {
          instance.record_event({event:'patient:dropped', data:data});
        }
        var patient = $('*[data-user-id='+patient_id+']').data('linked-object');

        var column = data.origin.closest('.column').first().data('linked-object');

        column.record_event({event:'patient:pulled', data:{patient:patient}});

      });
      return instance;
    }
    function create_with_parent_and_children(parent, children) {
      // parent.append
      var instance = create_with_parent(parent);

      append_children_to_instance(children, instance);
      return instance;
    }
    function append_children_to_instance(children, instance) {
      instance.appendChildrenGroups(children);
    }
    function create_with_parent(parent_node) {
      var instance = new Object();
      instance = blueprint(instance);

      instance.parent_node = parent_node;
      var node = instance.create_node();
      instance.node_width = $(parent_node).data('column-width');
      node.id = new Date().getTime();
      node.appendTo(parent_node);
      // parent_node.append(node);
      // instance.node = $('#'+node.id);
      instance.node = node;
      instance.node.data('linked-object', instance);
      instance.groups = [];
      setup_listeners(instance);
      return instance;
    }
    function create_from_node(node) {
      var instance = new Object();
      instance = blueprint(instance);

      instance.parent_node = node.parent();
      instance.node = node;
      instance.node_width = $(instance.parent_node).data('column-width');
      instance.node.data('linked-object', instance);
      instance.identifier = instance.node.data('identifier');

      instance.groups = [];
      // instance.install_controls();
      scan_for_groups(instance);

      setup_listeners(instance);

      return instance;
    }

    return {create_from_node:create_from_node, create_with_parent_and_children: create_with_parent_and_children, create_with_parent:create_with_parent, append_children_to_instance:append_children_to_instance};
  }();
  var PatientUniquenessFilter = function () {
    function instantiate() {
      var me = new Object();
      me.uniqueness_dictionary = {};
      me.setup = function () {
        var users = $('div.user[data-user-hash]');
        me.setup_users(users);
        $(document).on('patient:dropped', me.patient_dropped);
        $(document).on('column:createFromNode', me.patient_group_created);
        $(document).on('column:createFromNode:done', me.patient_group_created);
        return me;
      };
      me.setup_users = function (users) {
        var columns = $('.column');
        columns.each(function (column_index, column) {
          users_in_column = $(column).find('div.user[data-user-hash]');
          users_in_column.each(function (user_index, u) {
            var user = $(u), 
              hash = user.data('user-hash') + 'col_+' + column_index,
              user_id = user.data('user-id');
            if (user.data('uniqueness-filtered') === true) {
              return;
            }
            if (typeof(me.uniqueness_dictionary[hash]) === 'undefined') {
              me.uniqueness_dictionary[hash] = [];
            } else {
              user.hide();
            }
            me.uniqueness_dictionary[hash].push(user_id);
            user.data('uniqueness-filtered', true);
          });
          //hide invisible groups
          var groups = $(column).find('.user_group');
          groups.each(function (group_index, group) {
            if ($(group).find('div.user[data-user-hash]:visible').length === 0) {
              $(group).hide();
            }}
          );
        });

      };
      me.patient_group_created = function (event, data) {
        var users = $('div.user[data-user-hash]');
        me.setup_users(users);
      };
      me.patient_dropped = function (event, data) {
        var patient_uniqueness_hash = data.source.hash_with_column_index,
          destination_group_id = data.destination.group_id;

        var source_group_id = data.source.group_id;
        var patient_ids = [];

        patient_ids = me.user_ids_for_hash(patient_uniqueness_hash);

        var patient_data = {
          patient_ids: patient_ids,
          dest_id: destination_group_id, 
          source: data.source,
          group_id: source_group_id
        };
        if (patient_ids.length > 0) {
          $(document).trigger('patients:move', patient_data);
        }
      };
      me.user_ids_for_hash = function (hash) {
        return me.uniqueness_dictionary[hash];
      };
      return me;
    }
    return {instantiate:instantiate};
  }();
  function select_group(id) {
    $(document).trigger('group:selected', {group:group});
  }
  var container;
  function init() {
    $(function () {
      container = $('#person_consolidator');
      var move_patient_uri = container.data('move-patient-uri');
      var move_patients_uri = container.data('move-patients-uri');
      var new_group_uri = container.data('new-group-uri');
      var destroy_search_uri = container.data('destroy-search-uri');
      var patient_moves = 0;
      $(document).on('patient:dropped', function (event, data) {
        var patient_id = data.source.user_id,
          patient = $('*[data-user-id='+patient_id+']').data('linked-object'),
          group_id = data.destination.group_id;
        $('.commit_button').attr('disabled', true);
        ++patient_moves;
        $.ajax(move_patient_uri, {type:'POST', data:{patient_id:patient_id, group_id: group_id}, dataType:'json',
          success: function () {
            $(document).trigger('patient:drop:succeeded', {patient:patient});
          },
          error: function (e) {
            var err = JSON.parse(e.responseText), error_node = $('<ul></ul>');
            $(err.errors.group).each(function (msg) {
              var message = this;
              var message_node = $('<li>'+message+'</li>');
              error_node.append(message_node);
            });
            $(err.errors.patient).each(function (msg) {
              var message = this;
              var message_node = $('<li>'+message+'</li>');
              error_node.append(message_node);
            });
            Flash.alert('Patient "' + patient_id +'" cannot be moved to Group "' + group_id + '"; ' + error_node.html());
            $(document).trigger('patient:drop:failed', {patient:patient});
            $(document).trigger('patient:failedToMovePatientID', {patient_id:patient_id});
          },
          complete: function () {
            --patient_moves;
            if (patient_moves == 0) {
              $('.commit_button').attr('disabled', false);
            }
          }});
      });

      $(document).on('patients:move', function (event, data) {
        var patient_ids = data.patient_ids,
          group_id = data.group_id,
          dest_id = data.dest_id;
        ++patient_moves;
        $('.commit_button').attr('disabled', true);
        $.ajax(move_patients_uri, {type:'POST', data:{patient_ids:patient_ids, group_id: dest_id}, dataType:'json',
          success: function () {
            $(document).trigger('group:drop:succeeded', {group:data.source});
          },
          error: function (e) {
            var err = JSON.parse(e.responseText), error_node = $('<ul></ul>');
            $(err.errors.group).each(function (msg) {
              var message = this;
              var message_node = $('<li>'+message+'</li>');
              error_node.append(message_node);
            });
            $(err.errors.patient).each(function (msg) {
              var message = this;
              var message_node = $('<li>'+message+'</li>');
              error_node.append(message_node);
            });
            Flash.alert('Group "' + group_id +'" cannot be moved to Group "' + dest_id + '"; ' + error_node.html());
            $(document).trigger('group:drop:failed', {group:data.source});
            $(document).trigger('group:failedToMoveGroupID', {group_id:group_id, dest_id:dest_id});
          },
          complete: function() {
            --patient_moves;
            if (patient_moves == 0) {
              $('.commit_button').attr('disabled', false);
            }
          }});
      });

      $(document).on('group:dropFinishedMovingNodes', function (event, data) {
        var group_id = data.source.group_id, dest_id = data.destination.group_id;
        // group = $('*[data-group-id='+group_id+']').data('linked-object'),
        // group_id = data.destination.group_id;
        var patient_ids = [];
        $('*[data-group-id='+dest_id+']').find('.user').each(function () {
          var user_id = $(this).data('user-id');
          patient_ids.push(user_id);
        });
        var patient_data = {
          patient_ids:patient_ids,
          dest_id:dest_id, 
          source: data.source,
          group_id:group_id
        };
        $(document).trigger('patients:move', patient_data);
      });


      $('a.select_action').on('click', function (event) {
        var element, id;
        element = $(event.target);
        group = element.closest('.user_group').data('linked-object');
        select_group(group);
      });

      $('button.create_group').on('click', function (event) {
        var element = $(event.target),
          target_column_identifier = element.data('column-target');
        var createColumn = '';
        if (target_column_identifier === '_new') {
          createColumn = '&ajaxColumnOnCreate=true';
        }

        $.ajax(new_group_uri, {type:'POST', dataType:'json', data: createColumn,
          success: function (data, status, jqXHR) {
            var group_id = data['group_id'];
            $(document).trigger('group:create', {column_id:target_column_identifier, group_id:group_id});
          },
          error: function () {

            if (createColumn.length > 2) {
              $(document).trigger('column:failedToCreateNode');
            }
            Flash.error('Group cannot be created. Please contact your administrator.');
          }});
      });

      $(document).on('search:failed', function () {
        $(document).trigger('column:failedToCreateNode');
      });

      $(document).on('column:wasDestroyed', function (event, data) {
        var column = data.column;
        if (column.search_id !== null) {
          $.ajax(destroy_search_uri, {type:'POST', data:{search_request_id:column.search_id}, dataType:'json', error: function () {
            Flash.error('Could not remove column "' + column.title + '" from the server. Please contact your administrator.');
          }});
        }

      });


      $(document).on('group:selected', function (event, data) {
        var group_id = data.group.group_id,
          group_title = data.group.title();
        container.attr('data-selected-group-id', group_id);

        $('.commit_button').removeAttr('disabled').removeClass('disabled').find('span').text('Create Report for "'+group_title+'"');
        $('#selected_group_id').val(group_id);
      });

      ColumnContainer.create_from_node(container.find('#column_container'));
    }); // end document.ready
  }

  return {ColumnContainer:ColumnContainer, Column: Column, Group:Group, Patient: Patient, PatientUniquenessFilter:PatientUniquenessFilter, init:init};

}();


PatientConsolidator.init();
