$(function() {
  if ($('#organization_settings_limit_organization_admins_value').is(':checked')) {
    $('#organization_admins_limit').removeClass('hidden');
  }

  return $('#organization_settings_limit_organization_admins_value').on('change', function() {
    if ($('#organization_settings_limit_organization_admins_value').is(':checked')) {
      return $('#organization_admins_limit').removeClass('hidden');
    } else {
      return $('#organization_admins_limit').addClass('hidden');
    }
  });
});
  