/**
 * Created by tpowell on 10/2/16.
 */

var NewJersey = (function() {
  function nj_delegate_adjust_buttons() {
    if ($('#user_professional_license_type').val() === 'cma') {
      $('#delegateAlertButton').prop('disabled', true);
      $('#nj_delegate_acknowledgment').prop('disabled', false);
      $('#nj_delegate_acknowledgment').show();
    } else {
      $('#delegateAlertButton').prop('disabled', false);
      $('#nj_delegate_acknowledgment').prop('disabled', true);
      $('#nj_delegate_acknowledgment').hide();
    }
  }

  // called dynamically from nj_delegate_acknowledgment
  function nj_delegate_registration_hooks() {
    nj_delegate_adjust_buttons();
    $('#user_professional_license_type').on('change', function () {
      nj_delegate_adjust_buttons();
    });
    $('#acknowledged').on('change', function() {
      if($('#acknowledged').is(':checked')) {
        $('#delegateAlertButton').prop('disabled', false);
      }
    });
  }

  function nj_update_supervisor_continue_button() {
    if($('#acknowledged').is(':checked')) {
      $('#supervisorContinueButton').prop('disabled', false);
    } else {
      $('#supervisorContinueButton').prop('disabled', true);
    }
  }

  function nj_supervisor_hooks() {
    $('#approve_delegate_button').on('click', function(e) {
      var target = $(e.target).data('confirmUrl');
      $('#nj_supervisor_acknowledgment_modal').modal('show');
      nj_update_supervisor_continue_button();
      $('#supervisorContinueButton').on('click', function() {
        $.ajax({
          type: 'PATCH',
          url: target,
          data: '',
          success: null,
          dataType: 'script'
        });
      });
      return false;
    });
    $('#acknowledged').on('change', function() {
      nj_update_supervisor_continue_button();
    });
  }
  return {
    delegate_registration_hooks: nj_delegate_registration_hooks,
    supervisor_hooks: nj_supervisor_hooks
  };
})();
