$(function () {
  $('.scrolltable').on('scroll', function(){
    var $this = $(this);

    if($this.data('all-results-fetched') || $this.data('fetching-results') || $this.data('endless-pagination-data-url') === undefined){
      return;
    }

    if($this.scrollTop() + $this.innerHeight() >= $this[0].scrollHeight - 25) {
      var page = $this.data('endless-pagination-starting-page');
      ++page;
      var url = $this.data('endless-pagination-data-url') + '?page=' + page;
      $this.data('fetching-results', true);

      $.get(url, function(response){
        if(response.trim().length === 0){
          $this.data('all-results-fetched', true);
        }else{
          $this.find('*[data-endless-pagination-appendable-content]').append(response);
          $this.data('endless-pagination-starting-page', page);
        }

        $this.data('fetching-results', false);
      });
    }
  });
});
