// Add 508 support.
export const x508 = (function($, undefined){
  var say = function (text, optionalDelay) {
    if (!optionalDelay) {
      optionalDelay = 0;
    }

    setTimeout(function () {
      let variableName = String(Math.random());
      let uniqueId = `sr-alert_${variableName}`;
      let alertComponent = $('#x508-sr-alert');
      let newAlert = $('<span id=' + uniqueId + '></span>');
      newAlert.text(text);
  
      $(alertComponent).append(newAlert);
      
      setTimeout(function () {
        let theItem = document.getElementById(uniqueId);
        $(theItem).remove();
      }, 2000);
    }, optionalDelay);
  };

  var keyboard_clickable = function(e) {
    if (e.which == 13 || e.which == 32) { //code for enter or space keys.
      e.preventDefault();
      $(e.target).trigger('click');
    }
  };

  var make_button_role_tabbable = function () {
    // For Section 508 any <a> with a role="button" should be keyboard focusable and clickable.
    $('[role=\'button\']')
      .attr('tabindex', '0');
  };

  /* 
    Adds event listeners for role="button" elements to mimic semantic buttons.
    Supports an optional parameter to pass a particular scope. 
    Useful for preventing global scope event listener rewrites in cases when a 
    portion of a page is injected and needs event listeners added after page load.
   */
  var handle_button_role_down = function (event_listener_scope) {
    let theScopedSelector;
    if (! event_listener_scope) {
      theScopedSelector = $('[role=\'button\']');
    } else {
      theScopedSelector = $(event_listener_scope).find('[role=\'button\']');
    }
    $(theScopedSelector).off('keydown.button_role').on('keydown.button_role', function (e) {
      keyboard_clickable(e);
    });
  };

  var sortedHeaderMessage = function () {
    var column_currently_sorted = $('.cloned-header th.sort');
    var sorting_direction = $(column_currently_sorted).attr('aria-sort');
    var column_currently_sorted_text = $('.cloned-header a.sort').text();
    var sr_message = `Sorted By ${column_currently_sorted_text}: ${sorting_direction}`;
    x508.say(sr_message, 100);
  };

  return {
    say: say,
    keyboard_clickable: keyboard_clickable,
    make_button_role_tabbable: make_button_role_tabbable,
    handle_button_role_down: handle_button_role_down,
    sortedHeaderMessage: sortedHeaderMessage
  };
})(jQuery);

$(function(){

  x508.make_button_role_tabbable();

  x508.handle_button_role_down();

  /*
    * Function to ensure expanded collapse elements are visible to both visual
    * screen-reader users.
    */
  function set_collapse_to_visible(collapse_element) {
    var that = collapse_element;
    $(that).attr('aria-hidden', false);
    $(that).trigger('aria-hidden-changed');

    $(that).css('visibility', 'visible');

    var id = $(that).attr('id');
    var collapse_control = $('*[data-target="#' + id + '"]');
    collapse_control.attr('aria-expanded', true);

    /*
     * Not thinking we are using this at the moment. If anyone has a clue,
     * I'd like to get this out of here.
    */
    $(this).find('table.collapsible_headers').find('th').attr('tabindex', '0');
  }

  /*
  * Function to ensure hidden collapse elements are invisible to both visual
  * screen-reader users.
  */
  function set_collapse_to_hidden(collapse_element) {
    var that = collapse_element;
    $(that).attr('aria-hidden', true);
    $(that).trigger('aria-hidden-changed');

    $(that).css('visibility', 'hidden');
    var id = $(that).attr('id');
    var collapse_control = $('*[data-target="#' + id + '"]');
    collapse_control.attr('aria-expanded', false);

    /*
      * Not thinking we are using this at the moment. If anyone has a clue,
      * I'd like to get this out of here.
      */
    $(this).find('table.collapsible_headers').find('th').attr('tabindex', '-1');
  }

  /* Setup aria-hidden on any .collapse header. */
  $('*.collapse').each(function(){
    return !$(this).parent().hasClass('navbar');

    if ($(this).hasClass('show')) {
      $(this).attr('aria-hidden', 'false');
      set_collapse_to_visible(this);
    } else {
      $(this).attr('aria-hidden', 'true');
      set_collapse_to_hidden(this);
    }
  });

  /* For section 508 correct ARIA settings on collapse
  shown and hide events for screen readers. */
  $('*.collapse[aria-hidden]').on('show.bs.collapse', function(e) {
    if (e.target !== this)
      return; // only interested in the event parent only

    set_collapse_to_visible(this);
  });

  $('*.collapse[aria-hidden]').on('hidden.bs.collapse', function(e) {
    if (e.target !== this)
      return; // only interested in the event parent only

    set_collapse_to_hidden(this);
  });

  $('span.fileinput-button').on('keypress', function(e){
    if (e.which == 13 || e.which == 32) { //code for enter or space keys.
      $(e.target).find('input[type="file"]').trigger('click');
      e.preventDefault();
      return false;
    }
  }).attr('tabindex','0');


  /**
Handle invalid, required fields.
Note: Server-side-validation version of what we are doing in validatorian.js
*/
  function display_error_message(field_with_error) {

    var new_error_message = 'Error: This field is invalid';

    /* Get a bunch of attributes for manipulation, setting fallbacks as needed */
    $(field_with_error).attr('aria-invalid', 'true');

    var field_with_error_reference = $(field_with_error).attr('id');
    if (!field_with_error_reference) {
      field_with_error_reference = 'fallback_id';
    }

    var new_reference_for_error_message = field_with_error_reference + Math.random();

    var new_message_container = '<span class="help-block traditional_error_message" id="'
  + new_reference_for_error_message + '">' +
  new_error_message +
  '</span>';

    /* Get current aria-describedby attribute value,
  set if undefined, update if present. Finally, add the new value back to the node.
  */
    var field_with_error_descriptor = $(field_with_error).attr('aria-describedby');
    if (!field_with_error_descriptor) {
      field_with_error_descriptor = new_reference_for_error_message;
    } else {
      field_with_error_descriptor = field_with_error_descriptor + ' ' + new_reference_for_error_message;
    }
    $(field_with_error).attr('aria-describedby', field_with_error_descriptor);

    var accessible_datepicker_check = $(field_with_error).closest('.calendar-datepicker').get(0);

    /* Let's check against accessible datepicker presence, due to its difference structure */
    if ($(accessible_datepicker_check).length > 0) {
      $(accessible_datepicker_check).after(new_message_container);
    } else {
      $(field_with_error).after(new_message_container);
    }
  }

  var invalid_input_field = $('div.field_with_errors').find('input, textarea, select');
  $(invalid_input_field).each(function() {
    display_error_message(this);
  });

  //mark fields that have a parent marked required as aria-required='true'
  //except  if the form field has override-required class.
  $('.required label[for]').each(function(){
    var for_id = $(this).attr('for');
    if(! $(this).hasClass('override-required')){
      $('#'+for_id).attr('aria-required', 'true');
    }
  });

  // hook to check if there is a data-508say attribute on returned addresscards.
  $( 'div#address_card' ).ajaxSuccess(function( event, xhr, settings ) {
    try
    {
      var obj = $(this).find('[data-x508say]');
      if(obj.length > 0){
        var say = obj.data('x508say');
        x508.say(say);
      }
    }catch(e)
    {
      console.log(e);
    }
  });

  //lets css tie into user agent. via html[data-useragent*='MSIE 10.0'] for CSS HAXz.
  document.documentElement.setAttribute('data-useragent', navigator.userAgent);
});
