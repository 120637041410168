export const PMPiSearchPolling = (function() {
  var wait_delta = 1, timer = null, should_time = true, record_url = null;

  var instantiate = function (url) {
    record_url = url;
    _poll();
  };

  var _timeout_error = function (rx_request) {
    should_time = false;
    if (!rx_request.patient_results_empty) {
      _completed(rx_request);
    } else {
      clearTimeout(timer);
      $(document).trigger('PMPi:timeout');
    }
  };

  var _completed = function (rx_request) {
    should_time = false;
    clearTimeout(timer);
    $(document).trigger('PMPi:complete', rx_request);
  };

  var _poll = function () {
    $.ajax(record_url).done(function (data) {
      if (data.status === 'complete') {
        _completed(data.rx_request);
        return;
      }

      if (wait_delta >= 1.09) {
        wait_delta = Math.floor(wait_delta) * 2;
      } else {
        wait_delta = wait_delta + 0.01;
      }

      //almost 2 minutes of actual wait time
      if (wait_delta > 110) {
        _timeout_error(data.rx_request);
        return;
      } else if (should_time) {
        timer = setTimeout(function(){
          _poll();
        }, wait_delta * 1000);
      }

    });
  };

  return {
    instantiate: instantiate
  };
})();

window.PMPiSearchPolling = PMPiSearchPolling;