export const AccessibleDateConstraints = function (
  date_format,
  min_allowed_date,
  max_allowed_date
) {
  this.date_format = date_format ? date_format : 'mm/dd/yyyy';
  this.min_allowed_date = min_allowed_date ? min_allowed_date : new Date('01/01/1900');
  this.max_allowed_date = max_allowed_date ? max_allowed_date : new Date('01/01/2100');
};

AccessibleDateConstraints.prototype = {
  constructor: AccessibleDateConstraints,
  setDateFormat: function (f) {
    if (f !== undefined) {
      this.date_format = f;
    }
  },
  setMinAllowedDate: function (d) {
    if (d !== undefined) {
      var obj = $.fn.datepicker.DPGlobal.parseDate(
        d,
        $.fn.datepicker.DPGlobal.parseFormat(this.date_format),
        'en',
        true
      );
      this.min_allowed_date = accessibleDatePickerHelper.fixTimeZoneMadness(
        this.date_format,
        obj
      );
    }
  },

  setMaxAllowedDate: function (d) {
    if (d !== undefined) {
      this.max_allowed_date = accessibleDatePickerHelper.fixTimeZoneMadness(
        this.date_format,
        d
      );
    }
  },
};

export const accessibleDatePickerHelper = (function ($) {
  // returns -1 if DateA > DateB
  // returns 0 if DateA == DateB
  // returns 1 if DateA < DateB
  // return null if parse error.
  var compare = function (DateA, DateB) {
    try {
      var a = new Date(DateA);
      var b = new Date(DateB);

      var msDateA = Date.UTC(
        a.getFullYear(),
        a.getMonth() + 1,
        a.getDate()
      );
      var msDateB = Date.UTC(
        b.getFullYear(),
        b.getMonth() + 1,
        b.getDate()
      );

      if (parseFloat(msDateA) < parseFloat(msDateB)) {
        return -1; // lt
      } else if (parseFloat(msDateA) == parseFloat(msDateB)) {
        return 0; // eq
      } else if (parseFloat(msDateA) > parseFloat(msDateB)) {
        return 1; // gt
      }
    } catch (error) {
      console.log(error);
    }
    return null; // error
  };

  var enforceDateRange = function (start_field, end_field) {
    if ($(start_field).val() == '') return; // nothing to do if start date is blank.

    var start_date = Date.parse($(start_field).val());
    if (isNaN(start_date)) {
      $(start_field).val(''); // if the start date is not a date then set it to blank.
      return;
    }

    if ($(end_field).val() == '') return; // nothing to do if end date range is blank.

    var end_date = Date.parse($(end_field).val());
    if (isNaN(end_date)) return; // nothing to do if end date is not set.

    var comparison = accessibleDatePickerHelper.compare(start_date, end_date);

    if (comparison == null || comparison == 1) {
      // start date is after end date!
      $(start_field).val($(end_field).val()); //set start to to end date.
      return; //done
    }
  };

  // this function checks dates without delimiters and tries to guess a sane result- I.E., 02011990 is 02/01/1990
  var formatDate = function (constraints, date) {
    let new_date = date;
    if (!date.match(/-|\//)) {
      var year = date.slice(date.length - 4),
        day = date.slice(2, year.length),
        month = date.slice(0, day.length);
      
      new_date = month + '/' + day + '/' + year;
    }

    if (new_date === '//') {
      return '';
    }
    
    return new_date;
  };

  var enforceMinMaxDate = function (constraints, value) {
    if( !(value == '') ) {
      let comparison = accessibleDatePickerHelper.compare(value, constraints.min_allowed_date);
      if( comparison == null  ||
        comparison == -1 )    // before the minimum allowed date.
        return constraints.min_allowed_date;

      comparison = accessibleDatePickerHelper.compare(value, constraints.max_allowed_date);
      if(comparison == null ||
        comparison == 1 )    // after the max allowed date.
        return constraints.max_allowed_date;
    }
    return value;
  };

  var parseDate = function (format, d) {
    if (d instanceof Date) {
      return accessibleDatePickerHelper.fixTimeZoneMadness(format, d);
    }

    return d;
  };

  var fixTimeZoneMadness = function (format, date) {
    if (date instanceof Date) {
      if (isNaN(date.getDate())) {
        return '';
      }
     
      var parts = date.toLocaleDateString('en-US').split('/');

      var year = parts[2];
      var month = parts[0];
      var day = parts[1];

      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
       
      return month + '/' + day + '/' + year;
    
    } else {
      throw 'Expected instanceof Date got ' + date;
    }
  };

  var requiredDetection = function (obj) {
    if ($(obj).attr('aria-required') == 'true') return true;

    if ($(obj).closest('fieldset').find('legend').hasClass('required'))
      return true;

    return false;
  };

  return {
    compare: compare,
    enforceMinMaxDate: enforceMinMaxDate,
    enforceDateRange: enforceDateRange,
    fixTimeZoneMadness: fixTimeZoneMadness,
    parseDate: parseDate,
    requiredDetection: requiredDetection,
    formatDate: formatDate,
  };
})(jQuery);
