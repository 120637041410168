window.FileUploadHelper = FileUploadHelper;

export const FileUploadHelper = {
  event_listeners: function() {
    $('.file_upload_input_button').on('click', function() {
      $(this).closest('[data-inputgroup="file-upload"]').find('.file_upload_field').trigger('click');
    });

    $('.file_upload_field').on('change', function() {
      $(this).closest('[data-inputgroup="file-upload"]').find('.file_upload_file_name').val($(this).val());
    });
  },

  plug_upload_event: function() {
    var upload_selector = $('#new_peer_monitor_reviewees_upload');
    if (upload_selector.length > 0) {
      $('#new_peer_monitor_reviewees_upload').fileupload({
        url: '/admin/peer_monitor_reviewees_uploads',
        autoUpload: false,
        showElementClass: 'show',
        formData: {
          'peer_monitor_reviewees_upload[user_id]': $(document).find('.peer_monitor_upload_container').data().userId,
        },
        fileContainer: $('#file_upload_field'),
        add: function(e, data) {
          $('#submit-new-peer-monitor-upload').off('click').one('click', function(e) {
            e.preventDefault();
            data.submit()
              .done(function (result, textStatus, jqXHR) {
                $('.peer_monitor_upload_container').html(result.html);
              });
          });
        },
      });
    }
  }
};
