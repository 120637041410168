export const initializeTooltips = function () {
  // We're using custom code for bootstrap tooltips because the default behavior is not accessible re: hover
  // Specifically, hover does not allow the user to interact with the tooltip content, as it disappears when the user moves the mouse

  $('[data-behavior="tooltip"]').tooltip({ trigger: 'manual' });
  $('[data-behavior="tooltip"]').hover(
    function () {
      $(this).tooltip('show');
    }, function () {
      let tooltipId = $(this).attr('aria-describedby');
      let tooltip = $('#' + tooltipId);
      if (tooltip.is(':hover')) {
        tooltip.on('mouseleave', function () { $(this).tooltip('hide'); });
      } else {
        $(this).tooltip('hide');
      }
    }
  );
  $('[data-behavior="tooltip"]').on('focusin', function () {
    $(this).tooltip('show');
  }).on('focusout', function () {
    $(this).tooltip('hide');
  });
};

window.initializeTooltips = initializeTooltips;

$(function () {
  initializeTooltips();
});

$(document).on('ajax:complete', function () {
  initializeTooltips();
});

/**
 * Accessibility: Close tooltips and popovers on ESC key (WCAG 1.4.13)
 * Note: Using event capture:true to cancel the propagation preventing the modal to close at first (hence no `.on()` here)
 * Tested with Bootstrap v3.4.1; Does not support IE 11
 */

$.fn.listenEscKeyToCloseOverlays = function () {
  return this.each(function () {
    if (('undefined' !== typeof $.fn.tooltip) || ('undefined' !== typeof $.fn.popover)) {
      document.addEventListener('keydown', function (e) {
        if ('Escape' === e.key) {
          const $openTooltips = $('.tooltip');
          const $openPopovers = $('.popover');
          if ($openPopovers.length || $openTooltips.length) {
            e.stopPropagation();
            $openTooltips.tooltip('hide');
            $openPopovers.popover('hide');
          }
        }
      }, true);
    }
  });
};

$(document).listenEscKeyToCloseOverlays();
