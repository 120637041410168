var DemographicsDisabler = (function ($) {

  function instantiate() {
    var me = new Object();

    me.missing_fields = [];
    me.disable_demographics = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      if (required_fields && required_fields.length > 0) {
        // Disable all inputs on the page for returning users.
        // For each required field, check if it's empty. If it is, re-enable it.
        $('.demographics input, .demographics select').prop('readonly', true);
        $('#dea_numbers_table').hide();
        $('#employer_identifier_dea_table').hide();
        $('#employer_identifier_npi_table').hide();
        $('#employer_identifier_ncpdp_nabp_table').hide();
        $('.healthcare_specialty_container').hide();
        $('.healthcare_specialty_browse_all').hide();

        $.each(required_fields, function(fn) {
          var el = $('[name="user[' + required_fields[fn] + ']"]');
          if (el.val() === '') {
            el.prop('readonly', false);
            me.missing_fields.push(required_fields[fn]);
            el.addClass('error');
          }
        });
      }
    };

    me.enable_ssn_fields = function() {
      var enable = $('[data-enable-ssn-fields]').data('enable-ssn-fields');
      if (enable) {
        var ssn_field = $('[name="user[last_4_ssn]"]');
        ssn_field.prop('readonly', false);
        var license_num_field = $('[name="user[professional_license_number]"]');
        license_num_field.prop('readonly', false);
      }
    };

    me.enable_dea_fields = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      var enable = $('[data-enable-dea-field]').data('enable-dea-field') && required_fields.indexOf('dea_number') > -1;

      if (enable) {
        $('[name=dea_number]').prop('readonly', false);
        $('#dea_numbers_table').show();
      }
    };

    me.enable_institution_number_fields = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      var enable = $('[data-enable-institution-number-field]').data('enable-institution-number-field') && required_fields.indexOf('institution_number') > -1;

      if (enable) {
        $('[name=institution_number]').prop('readonly', false);
      }
    };

    me.enable_employer_dea_fields = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      var enable = $('[data-enable-employer-dea-field]').data('enable-employer-dea-field') && required_fields.indexOf('employer_dea_number') > -1;

      if (enable) {
        $('[name=employer_dea_number]').prop('readonly', false);
        $('#employer_identifier_dea_table').show();
      }
    };

    me.enable_employer_npi_fields = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      var enable = $('[data-enable-employer-npi-field]').data('enable-employer-npi-field') && required_fields.indexOf('employer_npi_number') > -1;

      if (enable) {
        $('[name=user_employer_npi_number]').prop('readonly', false);
        $('#employer_identifier_npi_table').show();
      }
    };

    me.enable_employer_ncpdp_fields = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      var enable = $('[data-enable-employer-ncpdp-field]').data('enable-employer-ncpdp-field') && required_fields.indexOf('ncpdp_nabp_number') > -1;

      if (enable) {
        $('[name=user_ncpdp_nabp_number]').prop('readonly', false);
        $('#employer_identifier_ncpdp_nabp_table').show();
      }
    };

    me.enable_healthcare_specialty_fields = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      var enable = $('[data-enable-healthcare-specialties]').data('enable-healthcare-specialties') && required_fields.indexOf('healthcare_specialty') > -1;

      if (enable) {
        $('[name=input-health-care-specialties]').prop('readonly', false);
        $('.healthcare_specialty_container').show();
        $('.healthcare_specialty_browse_all').show();
      }
    };

    me.enable_agency_fields = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      var enable = $('[data-enable-agency-field]').data('enable-agency-field') && required_fields.indexOf('agency') > -1;

      if (enable) {
        $('[name="user[agency_id]"]').prop('readonly', false);
      }
    };

    me.enable_delegate_fields = function() {
      var required_fields = $('[data-disable-demographics-for-fields]').data('disable-demographics-for-fields');
      var enable = required_fields && required_fields.indexOf('delegate') > -1;

      if (enable) {
        $('[name=email_search]').prop('readonly', false);
      }
    };

    me.enable_error_fields = function () {
      //This fixes the dropdown for license type to be editable, as well as anything else that might have been missed.
      $('div.field_with_errors [type=text][readonly]').prop('readonly', false);
      $('div.field_with_errors [type=select][readonly]').prop('readonly', false);
    };

    me.enable_professional_license_type = function() {
      // This field may not be listed in the role's required field, but it still might be a required field if SSN verification is on
      var enable = $('[data-enable-professional-license-type]').data('enable-professional-license-type');

      if (enable) {
        $('[name="user[professional_license_type]"]').prop('readonly', false);
        me.missing_fields.push('professional_license_type');
      }
    };

    me.show_missing_fields = function() {
      $('#missing_required_fields').html(me.missing_fields.join(', ').replace('_', ' ') + '.');
    };
    return me;
  }

  function actuate() {
    var disabler = instantiate();
    disabler.disable_demographics();
    disabler.enable_ssn_fields();
    disabler.enable_dea_fields();
    disabler.enable_institution_number_fields();
    disabler.enable_healthcare_specialty_fields();
    disabler.enable_employer_dea_fields();
    disabler.enable_employer_npi_fields();
    disabler.enable_employer_ncpdp_fields();
    disabler.enable_agency_fields();
    disabler.enable_delegate_fields();
    disabler.enable_error_fields();
    disabler.enable_professional_license_type();
    disabler.show_missing_fields();
  }

  return {
    actuate:actuate,
    instantiate:instantiate
  };
})($);

$(function(){
  DemographicsDisabler.actuate();
});
