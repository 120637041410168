const checkbox_selector = 'input[name="selected_patients[]"][type="checkbox"]';

$(function (event) {
  $('#select_all').on('click', function() {
    if (this.checked) {
      $('input[id^="partial_search_patient_checkbox"]').each(function() {
        this.checked = true;
      });
    } else {
      $('input[id^="partial_search_patient_checkbox"]').each(function() {
        this.checked = false;
      });
    }
    disable_enable_button();
  });

  function disable_enable_button(){
    if ($(checkbox_selector + ':checked').length > 0) {
      $('#run_button').prop('disabled', false);
    } else {
      $('#run_button').prop('disabled', true);
    }
  }
});
