$(function() {
  $('#pdmp-reports-setting-form').on('click', '.pdmp-resource-link-remove', function(){
    $(this).closest('.link-details').remove();
    return false;
  });
  $('#pdmp-reports-setting-form').on('click', '.pdmp-resource-link-add', function(){
    $(this).before(NewRowTemplate);
    return false;
  });

  var NewRowTemplate = 
    '<div class="link-details mb-4">' + 
      '<div class="form-group d-flex align-items-center row col-8 mb-0">' +
        '<span class="pr-2 mb-2">New link</span>' +
        '<label class="row col-12 col-md-5 mr-1" for="pdmp_reports_setting_data_links__title">' +
          '<span class="sr-only">title</span>' +
          '<input type="text" name="pdmp_reports_setting[resources_links][][title]" id="pdmp_reports_setting_data_links__title" value="" class="form-control" placeholder="Title">' +
        '</label> ' + 
        '<label class="row col-12 col-md-5 mr-1" for="pdmp_reports_setting_data_links__url">' +
          '<span class="sr-only">URL</span>' +
          '<input type="text" name="pdmp_reports_setting[resources_links][][url]" id="pdmp_reports_setting_data_links__url" value="" class="form-control" placeholder="URL">' +
        '</label>' +
      '</div>' +
      '<div class="row col-12">' +
        '<label class="row align-items-center col-12">' +
          '<input type="checkbox" name="pdmp_reports_setting[resources_links][][new_page]" id="pdmp_reports_setting_data_links__new_page" value="true" class="mr-1" checked="checked">' +
          'Open in new page?' +
        '</label>' +
      '</div>' +
      '<a class="pdmp-resource-link-remove" href="#">Remove</a>' +
    '</div>';
});
