/*
 * File:   datepicker_daterange.js
 * 
 * Designed as an addon for the datepicker.js library adapted for
 *  the PMP AWARxE platform by Bamboo Health 06/20
 * 
 * Original datepicker.js content is licensed according to the W3C Software License at
 *  https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 */

import { AccessibleDateConstraints, accessibleDatePickerHelper } from '../accessible_date_helpers';
import { DateRangeDatePicker } from './daterange_datepicker';

export const DatePickerDateRange = function (dateRangeNode) {
  this.dateRangeNode = dateRangeNode;

  this.dateRangeBeginInputNode = $(dateRangeNode).find('[data-date-range-definition="begin"]').get(0);
  this.dateRangeEndInputNode = $(dateRangeNode).find('[data-date-range-definition="end"]').get(0);
  this.dateRangeBeginInputNodeParent = $(this.dateRangeBeginInputNode).parent();
  this.dateRangeEndInputNodeParent = $(this.dateRangeEndInputNode).parent();
  this.dateRangeBeginInputNodeButton = $(this.dateRangeBeginInputNodeParent).find('button').get(0);
  this.dateRangeEndInputNodeButton = $(this.dateRangeEndInputNodeParent).find('button').get(0);
  this.dateRangeBeginInputDialog = $(this.dateRangeBeginInputNodeParent).find('.datepickerDialog').get(0);
  this.dateRangeEndInputDialog = $(this.dateRangeEndInputNodeParent).find('.datepickerDialog').get(0);

  this.earliestYear = String(new Date().getFullYear() - 121);
  this.latestYear = String(new Date().getFullYear() + 100);
  this.defaultCalendarDate = new Date();
  this.endDate = this.setEndDate(this.dateRangeNode);
  this.startDate = this.setStartDate(this.dateRangeNode);
  this.defaultDate = this.defaultCalendarDate;
  

  this.savedBeginDate = this.startDate;
  this.savedEndDate = this.endDate;

  this.dateConstraints = new AccessibleDateConstraints('MM/DD/YYYY', this.startDate, this.endDate);

  this.beginDatePicker = new DateRangeDatePicker(this, 'start');
  this.endDatePicker = new DateRangeDatePicker(this, 'end');
};

DatePickerDateRange.prototype.init = function () {
  this.beginDatePicker.init();
  this.endDatePicker.init();

  $(this.dateRangeBeginInputNode).on('blur', () => {
    this.finalDateCheck(this.dateRangeBeginInputNode, this.defaultDate);
  });

  $(this.dateRangeEndInputNode).on('blur', () => {
    this.finalDateCheck(this.dateRangeEndInputNode, this.defaultDate);
  });
};

DatePickerDateRange.prototype.saveDateFromChild = function (val, inputNode) {
  this.saveDate(val, inputNode);
};


DatePickerDateRange.prototype.finalDateCheck = function (inputNode, inputNodeInitialValue) {
  let orig_val = $(inputNode).val();
  this.saveDate(orig_val, inputNode);
  let required = accessibleDatePickerHelper.requiredDetection(inputNode);
  let updated_val;
  
  updated_val = accessibleDatePickerHelper.formatDate(this.dateConstraints.date_format, orig_val);
  this.saveDate(updated_val, inputNode);

  updated_val = accessibleDatePickerHelper.parseDate(this.dateConstraints.date_format, updated_val);
  updated_val = accessibleDatePickerHelper.enforceMinMaxDate(this.dateConstraints, updated_val);

  this.saveDate(updated_val, inputNode);

  if (required && (updated_val == '')) {
    accessibleDatePickerHelper.enforceDateRange(this.dateRangeBeginInputNode, this.dateRangeEndInputNode);
  } else {
    accessibleDatePickerHelper.enforceDateRange(this.dateRangeBeginInputNode, this.dateRangeEndInputNode);
  }

  /* 
    Update the value if the date input is the current node OR 
    update the value if the date input already has a value——
    in case the opposite input has a value requiring a change to the current input. 
  */
  if (inputNode == this.dateRangeBeginInputNode || this.dateRangeBeginInputNode.value !== '') {
    this.saveDate(this.dateRangeBeginInputNode.value, this.dateRangeBeginInputNode);
  }

  if (inputNode == this.dateRangeEndInputNode || this.dateRangeEndInputNode.value !== '') {
    this.saveDate(this.dateRangeEndInputNode.value, this.dateRangeEndInputNode);
  }

  $(this.dateRangeBeginInputNode).trigger('change');
  $(this.dateRangeEndInputNode).trigger('change');
};

DatePickerDateRange.prototype.setStartDate = function (dataNode) {
  let startDateFromNode = dataNode.getAttribute('data-date-start-date');
  let stateDateIsAfterEndDate = false;

  if (startDateFromNode) {
    startDateFromNode = new Date(startDateFromNode);
    stateDateIsAfterEndDate = startDateFromNode > this.endDate;
  }

  if (stateDateIsAfterEndDate) {
    console.warn(`A datepicker start date cannot come after the end date. 
      The start date has been set to the default start date.`);
  }

  if (!startDateFromNode || stateDateIsAfterEndDate) {
    return new Date(this.earliestYear);
  } else {
    return new Date(startDateFromNode);
  }
};


DatePickerDateRange.prototype.setEndDate = function (inputNode) {
  var endDateFromNode = inputNode.getAttribute('data-date-end-date');
  if (!endDateFromNode) {
    return new Date(this.earliestYear);
  } else if (endDateFromNode === '0d') {
    return new Date();
  } else {
    return new Date(endDateFromNode);
  }
};

DatePickerDateRange.prototype.saveDate = function (newDate, inputNode) {
  let required = accessibleDatePickerHelper.requiredDetection(inputNode);
  if (inputNode == this.dateRangeBeginInputNode) {
    if (newDate === '' && required) {
      this.savedBeginDate = new Date();
      $(inputNode).val(this.getParsedDate(this.savedBeginDate));
      return;
    } else if (newDate === '') {
      this.savedBeginDate = '';
      return;
    } else if (newDate !== '') {
      this.savedBeginDate = new Date(newDate);
      $(inputNode).val(this.getParsedDate(this.savedBeginDate));
      return;
    }
  }

  if (inputNode == this.dateRangeEndInputNode) {
    if (newDate === '' && required) {
      this.savedEndDate = new Date();
      $(inputNode).val(this.getParsedDate(this.savedEndDate));
      return;
    } else if (newDate === '') {
      this.savedEndDate = '';
      return;
    } else if (newDate !== '') {
      this.savedEndDate = new Date(newDate);
      $(inputNode).val(this.getParsedDate(this.savedEndDate));
      return;
    }
  }
};

DatePickerDateRange.prototype.getParsedDate = function (savedDate) {
  return accessibleDatePickerHelper.parseDate(this.dateConstraints.date_format, savedDate);
};
