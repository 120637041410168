import { EJS } from '../app/embedded';

var AutoUpdateTemplate = (function ($) {
  function setup() {
    $(document).on('ajaxSuccess', function (event, xhr, options, data) {
      // Loop through specially formatted json hash from server- update
      // all elements with the associated template and data
      if (data !== undefined) {
        var templates = data['templates'];
      }

      if (templates !== undefined) {
        for (let node_id in templates) {
          if (templates.hasOwnProperty(node_id)) {
            var template_key = templates[node_id]['key'],
              template_data = templates[node_id]['data'],
              node_to_update = $('#'+node_id),
              template = EJS.Template.named(template_key);
            if (template === undefined) {
              throw 'Template \'' + template_key + '\' not found!';
            }
            if (template_data !== undefined) {
              if (template_data['collection'] !== undefined) {
                node_to_update.html(template.render_collection(template_data));
              } else {
                node_to_update.html(template.render(template_data));
              }
            } else {
              node_to_update.html(template.render());
            }
            var pagination_metrics = {
              total_pages: xhr.getResponseHeader('Pagination-TotalPages'),
              total_items: xhr.getResponseHeader('Pagination-TotalResults')
            };
            $(document).trigger('pagination:update_metrics', pagination_metrics);
            $(document).trigger('table_updated');
          }
        }
      }
    });
  }
  return {
    setup: setup
  };
}(jQuery));

AutoUpdateTemplate.setup();
