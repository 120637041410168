function setupClinicalAlertSettings() {
  $('#clinical_alert_config_settings_email_notification').on('change', function(e) {
    if(e.target.checked) {
      $('#alert_frequency_settings').show(200);
    } else {
      $('#alert_frequency_settings').hide(200);
    }
  });

  $('input[name=alert_frequency]').on('change', function(e) {
    if($('input[name=alert_frequency]:checked').val() == 'monthly') {
      $('#alert_frequency_monthly_settings').show(200);
    } else {
      $('#alert_frequency_monthly_settings').hide(200);
    }
  });

  $('#clinical_alert_config_settings_email_notification').trigger('change');
  $('#alert_frequency_monthly').trigger('change');
}

$(document).on('load turbolinks:load', function() {
  setupClinicalAlertSettings();
});

$(function() {
  setupClinicalAlertSettings();
});

