import { Application } from '../application';
import { RemoteSubmission } from '../app/remote_submission';

const RemoteErrorCorrection = function ($) {
  var selector;

  function mark_required() {
    if (!this.parent().parent().hasClass('required')) {

      $(this.parents('[for*=\'' + this.attr('id') + '\']').find('span')[0]).addClass('required');

      //begin hardcoded goofiness to mark table headers if inside of drugs table
      if (this.length < 1) {
        //sometimes we get blank nodes. It will throw an error if we try .attr on blank nodes.
        return;
      }
      var table = this.closest('table');
      if (table.length > 0 && this.attr('id')) {

        if (this.attr('id').match(/product_identifier/)) {
          $(table.find('td')[0]).find('span').addClass('required');
        }
        if (this.attr('id').match(/quantity/)) {
          $(table.find('td')[1]).find('span').addClass('required');
        }
        if (this.attr('id').match(/units/)) {
          $(table.find('td')[2]).find('span').addClass('required');
        }
      }

      this.attr('aria-required', true);
      this.attr('data-validate', true);
      this.attr('data-validates-matching', '/.+/');
      this.attr('data-validation-failed-message', 'Field is required.');
      $(document).trigger('enable_validations');
    }
    if(this.length > 1) {
      this.each(function(index, value) {
        mark_required.call($(value));
      });
    }
  }

  function mark_not_required() {
    this.parent().find('span').removeClass('required');
    this.attr('data-validate', false);
  }

  function mark_all_required() {
    if ($('*[data-required-fields]').length == 0) {
      return;
    }
    $.each($('*[data-required-fields]').data('required-fields'), function (index, value) {
      mark_required.call($(selector.field_selector(value[0], value[1], null, false)));
    });
  }

  function create_new_error_banner(parent_node) {
    var me = new Object();
    me.parent_node = $(parent_node);
    me.node = null;
    me.error_count = 0;

    me.create_node = function () {
      var node_string = '<div class="error_banner"><i class="icon-farm-x large"></i><span> ';
      node_string = node_string + me.error_count;
      node_string = node_string + ' Error';
      if (me.error_count !== 1) {
        node_string = node_string + 's';
      }
      node_string = node_string + '  Unresolved</span></div>';
      if (me.error_count < 1) {
        node_string = '<div class="error_banner"></div>';
      }
      return $(node_string);
    };
    me.grab_node = function () {
      me.node = me.parent_node.find('div.error_banner');
    };
    me.create_and_append_node = function () {
      me.parent_node.append(me.create_node());
      me.grab_node();
    };
    me.refresh_node = function () {
      me.node.empty();
      me.node.replaceWith(me.create_node());
      me.grab_node();
      if (me.error_count === 0) {
        me.node.hide();
      }
    };
    me.increment_error_count = function () {
      me.error_count = me.error_count + 1;
      me.refresh_node();
    };
    me.decrement_error_count = function () {
      me.error_count = me.error_count - 1;
      if (me.error_count < 0) {
        me.error_count = 0;
      }
      me.refresh_node();
    };
    me.set_error_count = function (count) {
      me.error_count = parseInt(count);
      me.refresh_node();
    };
    me.setup_listeners = function () {
      $(document).on('error_count:will_be_updated', function (event, data) {
        me.set_error_count(data.error_count);
      });
      $(document).on('error_parser:error_found', function (event, data) {
        me.increment_error_count();
      });
      $(document).on('error_field:was_updated', function (event, data) {
        if (data['error_count']) {
          for (var i = 0; i < parseInt(data['error_count']); i++) {
            me.decrement_error_count();
          }
        } else {
          me.decrement_error_count();
        }

      });
    };
    me.instantiate = function () {
      me.create_and_append_node();
      me.setup_listeners();
    };
    me.instantiate();
    return me;
  }

  function create_new_error_parser() {
    var me = new Object();
    me.parse = function (error_collection) {
      me.error_collection = error_collection;
      if (!error_collection) {
        return me;
      }
      for (var i = error_collection.length - 1; i >= 0; i--) {
        var error = error_collection[i];
        $(document).trigger('error_parser:error_found', error);
      }
    };

    return me;
  }

  function create_new_correction_section(node) {
    var me = new Object();
    var submission = new RemoteSubmission('remote_error_correction');
    me.node = node;
    me.disable_inputs = function () {
      me.node.find('input, select').prop('disabled', true);
      //we also need to hide the add button to create new drug rows!
      $('td[data-action]').hide();
    };
    me.highlight_error_field = function (model_name, attribute_name, error_description, model_id) {
      var selector = submission.field_selector(model_name, attribute_name, model_id);
      var highlight_node = $(selector);

      if (highlight_node.length > 0) {
        me.expand();
        highlight_node.addClass('attribute_error');
        highlight_node.removeAttr('disabled');
        if (highlight_node.parent().find('i.icon-farm-x').length > 0) {
          if (highlight_node.parent().has(':contains(\'' + error_description + '\')').length === 0){
            highlight_node.parent().append($('<span class=\'attribute_error_message\'>' + error_description + '</span>'));
          }
        } else {
          highlight_node.parent().append($('<i class=\'icon-farm-x ml-1\'></i> <span class=\'attribute_error_message\'>' + error_description + '</span>'));
        }


        highlight_node.on('change keydown', function () {
          if (highlight_node.data('node_was_altered')) {

          } else {
            highlight_node.parent().find('i.icon-farm-x').remove();
            var error_messages = highlight_node.parent().find('span.attribute_error_message'),
              error_messages_count = error_messages.length;
            error_messages.remove();
            highlight_node.removeClass('attribute_error');
            $(document).trigger('error_field:was_updated', {error_count:error_messages_count});
            highlight_node.data('node_was_altered', true);
          }

        });
      }


    };
    me.collapse = function () {
      $('i.icon-disclosure[for="'+me.node.attr('id')+'"]').removeClass('open').addClass('closed');
      me.node.removeClass('in');
    };
    me.expand = function () {
      $('i.icon-disclosure[for="'+me.node.attr('id')+'"]').addClass('open').removeClass('closed');
      me.node.addClass('in');
    };
    me.setup_listeners = function () {
      $(document).on('error_parser:error_found', function (event, error) {
        me.highlight_error_field(error.model_type, error.attribute_name, error.error, error.model_id);
      });
    };
    me.instantiate = function () {
      me.setup_listeners();
      // me.collapse();
    };
    me.instantiate();
    return me;
  }

  // Fill in the results of an id search
  function fill_in_id_fields(remote_model, id_type, id_model, id_info) {
    var prefix = '#' + remote_model + '_' + id_model + '_';

    if (id_model === 'dispensary') {
      $(prefix + 'name').val(id_info.name || id_info.organization_name);
    } else {
      $(prefix + 'first_name').val(id_info.first_name || id_info.authorized_official_first_name);
      $(prefix + 'last_name').val(id_info.last_name || id_info.authorized_official_last_name);
    }

    $(prefix + 'address_one').val(id_info.clean_address_one || id_info.business_mailing_address_line1 || id_info.mailing_address1 || id_info.address_line1);
    $(prefix + 'address_two').val(id_info.clean_address_two || id_info.business_mailing_address_line2 || id_info.mailing_address2 || id_info.address_line2);
    $(prefix + 'city').val(id_info.clean_city || id_info.business_mailing_address_city || id_info.mailing_city);
    $(prefix + 'state').val(id_info.clean_state || id_info.business_mailing_address_state || id_info.mailing_state_code);
    $(prefix + 'postal_code').val(id_info.clean_postal_code || id_info.business_mailing_address_postal_code || id_info.mailing_zip_code);

    if (id_type !== 'dea') $(prefix + 'phone_number').val(id_info.contact_phone || id_info.business_phone);
  }

  // When a user clicks on the identifier search buttons, initiate a ajax call to search and fill in the results
  function register_search_callbacks() {
    $(document).on('click', '.identifier-search', function(evt) {
      var button = $(this);
      var error_message = $('#' + button.data('error-message-id'));
      var id_type = button.data('id-type');
      var id_model = button.data('id-model');
      var identifier = button.closest('.input-group').find('input[type=text]').val();

      if (!identifier || identifier === '') {
        if (error_message) {
          error_message.hide();
          button.attr('aria-describedby', '');
        }
        return;
      }
      $.ajax({
        url: '/error_corrections/identifier_search',
        method: 'POST',
        data: { 'identifier': identifier, 'identifier_type': id_type},
        success: function (data) {
          if (error_message) {
            error_message.hide();
            button.attr('aria-describedby', '');
          }
          fill_in_id_fields('remote_manual_submission_remote_manual_submission', id_type, id_model, data);
          fill_in_id_fields('remote_error_correction_remote_error_correction', id_type, id_model, data);
        },
        error: function (data) {
          if (error_message) {
            error_message.show();
            button.attr('aria-describedby', button.data('error-message-id'));
          }
        }
      });
    });
  }

  function create_new(a_root_node) {
    selector = new RemoteSubmission('remote_error_correction');

    register_search_callbacks();

    mark_all_required();

    $(document).on('single_nest_row:row_added', function () {
      mark_all_required();
    });

    var me = new Object();
    me.root_node = $(a_root_node);
    me.error_banner = create_new_error_banner(me.root_node.find('#content>h3'));
    me.correction_sections = [];
    me.parser = create_new_error_parser();

    $('div.fields_container .dispensation_correction_field_group').each(function () {
      var div = $(this), correction_section = create_new_correction_section(div); // we need to run this to populate errors

      if ($('div.fields_container').data('should-disable-correct-fields') === true) {
        me.correction_sections.push(correction_section);
        correction_section.disable_inputs();
        correction_section.collapse();
      }
    });


    $(document).on('submit', function (event, data) {
      if (!event.isDefaultPrevented()) {
        $('#remote_wait_modal').modal('show');
        $('input, select').removeAttr('disabled');
      }
    });
    $(document).on('hide.bs.modal', function (event, data) {
      if (event.target.id === 'remote_wait_modal') {
        event.preventDefault();
      }
    });

    if($('#patient_species_human').is(':checked')) {
      $('#patient_animal_name').parent().hide();
      mark_not_required.call($('#patient_animal_name'));
    } else {
      mark_required.call($('#patient_animal_name'));
    }

    //Allows for error correction if an incorrect species code somehow gets through the system.
    if(!$('#patient_species_human').is(':checked') && !$('#patient_species_animal').is(':checked') && document.getElementById('patient_species_human') != null ) {

      document.getElementById('patient_species_human').removeAttribute('disabled');
      document.getElementById('patient_species_animal').removeAttribute('disabled');

    }

    $(document).on('change', 'input:radio', function(event) {
      if($('#patient_species_human').is(':checked')) {
        mark_not_required.call($('#patient_animal_name'));
        // Necessary when correcting invalid species code to human as CH validates animal name as required if patient animal section exists
        $('#patient_animal_name').val('none');
        $('#patient_animal_name').parent().hide();
      } else {
        mark_required.call($('#patient_animal_name'));
        $('#patient_animal_name').parent().show();
      }
    });

    me.parser.parse($('*[data-fields-errors]').data('fields-errors'));
    return me;
  }

  return {
    'instantiate':create_new
  };
}($);

export const sendDispensationVoid = function(el) {
  var voidUrl = $(el).data('void-url');
  var redirectUrl = $(el).data('success-url');
  $.ajax({
    method: 'GET',
    url: voidUrl,
    data: { void_reason: $('#void_reason').val() }
  }).done(function() {
    window.location.replace(redirectUrl);
  }).fail(function() {
    alert('There was a problem contacting the server. Please try again later.');
  });
};

window.sendDispensationVoid = sendDispensationVoid;

$(function () {
  var controllers = ['error_correction', 'dispensation_corrections'];
  if (Application.current({ controllers: controllers})) {
    var error_correction = RemoteErrorCorrection.instantiate($('body'));
  }
});
