var LiveForm = (function ($) {
  var submit_timer = null,
    form_monitor_active = false;
  function handle_form_change(event) {
    if (!form_monitor_active) {
      return;
    }
    var target = $(event.target),
      parent_form = target.closest('*[data-live-form-delay-ms]'),
      form_change_delta = 100;
    if (parent_form.length > 0) {
      form_change_delta = parent_form.data('live-form-delay-ms');
      clearTimeout(submit_timer);
      submit_timer = setTimeout(function () {
        $(parent_form).trigger('submit');
      }, form_change_delta);
    }
  }

  function initialize_listeners() {
    $(document).on('change keyup propertychange input', handle_form_change);

    // Internet explorer submits input events when a page initializes. Maybe 
    // they thought setting the initial values of form fields would constitute 
    // user input? Sigh.
    // Let's wait a second after the page is ready to ignore those.
    $(function () {
      $('form[data-auto-trigger=true]').trigger('submit');
      setTimeout(function () {
        form_monitor_active = true;
      }, 1000);
    });
  }
  return {
    setup: initialize_listeners,
    monitoring: form_monitor_active
  };
})(jQuery);

LiveForm.setup();
