var InputClear = (function ($) {
  $(document).on('click', '*[data-form-clear-selector]', function (event) {
    event.preventDefault();
    var selector = $(event.target).data('form-clear-selector');
    var validateButtonSelector = $(event.target).data('form-validate-button-selector');
    $(selector).each(function () {
      var item = $(this);
      item.val('');
    });
    $(validateButtonSelector).prop("disabled", true);
  });
})(jQuery);
