$(function() {
  let get_url_parameter;
  $(function() {
    toggle_add_user_button(get_url_parameter('organization_id'));

    return $('.organization-users #organization').on('change', function() {
      return toggle_add_user_button(this.value);
    });
  });

  var toggle_add_user_button = function(organization) {
    if ((organization != null) && (organization !== '')) {
      $('.organization-users .add_user').attr('disabled', false);
      return $('.organization-users .add_user').attr('href', `/organizations/organizations/${organization}/users/new`);
    } else {
      return $('.organization-users .add_user').attr('disabled', true);
    }
  };

  return get_url_parameter = function(param) {
    const page_url = window.location.search.substring(1);
    const url_variables = page_url.split('&');
    let i = 0;
    while (i < url_variables.length) {
      const param_name = url_variables[i].split('=');
      if (param_name[0] === param) {
        if (param_name[1] === undefined) { return true; } else { return decodeURIComponent(param_name[1]); }
      }
      i++;
    }
  };
});