import { XssSanitizer } from '../app/xss_sanitizer';

//Append legal information to  email body
$(function () { $('#email_preview_modal').on('show.bs.modal', function () {
  var legalTextAreaValue = $('#legal_info').val();
  $('#email_preview_modal').find('.modal-body').append(`<div><br><br><p>${XssSanitizer.sanitize_dom_text(legalTextAreaValue)}</p></div>`);
});
});

//Empty out legal info from modal so fresh legal info can be appended later
$(function () { $('#email_preview_modal').on('hidden.bs.modal', function () {
  $('#email_preview_modal').find('.modal-body div').empty();
});
});

$(function () { $('#email_preview_modal_full').on('show.bs.modal', function () {
  var legalTextAreaValue = $('#legal_info').val();
  legalTextAreaValue = legalTextAreaValue.trim().replace(/\r?\n/g, '<br />');

  $('#email_preview_modal_full').find('.modal-body').html(XssSanitizer.sanitize_dom_text(legalTextAreaValue));
});
});
