// Globally scoping this so it can be used in the javascript templates in _role_fields.html.erb.
// Hooray for the global scope.
function toggleNotaryForm(elem) {
  $.ajax({
    type: 'PATCH',
    url: elem.data('toggle-path'),
    data: {
      'toggle_value': elem.is(':checked')
    }
  });
}

function setupFileinputs(fileInput) {
  fileInput.fileupload({
    autoUpload:true,
    filesContainer: fileInput.closest('.files'),
    showElementClass: 'show',
    progress: function (e, data) {
      var progress = parseInt(data.loaded / data.total * 100, 10);
      $(this).closest('.files').find('.progress .progress-bar').css( 'width', progress + '%');
    },
  });

  fileInput.on('fileuploaddestroyed', function (e, data) {
    $(this).closest('.document').show();
  }).on('fileuploadstart', function (e, data) {
    $(this).closest('.document').hide();
  }).on('fileuploadalways', function (e, data) {
    const templateId = data.result['files'][0]['document_id'];
    if (templateId !==  undefined) {
      let documentId = $(this).parents('.files').find('.document-id');
      documentId.val(templateId);
      let deleteLink = $(this).parents('.files').find('.delete-link');
      deleteLink.attr('href', '/validation_templates/' + templateId);
      let formId = $(this).parents('.required-document');
      formId.attr('id', 'required-document-' + templateId);
    }
  });
}

var RegistrationSettings = (function() {
  var form;

  function autofill_fields_with_selectors (map) {
    $.each(map, function(key, value) {
      $(key).val(value).effect('highlight');
    });
  }
  function clicked_checkbox(event) {
    try {
      if($('input:checkbox[id^="role_form_fields"]:checked').length > 0){
        document.getElementById('role_apply_to').disabled = true;
      }
      else {
        document.getElementById('role_apply_to').disabled = false;
      }
    } catch (error) {
      //do nothing if elements are missing.
    }


    var element = $(event.target);
    var id = element.data('id');

    if (element.attr('value') === 'required') {
      if (element.is(':checked')) {
        $('input[data-id="' + id + '"]').prop('checked', true);
      }
    }
  }

  function setup_listeners (event) {
    form = $('form#registration_settings');

    if(form.length > 0){
      form.fileupload({ autoUpload:true });
    }

    $('.fileupload').each(function () {
      if ($(this).closest('.files').find('.template-download').length > 0 ) {
        $(this).closest('.document').hide();
      }
      setupFileinputs($(this));

    });

    $('.inline-delete').on('click', function (event) {
      event.preventDefault();
      let hide_row = '#attachment_row_' + $(this).attr('name');
      $.ajax({
        url:$(this).attr('href'),
        type:'DELETE',
        success:function (response) {
          $.find(hide_row).hide();
        }
      });
      return false;
    });
    if (document.URL.match(/(admin\/registration_settings|roles\/\d+)/)) {
      $(document).on('click', 'input[type="checkbox"]', clicked_checkbox);
    }

    $(document).on('change', '#role_validation_required', function (e) {
      $('input:file').prop('disabled',!$('input:file').prop('disabled'));
      $('a.btn.fileinput-button').toggleClass('disabled');
      $('a.btn.add-document-button').toggle();
    });

    $('.notary-check-box').on('change', function() {
      toggleNotaryForm($(this));
    });

    $(document).on('click', '#role_preview', function (e) {
      var button = $(e.target);
      $.ajax({
        url: button.data('url'),
        data: $('.registration_form_obj').serialize(),
        dataType: 'html',
        type: 'PATCH',
        success:function (response) {
          $('#preview-modal').html(response);
          $('#preview-modal').modal('show');
        },
        error:function (jxhr) {}
      });
    });

    $(document).on('click', '#role_apply_to', function (e) {
      var button = $(e.target);
      e.preventDefault();
      $.ajax({
        url: button.data('url'),
        data: $('.registration_form_obj').serialize(),
        dataType: 'html',
        type: 'PATCH',
        success:function (response) {
          $('#apply-to-modal').html(response);
          $('#apply-to-modal').modal('show');
        },
        error:function (jxhr) {}
      });
    });
  }
  return {
    document_ready:setup_listeners
  };
})();

$(function () {
  RegistrationSettings.document_ready();
  if (!$('#role_validation_required').is(':checked')) {
    $('a.btn.add-document-button').hide();
  }
});

$(document).ajaxComplete(function( event, xhr, settings ){
  if(settings.url.match('validation_templates')){
    const fileInput = $('.fileupload').last();
    setupFileinputs(fileInput);
  }
});
