import { DashboardGraph, DateParser } from '../app/dashboard';

var UserGraph = (function ($) {

  function initialize(node) {
    $(function() {
      if ($(node).length < 1) {
        return;
      }
      var _parent_instance = DashboardGraph.instantiate();
      var current_date = new Date();
      let FreqActive = [], Approved = [], MonthlyActive = [];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      $('<div id=\'tooltip\' class=\'before_arrow\'></div>').css({
        position: 'absolute',
        display: 'none',
        border: '1px solid #ddf',
        padding: '2px',
        'background-color': '#eef',
        opacity: 0.80
      }).appendTo('body');

      function dateFromString(string) {
        return DateParser.dateFromString(string);
      }

      function update_user_chart_with_json(json) {
        FreqActive = [];
        Approved = [];
        MonthlyActive = [];

        for (let i = 0; i < json['Approved'].length; i++) {
          let a = json['Approved'][i];
          let date = dateFromString(a.reported_on);
          const label = months[date.getMonth() - 1];
          Approved.push([label, parseInt(a.value)]);
        }

        for (let i = 0; i < json['FreqActive'].length; i++) {
          let f = json['FreqActive'][i];
          let date = dateFromString(f.reported_on);
          const label = months[date.getMonth() - 1];
          FreqActive.push([label, parseInt(f.value)]);
        }
        
        for (let i = 0; i < json['MonthlyActive'].length; i++) {
          let m = json['MonthlyActive'][i];
          let date = dateFromString(m.reported_on);
          const label = months[date.getMonth() - 1];
          MonthlyActive.push([label, parseInt(m.value)]);
        }

        plotUserWithOptions();
      }

      function plotUserWithOptions() {
        _parent_instance.legend_node = $(node).closest('.panel').find('div.legend');
        $.plot(node, [
          {
            data: Approved,
            label: 'Total Users',
            bars: {show: true, lineWidth: 1},
            color: '#A798AF'
          },
          {
            data: FreqActive,
            label: 'Daily Active Users',
            points: {show: true},
            bars: {show: false},
            color: '#A5072B',
            lines: {show: true}
          },
          {
            data: MonthlyActive,
            label: 'Monthly Active Users',
            points: {show: true},
            bars: {show: false},
            color: '#181623',
            yaxis: 1,
            lines: {show: true}
          } ],
        _parent_instance.chart_settings({
          yaxes: [ { }, { position: 'right', min: 0, tickFormatter: function (value) {
            return value + '%';
          } } ],
          series:{
          }
        })
        );
      }

      plotUserWithOptions();

      $( document ).ajaxComplete(function( event, xhr, settings ) {
        if ( settings.url.match('dashboard/user_stats')) {
          try {
            var json = JSON.parse(xhr.responseText);
            update_user_chart_with_json(json);
          } catch (error) {
            console.error(error);
          }

        }
      });

      $(node).on('plothover', function (event, pos, item) {
        if (item) {
          var value = item.datapoint[1];
          var message = value;

          $('#tooltip').css({top: item.pageY - 14, left: item.pageX+23}).html(message).fadeIn(200);
        } else {
          $('#tooltip').hide();
        }
      });
    });
  }
  return {
    initialize:initialize
  };
})($);
$(function (event) {
  UserGraph.initialize('#user_chart');
});
