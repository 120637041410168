$(document).on('change', '*[data-check]', function(event){
  if($(this).is(':checked')) {
    var modal = $($(this).data('modal'));
    modal.modal('show');
    var selectAll = $(this);
    $(selectAll.data('modal') + ' a[data-accepted=\'false\']').on('click', function(){
      selectAll.attr('checked', false);
      return;
    });
    $(selectAll.data('modal') + ' a[data-accepted=\'true\']').on('click', function(){
      modal.modal('hide');
      $('.' + selectAll.data('check')).attr('checked', selectAll.is(':checked'));
      return;
    });
  } else {
    $('.' + $(this).data('check')).attr('checked', $(this).is(':checked'));
  }
});
