$(function(){
  $('#new_request_note').on('ajax:success', function(e, data){
    if (data.errors) {
      show_note_errors(data.errors, $(this));
    } else {
      hide_note_errors($(this));
      $('#new_form_request_note').modal('hide');
      $('#multiline_request_note').text(data['note']);
      $('#edit_request_note_text').val(data['note']);
      $('#new_request_note_text').val('');
      $('#new_form_request_note_link').hide();
      $('#edit_form_request_note_link, #destroy_form_request_note_link, #edit_destroy_span').show();
      $('#edit_request_note').attr('action', '/request_notes/' + data['note_id']);
      $('#destroy_request_note').attr('href', '/request_notes/' + data['note_id']);
      $('#request_note_id').val(data['note_id']);
    }
  });

  $('#edit_request_note').on('ajax:success', function(e, data){
    if (data.errors) {
      show_note_errors(data.errors, $(this));
    } else {
      hide_note_errors($(this));
      $('#edit_form_request_note').modal('hide');
      $('#multiline_request_note').text(data['note']);
    }
  });

  $('#destroy_request_note').on('ajax:success', function(e, data){
    if (data.errors) {
      show_note_errors(data.errors, $(this));
    } else {
      hide_note_errors($(this));
      $('#destroy_form_request_note').modal('hide');
      $('#multiline_request_note').text('');
      $('#edit_request_note_text').val('');
      $('#new_request_note_text').val('');
      $('#new_form_request_note_link').show();
      $('#edit_form_request_note_link, #destroy_form_request_note_link, #edit_destroy_span').hide();
    }
  });
});

function show_note_errors(errors, error_block) {
  error_block.find('.note-error-box > .note-errors-list').html('');
  error_block.find('.note-error-box').removeClass('hidden');
  for(i=0; i<errors.length; i++) {
    error_block.find('.note-error-box > .note-errors-list').append('<li>' + errors[i] + '</li>');
  }
}

function hide_note_errors(error_block) {
  error_block.find('.note-error-box').addClass('hidden');
  error_block.find('.note-error-box > .note-errors-list').html('');
}
