import { x508 } from '../app/x508';

$(function () {

  // Remove info class whenever a new row is clicked.
  var trs = $('#pharmacy_search_results').find('tr');
  $('#pharmacy_search_results').on('click', function () {
    $('#pharmacy_search_results').find('tr').removeClass('info');
  });

  $('#pharmacy_search_form')
    .on('ajax:beforeSend', function (evt, xhr, settings) {
      //todo: spinner
    })
    .on('ajax:success', function (evt, data, status, xhr) {
      try {
        eval(xhr.responseText);
      } catch (error) {
        $('#results').html(xhr.responseText);
        //Re-bind click events to pharmacy search.
        // trs = $('#pharmacy_search_results').find('tr');
        // trs.on('click', function () {
        //   trs.removeClass('info');
        // });
      }

    })
    .on('ajax:complete', function (evt, xhr, status) {
      x508.sortedHeaderMessage();
    })
    .on('ajax:error', function (evt, xhr, status, error) {
      $('.scrolltable').html(xhr.responseText);
      alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
      $(document).trigger('search:failed', {error: error});
    });

});

$(document).on('hide', function (event) {
  var node = $(event.target);
  if ($.inArray(node.attr('id'), ['edit_pharmacy_modal']) > -1) {
    var pharmacy_id = node.attr('data-pharmacy-id');
    if (pharmacy_id != null) {
      update_pharmacy_with_id(pharmacy_id);
    }
  }
});

$(document).on('ajax:complete', '.pharmacy_data_to_update', function(){
  var pharmacy_id = $(this).data('pharmacy-id');
  if (pharmacy_id != null) {
    update_pharmacy_with_id(pharmacy_id);
  }
});

function update_pharmacy_with_id(id) {
  var row_to_replace = $('#search_result_row_pharmacy_id_' + id);
  var refresh_url = row_to_replace.attr('data-refresh-url');

  $.get(refresh_url, function (data) {
    row_to_replace.replaceWith(data);
    var replaced_row = $('#search_result_row_pharmacy_id_' + id);
    replaced_row.addClass('info');
  });
}

$(function () {
  //update which table row is selected
  $('div#results_with_pagination').on('click', 'tr', function (event) {
    var object = $(event.target).parents('tr');
    $('table#pharmacy_search_results tr').removeClass('info');
    if(!object.hasClass('not-info')){
      object.addClass('info');
    }
  });
});
