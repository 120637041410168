import { XssSanitizer } from '../app/xss_sanitizer';

var ThresholdSettingsLiveUpdate = (function ($) {
  var node, form, farb;

  function update_relative_node_color(n) {
    var lighter_color = '#fff',
      border_color = '#000',
      tab_group_node = n.parents('.tab-pane.active'),
      link_node = tab_group_node.find('a#results_toggle'),
      toolbar_node = link_node.parents('.toolbar-bg'),
      color = n.val();

    var hsl=[], rgb=[];
    hsl = farb.RGBToHSL(farb.unpack(color));
    hsl[2] = 0.97;
    rgb = farb.pack(farb.HSLToRGB(hsl));

    lighter_color = rgb;

    hsl[2] = 0.87;
    border_color = farb.pack(farb.HSLToRGB(hsl));

    link_node.css({
      color:color
    });
    toolbar_node.css({
      background:lighter_color,
      'border-color':border_color
    });
  }

  function update_relative_node_title(n) {
    n.parents('.tab-pane.active').find('a#results_toggle').html(`<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ${XssSanitizer.sanitize_dom_text(n.val())}`);
  }

  function update_relative_node_body(n) {
    n.parents('.tab-pane.active')
      .find('div.patient_alert_body')
      .html(
        XssSanitizer.sanitize_disallowed_tags(
          XssSanitizer.sanitize_anchor_tags(n.val())
        ));
  }

  function watched_form_change(event) {
    var input_changed = $(event.target);
    if (/clinical_alerts_display_settings_.*_color/.test(input_changed.attr('id'))) {
      update_relative_node_color(input_changed);
    }
    if (/clinical_alerts_display_settings_.*_title/.test(input_changed.attr('id'))) {
      update_relative_node_title(input_changed);
    }
    if (/threshold_settings_body_.*/.test(input_changed.attr('id'))) {
      update_relative_node_body(input_changed);
    }
  }

  function setup() {
    node = $('[data-live-update-from-form]');
    form = $(node.data('live-update-from-form'));

    $('body').append('<div id="farbtastic_container"/>');
    var n = $('#farbtastic_container');
    n.hide();
    farb = $.farbtastic(n);
    form.on('change', watched_form_change);
    form.on('keyup', watched_form_change);
  }

  $(function () {
    setup();
  });

  return {
    setup:setup
  };
})(jQuery);
