/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   datepicker.js
 *
 * Adapted for for the PMP AWARxE platform by Bamboo Health on 06/20
 *
 * Statement of changes:
 * All references to cancel and submit buttons have been removed,
 * including the initial declaration of `this.[button-type] = ...`
 * and subsequent functions.
 *
 * Implementation
 *
 * Start and end dates
 *
 * By default, enddate is the current date and startdate is 1/1/1900.
 * Both can be set/either or. Start date must be equal to or earlier than the enddate.
 * API
 *
 */

import { DatePickerDay } from './datepicker_day';
import { x508 } from '../../../app/x508';

export const DatePickerFeatures = function (datePickerInstance) {
  datePickerInstance.dayLabels = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  datePickerInstance.monthLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
    
  this.datePickerInstance = datePickerInstance;

  datePickerInstance.messageCursorKeys = 'Cursor keys can navigate dates';
  datePickerInstance.lastMessage = '';

  datePickerInstance.lastRowNode = null;

  datePickerInstance.days = [];

  datePickerInstance.isMouseDownOnBackground = false;

  datePickerInstance.keyCode = Object.freeze({
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
  });
};

DatePickerFeatures.prototype.init = function () {
  this.datePickerInstance.prevMonthNode.addEventListener(
    'click',
    this.handlePreviousMonthButton.bind(this)
  );
  this.datePickerInstance.nextMonthNode.addEventListener(
    'click',
    this.handleNextMonthButton.bind(this)
  );
  this.datePickerInstance.prevYearNode.addEventListener(
    'click',
    this.handlePreviousYearButton.bind(this)
  );
  this.datePickerInstance.nextYearNode.addEventListener(
    'click',
    this.handleNextYearButton.bind(this)
  );

  this.datePickerInstance.prevMonthNode.addEventListener(
    'keydown',
    this.handlePreviousMonthButton.bind(this)
  );
  this.datePickerInstance.nextMonthNode.addEventListener(
    'keydown',
    this.handleNextMonthButton.bind(this)
  );
  this.datePickerInstance.prevYearNode.addEventListener(
    'keydown',
    this.handlePreviousYearButton.bind(this)
  );
  this.datePickerInstance.nextYearNode.addEventListener(
    'keydown',
    this.handleNextYearButton.bind(this)
  );
  document.body.addEventListener(
    'mousedown',
    this.handleBackgroundMouseDown.bind(this)
  );
  document.body.addEventListener(
    'mouseup',
    this.handleBackgroundMouseUp.bind(this)
  );
  this.datePickerInstance.inputNode.addEventListener(
    'blur',
    this.handleInputNodeBlur.bind(this)
  );
  // Create Grid of Dates
  this.datePickerInstance.tbodyNode.innerHTML = '';
  var index = 0;
  for (var i = 0; i < 6; i++) {
    var row = this.datePickerInstance.tbodyNode.insertRow(i);
    this.datePickerInstance.lastRowNode = row;
    row.classList.add('dateRow');
    row.setAttribute('role', 'row');
    for (var j = 0; j < 7; j++) {
      var cell = document.createElement('td');
      cell.classList.add('dateCell');
      cell.setAttribute('role', 'gridcell');
      var cellButton = document.createElement('button');
      cellButton.classList.add('dateButton');
      cellButton.setAttribute('type', 'button');
      cell.appendChild(cellButton);
      row.appendChild(cell);
      var dpDay = new DatePickerDay(cellButton, this.datePickerInstance, index, i, j);
      dpDay.init();
      this.datePickerInstance.days.push(dpDay);
      index++;
    }
  }
  this.setPlaceholder();
  this.setMask();
  this.updateGrid();
  this.setFocusDay();
  this.setUniqueIdRelationship();
};

DatePickerFeatures.prototype.updateGrid = function () {
  var i, flag;
  var fd = this.datePickerInstance.focusDay;
  this.datePickerInstance.MonthYearNode.innerHTML =
        this.datePickerInstance.monthLabels[fd.getMonth()] + ' ' + fd.getFullYear();

  var firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1);
  var firstDayOnCalendar = new Date(fd.getFullYear(), fd.getMonth(), 1);
  var daysInMonth = new Date(
    fd.getFullYear(),
    fd.getMonth() + 1,
    0
  ).getDate();
  var dayOfWeek = firstDayOfMonth.getDay();
  firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek);
  firstDayOnCalendar.setDate(firstDayOnCalendar.getDate());
  var d = new Date(firstDayOfMonth);
  var c = new Date(firstDayOnCalendar);

  for (i = 0; i < this.datePickerInstance.days.length; i++) {
    flag = d.getMonth() != fd.getMonth();
    this.datePickerInstance.days[i].updateDay(flag, d);
    d.setDate(d.getDate() + 1);
  }

  if (this.datePickerInstance.inputNode.value) {
    this.setAriaCurrentDate(fd);
  }

  this.updateLastRowVisibility(dayOfWeek, daysInMonth);

  /* ensures proper height based on the rows the updated grid displays */
  this.updateDateDialogCoordinates();

  /* Show and hide navigation arrows based on current dates and navigation constraints. */
  this.updateNavigationArrowVisibility(c, fd);
};

DatePickerFeatures.prototype.updateLastRowVisibility = function (
  dayOfWeek,
  daysInMonth
) {
  if (dayOfWeek + daysInMonth < 36) {
    this.hideLastRow();
  } else {
    this.showLastRow();
  }
};

DatePickerFeatures.prototype.setPlaceholder = function () {
  this.datePickerInstance.inputNode.setAttribute(
    'placeholder',
    this.datePickerInstance.accessibleDateConstraints.date_format
  );
};

DatePickerFeatures.prototype.setMask = function () {
  jQuery(this.datePickerInstance.inputNode).mask('99/99/9999', {
    placeholder: this.datePickerInstance.accessibleDateConstraints.date_format,
  });
};

DatePickerFeatures.prototype.updateNavigationArrowVisibility = function (d) {
  this.limitArrowsPerLatestAllowedDate(d);
  this.limitArrowsPerEarliestAllowedDate(d);
};

DatePickerFeatures.prototype.limitArrowsPerLatestAllowedDate = function (
  visibleCalendarMonth
) {
  var latestYear =
        this.datePickerInstance.endDate.getFullYear() == visibleCalendarMonth.getFullYear();
  var latestMonth =
        latestYear &&
        this.datePickerInstance.endDate.getMonth() == visibleCalendarMonth.getMonth();
  var monthOutsideLatestYear =
        this.datePickerInstance.endDate.getFullYear() == visibleCalendarMonth.getFullYear() + 1 &&
        this.datePickerInstance.endDate.getMonth() < visibleCalendarMonth.getMonth();

  if (latestYear || monthOutsideLatestYear) {
    this.hideNextYear();
  } else {
    this.showNextYear();
  }

  if (latestMonth) {
    this.hideNextMonth();
  } else {
    this.showNextMonth();
  }
};

DatePickerFeatures.prototype.limitArrowsPerEarliestAllowedDate = function (
  visibleCalendarMonth
) {
  var earliestYear =
        this.datePickerInstance.startDate.getFullYear() == visibleCalendarMonth.getFullYear();
  var earliestMonth =
        earliestYear &&
        this.datePickerInstance.startDate.getMonth() == visibleCalendarMonth.getMonth();
  var monthOutsideEarliestYear =
        this.datePickerInstance.startDate.getFullYear() ==
            visibleCalendarMonth.getFullYear() - 1 &&
        this.datePickerInstance.startDate.getMonth() >= visibleCalendarMonth.getMonth();

  if (earliestYear || monthOutsideEarliestYear) {
    this.hidePrevYear();
  } else {
    this.showPrevYear();
  }

  if (earliestMonth) {
    this.hidePrevMonth();
  } else {
    this.showPrevMonth();
  }
};

DatePickerFeatures.prototype.hideLastRow = function () {
  this.datePickerInstance.lastRowNode.style.display = 'none';
};

DatePickerFeatures.prototype.showLastRow = function () {
  this.datePickerInstance.lastRowNode.style.display = 'table-row';
};

DatePickerFeatures.prototype.hideNextYear = function () {
  this.datePickerInstance.nextYearNode.style.visibility = 'hidden';
  this.datePickerInstance.nextYearNode.classList.remove('available-to-focus');
};

DatePickerFeatures.prototype.showNextYear = function () {
  this.datePickerInstance.nextYearNode.style.visibility = 'visible';
  this.datePickerInstance.nextYearNode.classList.add('available-to-focus');
};

DatePickerFeatures.prototype.hideNextMonth = function () {
  this.datePickerInstance.nextMonthNode.style.visibility = 'hidden';
  this.datePickerInstance.nextMonthNode.classList.remove('available-to-focus');
};

DatePickerFeatures.prototype.showNextMonth = function () {
  this.datePickerInstance.nextMonthNode.style.visibility = 'visible';
  this.datePickerInstance.nextMonthNode.classList.add('available-to-focus');
};

DatePickerFeatures.prototype.hidePrevMonth = function () {
  this.datePickerInstance.prevMonthNode.style.visibility = 'hidden';
  this.datePickerInstance.prevMonthNode.classList.remove('available-to-focus');
};

DatePickerFeatures.prototype.showPrevMonth = function () {
  this.datePickerInstance.prevMonthNode.style.visibility = 'visible';
  this.datePickerInstance.prevMonthNode.classList.add('available-to-focus');
};

DatePickerFeatures.prototype.hidePrevYear = function () {
  this.datePickerInstance.prevYearNode.style.visibility = 'hidden';
  this.datePickerInstance.prevYearNode.classList.remove('available-to-focus');
};

DatePickerFeatures.prototype.showPrevYear = function () {
  this.datePickerInstance.prevYearNode.style.visibility = 'visible';
  this.datePickerInstance.prevYearNode.classList.add('available-to-focus');
};

DatePickerFeatures.prototype.setFocusDay = function (flag) {
  if (typeof flag !== 'boolean') {
    flag = true;
  }
  var fd = this.datePickerInstance.focusDay;

  function checkDay(d) {
    d.domNode.setAttribute('tabindex', '-1');
    if (
      d.day.getDate() == fd.getDate() &&
            d.day.getMonth() == fd.getMonth() &&
            d.day.getFullYear() == fd.getFullYear()
    ) {
      d.domNode.setAttribute('tabindex', '0');
      if (flag) {
        d.domNode.focus();
      }
    }
  }

  this.datePickerInstance.days.forEach(checkDay.bind(this));
};

DatePickerFeatures.prototype.updateDay = function (day) {
  var d = this.datePickerInstance.focusDay;
  this.datePickerInstance.focusDay = day;
  if (
    d.getMonth() !== day.getMonth() ||
        d.getFullYear() !== day.getFullYear()
  ) {
    this.updateGrid();
    this.setFocusDay();
  }
};

DatePickerFeatures.prototype.getDaysInLastMonth = function () {
  var fd = this.datePickerInstance.focusDay;
  var lastDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 0);
  return lastDayOfMonth.getDate();
};

DatePickerFeatures.prototype.getDaysInMonth = function () {
  var fd = this.datePickerInstance.focusDay;
  var lastDayOfMonth = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
  return lastDayOfMonth.getDate();
};

DatePickerFeatures.prototype.show = function () {
  this.datePickerInstance.dialogNode.style.display = 'block';
  this.datePickerInstance.dialogNode.style.zIndex = 2;
  this.datePickerInstance.buttonNode.setAttribute('aria-expanded', 'true');
  this.getDateInput();
  this.updateGrid();
  this.setFocusDay();
  var sr_message = 'Cursor keys can navigate dates';
  x508.say(sr_message);

  jQuery(window).on('scroll', () => {
    this.updateDateDialogCoordinatesOnScroll();
  });
};

DatePickerFeatures.prototype.isOpen = function () {
  return window.getComputedStyle(this.datePickerInstance.dialogNode).display !== 'none';
};

DatePickerFeatures.prototype.hide = function () {
  this.setMessage('');
  this.datePickerInstance.dialogNode.style.display = 'none';
  this.datePickerInstance.buttonNode.setAttribute('aria-expanded', 'false');

  this.datePickerInstance.hasFocusFlag = false;
  this.reupdateDateDialogCoordinates();
  this.datePickerInstance.dateInput.setFocus();

  /* 
  When the datepicker is closed, let's 
  trigger the input validation that happens on input blur 
  */
  jQuery(this.datePickerInstance.inputNode).trigger('blur');

  jQuery(window).off('scroll', this.updateDateDialogCoordinatesOnScroll());
};

DatePickerFeatures.prototype.handleBackgroundMouseDown = function (event) {
  if (
    !this.datePickerInstance.buttonNode.contains(event.target) &&
        !this.datePickerInstance.dialogNode.contains(event.target)
  ) {
    this.datePickerInstance.isMouseDownOnBackground = true;

    if (this.isOpen()) {
      this.hide();
    }
  }
};

DatePickerFeatures.prototype.handleBackgroundMouseUp = function () {
  this.datePickerInstance.isMouseDownOnBackground = false;
};

DatePickerFeatures.prototype.handleInputNodeBlur = function () {
  let inputValue = this.datePickerInstance.inputNode.value;
  this.saveDate(inputValue);

  if (inputValue === '' || inputValue === this.datePickerInstance.accessibleDateConstraints.date_format) {
    return;
  }

  var result = this.datePickerInstance.accessibleDatePickerHelper.enforceMinMaxDate(
    this.datePickerInstance.accessibleDateConstraints,
    this.datePickerInstance.savedDate
  );
  this.saveDate(result);
  this.updateInputNodeValue();
  // this.getDateInput();
  // this.updateGrid();
};

DatePickerFeatures.prototype.saveDate = function (newDate) {
  this.datePickerInstance.savedDate = newDate === '' ? new Date(this.datePickerInstance.endDate) : new Date(newDate);
};

DatePickerFeatures.prototype.updateInputNodeValue = function () {
  $(this.datePickerInstance.inputNode).val(
    this.datePickerInstance.accessibleDatePickerHelper.fixTimeZoneMadness(
      this.datePickerInstance.accessibleDateConstraints.date_format,
      this.datePickerInstance.savedDate
    )
  );
};

DatePickerFeatures.prototype.handleNextYearButton = function (event) {
  var flag = false;

  switch (event.type) {
  case 'keydown':
    switch (event.keyCode) {
    case this.datePickerInstance.keyCode.ESC:
      this.hide();
      flag = true;
      break;

    case this.datePickerInstance.keyCode.ENTER:
    case this.datePickerInstance.keyCode.SPACE:
      this.moveToNextYear();
      this.setFocusDay(false);
      flag = true;
      break;
    }

    break;

  case 'click':
    this.moveToNextYear();
    this.setFocusDay(false);
    flag = true;
    break;

  default:
    break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

DatePickerFeatures.prototype.handlePreviousYearButton = function (event) {
  var flag = false;

  switch (event.type) {
  case 'keydown':
    switch (event.keyCode) {
    case this.datePickerInstance.keyCode.ENTER:
    case this.datePickerInstance.keyCode.SPACE:
      this.moveToPreviousYear();
      this.setFocusDay(false);
      flag = true;
      break;

    case this.datePickerInstance.keyCode.TAB:
      if (event.shiftKey) {
        this.setFocusDay();
        flag = true;
      }
      break;

    case this.datePickerInstance.keyCode.ESC:
      this.hide();
      flag = true;
      break;

    default:
      break;
    }

    break;

  case 'click':
    this.moveToPreviousYear();
    this.setFocusDay(false);
    flag = true;
    break;

  default:
    break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

DatePickerFeatures.prototype.handleNextMonthButton = function (event) {
  var flag = false;

  switch (event.type) {
  case 'keydown':
    switch (this.datePickerInstance.keyCode) {
    case this.datePickerInstance.keyCode.ESC:
      this.hide();
      flag = true;
      break;

    case this.datePickerInstance.keyCode.ENTER:
    case this.datePickerInstance.keyCode.SPACE:
      this.moveToNextMonth();
      this.setFocusDay(false);
      flag = true;
      break;
    }

    break;

  case 'click':
    this.moveToNextMonth();
    this.setFocusDay(false);
    flag = true;
    break;

  default:
    break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

DatePickerFeatures.prototype.handlePreviousMonthButton = function (event) {
  var flag = false;

  switch (event.type) {
  case 'keydown':
    switch (event.keyCode) {
    case this.datePickerInstance.keyCode.ESC:
      this.hide();
      flag = true;
      break;

    case this.datePickerInstance.keyCode.ENTER:
    case this.datePickerInstance.keyCode.SPACE:
      this.moveToPreviousMonth();
      this.setFocusDay(false);
      flag = true;
      break;
    }

    break;

  case 'click':
    this.moveToPreviousMonth();
    this.setFocusDay(false);
    flag = true;
    break;

  default:
    break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

DatePickerFeatures.prototype.moveToNextYear = function () {
  this.datePickerInstance.focusDay.setFullYear(this.datePickerInstance.focusDay.getFullYear() + 1);

  this.updateGrid();
};

DatePickerFeatures.prototype.moveToPreviousYear = function () {
  var get_first_available_year = new Date('1900');
  var get_the_previous_year = this.datePickerInstance.focusDay.getFullYear() - 1;

  if (get_the_previous_year > get_first_available_year) {
    this.datePickerInstance.focusDay.setFullYear(this.datePickerInstance.focusDay.getFullYear() - 1);
    this.updateGrid();
  }
};

DatePickerFeatures.prototype.moveToNextMonth = function () {
  this.datePickerInstance.focusDay.setMonth(this.datePickerInstance.focusDay.getMonth() + 1);
  this.updateGrid();
};

DatePickerFeatures.prototype.moveToPreviousMonth = function () {
  this.datePickerInstance.focusDay.setMonth(this.datePickerInstance.focusDay.getMonth() - 1);
  this.updateGrid();
};

DatePickerFeatures.prototype.moveFocusToDay = function (day) {
  var d = this.datePickerInstance.focusDay;
  this.datePickerInstance.focusDay = day;

  /* We won't move focus beyond start or enddate */
  if (this.datePickerInstance.focusDay < this.datePickerInstance.startDate || this.datePickerInstance.focusDay > this.datePickerInstance.endDate) {
    this.datePickerInstance.focusDay = d;
  } else {
    if (
      d.getMonth() != this.datePickerInstance.focusDay.getMonth() ||
            d.getYear() != this.datePickerInstance.focusDay.getYear()
    ) {
      this.updateGrid();
    }
    this.setFocusDay();
  }
};

DatePickerFeatures.prototype.moveFocusToNextDay = function () {
  var d = new Date(this.datePickerInstance.focusDay);
  d.setDate(d.getDate() + 1);
  this.moveFocusToDay(d);
};

DatePickerFeatures.prototype.moveFocusToNextWeek = function () {
  var d = new Date(this.datePickerInstance.focusDay);
  var new_week = d.getDate() + 7;
  d.setDate(new_week);
  this.moveFocusToDay(d);
};

DatePickerFeatures.prototype.moveFocusToPreviousDay = function () {
  var d = new Date(this.datePickerInstance.focusDay);
  d.setDate(d.getDate() - 1);
  this.moveFocusToDay(d);
};

DatePickerFeatures.prototype.moveFocusToPreviousWeek = function () {
  var d = new Date(this.datePickerInstance.focusDay);
  d.setDate(d.getDate() - 7);
  this.moveFocusToDay(d);
};

DatePickerFeatures.prototype.moveFocusToFirstDayOfWeek = function () {
  var d = new Date(this.datePickerInstance.focusDay);
  d.setDate(d.getDate() - d.getDay());
  this.moveFocusToDay(d);
};

DatePickerFeatures.prototype.moveFocusToLastDayOfWeek = function () {
  var d = new Date(this.datePickerInstance.focusDay);
  d.setDate(d.getDate() + (6 - d.getDay()));
  this.moveFocusToDay(d);
};

DatePickerFeatures.prototype.setTextboxDate = function (day) {
  if (day) {
    this.datePickerInstance.dateInput.setDate(day);
    let theInputNode = $(this.datePickerInstance.dateInput.inputNode).get(0);

    /* triggers token create/update via tokenized_search.js */
    $(theInputNode).trigger('change');
  } else {
    this.datePickerInstance.dateInput.setDate(this.datePickerInstance.focusDay);
  }
};

DatePickerFeatures.prototype.setAriaCurrentDate = function (day) {
  var fd = day;

  function checkDay(d) {
    d.domNode.removeAttribute('aria-current');
    if (
      d.day.getDate() == fd.getDate() &&
            d.day.getMonth() == fd.getMonth() &&
            d.day.getFullYear() == fd.getFullYear()
    ) {
      d.domNode.setAttribute('aria-current', 'date');
    }
  }

  this.datePickerInstance.days.forEach(checkDay.bind(this));
};

DatePickerFeatures.prototype.getDateInput = function () {
  var parts = this.datePickerInstance.dateInput.getDate().split('/');

  if (
    parts.length === 3 &&
        Number.isInteger(parseInt(parts[0])) &&
        Number.isInteger(parseInt(parts[1])) &&
        Number.isInteger(parseInt(parts[2]))
  ) {
    this.datePickerInstance.focusDay = new Date(
      parseInt(parts[2]),
      parseInt(parts[0]) - 1,
      parseInt(parts[1])
    );
    this.datePickerInstance.selectedDay = new Date(this.datePickerInstance.focusDay);
  } else if (this.datePickerInstance.endDate) {
    // this.datePickerInstance.focusDay = this.datePickerInstance.endDate;
    this.datePickerInstance.focusDay = this.datePickerInstance.defaultDate;
    this.datePickerInstance.selectedDay = this.datePickerInstance.focusDay;
  } else {
    // If not a valid date (MM/DD/YY) and no set end date, initialize with today's date
    this.datePickerInstance.focusDay = new Date();
    this.datePickerInstance.selectedDay = new Date(0, 0, 1);
    // this.datePickerInstance.focusDay = this.datePickerInstance.defaultDate;
    // this.datePickerInstance.selectedDay = this.datePickerInstance.focusDay;
  }
};


// /* 
//   BH Custom Function 
//   This function positions the fixed datepicker to the right side of the 
//   relative buttonNode that triggers the `open/closed` states of the datepicker.
// */
DatePickerFeatures.prototype.updateDateDialogCoordinates = function () {
  this.reupdateDateDialogCoordinates();
  var inputNode = this.datePickerInstance.inputNode;
  var inputNodeCorrdinates = inputNode.getBoundingClientRect();
  var dialogNode = this.datePickerInstance.dialogNode;

  /* 
    Position the current dialogNode to the right and bottom sides 
    of the trigger button. 
    Add an extra 15 pixels for a little breathing room 
    Set the updated positioning to a varaible
  */
  dialogNode.style.left = parseInt(inputNodeCorrdinates.left) + 'px';
  dialogNode.style.top = inputNodeCorrdinates.bottom + 'px';

  var updatedDialogNodeCorrdinates = dialogNode.getBoundingClientRect();

  var dialogNodeHeight = updatedDialogNodeCorrdinates.height;

  /* Position the datepicker to always be within the viewport */
  if (
    updatedDialogNodeCorrdinates.top >= 0 &&
        updatedDialogNodeCorrdinates.left >= 0 &&
        updatedDialogNodeCorrdinates.right <=
            (window.innerWidth || document.documentElement.clientWidth) &&
        updatedDialogNodeCorrdinates.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)
  ) {
    dialogNode.style.top = updatedDialogNodeCorrdinates.top + 'px';
    dialogNode.style.bottom = 'auto';
  } else {
    dialogNode.style.top =
            inputNodeCorrdinates.top - dialogNodeHeight - 35 + 'px';
    dialogNode.style.bottom = 'auto';
  }
};

DatePickerFeatures.prototype.updateDateDialogCoordinatesOnScroll = function () {
  clearTimeout(timedScrolledUpdate);

  var timedScrolledUpdate = setTimeout(() => {
    this.updateDateDialogCoordinates();
  }, 300);
};

// /* 
//   BH Custom Function 
//   This function resets the styling on the datepicker.
//   It is designed to be called when the datepicker is hidden.
//   Because the `setDialogCorrdinates` function alters the positioning
//     of the datepicker (if it is by default outside of the viewpoint),
//     we reset the styling every time the datepicker is closed
//      to avoid affecting the truthiness of the viewport conditional
// */
DatePickerFeatures.prototype.reupdateDateDialogCoordinates = function () {
  var dialogNode = this.datePickerInstance.dialogNode;
  dialogNode.style.top = '';
  dialogNode.style.bottom = '';
};

DatePickerFeatures.prototype.getDateForButtonLabel = function (year, month, day) {
  if (
    typeof year !== 'number' ||
        typeof month !== 'number' ||
        typeof day !== 'number'
  ) {
    this.datePickerInstance.selectedDay = this.datePickerInstance.focusDay;
  } else {
    this.datePickerInstance.selectedDay = new Date(year, month, day);
  }

  var label = this.datePickerInstance.dayLabels[this.datePickerInstance.selectedDay.getDay()];
  label += ' ' + this.datePickerInstance.monthLabels[this.datePickerInstance.selectedDay.getMonth()];
  label += ' ' + this.datePickerInstance.selectedDay.getDate();
  label += ', ' + this.datePickerInstance.selectedDay.getFullYear();
  return label;
};

DatePickerFeatures.prototype.setEndDate = function (inputNode) {
  var endDateFromNode = inputNode.getAttribute('data-date-end-date');
  if (!endDateFromNode) {
    return new Date(this.datePickerInstance.earliestYear);
  } else if (endDateFromNode === '0d') {
    return new Date();
  } else {
    return new Date(endDateFromNode);
  }
};

DatePickerFeatures.prototype.setStartDate = function (dataNode) {
  let startDateFromNode = dataNode.getAttribute('data-date-start-date');
  let stateDateIsAfterEndDate = false;

  if (startDateFromNode) {
    startDateFromNode = new Date(startDateFromNode);
    stateDateIsAfterEndDate = startDateFromNode > this.datePickerInstance.endDate;
  }

  if (stateDateIsAfterEndDate) {
    console.warn(`A datepicker start date cannot come after the end date. 
      The start date has been set to the default start date.`);
  }

  if (!startDateFromNode || stateDateIsAfterEndDate) {
    // Bug makes it not currently possible to set a good fallback. 
    // Warn that datepicker will not work properly unless set.
    console.warn('Datepicker requires a start date to be set');
  } else {
    return new Date(startDateFromNode);
  }
};

DatePickerFeatures.prototype.setMessage = function (str) {
  function setMessageDelayed() {
    this.datePickerInstance.messageNode.textContent = str;
  }

  if (str !== this.datePickerInstance.lastMessage) {
    setTimeout(setMessageDelayed.bind(this), 200);
    this.datePickerInstance.lastMessage = str;
  }
};

DatePickerFeatures.prototype.setUniqueIdRelationship = function () {
  var get_current_header_month_year = this.datePickerInstance.MonthYearNode.getAttribute('id');
  var get_current_dialog_labeling =
    this.datePickerInstance.dialogNode.getAttribute('aria-labelledby');

  /* Set unique ids */
  var get_a_unique_identifier = Math.random();
  get_current_header_month_year =
        get_current_header_month_year + get_a_unique_identifier;
  get_current_dialog_labeling =
        get_current_dialog_labeling + get_a_unique_identifier;

  this.datePickerInstance.MonthYearNode.setAttribute('id', get_current_header_month_year);
  this.datePickerInstance.dialogNode.setAttribute(
    'aria-labelledby',
    get_current_dialog_labeling
  );
};
