$(document).ready(function() {
  $('#threshold_request_table').tablesorter({
    cssAsc: 'sort asc',
    cssDesc: 'sort desc',
    headers: { 0: { sorter: false} }, // Disable first column sorting
    sortList: [[1,0]], //Sort on Second column ASC
    onRenderHeader: function(index) {
      $(this).addClass('sortable');
    }
  });

  $('#threshold-roles-select-all').on('click', function () {
    $('.threshold-roles :input').not(this).prop('checked', this.checked);
  });
});
