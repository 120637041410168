import { IndeterminateProgress } from '../app/indeterminate_progress';

var UpdateWithResponseFromURL = (function ($, parser) {
  function setup() {
    $(document).on('ajaxComplete', function (event, xhr, status) {
      parser.href = status.url;

      var pathname = parser.pathname,
        dom_node = $('*[data-update-with-response-from-url="' + pathname + '"]');
      if (dom_node.length > 0) {
        dom_node.html(xhr.responseText);
      }
    });

    $(document).on('ajaxSend', function (event, xhr, status) {
      parser.href = status.url;

      var pathname = parser.pathname,
        dom_node = $('*[data-update-with-response-from-url="' + pathname + '"]');
      if (dom_node.length > 0) {
        dom_node.html(IndeterminateProgress.init({white: false, size: 'large'}));
      }
    });
  }

  return {
    setup: setup
  };
}(
  jQuery,
  document.createElement('a')
));

UpdateWithResponseFromURL.setup();
