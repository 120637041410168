$(function() {
  $(document).on('click', '.edit-note-content', function() {
    const note = $(this).closest('.note');
    toggle_hidden_note_segment(note);
    return $(note).find('.edit-note-value').val($(note).find('.content').text());
  });

  $(document).on('click', '.cancel-note-content', function() {
    const note = $(this).closest('.note');
    return toggle_hidden_note_segment(note);
  });

  return $(document).on('click', '.save-note-content', function() {
    const note = $(this).closest('.note');
    const note_value = $(note).find('.edit-note-value').val();
    return $.ajax(note.data('update-url-attribute'), {
      type: 'PATCH',
      data: { request_note: { 'note' : note_value }},

      success(data) {
        return $(note).find('.content').text(note_value);
      },

      complete(data) {
        return toggle_hidden_note_segment(note);
      },

      error(a, b, errorThrown) {
        return alert(errorThrown);
      }
    }
    );
  });
});

  
var toggle_hidden_note_segment = note => $(note).find('.content-change, .save-note-content, .cancel-note-content, .content, .delete-note-content, .edit-note-content').toggleClass('hidden');
  