var Selectors = (function ($) {
  function initialize() {
    $(document).on('click', function (event) {
      var element = $(event.target), selector = element.data('selector');
      if (typeof(selector) !== 'undefined') {
        $(element).trigger('selector:' + selector);
        event.preventDefault();
      }
    });
  }
  return {initialize:initialize};
})($);

$(function() {
  Selectors.initialize();
});
