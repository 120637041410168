var OrganizationUserSelect = (function($) {
  function instantiate(node) {
    var me = new Object();
    me.organization_selected = function(event) {
      node.find('.org_user_select').hide();
      var dropdown_value = this.value;
      $('#organizations_' + dropdown_value + '_users').parent().show();
    };
    $(node).find('select#organization').on('change', me.organization_selected);
    return me;
  }
  function setup() {
    $('*[data-org-user-select]').each(function () {
      instantiate($(this));
    });
  }
  return {
    setup: setup
  };
}(jQuery));

$(function () {
  OrganizationUserSelect.setup();
});
