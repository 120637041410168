import { Application } from '../application';
import { HTMLTemplate } from '../app/html_template';

var ModalTemplateRenderer = (function($) {
  function setup() {
    var should_setup = false;
    if (Application.current({
      controller: 'messages',
      action: 'index'
    })) {
      should_setup = true;
    }
    if (Application.current({
      controller: 'care_notes',
      action: 'index'
    })) {
      should_setup = true;
    }
    if (Application.current({
      controller: 'messages_and_care_notes',
      action: 'index'
    })) {
      should_setup = true;
    }
    if (should_setup) {
      $(document).on('click', 'tr a[data-toggle="modal"]', function(event) {
        var row = $(event.target).parents('tr');
        populate_modal(row);
      });
    }
  }

  function find_modal(modal_data) {
    for (let key in modal_data) {
      if (modal_data.hasOwnProperty(key)) {
        var modal_template = $('*[data-template="' + key + '"]');
        if (modal_template.length > 0) {
          var fields = modal_data[key],
            template = HTMLTemplate.instantiate(key);
          template.render(fields);
        }
      }
    }
  }

  function populate_modal(row) {
    var modal_data = row.data('modal-contents');
    $(modal_data).each(function() {
      find_modal(this);
    });

  }
  return {
    setup: setup
  };
})(jQuery);

$(function() {
  ModalTemplateRenderer.setup();
});
