// Handles the ajax reload on the sorting of this special case of a double table with only one sortable.

//Do what is needed for the pdf generation.
function pdf_link(source, a){
  var pdf_link = $('[data-original-pdf-path]').first();
  pdf_href = pdf_link.data('original-pdf-path');
  pdf_link.attr('href', pdf_href + a.search);
}

function get_current_sort_class(item){
  if(item.hasClass('desc')){
    return 'desc';
  }else{
    return 'asc';
  }
}

//Handles sorting 
$(document).on('click', 'div.collapsible_data_two_tables thead th', function() {

  var main_table = $('#main_table');
  var main_table_th = main_table.children('thead').children('tr').children('.sort');

  var second_table = $('#secondary_table');
  var second_table_th = second_table.children('thead').children('tr').children('.sort');

  var source = main_table.parents('div.collapsible_data_two_tables');

  var sort_symbol = main_table_th.data('sort-symbol');
  var sort_symbol_table_2 = second_table_th.data('sort-symbol-table-2');

  // figure out what sort class they already have.
  var sort_direction = get_current_sort_class(main_table_th);
  var sort_direction_table_2 = get_current_sort_class(second_table_th);

  // Find which one was clicked and update it.
  var clicked = $(this);
  if(typeof(clicked.data('sort-symbol')) !== 'undefined'){
    sort_symbol = clicked.data('sort-symbol');
    sort_direction = (clicked.hasClass('asc') ? 'desc' : 'asc');
  }else if(typeof(clicked.data('sort-symbol-table-2')) !== 'undefined'){
    sort_symbol_table_2 = clicked.data('sort-symbol-table-2');
    sort_direction_table_2 = (clicked.hasClass('asc') ? 'desc' : 'asc');
  }

  //alert('fix pdf_link');

  var a = document.createElement('a');
  a.href =  source.data('url');
  a.search += a.search.substring(0,1) == '?' ? '&' : '?';
  a.search += encodeURIComponent('sort_symbol') + '=' + encodeURIComponent( sort_symbol );
  a.search += '&' + encodeURIComponent('sort_direction') + '=' + encodeURIComponent(sort_direction);
  a.search += '&' + encodeURIComponent('sort_symbol_table_2') + '=' + encodeURIComponent( sort_symbol_table_2 );
  a.search += '&' + encodeURIComponent('sort_direction_table_2') + '=' + encodeURIComponent(sort_direction_table_2);

  //The sort url.
  //alert(a.href);

  pdf_link(source, a);
  //pdf_link(source, th.data('sort-symbol'), sort_direction, sort_symbol_table_2, sort_direction_table_2 )

  source.find('.progress').show();
  source.load(a.href, function() {
    source.find('.progress').hide();
  });
});
