export const Flash = (function() {
  var klass;

  klass = new Object();

  klass.new_with_element = function(element) {
    var instance = new Object();
    instance.node = $(element);
    if ($(element).data('scrolled') === undefined) {
      if (window.navigator.userAgent.indexOf('Edge') > -1) {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
      } else {
        $(element).scrollintoview();
      }
      $(element).data('scrolled', 'true');
    }
    return instance;
  };

  klass.generate_node = function (type, message, category, onMainPage) {
    var id = 'alert-' + type;
    var html = '<div id="'+ id +'" class="flash-' + category.toLowerCase() + ' flash alert flash_padding">';
    html = html + '<div class="flash-left"><i aria-hidden="true" class="icon-'+ category.toLowerCase() +' large"></i></div>';
    html = html + '<div class="flash-right"><strong>' + category + '</strong><br>';
    html = html + message;
    html = html + '</div><a href="#" data-dismiss="alert" class="alert-dismiss">DISMISS</a>';
    html = html + '<div class="clearfix"></div></div';
    var node = $(html), visible_modal = $('.modal:visible .modal-body');
    //If a modal dialog is up, present the alert inside of the dialog instead of behind it.
    $('#' + id).remove();
    if (visible_modal.length > 0 && !onMainPage) {
      visible_modal.prepend(node);
      //This requires us to adjust the contents to fit inside of the modal comfortably.
      node.find('.flash-right').css({'width':'78%'});
    } else {
      $('div#content .js-flash').prepend(node);
    }
    Flash.new_with_element($('#'+id));
  };
  klass.notice = function (message) {
    klass.generate_node('notice', message, 'Success', false);
  };
  klass.error = function (message) {
    klass.generate_node('error', message, 'Error', false);
  };
  klass.alert = function (message) {
    klass.generate_node('alert', message, 'Warning', false);
  };

  klass.notice_on_main = function (message) {
    klass.generate_node('notice', message, 'Success', true);
  };

  klass.clear = function (element) {
    $(element).remove();
    $('#alert-error').remove();
    $('#alert-alert').remove();
    $('#alert-notice').remove();
  };

  klass.warning = klass.alert;
  klass.info = klass.notice;
  return klass;
}());

$(document).on('ajaxComplete', function() {
  $('.flash').each(function() {
    Flash.new_with_element(this);
  });
});

window.Flash = Flash;
