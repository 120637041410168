import { DashboardGraph, DateParser } from '../app/dashboard';

// Where Patient Requests are Originating
var UsageGraph = (function ($) {
  function initialize(node) {
    var $node = $(node), $panel = $node.closest('.panel');
    $(function() {
      if ($node.length < 1) {
        return;
      }
      var _parent_instance = DashboardGraph.instantiate();
      var web = [], pmpi = [];
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      $('<div id=\'tooltip\' class=\'before_arrow\'></div>').css({
        position: 'absolute',
        display: 'none',
        border: '1px solid #ddf',
        padding: '2px',
        'background-color': '#eef',
        opacity: 0.80
      }).appendTo('body');

      function dateFromString(string) {
        return DateParser.dateFromString(string);
      }

      function update_chart_with_json(json) {
        var label = function(e) {
          var date = dateFromString(e.reported_on);
          switch (json['timeframe']) {
          case 'Yearly':
            return date.getFullYear();
          case 'Quarterly':
            var current_quarter = DateParser.getQuarter(dateFromString(e.reported_on)) + 1;
            return 'Q' + (current_quarter) + ' ' + date.getFullYear();
          case 'Monthly':
            return months[e.reported_on.month-1];
          case 'Weekly':
            return date.getMonth() + '/' + date.getDate();
          case 'Daily':
            return date.getMonth() + '/' + date.getDate();
          }
        };

        pmpi = json['pmpi'].map(v => [label(v), v.value]);
        web = json['web'].map(v => [label(v), v.value]);
        plotWithOptions();
      }

      function update_top_three_with_json(json) {
        var top_three_data = json['topthree'];
        var n = $panel.find('div.top_three_container ol');
        n.empty();
        for (var i = 0; i < top_three_data.length; i++) {
          var data = top_three_data[i];
          var value = parseInt(data['value']);
          var new_html = $('<li class="top_state">' + data['name'] + ' <span class="value">'+value+'</span></li>');
          n.append(new_html);
        }
      }

      function plotWithOptions() {
        _parent_instance.legend_node = $(node).closest('.panel').find('div.legend');
        $.plot(node, [ {data:web, label:'In State'}, {data:pmpi, label:'Out of State'} ],  _parent_instance.chart_settings({
          series:{
            stack:true
          },
          colors:['#1292AD','#B2D3DA'],

        }));
      }

      $( document ).ajaxComplete(function( event, xhr, settings ) {
        if ( settings.url.match('dashboard/usage_stats')) {
          try {
            var json = JSON.parse(xhr.responseText);
            update_chart_with_json(json);
            update_top_three_with_json(json);
          } catch (error) {
            //silently fail.
          }
        }
      });

      $(node).on('plothover', function (event, pos, item) {
        if (item) {
          let web_value = parseInt(web[item.datapoint[0]][1]),
            pmpi_value = parseInt(pmpi[item.datapoint[0]][1]),
            total = web_value + pmpi_value,
            percent = 0;

          if (item.series.label === 'Out of State') {
            //We've highlighted the upper block
            percent = (pmpi_value / total) * 100;
          } else {
            //lower block
            percent = (web_value / total) * 100;
          }
          var message = parseInt(percent) + '% of ' + total;

          $('#tooltip').css({top: item.pageY - 14, left: item.pageX+23}).html(message).fadeIn(200);
        } else {
          $('#tooltip').hide();
        }
      });
    });
  }

  return {
    initialize:initialize
  };
})($);

$(function () {
  UsageGraph.initialize('#usage_chart');
});
