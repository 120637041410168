$(function() {
  $('.select2-bootstrap-multiple').select2({
    theme: 'bootstrap4',
    placeholder: 'Nothing selected',
    allowClear: true,
    dropdownCssClass: 'dropdown-items-container',
    escapeMarkup(m) { return m; },
    templateResult(m) {
      if (m.selected === true) {
        return `${m.text} <span class='fontawesome fa fa-check check-mark pull-right'></span>`;
      } else {
        return m.text;
      }
    }
  });

  $('.select2-bootstrap').select2({
    theme: 'bootstrap4',
    placeholder: 'Nothing selected',
    allowClear: true,
    dropdownCssClass: 'dropdown-items-container',
  });

  $('.select2-bootstrap-group-choose').select2({
    theme: 'bootstrap4',
    dropdownParent: $('.with-group-choose')
  });

  // Catch clicking on parent, for group choosing
  return $(document).on('click', '.with-group-choose li.select2-results__option > .select2-results__group', function() {
    const group_selector = $(this).closest('.with-group-choose').find('.select2-bootstrap-group-choose');
    let checked_values = $(group_selector).val() || [];
    const group_name = $(this).text();
    const group_option_length = $(group_selector).find(`optgroup[label='${group_name}'] > option`).length;
    const group_option_selected_length = $(group_selector).find(`optgroup[label='${group_name}'] > option:selected`).length;
    if (group_option_length === group_option_selected_length) {
      const selected = [];
      $(group_selector).find(`optgroup[label='${group_name}'] > option:selected`).each(function() {
        return selected.push(this.value);
      });
      checked_values = checked_values.filter(el => selected.indexOf( el ) < 0);
    } else {
      const to_select = [];
      $(group_selector).find(`optgroup[label='${group_name}'] > option:not(:selected)`).each(function() {
        return to_select.push(this.value);
      });
      checked_values = checked_values.concat(to_select);
    }
    $(group_selector).select2('close');
    return $(group_selector).val(checked_values).trigger('change');
  });
});
  