$(function(){
  function activateButton(){
    var link = $('.activate-org');
    link.text('Deactivate');
    link.attr('data-method', 'delete');
    link.removeClass('activate-org');
    link.addClass('deactivate-org');
    $('.deactivate-org').on('ajax:success', deactivateButton);
  }

  function deactivateButton(){
    var link = $('.deactivate-org');
    link.text('Activate');
    link.attr('data-method', 'post');
    link.removeClass('deactivate-org');
    link.addClass('activate-org');
    $('.activate-org').on('ajax:success', activateButton);
  }

  $('.deactivate-org').on('ajax:success', deactivateButton);
  $('.activate-org').on('ajax:success', activateButton);
});
