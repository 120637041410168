$(function() {
  $('.catch-clicking')
    .on('mouseup', function() {
      $(this).removeClass('full-border');
      $(this).find('td:nth-child(1)').css('width', '');
      $(this).find('td:nth-child(2)').css('width', '');
      $(this).find('td:nth-child(3)').css('width', '');
      return $(this).find('td:nth-child(4)').css('width', '');
    }).on('mousedown', function() {
      $(this).addClass('full-border');
      let width = $(this).find('td:nth-child(1)').css('width');
      $(this).find('td:nth-child(1)').css('width', width);
      width = $(this).find('td:nth-child(2)').css('width');
      $(this).find('td:nth-child(2)').css('width', width);
      width = $(this).find('td:nth-child(3)').css('width');
      $(this).find('td:nth-child(3)').css('width', width);
      width = $(this).find('td:nth-child(4)').css('width');
      return $(this).find('td:nth-child(4)').css('width', width);
    });

  $('.update-csv-modal').on('click', function() {
    const modal_id = $(this).data('target');
    return $(`${modal_id} .chosen-item`).text(` ${$(this).data('description')}`);
  });

  $('#confirm_csv_form_modal_btn').on('click', function() {
    const preview_name = $('#report_export_view_template_name').val();
    const preview_report_type = $('input[name="report_export_view[report_type]"]:checked').val().replace(/_/g, ' ');
    const preview_roles = $('#report_export_view_role_ids option:selected');
    $('#confirm_csv_form_modal #preview_name').text(preview_name);
    $('#confirm_csv_form_modal #preview_report_type').text(preview_report_type);
    $('#confirm_csv_form_modal #preview_roles').text(join_option_results(preview_roles));
    return create_form_duplication();
  });

  $('.modal-confirmation').on('click', function() {
    const target_id = $(this).data('target-id');
    const table_id = $(this).data('table-id');
    const description_to_change = $(`${target_id} .chosen-item`).text().trim();
    const role_description_select = $(`${target_id} .modal-selector-role`);
    const new_role_description = role_description_select.val();
    const role_order = $(`${target_id} .role_order_input:checked`).val();
    if (description_to_change === new_role_description) { return false; }
    const table_line = $.find(`${table_id} tr[data-tr-name='${description_to_change}']`);
    const move_role_description_option = role_description_select.find(`option[value='${description_to_change}']`);
    $(`${target_id} .modal-selector-role`).find(`option[value='${description_to_change}']`).remove();
    $(document).find(`${table_id} tr[data-tr-name='${description_to_change}']`).remove();

    if (role_order === 'above') {
      table_line.insertBefore($.find(`${table_id} tr[data-tr-name='${new_role_description}']`));
      return move_role_description_option.insertBefore($.find(`${target_id} .modal-selector-role option[value='${new_role_description}']`));
    } else if (role_order === 'below') {
      table_line.insertAfter($.find(`${table_id} tr[data-tr-name='${new_role_description}']`));
      return move_role_description_option.insertAfter($.find(`${target_id} .modal-selector-role option[value='${new_role_description}']`));
    }
  });

  $('.select-all-checkbox-table-helper').on('click', function() {
    if (all_checkbox_checked(this)) {
      return change_checkbox_checked_param(this, false);
    } else {
      return change_checkbox_checked_param(this, true);
    }
  });

  $('.editable-checkbox').on('change', function() {
    if (all_checkbox_checked(this)) {
      return $(this).closest('table.table').find('.select-all-checkbox-table-helper').prop('checked', true);
    } else {
      return $(this).closest('table.table').find('.select-all-checkbox-table-helper').prop('checked', false);
    }
  });

  return $(document).on('click', '#preview_csv_export', function(e) {
    e.preventDefault();
    return $(document).find('#copied_export_view_form').submit();
  });
});

var all_checkbox_checked = function(helper_obj) {
  const tbody = $(helper_obj).closest('table.table').find('tbody');
  const inputs_count = $(tbody).find('.editable-checkbox').length;
  const checked_count = $(tbody).find('.editable-checkbox:checked').length;
  if (checked_count === inputs_count) { return true; }
  return false;
};

var change_checkbox_checked_param = function(helper_obj, bool_param) {
  const tbody = $(helper_obj).closest('table.table').find('tbody');
  return $(tbody).find('.editable-checkbox').prop('checked', bool_param);
};

var create_form_duplication = function() {
  $(document).find('#copied_export_view_form').remove();
  const cloned_form = $('form#csv_export_view_form').clone()
    .addClass('hidden')
    .attr('id', 'copied_export_view_form')
    .attr('action', '/configurable/csv_fields/preview')
    .attr('method', 'post');

  cloned_form.find('#confirm_csv_form_modal').remove();
  cloned_form.find('input[name="_method"]').val('post');
  return $(document).find('#csv_copied_form').html(cloned_form);
};

var join_option_results = function(results) {
  const join_result = [];
  $.each(results, (index, result) => join_result.push(result.text));
  return join_result.join(', ');
};
  