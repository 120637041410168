$(document).on('click', '#rx_search_request_search_type_interconnect', function() {
  $('div#interconnect_search').removeClass('hidden');
  $('div#rx_check_search').addClass('hidden');
});

$(document).on('click', '#rx_search_request_search_type_rx_check', function() {
  $('div#interconnect_search').addClass('hidden');
  $('div#rx_check_search').removeClass('hidden');
});

$(document).on('click', '#rx_search_request_search_type_none', function() {
  $('div#interconnect_search').addClass('hidden');
  $('div#rx_check_search').addClass('hidden');
});

$(document).on('change', 'div#rx_check_search :input[id^=rx_search_request_rx_check_states_]', function() {
  const checked = $(this).is(':checked');
  $('div#rx_check_search :input[id^=rx_search_request_rx_check_states_]').prop('checked',false);
  if (checked) {
    $(this).prop('checked',true);
  }
});
  