import { IndeterminateProgress } from '../app/indeterminate_progress';

$(function () {
  $(function() {
    if ($('#bulk-search')) {
      if ($('#bulk-search #multiple_supervisor_true').is(':checked')) {
        $('.toggle_required').attr('required', false);
        $('.multiple_supervisor').show();
        return $('.single_supervisor').hide();
      } else {
        $('.toggle_required').attr('required', true);
        $('.multiple_supervisor').hide();
        return $('.single_supervisor').show();
      }
    }
  });

  $('#bulk-search #multiple_supervisor_false').on('click', function() {
    $('.toggle_required').attr('required', true);
    $('.multiple_supervisor').hide();
    return $('.single_supervisor').show();
  });

  $('#bulk-search #multiple_supervisor_true').on('click', function() {
    $('.toggle_required').attr('required', false);
    $('.multiple_supervisor').show();
    return $('.single_supervisor').hide();
  });

  $('form').on('click', '#bulk-search-submit', function(event) {
    $('#new_bulk_patient_search').attr('data-enforce-unique-submissions', '');
    $('#new_bulk_patient_search').removeAttr('data-remote');
    if ($('#entry_type_manual').is(':checked')) {
      return $('.toggle_required').attr('required', false);
    } else {
      if ($('#multiple_supervisor_true').is(':checked')) {
        return $('.toggle_required').attr('required', false);
      } else {
        return $('.toggle_required').attr('required', true);
      }
    }
  });

  $('form').on('click', '#validate_bulk_upload_format', function(event) {
    event.preventDefault();
    $('#csv_result').html(IndeterminateProgress.init({white: false, size: 'large'}));

    const validate_url = $(this).data('validate-path');
    const fd = new FormData();

    fd.append( 'upload_batch_file', ($('#upload_batch_file'))[0].files[0] );
    fd.append( 'group_name', $('#bulk_patient_search_group_name').val() );
    fd.append( 'filled_at_begin', $('#bulk_patient_search_filled_at_begin').val() );
    fd.append( 'filled_at_end', $('#bulk_patient_search_filled_at_end').val() );
    fd.append( 'multiple_supervisor', $('input[name=multiple_supervisor]:checked').val() );

    return $.ajax({
      type: 'POST',
      url: validate_url,
      data: fd,
      processData: false,
      contentType: false,
    });
  });

  $('#new_bulk_patient_search').on('change', '.checkbox > .pmpi_state', function(event) {
    const originalAction = $('#new_bulk_patient_search').attr('action');
    $('#new_bulk_patient_search').attr('action', $('#pmpi').data('url'));

    $.ajax({
      type: 'POST',
      url: $('#pmpi').data('url'),
      data: $('#new_bulk_patient_search').serialize(),
      success(data) {
        return $('#new_bulk_patient_search').html(data);
      }
    });
    return $('#new_bulk_patient_search').attr('action', originalAction);
  });

  $('#bulk_patient_search').on('ajax:success', (event, data, status, xhr) => $('#results').html(xhr.responseText));

  $('form').on('click', '#bulk_upload_batch_file_button', function(event) {
    $('#upload_batch_file').trigger('click');
  });

  $('#validate_bulk_upload_format').prop('disabled', !$('#upload_batch_file')[0].files.length);

  $('form').on('change', '#upload_batch_file', function(event) {
    $('#validate_bulk_upload_format').prop('disabled', this.files.length === 0);
  });

  return $('form').on('change', '#upload_batch_file', function(event) {
    return $('#bulk_upload_batch_file_input').val($(this).val());
  });
});

const toggle_form_fields = function(show, hide) {
  $(show).removeClass('hidden');
  $(show).find('input, select').attr('readonly', false);
  $(show).find('.toggle_required').attr('required', true);
  $(hide).addClass('hidden');
  $(hide).find('input, select').attr('readonly', true);
  $(hide).find('input, checkbox').prop('checked', false);
  $(hide).find('input, select').not('input:submit').attr('value', '');
  return $(hide).find('.toggle_required').attr('required', false);
};

$(document).on('click', 'input[data-show]', function() {
  toggle_form_fields($(this).data('show'), $(this).data('hide'));
});

$(document).on('single_nest_row:row_added', function() {
  if ($('input[name=multiple_supervisor]:checked').val() === 'true') {
    return toggle_form_fields('.multiple_supervisor', '.single_supervisor');
  } else {
    return toggle_form_fields('.single_supervisor', '.multiple_supervisor');
  }
});
