var SortableColumns = (function($) {
  function setup() {
    $(document).on('click', 'th[data-sort-by-column]', function (event) {
      var target = $(event.target),
        sort_by_input = $('input[name=sort_by]'),
        sort_direction_input = $('input[name=sort_direction]'),
        sort_symbol = target.data('sort-by-column');
      sort_by_input.val(sort_symbol);
      if (target.hasClass('asc')) {
        target.removeClass('asc');
        target.addClass('desc');
        sort_direction_input.val('desc');
      } else {
        target.removeClass('desc');
        target.addClass('asc');
        sort_direction_input.val('asc');
      }
      sort_by_input.trigger('change');
    });
  }
  return {
    setup: setup
  };
}(jQuery));

SortableColumns.setup();
