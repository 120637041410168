import { XssSanitizer } from './xss_sanitizer';

var TextAreaMetrics = (function ($) {
  function initialize() {
    $(function () {
      $('*[data-character-count-element]').each(function(){
        var text_node = $(this);
        text_node.on('load input propertychange', function(event) {
          update_character_count(event, text_node);
        });
        update_character_count(null, text_node);
      });

      //AJAX loaded elements
      $(document).on('load input propertychange', '*[data-character-count-element]', function(event) {
        update_character_count(event, $(this));
      });

      function update_character_count(event, node) {
        var text_node_length = node.val().length,
          result_node = $(node.data('character-count-element')),
          maximum_chars_allowed = parseInt(node.data('maximum-character-length')),
          red_element_when_max_reached = $(node.data('color-element-red-at-max-length')),
          remaining_character_length = maximum_chars_allowed - text_node_length;

        if (remaining_character_length <= 0) {
          var text = node.val().substring(0, maximum_chars_allowed);
          node.val(text);
          remaining_character_length = maximum_chars_allowed - text_node_length;
        }

        if (remaining_character_length < 0) {
          remaining_character_length = 0;
        }
        if (red_element_when_max_reached.length > 0) {

          if (remaining_character_length < 1) {
            red_element_when_max_reached.addClass('red');
          } else {
            red_element_when_max_reached.removeClass('red');
          }
        }

        result_node.html(remaining_character_length);
      }
    });
  }

  return { initialize:initialize };
})(jQuery);

TextAreaMetrics.initialize();

var MultiSelectFilterHelper = (function ($) {
  function initialize(nodes_to_filter) {
    nodes_to_filter.attr('data-filterable-node', '');

    $(document).on('Multilist:added_to_destination', function (event, data) {

      data.items.each(function () {
        var item = $(this);

        if (data.source[0].id === nodes_to_filter.parent().attr('id')) {
          item.removeAttr('data-filterable-node');
        } else {
          item.attr('data-filterable-node', '');
        }
      });

    });
  }
  return {
    initialize:initialize
  };
})($);

var UserSearch = (function($) {
  function initialize(trigger, search_value, search_results) {
    instantiate(trigger, search_value, search_results);
  }

  function instantiate(trigger, search_value, search_results) {
    trigger.on('click', function(event) {
      event.preventDefault();
      search();
    });

    $('.selected-users-well').on('click', 'a', function(event){
      event.preventDefault();
      removeSelectedUser($(this));
    });

    search_results.on('change', function(){
      selectUser();
    });
    populateExistingUsers();

    function search() {
      search_results.empty();
      $.ajax({
        type: 'GET',
        url: '/admin/users/search_by_name.json?name=' + search_value.val(),
        success: function(data) {
          $.each(data.users, function(i, user){
            search_results.append('<option value=\''+ user.id +'\'>' + user.full_name + ' - ' + user.email + ' (' + user.role + ')</option>');
          });
          trigger.parent().parent().find('.help-text').remove();
          if (data.users.length == 0) {
            trigger.parent().parent().append('<small class=\'help-text\'>No results found. Search again</small>');
            search_results.addClass('no-display');
          } else {
            search_results.removeClass('no-display');
          }
        }
      });
    }

    function removeSelectedUser(element) {
      $('#selected-users option[value=\''+ element.data('value') +'\']').remove();
      element.parent().remove();
    }

    function populateExistingUsers() {
      $('#selected-users > option').each(function(){
        $('.selected-users-well').append(`<p>${XssSanitizer.sanitize_dom_text($(this).text())}<a class='close remove-selected-user' data-value='${XssSanitizer.sanitize_dom_text($(this).val())}' href='#'>&times;</a></p>`);
      });
    }

    function selectUser() {
      var value = $('#search-results option:selected').val();
      var text = $('#search-results option:selected').text();
      $('#search-results option:selected').remove();
      $('#search-input').parent().parent().find('small').remove();
      if ($('#selected-users option[value=\''+ value +'\']').length == 0) {
        $('#selected-users').append(`<option selected='selected' value='${XssSanitizer.sanitize_dom_text(value)}'>${XssSanitizer.sanitize_dom_text(text)}</option>`);
        $('.selected-users-well').append(`<p>${XssSanitizer.sanitize_dom_text(text)}<a class='close remove-selected-user' data-value='${XssSanitizer.sanitize_dom_text(value)}' href='#'>&times;</a></p>`);
      } else {
        $('#search-input').parent().parent().append('<small class=\'help-text\'>User already selected</small>');
      }
      $('#search-results').addClass('no-display');
      $('#search-input').val('');
    }
  }
  return {initialize:initialize};
})($);

$(function () {
  MultiSelectFilterHelper.initialize($('#available_roles option'));
  MultiSelectFilterHelper.initialize($('#available_areas option'));
  setAnnouncementAreaVisibility();
  UserSearch.initialize($('#search-button'), $('#search-input'), $('#search-results'));
  $('input[type=radio][name=announcement_area]').on('change', function() {
    setAnnouncementAreaVisibility();
  });
});

function setAnnouncementAreaVisibility() {
  if ($('#announcement_area_zips').is(':checked')) {
    $('.area-no-display').removeClass('area-no-display');
  } else {
    $('.area-no-display').addClass('area-no-display');
  }
}
