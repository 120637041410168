var PeoplePicker = (function ($) {
  function new_person_form_node_from_node(node, id) {
    var dup = node.clone();
    dup.wrapInner('<div class=\'pp_person\'>');
    dup = dup.children('div.pp_person').first();
    dup.append('<input type=\'hidden\' value=\''+ id +'\' name=\'people[]\'>');
    dup.append('<a href="#" class="remove"><i class="fa fa-minus-circle" aria-hidden="true"></i></a>');
    return dup;
  }
  function initialize() {
    $(document).on('click', 'a#share_toggle', function (event) {
      event.preventDefault();
    });
    $(document).on('click', 'a.pp_person', function (event) {
      event.preventDefault();
      // <a href="#" data-people-picker-person-id="<%= user[:id] %>" data-people-picker-insert-into="#people_picker_selections" class="pp_person">
      var target = $(event.target);
      if (target[0].tagName !== 'A') {
        target = target.parents('a').first();
      }
      var person_id = target.data('people-picker-person-id'),
        receptacle = $(target.data('people-picker-insert-into')),
        existing_selections = $('input[type=hidden][name="people[]"][value="'+person_id+'"]');

      receptacle.find('.help-text').hide();
      if (existing_selections.length > 0) {
        var existing_selection = existing_selections.first().parent('.pp_person');
        existing_selection.css({'background-color':'#fff3a5'}).animate({'background-color':'#ffffff'});
      } else {
        receptacle.append(new_person_form_node_from_node(target, person_id));
      }
    });

    $(document).on('click', 'div.pp_person a.remove', function (event) {
      event.preventDefault();
      // <a href="#" data-people-picker-person-id="<%= user[:id] %>" data-people-picker-insert-into="#people_picker_selections" class="pp_person">
      var target = $(event.target).parents('.pp_person').first();
      if (target.siblings('.pp_person').length <= 0) {
        target.parent().find('.help-text').show();
      }
      target.remove();
    });
  }

  return {
    initialize:initialize
  };
})($);

PeoplePicker.initialize();
