/**
 * Created with JetBrains RubyMine.
 * User: tpowell
 * Date: 2/25/13
 * Time: 11:02 AM
 * To change this template use File | Settings | File Templates.
 */

import { adulterate_address_card, update_address_card } from '../admin/registration';
import { StaticTableHeaders } from '../app/static_table_headers';
import { x508 } from '../app/x508';

function adulterate_delegate_row(id, class_name) {
  // jquery will only animate the row blinding up if I do the cells and the row... I wish we were using scripty.
  var row = $('#search_result_row_delegate_id_' + id);
  var cells = row.children('td');

  $(row).animate({
    height: '0px'
  }, {
    duration:'1500',
    complete: function() {
      row.remove();
    }
  } );
  $(cells).slideUp(150, function () {
    StaticTableHeaders.tables_need_update();
  });
}

function update_delegate_with_id(id) {
  var row_to_replace = $('#search_result_row_delegate_id_' + id);
  var refresh_url = row_to_replace.attr('data-refresh-url');
  var promise = $.Deferred();

  $.get(refresh_url, function(data) {
    row_to_replace.replaceWith(data);
    var replaced_row = $('#search_result_row_delegate_id_' + id);

    update_address_card(replaced_row).done(function(){
      promise.resolve();
    });
    replaced_row.addClass('info');

    replaced_row.find('a[role=\'button\']').on('keypress', function(e){
      if (e.which == 13 || e.which == 32) { //code for enter or space keys.
        $(e.target).trigger('click');
      }
    }).attr('tabindex','0').attr('aria-pressed','true').attr('aria-describedby','search_result_row_delegate_id_' + id);
  });

  return promise.promise();
}

export const approve_delegate = function(id) {
  hide_liability_statement();
  update_delegate_with_id(id).done(function(){
    adulterate_address_card('delegate_approved');
    $('#approve_delegate_button').popover('hide');
    hide_action_buttons();
    x508.say('this user was approved');
  });
};

window.approve_delegate = approve_delegate;

export const ack_rejected_delegate_history = function(id) {
  hide_liability_statement();
  update_delegate_with_id(id).done(function(){
    adulterate_address_card('rejected_delegate_history_ackd');
    $('#approve_delegate_button').popover('hide');
    hide_action_buttons();
    x508.say('this user\'s history was acknowledged');
  });
};

window.ack_rejected_delegate_history = ack_rejected_delegate_history;

function hide_liability_statement() {
  var element = $('#liability-confirmation-modal');
  if (element.length) {
    element.modal('hide');
  }
}

function hide_action_buttons() {
  $('#reject_delegate_button').hide();
  $('#approve_delegate_button').hide();
}

export const remove_delegate = function(id) {
  update_delegate_with_id(id).done(function(){
    adulterate_delegate_row(id, 'removed');
    adulterate_address_card('delegate_removed');
    $('#remove_delegate_button').popover('hide');
    x508.say('this user was removed');
  });
};

window.remove_delegate = remove_delegate;

export const reject_delegate = function(id) {
  adulterate_delegate_row(id, 'rejected');
  adulterate_address_card('delegate_rejected');
  $('#reject_delegate_button').popover('hide');
  hide_action_buttons();
  x508.say('this user was rejected');
};

window.reject_delegate = reject_delegate;

$(function (event) {
  $('#address_card').on('click', '#approve_delegate_button', function(event){
    var element = $('#liability-confirmation-modal');
    if (element.length) {
      event.preventDefault();
      element.modal('show');
      element.find('.confirmation-button').attr('href', $(this).data('confirm-url'));
    }
  });
});

var DelegateRequestHistoryController = (function ($) {
  function get_request_history(event) {
    var target = $(event.target);
    var delegate_id = target.data('delegate-id');
    $('#delegate_request_history_' + delegate_id + ' .modal-content').html('');
    $('#delegate_request_history_' + delegate_id + ' .activity-spinner').show();
    $.ajax(target.data('delegate-history-url'))
      .always(function(){
        $('#delegate_request_history_' + delegate_id + ' .activity-spinner').hide();
      });
  }

  function acknowledge_request_history(event) {
    var target = $(event.target);
    var delegateId = target.data('delegate-id');
    var relatedCheckbox = $('#acknowledge_delegate_history_' + delegateId);

    if(relatedCheckbox.is(':checked')){
      $.ajax({
        type: 'patch',
        url: relatedCheckbox.data('acknowledgement-url'),
        data: { request_history_acknowledged: true },
      }).always(function(xhr) {
        $('#acknowledge_delegate_history_' + delegateId).prop('disabled', true);
        var approve_button = $('.approve-delegate-button-' + delegateId);
        var object = $('#search_result_row_delegate_id_' + delegateId);
        update_address_card(object);
        if(approve_button){
          approve_button.removeAttr('disabled');
        }
        else {
          $('#delegate_request_history_' + delegateId).modal('hide');
        }

        if (xhr.nag_should_not_be_visible) {
          $(':contains("You have delegates to review")').closest('.flash').removeClass('d-flex').hide();
        }
       
        $('#delegate_review_nag_' + delegateId).hide();
      });
    }
  }

  function toggle_approval_button(event) {
    var target = $(event.target);
    var delegate_id = target.data('delegate-id');
    var approve_button = $('#approve-delegate-button-' + delegate_id);
    if (target.is(':checked')) {
      approve_button.removeAttr('disabled');
    } else {
      approve_button.attr('disabled', 'true');
    }
  }

  $(document).on('click', '.delegate_request_history', function (e) {
    get_request_history(e);
    return false;
  });

  $(document).on('change', 'input[name="request_history_acknowledged"]', toggle_approval_button);

  $(document).on('click', '.approve-delegate-button', function (e) {
    acknowledge_request_history(e);
    return false;
  });

})(jQuery);
