import { IndeterminateProgress } from '../app/indeterminate_progress';

function enable_auto_popover() {
  // Build up whitelist for popovers requiring forms
  var allowedFormControlList = $.fn.tooltip.Constructor.Default.whiteList;
  var allowed_form_control_list = ['type', 'name', 'id', 'value', 'data-validate', 'data-validates-matching', 'data-validation-message', 'data-validatorian-validating', 'data-validation-failed-message'];
  allowedFormControlList.form = ['action', 'accept-charset', 'data-remote', 'method'];
  allowedFormControlList.input = allowed_form_control_list;
  allowedFormControlList.textarea = allowed_form_control_list;
  allowedFormControlList.button = [];

  $('[data-auto-popover]').popover();

  $('[data-auto-popover-form]').popover({
    template: '<div class="popover organization-reject-popover" role="tooltip"><div class="arrow"></div><h3 class="h5 popover-header"></h3><div class="popover-body"></div></div>',
    whiteList: allowedFormControlList,
    boundary: 'viewport',
    placement: 'right'
  });

  //close previous open popover before opening a new one
  $('body').on('click', function (e) {
    $('[data-toggle="popover"], [data-auto-popover-form], [data-auto-popover]').each(function () {
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
        $(this).popover('hide');
      }
    });
  });
}
$(function (event) {
  enable_auto_popover();
});

$(document).ajaxComplete(function (event) {
  enable_auto_popover();
});


// data-remote-modal data-content-url="/" data-remote-id
$(document).on('click', '*[data-remote-modal]', function(event) {
  var node = $(this),
    url = node.data('content-url'),
    id = node.data('remote-id'),
    modal_container = $('#modal_container'),
    modal = $('#' + id);
  var spinner = $(IndeterminateProgress.init({size:'medium'}));


  if (modal_container.length < 1) {
    $('body').append($('<div id=\'modal_container\' />'));
    modal_container = $('#modal_container');
  }

  if (modal.length < 1) {

    modal_container.append($('<div id=\''+id+'\' class=\'modal hidden fade\' role=\'dialog\'> '
        // +    "<div class='modal-header'>"
        // +    "<button type='button' class='close' data-dismiss='modal' aria-hidden='true'>×</button>"
        // +    "<h3 id='myModalLabel'>Modal header</h3>"
        // +  "</div>"
        +  '<div class=\'modal-body\' style=\'margin:0px;padding:0px;\'>'
        +    '<p style=\'text-align:center;min-height:100px;line-height:100px;\'></p>'
        +  '</div>'
        // +  "<div class='modal-footer'>"
        // +    "<a href='#' class='btn'>Close</a>"
        // +    "<a href='#' class='btn btn-primary'>Save changes</a>"
        // +  "</div>"
        +'</div>'));
    modal = $('#' + id);
    modal.find('div.modal-body p').prepend(spinner);

  }
  modal.modal('show');

  $.ajax(url, {
    complete: function(xhr, status) {
      var html = xhr.responseText;
      // console.log(xhr.responseText);
      html = html.replace('modal hidden fade', '').replace(/id=\"[^\"]+\"/,'');
      // var node = $(html);

      // node.removeClass('hide').removeClass('fade').removeClass('modal');
      // modal.find('div.modal-body').remove();
      modal.html(html);

      // modal.find('.modal-body').first().unwrap();
      // console.log(modal);
    }
  });
});
