import { update_address_card } from '../admin/registration.js';
import { StaticTableHeaders } from '../app/static_table_headers';

function remove_deleted_row(id, not_found) {
  var spinner = $('.indeterminate_progress');
  spinner.hide();
  $('#approve_account_button').prop('disabled',true);
  $('#reject_account_button').prop('disabled',true);
  adulterate_user_row(id);
}

function fail_to_approve_state_account(messages) {
  //console.log(messages);
  alert('Could not approve account.');
}

function fail_to_reject_state_account(messages) {
  //console.log(messages);
  alert('Could not reject account.');
}

export const update_ms_submission_row_with_id = function (id) {
  var row_to_replace = $('#search_result_row_id_' + id);
  var refresh_url = row_to_replace.attr('data-refresh-url');

  $.get(refresh_url, function (data) {
    row_to_replace.replaceWith(data);
    var replaced_row = $('#search_result_row_id_' + id);
    update_address_card(replaced_row);
    replaced_row.addClass('info');
  });
};

window.update_ms_submission_row_with_id = update_ms_submission_row_with_id;

function adulterate_user_row(id) {
  // jquery will only animate the row blinding up if I do the cells and the row... I wish we were using scripty.
  var row = $('#search_result_row_id_' + id);
  var cells = row.children('td');
  $(row).animate({
    height: '0px'
  }, {
    duration: '1500',
    complete: function () {
      row.remove();
    }
  });
  $(cells).slideUp(150, function () {
    StaticTableHeaders.tables_need_update();
  });
}

$(document).on('click', '#reject_account_button, #approve_account_button', function(){
  $(this).find('i.indeterminate_progress').show();
});
