/*
 * getStyleObject Plugin for jQuery JavaScript Library
 * From: http://upshots.org/?p=112
 *
 * Modifications have been made.
 */
import { XssSanitizer } from '../app/xss_sanitizer';

(function($){
  $.fn.getCaretPosition = function() {
    var oField = this.get(0);
    // Initialize
    var iCaretPos = 0;

    // IE Support
    if (document.selection) {

      // Set focus on the element
      oField.focus ();

      // To get cursor position, get empty selection range
      var oSel = document.selection.createRange ();

      // Move selection start to 0 position
      oSel.moveStart ('character', -oField.value.length);

      // The caret position is selection length
      iCaretPos = oSel.text.length;
    }

    // Firefox support
    else if (oField.selectionStart || oField.selectionStart == '0')
      iCaretPos = oField.selectionStart;

    // Return results
    return (iCaretPos);
  };
})(jQuery);


(function($){
  $.humanizeString = function(s){
    var toTitleCase = function(string) {
      var i, str, lowers, uppers;
      str = string.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });

      // Certain minor words should be left lowercase unless
      // they are the first or last words in the string
      lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At',
        'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
      for (i = 0; i < lowers.length; i++)
        str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'),
          function(txt) {
            return txt.toLowerCase();
          });

      // Certain words such as initialisms or acronyms should be left uppercase
      uppers = ['Id'];
      for (i = 0; i < uppers.length; i++)
        str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'),
          uppers[i].toUpperCase());

      return str;
    };

    var string = String(s);
    string = string.split('_').join(' ');
    return toTitleCase(string);
  };
})(jQuery);

var SearchToken = function(params) {
  this.key = params['key'].trim();
  this.human_key = $.humanizeString(this.key);
  this.value = XssSanitizer.sanitize(params['value']).trim();

  this.human_value = this.value.split('_').join(' ');//$.humanizeString(this.value);

  this.is_checkbox = false;

  if (this.value.match(/[A-Z]+/)) { //don't want to humanize acronyms or initialisms...
    this.human_value = this.value;
  }

  this.related_field = params['field'];
  if (this.related_field[0].type.toLowerCase() === 'checkbox') {
    this.is_checkbox = true;
    this.human_value = 'No';
    if ((this.value.toLowerCase() === 'true') || (this.value.toLowerCase() === 'yes')) {
      this.human_value = 'Yes';
    }
  }

  /* 
  Dedicated button to remove the token. This element is added to each search token node, this button element provides 
  better accessibility to the token by providing a dedicated button to remove the token
  (Note: the outcome is equivalent to backspacing over a token.). 
  */
  this.remove_token_button = 
    `<button class='btn btn-primary remove-token' type='button'><span class='sr-only close'>Delete the search critera token: 
    ${XssSanitizer.sanitize_dom_text(this.human_key.toLowerCase())} 
    , with the key ${XssSanitizer.sanitize_dom_text(this.human_value)}. 
    You can also tab forward and use the search button to resubmit the search query.</span>
    <span aria-hidden='true' class='remove-token-icon'><i class='fa fa-times'></i></span></button>`;

  this.node = 
    $(`<span class='token' data-key='${XssSanitizer.sanitize_dom_text(this.key.toLowerCase())}'>
        <span class='token-key'>
          <span class='sr-only'>Search Criteria: </span>
          <span class='token-visible-key'>
            ${XssSanitizer.sanitize_dom_text(this.human_key.toLowerCase())}
          </span>
        </span>
      <span class='sr-only'>Search Criteria key: </span>
      <span class='token-value'>
        <span class='token-visible-value'>
          ${XssSanitizer.sanitize_dom_text(this.human_value)}
        </span>
      </span>
      ${this.remove_token_button}
    </span>`);

  this.sync_related_field();
};

SearchToken.prototype = {
  keyup: function(event){

  },
  click: function(method){
    var m = $.proxy(method, this);
    this.node.on('click', m);
  },
  select: function(){
    this.node.addClass('selected');
    // let's find scrolling container!
    this.scrollToSelf();

  },
  scrollToSelf: function(){
    var scroll_container;
    this.node.parents().each(function() {
      if ($(this).getStyleObject()['overflowX'] === 'hidden') {
        scroll_container = this;
        return false;
      }
    });
    // scroll_container = this.node.parents('.relative_container').first();

    if (scroll_container) {

      var clipWidth = $(scroll_container).width();
      var new_left = -1;

      if (($(this.node).width() + $(this.node).position().left) > (scroll_container.scrollLeft + clipWidth)) {
        new_left = ($(this.node).position().left + $(this.node).width() + 20) - clipWidth; //20 is padding for a token
      }

      if (($(this.node).position().left) < (scroll_container.scrollLeft)) {
        new_left = ($(this.node).position().left);
      }

      if (new_left > -1) {
        $(scroll_container).animate({
          scrollLeft:new_left
        }, {duration:100});
      }


    }
  },
  deselect: function(){
    this.node.removeClass('selected');
  },
  is_selected: function(){
    return this.node.hasClass('selected');
  },
  remove: function(){
    this.node.remove();
    var field = this.related_field.get(0);
    if (field.nodeName.toLowerCase() == 'select') {
      $(field).find('option').first().prop('selected', 'selected');
    }

    if (field.type.toLowerCase() == 'radio') {
      this.related_field.filter('[value="'+this.value+'"]').prop('checked', false);
    }

    if (field.type.toLowerCase() == 'text') {
      $(field).val('');
    }

    if (field.type.toLowerCase() == 'checkbox') {
      $(field).prop('checked', false);
    }
    $(field).trigger('tokenized_search.token_removed');
  },

  removeToken: function () {
    var that = this;
    $(this.node).find('.remove-token').on('click', function (event) {
      that.remove();
    });
  },

  sync_related_field: function(){
    var field = this.related_field.get(0);
    if (field.nodeName.toLowerCase() == 'select') {
      var value = this.human_value;
      $(field).find('option').each(function() {
        if (this['value'] == field.value) {
          $(this).attr('selected', 'selected');
          return false;
        }
      });
    }

    if (field.type.toLowerCase() == 'radio') {
      this.related_field.filter('[value="'+this.value+'"]').attr('checked', true);
    }

    if (field.type.toLowerCase() == 'checkbox') {
      // console.log(this.related_field, this);
      if (this.human_value.toLowerCase() === 'no') {
        // this.related_field.removeAttr('checked');
        $(field).attr('checked', false);
      } else {
        // this.related_field.attr('checked', 'checked');
        $(field).attr('checked', true);
      }

      // this.related_field.filter('[value="'+this.value+'"]').attr('checked', true);
    }

    if (field.type.toLowerCase() == 'text') {
      this.related_field.val(this.value);
    }
    $(field).trigger('tokenized_search.token_sync', {field:field});
  }
};

var TokenizedSearch = function(input_node) {
  this.processing_autocomplete = false;
  this.should_highlight_last_token = false;
  this.input_node = $(input_node);
  this.form_node = $(this.input_node.parents('form'));

  var tokenized_form_sync = $.proxy(this.tokenize_form_sync,this);

  //Form was changed
  this.form_node.find('input, select').on('change', tokenized_form_sync);

  this.process_form_for_autocomplete(this.form_node);
  this.facsimile_container = $('<div id="tokenized_input_container" class="col py-0 h-auto tokenized_input_container"></div>');
  this.relative_container = $('<div class="relative_container"></div>');
  this.token_container = $('<div class="token_container"></div>');
  this.relative_container.append(this.token_container);

  this.facsimile_container.css(this.input_node.getStyleObject());
  var padding_top = parseInt(this.facsimile_container.get(0).style['paddingTop']);
  var padding_bottom = parseInt(this.facsimile_container.get(0).style['paddingBottom']);
  var new_height = this.facsimile_container.outerHeight();

  this.facsimile_container.css({
    height:new_height + 'px'
  });

  this.facsimile_container.append(this.relative_container);

  var parent = this.input_node.parent();
  var original_input_node_id = this.input_node.get(0).id;
  var original_input_node_name = this.input_node.get(0).name;
  var original_input_aria_label = this.input_node.attr('aria-label');

  this.input_placeholder = this.input_node.get(0).placeholder;
  this.input_node.remove();
  $(parent).append(this.facsimile_container);
  this.input_node = $('<input class="token_input"> </input>');
  this.input_node.get(0).id = original_input_node_id;
  this.input_node.get(0).name = original_input_node_name;
  this.input_node.get(0).placeholder = this.input_placeholder;
  this.input_node.attr('aria-label', original_input_aria_label);

  this.relative_container.append(this.input_node);

  var input_proxy = $.proxy(this.process_input, this);
  var post_input_proxy = $.proxy(this.post_process_input, this);
  this.input_node.on('keydown', input_proxy);
  this.input_node.on('keyup', post_input_proxy);
  this.input_node.on('focus', $.proxy(this.input_focus, this));
  this.tokens = new Object();

  this.hidden_input_for_measurement = $('<span id=\'yardstick\'></span>'); // suck on that, metric system!
  $(document.body).append(this.hidden_input_for_measurement);
  this.hidden_input_for_measurement.css(this.input_node.getStyleObject());
  this.hidden_input_for_measurement.css({opacity:0, position:'absolute', top:'0px', left:'0px', display:'inline', width:'auto', bottom:'auto', right:'auto'});


  $(document).on('keyup', $.proxy(this.global_keyup, this));
  this.form_node.on('submit', $.proxy(this.before_submit, this));

  var form_elements = this.form_node.find('input[type!="hidden"]');
  form_elements = form_elements.not(this.input_node);
  var radios = form_elements.filter('input[type="radio"]');
  var texts = form_elements.filter('input[type="text"]');
  var myself = this;
  form_elements.each(function () {
    // myself.tokenize_with_field(this);
    myself.tokenize_form_sync({target:this});
  });
  // setTimeout(function () {
  //   myself.input_node.focus();
  // }, 10000);

  this.input_node.trigger('focus');
};

TokenizedSearch.prototype = {
  before_submit: function(){
    $('.automagic-form-field').remove();
    if (this.input_node.val().length > 0) {
      // attempt to guess first and last names!
      var names = this.input_node.val().split(' ');
      var first, last;
      first = names[0];
      const name = this.input_node.val();
      last = names[1];

      if (first == undefined) {
        first = this.input_node.val(); // default to guessing first name only
      }

      var magic_field_first_name = $(`<input type='hidden' name='first_name' value='${XssSanitizer.sanitize_dom_text(first)}' class='automagic-form-field' />`);
      var magic_field_last_name =  $(`<input type='hidden' name='last_name' value='${XssSanitizer.sanitize_dom_text(last)}' class='automagic-form-field' />`);
      var magic_field_name =       $(`<input type='hidden' name='name' value='${XssSanitizer.sanitize_dom_text(name)}' class='automagic-form-field' />`);

      var form_node = this.form_node;
      var uses_first_name = false, uses_last_name = false;
      $(this.form_node.serializeArray()).each(function() {

        if (this['name'] == 'first_name') {
          if (String(this['value']) < 1) {
            if (first != undefined) {
              uses_first_name = true;
              form_node.append(magic_field_first_name);
            }
          }
        }

        if (this['name'] == 'last_name') {
          if (String(this['value']) < 1) {
            if (last != undefined) {
              uses_last_name = true;
              form_node.append(magic_field_last_name);
            }

          }
        }

        if (this['name'] == 'name') {
          // console.log('name betch');
          if (String(this['value']) < 1) {
            // console.log('uses_last_name:', uses_last_name, 'uses_first_name:', uses_first_name);
            if (!uses_last_name && !uses_first_name) {
              // console.log('undef');
              form_node.append(magic_field_name);
            }

          }
        }
      });


    }
  },
  clear_form: function () {
    $.each(this.tokens, function(key, val) {
      val.remove();
    });
  },
  process_form_for_autocomplete: function(form){

    var d = new Object();
    $(form).find('input').each(function() {
      var name = this.name;
      if($.inArray(this.type, ['hidden', 'text']) == -1){
        if (d[name] === undefined){d[name] = [];}
        if (this.type.toLowerCase() === 'checkbox') {
          d[name].push('Yes');
          // d[name].push('NO');

        } else {
          d[name].push(this.value);
        }

      }
    });

    $(form).find('select').each(function() {

      var name = this.name;
      if (d[name] === undefined){d[name] = [];}
      $(this).find('option').each(function() {
        // Weed out those difficult blank options
        if (String($(this).val()).length > 0) {
          d[name].push($(this).text());
        }

      });
    });
    this.autocomplete_data = d;
  },
  input_focus: function(event){
    var target = event.target;
    setTimeout(function () {
      target.hideFocus = true;
    }, 1000);

    $.each(this.tokens, function(key, val) {
      val.deselect();
    });

    var scroll_container;
    this.input_node.parents().each(function() {
      if ($(this).getStyleObject()['overflowX'] == 'hidden') {
        scroll_container = this;
        return false;
      }
    });


    if (scroll_container) {
      var clipWidth = $(scroll_container).width();
      var new_left = -1;

      new_left = this.token_container.width() - (clipWidth);
      // alert(new_left);
      // if (new_left > -1) {
      // setTimeout(function () {
      //
      // }, 1000);
      $(scroll_container).animate({
        scrollLeft:new_left
      }, {duration:100});
      // this._container_scrollLeft = new_left;
      // var myself = this;

      // };


    }


  },
  process_input: function(event){
    var string = event.target.value;
    var date_pattern = /(\d+)\/(\d+)\/(\d+)/;
    if (string.match(date_pattern)) {
      return true;
    }
    // sync hidden input for measuring!
    this.hidden_input_for_measurement.html(XssSanitizer.sanitize(string));

    switch(event.which){
    // TODO: trap delete/backspace so that it removes the last token without selecting it! if the caret is at the beginning
    case 13: //return key
      if (string.match(':')) {
        this.tokenize_from_autocomplete();
        return false;
      } else {
        this.form_node.trigger('submit');
        return false;
      }
      break;
    case 32: //spacebar
      if (string.match(':')) {
        this.tokenize_from_autocomplete();
        return false;
      }

      break;
    case 8: //delete
    case 46: //backspace
      var token = this.tokens[$('span.token').last().data('key')];
      var removeToken = false;
      var d = event.srcElement || event.target;
      if ((d.tagName.toUpperCase() === 'INPUT' && (d.type.toUpperCase() === 'TEXT' || d.type.toUpperCase() === 'PASSWORD'))
                     || d.tagName.toUpperCase() === 'TEXTAREA') {
        this.save_container_scrollLeft();
        var caretPosition = $(this.input_node).getCaretPosition();
        this.restore_container_scrollLeft();
        if (caretPosition == 0) {
          removeToken = true;

        } else {
          removeToken = false;
        }


      }


      // token.select();
      // this.delete_selected_tokens();
      if (token && removeToken) {
        token.remove();
      }
      break;
      // return false;
    case 37: //left
      this.save_container_scrollLeft();
      var caretPosition = $(this.input_node).getCaretPosition();
      this.restore_container_scrollLeft();
      if (caretPosition === 0) {
        this.should_highlight_last_token = true;
      } else {
        this.should_highlight_last_token = false;
      }
      // return false;
      break;
    case 38: //up
      if (this.handle_up_arrow()) { return false; }
    case 39: //right
      // return false;
      break;
    case 40: //down
      if (this.handle_down_arrow()) { return false; }
    }


  },
  post_process_input: function(event){
    var string = event.target.value;
    var date_pattern = /(\d+)\/(\d+)\/(\d+)\s/;
    var result;
    if (result = string.match(date_pattern)) {
      var date = new Date();
      var m = parseInt(result[1]) - 1; // lol jan is 0... oh javascript
      var d = parseInt(result[2]);
      var y = parseInt(result[3]);
      date.setFullYear(y,m,d);
      this.process_date(date);
    }

    if (this.processing_autocomplete) {
      this.processing_autocomplete = false;
      return false;

    }

    var ACWK = $.proxy(this.autocomplete_with_key, this);
    var match_found = false;
    $.each(this.autocomplete_data,function(key, value) {
      var human_key = key.split('_').join(' ');
      var key_pattern = new RegExp('(' + key + '|' + human_key + ')' + ':(.*)', 'i');
      if (result = string.match(key_pattern)) {

        match_found = true;
        ACWK(key, result[2]);
        return false;
      }
    });
    if (match_found===false) {

      $('ul.autocomplete').remove();
    }
  },
  delete_selected_tokens: function(){
    var tokens = $('span.token');
    var selected_tokens = $('span.token.selected');
    if (selected_tokens.length < 1) {
      return;
    }
    var remove_tokens_with_key = $.proxy(this.remove_tokens_with_key, this);
    var outermost_left_token, outermost_right_token;
    tokens.each(function(index) {
      if ($(this).attr('data-key') === selected_tokens.first().attr('data-key')) {
        outermost_left_token = tokens[index - 1];
      }
      if ($(this).attr('data-key') === selected_tokens.last().attr('data-key')) {
        outermost_right_token = tokens[index + 1];
      }
    });
    selected_tokens.each(function() {
      var key = $(this).attr('data-key');
      remove_tokens_with_key(key);
    });
    if (outermost_left_token != undefined) {
      // console.log("outermost left token: ", outermost_left_token, $(outermost_left_token), $(outermost_left_token).attr("data-key"));

      var t = this.tokens[$(outermost_left_token).attr('data-key')];
      // console.log(t);
      // console.log('tokenz: ', this.tokens);
      t.select();
    } else {
      if (outermost_right_token != undefined) {
        this.tokens[$(outermost_right_token).attr('data-key')].select();
      } else {
        this.save_container_scrollLeft();
        this.input_node.trigger('focus');
        this.restore_container_scrollLeft();
      }
    }

  },
  save_container_scrollLeft: function () {
    this._container_scrollLeft = $(this.input_node).parents('div.relative_container').first().get(0).scrollLeft;
  },
  restore_container_scrollLeft: function () {
    $(this.input_node).parents('div.relative_container').first().get(0).scrollLeft = this._container_scrollLeft;
  },
  global_keyup: function(event){
    // Don't do stuff if we are editing other form elements!

    var focused_dom_nodes = $('*:focus');
    if (focused_dom_nodes.length > 0 && (focused_dom_nodes.get(0).id != this.input_node.get(0).id) && focused_dom_nodes.get(0).nodeName.toLowerCase() == 'input') {

    } else {
      switch(event.which){
      case 13: //return
        if ($('span.token.selected').length > 0) {
          this.form_node.trigger('submit');
          break;
          // return false;
        }
      case 8: //delete
        this.delete_selected_tokens();
        event.preventDefault();
        // return false;
        break;
      case 46: // backspace
        this.delete_selected_tokens();
        event.preventDefault();
        // return false;
        break;
      case 37: //left
        if ($(this.input_node).is(':focus')) {
          // var scrollLeft = $(this.input_node).parents('div.relative_container').first().get(0).scrollLeft;
          // this.save_container_scrollLeft();
          if (this.should_highlight_last_token) {
            // this.restore_container_scrollLeft();
            // $(this.input_node).parents('div.relative_container').first().get(0).scrollLeft = scrollLeft;
            var key = $('span.token').last().attr('data-key');
            if (typeof key === 'undefined') {
              break;
            }
            this.tokens[key].select();
            event.target.blur();
            // return false;
            break;
          }
        }
        var token_nodes = $('span.token');
        var tokens = this.tokens;
        token_nodes.each(function(index) {
          var token = tokens[$(this).attr('data-key')];
          var previous_token = tokens[$(token_nodes[index - 1]).attr('data-key')];
          var next_token = tokens[$(token_nodes[index + 1]).attr('data-key')];
          if (token.is_selected()) {
            //if notshift deselect others

            if (previous_token != undefined) {
              if (event.shiftKey == false) {
                token_nodes.each(function() {
                  var t = tokens[$(this).attr('data-key')];
                  t.deselect();
                });
              }
              // token.deselect();
              previous_token.select();
            }
          }
        });
        break;
        // return false;

      case 39: //right
        var token_nodes = $('span.token');
        var tokens = this.tokens;
        // var token = tokens[$(this).attr("data-key")];
        // var previous_token = tokens[$(token_nodes[index - 1]).attr("data-key")];
        // var next_token = tokens[$(token_nodes[index + 1]).attr("data-key")];
        if ($(this.input_node).not(':focus')) {
          var token = tokens[$(token_nodes.last().get(0)).attr('data-key')];
          if (typeof token === 'undefined') {
            break;
          }
          if (token.is_selected()) {
            this.save_container_scrollLeft();
            this.input_node.trigger('focus');
            this.restore_container_scrollLeft();
            token_nodes.each(function() {
              var t = tokens[$(this).attr('data-key')];
              t.deselect();
            });
            // return false;
            break;
          }

        }


        //This is stupidly painful in jquery.
        var reverse_token_nodes = $(token_nodes.toArray().reverse()).toArray();
        for (var i = 0; i <= reverse_token_nodes.length - 1; i++){

          var token = tokens[$(reverse_token_nodes[i]).attr('data-key')];
          var previous_token = tokens[$(reverse_token_nodes[i + 1]).attr('data-key')];
          var next_token = tokens[$(reverse_token_nodes[i - 1]).attr('data-key')];

          if (token.is_selected()) {
            //if notshift deselect others


            if (next_token != undefined) {
              if (event.shiftKey == false) {
                token_nodes.each(function() {
                  var t = tokens[$(this).attr('data-key')];
                  t.deselect();
                });
              }

              next_token.select();
            }

          }

        }
        // return false;
        break;

      }
    }

  },
  autocomplete_is_visible: function(){
    return ($('ul.autocomplete').length > 0);
  },
  adulterate_selection_of_autocomplete: function(value){
    this.processing_autocomplete = true;

    if (this.autocomplete_is_visible()) {
      var old_selection = $('ul.autocomplete li.selected');

      var new_selection;
      if (value > 0) {
        new_selection = old_selection.next();
      } else {
        new_selection = old_selection.prev();
      }

      // var max_length = parseInt($("ul.autocomplete li").last().attr("data-index"));
      // var new_selection_index = Math.min(Math.max(parseInt(old_selection.attr("data-index")) + value, 0), max_length);
      // var new_selection = $("li[data-index="+ new_selection_index+"]");
      if (new_selection.length > 0) {
        old_selection.removeClass('selected');
        new_selection.addClass('selected');
      }

      return true;
    }
  },
  handle_up_arrow: function(){
    return this.adulterate_selection_of_autocomplete(-1);
  },
  handle_down_arrow: function(){
    return this.adulterate_selection_of_autocomplete(1);
  },
  autocomplete_with_key: function(key, partial_match){
    var autocomplete_pattern = new RegExp('^'+partial_match, 'i');
    var possibilities = $(this.autocomplete_data[key]);
    var matches = [];
    possibilities.each(function() {
      if (this.match(autocomplete_pattern)) {
        matches.push(this);
      }
    });
    this.show_autocomplete_with_array_and_key(matches, key);
  },
  show_autocomplete_with_array_and_key: function(array, key){

    if (this.autocomplete_menu) {
      this.autocomplete_menu.remove();
    }
    var ac_node = $('<ul class="autocomplete" data-category="'+key+'"></ul>');
    var hover_function = $.proxy(function(self) {
      this.autocomplete_menu.children('li').removeClass('selected');
      self.addClass('selected');
    }, this);
    var click_function = $.proxy(this.tokenize_from_autocomplete, this);
    $(array).each(function(index) {
      var node = $('<li data-index='+index+'>' + this + '</li>');
      ac_node.append(node);

      $(node).on('click', click_function).on('hover', function() {
        hover_function(node);
      });
    });
    this.autocomplete_menu = ac_node;
    $(document.body).append(ac_node);
    var position = {
      top:this.hidden_input_for_measurement.height(),
      left:this.hidden_input_for_measurement.width()
    };
    var offset = this.input_node.offset();
    var t = offset.top;
    var l = offset.left;
    t = t + position.top + this.relative_container.height();
    l = l + position.left;
    ac_node.css({
      top:t+'px',
      left:l+'px'
    });
    $('li[data-index=0]').addClass('selected');
  },
  process_date: function(date){
    // var field = $('#date_of_birth');
    var field = this.form_node.find('input[data-behaviour="datepicker"]').first();
    // field.value = date;
    field.datepicker('setDate', date);
    this.tokenize_with_field(field);
  },
  remove_tokens_with_key: function(key){

    var token = this.tokens[key];
    if (token) {
      token.remove();
      delete this.tokens[key];
    }
    if (this.token_container.children().length == 0) {
      this.input_node.get(0).placeholder = this.input_placeholder;
    }

    // $("span[data-key="+key+"]").remove();

  },
  tokenize_from_autocomplete: function(){
    if ($('ul.autocomplete').length > 0) {
      var key = $('ul.autocomplete').attr('data-category');
      var value = $('ul.autocomplete li.selected').text();

      var field = $('[name="'+key+'"]').not(':hidden');
      var label = key.split('_').join(' ');
      var token_string = value;

      this.input_node.val('');
      this.remove_tokens_with_key(key);
      this.insert_token(new SearchToken({
        'key':key,
        'value':token_string,
        'field':field
      }));
      $('ul.autocomplete').remove();
    } else {
      this.tokenize_without_autocomplete();
    }

  },
  tokenize_without_autocomplete: function(){
    var string = this.input_node.val();
    var key = string.split(':')[0];
    var value = string.split(':')[1];
    var machine_key = key.split(' ').join('_').toLowerCase();
    var field = $('[name="'+machine_key+'"]');
    var label = key.split('_').join(' ');
    this.input_node.val('');
    this.remove_tokens_with_key(machine_key);
    this.insert_token(new SearchToken({
      'key':machine_key,
      'value':value,
      'field':field
    }));

  },
  tokenize_with_field: function(form_field){

    var field = $(form_field);
    var key = field.attr('name');
    var label = field.parent().siblings('label').text().replace(':','').toLowerCase();
    var token_string = field.val();//this.input_node.val();
    this.input_node.val('');
    this.remove_tokens_with_key(key);
    // $("span[data-key="+key+"]").remove();
    this.insert_token(new SearchToken({
      'key':key,
      'value':token_string,
      'field':field
    }));
  },
  tokenize_form_sync: function(event){
    var field = $(event.target);
    var key = field.attr('name');
    var label;
    label = field.parents('div.control-group').children('label').text().replace(':','').toLowerCase();

    // tpowell. This was broken, but I don't see any why our code suddenly broke, subbed out .val() for .attr("value")
    //    var token_string = field.attr("value");
    var token_string = field.val();


    if (field.get(0).nodeName.toLowerCase() == 'select') {
      token_string = field.find('option[value=\''+token_string+'\']').text();
    }


    if (field.is(':checked')) {
      this.remove_tokens_with_key(key);
    }

    if (field.attr('type') === 'radio') {
      if (field.is(':checked')) {
        this.insert_token(new SearchToken({
          'key':key,
          'value':token_string,
          'field':field
        }));
      }
    } else {
      if (field.attr('type') === 'checkbox') {
        if (field.is(':checked')) {
          this.insert_token(new SearchToken({
            'key':key,
            'value':token_string,
            'field':field
          }));
        } else {
          this.remove_tokens_with_key(key);
        }
      } else {
        this.insert_token(new SearchToken({
          'key':key,
          'value':token_string,
          'field':field
        }));
      }
    }

    // $("span[data-key='"+key+"']").remove();

  },
  insert_token: function(token){
    if (this.tokens[token.key]) {
      this.tokens[token.key].node.remove();
    }
    this.tokens[token.key] = token;
    this.token_container.append(token.node);
    if (token.value.length < 1) {
      token.remove();
    } else {
      this.input_node.get(0).placeholder = '';
    }


    var tokens = this.tokens;
    token.click(function(event) {
      // remove token when clicking on the remove token button
      if(event.target.classList.contains("fa-times") || event.target.classList.contains("remove-token")) {
          token.remove();
          return;
      } 

      if (event.shiftKey == false) {
        $.each(tokens, function(key, val) {
          val.deselect();
        });
        this.select();
      } else {
        this.select();

        // walk dom. if selected and no selection exists before, start selecting.
        // if selection exists already and we come across something already selected, we have selected all we need to select.
        let should_select = false;
        let should_end_selection = false;
        $('span.token').each(function(index) {
          const t = tokens[$(this).attr('data-key')];
          if (t.is_selected()) {
            if (should_end_selection) {
              should_select = false;
            } else {
              should_select = true;
              should_end_selection = true;
            }
          }
          if (should_select) {
            t.select();
          }
        });

      }

    });
  }
};
var token_search;
$(function() {
  $('input[data-tokenizer]').each(function(elem) {
    token_search = new TokenizedSearch(this);
  });
});