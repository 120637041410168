$(function (event) {
  function reflower() {
    $('*[data-virgin]').removeAttr('data-virgin');
  }
  $('.disclosure').each(function () {
    var disclose_node = $(this),
      hidden = disclose_node.closest('fieldset').find('input').is(':checked');
    disclose_node.toggle(hidden);
  });
  $('#reset_button').on('click', function (event) {
    $('input[type=submit]').prop('disabled', true).addClass('disabled');
    reflower();
  });
  $('.enabler').on('change', function (change_event) {
    var target = $(change_event.target);
    var disclose_node = target.closest('fieldset').find('.disclosure').first();
    if (target.is(':checked')) {
      disclose_node.slideDown();
    } else {
      disclose_node.slideUp();
    }
  });
  $(':checkbox').on('change', function (change_event) {
    var target = $(change_event.target);
    target.attr('data-virgin', false);
    var checked_roles_count = $('form.disclaimers div.roles.column input[type=checkbox]:checked').length;
    if (checked_roles_count > 0) {
      $('form.disclaimers input[type=submit]').removeAttr('disabled').removeClass('disabled');
    } else {
      $('form.disclaimers input[type=submit]').prop('disabled', true).addClass('disabled');
    }

    // target.attr('data-virgin', false);
  });
  $('input.disclaimer_role').on('change', function (change_event) {
    var target = $(change_event.target);
    var disclaimers = target.data('disclaimers');
    $.each(disclaimers, function (attribute, json) {
      attribute = 'disclaimers_' + attribute;
      var disclaimer = JSON.parse(json);
      if (disclaimer !== null) {
        // if (disclaimer.enabled) {
        var enabled_input_node = $('input.enabler#' + attribute + '_enable[type=checkbox]');
        if (enabled_input_node.attr('data-virgin') === undefined) {
          setTimeout(function () {
            enabled_input_node.prop('checked', disclaimer.enabled);
            enabled_input_node.trigger('change');
          }, 10);
        }

        var textarea = enabled_input_node.closest('fieldset').find('textarea');
        if (textarea.val().length < 1) {
          textarea.val(disclaimer.disclaimer_text);
        }
        var acknowledgement_checkbox = enabled_input_node.closest('fieldset').find('input:checkbox');
        if (acknowledgement_checkbox.attr('data-virgin') === undefined) {
          acknowledgement_checkbox.prop('checked', disclaimer.acknowledgement_required);
        }
        // }
      }

    });
  });
});
