export const HTMLTemplate = (function($) {
  var template_cache = {}, is_cached = false;
  function setup() {
    $(function() {
      cache_templates();
    });
  }
  function cache_templates() {
    $('*[data-template]').each(function () {
      var template = $(this),
        key = template.data('template');
      template_cache[key] = template.clone();
      //blank out template to prevent weird formatting
      $(this).empty();
    });
    $(document).trigger('templates:cached');
    is_cached = true;
  }
  function when_cached(func) {
    if (is_cached) {
      func();
    } else {
      $(document).on('templates:cached', func);
    }
  }
  function instantiate(template_key) {
    var me = new Object,
      cached_template = template_cache[template_key];
    if (typeof(cached_template) === 'undefined') {
      throw('Cannot find template with key '+ template_key);
      return;
    }
    var node = cached_template.clone();
    function find_template_node(key) {
      return $('*[data-template=' + key + ']');
    }
    me.blank_template = function () {
      find_template_node(template_key).replaceWith(template_cache[template_key].clone());
    };

    me.render = function (locals) {
      me.blank_template();
      me.visit_node(find_template_node(template_key), locals);
    };

    me.display_if_variable_defined = function (node, local_vars) {
      var variable_name = node.data('display-if-variable-defined');
      if (typeof(variable_name) !== 'undefined') {
        if (typeof(local_vars[variable_name]) === 'undefined' ) {
          node.remove();
        }
      }
    };

    me.display_if_variable = function (node, local_vars) {
      var variable_name = node.data('display-if-variable');
      if (typeof(variable_name) !== 'undefined') {
        if (typeof(local_vars[variable_name]) === 'undefined' ) {
          node.remove();
        } else {
          if (String(local_vars[variable_name]) !== 'true') {
            node.remove();
          }
        }
      }
    };

    me.display_unless_variable = function (node, local_vars) {
      var variable_name = node.data('display-unless-variable');
      if (typeof(variable_name) !== 'undefined') {
        if (typeof(local_vars[variable_name]) !== 'undefined' ) {
          if (String(local_vars[variable_name]) === 'true') {
            node.remove();
          }
        }
      }
    };

    me.interpolate_variable_attribute = function (node, local_vars) {
      var attributes = node.data('variable-attribute'),
        variable_names = node.data('variable'),
        index;

      if (typeof(attributes) === 'undefined') {
        return false;
      }

      attributes = attributes.split(' ');
      variable_names = variable_names.split(' ');
      for (index = 0; index < attributes.length;  ++index) {
        node.attr(attributes[index], local_vars[variable_names[index]]);
      }
      return true;
    };

    me.interpolate_variable = function (node, local_vars) {
      var variable_name = node.data('variable');
      if (me.interpolate_variable_attribute(node, local_vars) === false) {
        if (typeof(variable_name) !== 'undefined') {
          node.html(local_vars[variable_name]);
        }
      }
    };
    me.interpolate_for_each = function (node, local_vars) {
      var for_each_variable_name = node.data('for-each');
      if (typeof(for_each_variable_name) !== 'undefined') {
        var locals_array = local_vars[for_each_variable_name];
        if (typeof(locals_array) !== 'undefined') {
          //create pristine node copy
          var blueprint_node = node.clone();
          //keep container, but remove for-each attribute to prevent infinite recursion
          blueprint_node.removeAttr('data-for-each');
          //empty containing node, but keep a reference to it around for later
          var parent_node = node.parent();
          node.parent().empty();
          //create an instance per array entry
          for (var i = 0; i < locals_array.length; i++) {
            var scoped_locals = locals_array[i],
              template_instance_node = blueprint_node.clone();
            me.visit_node(template_instance_node, scoped_locals);
            parent_node.append(template_instance_node);
          }
        }
        return true;
      } else {
        return false;
      }
    };
    me.visit_node = function (node, local_vars) {
      me.display_if_variable_defined(node, local_vars);
      me.display_if_variable(node, local_vars);
      me.display_unless_variable(node, local_vars);
      me.interpolate_variable(node, local_vars);
      if (me.interpolate_for_each(node, local_vars) === false) {
        node.children().each(function () {
          var child = $(this);
          me.visit_node(child, local_vars);
        });
      }
    };
    return me;
  }


  return {
    instantiate: instantiate,
    cache_templates: cache_templates,
    when_cached: when_cached,
    setup: setup
  };
})(jQuery);

HTMLTemplate.setup();
