$(function() {
  let mark_notice_read;
  $(document).on('change', '#items_per_page_counter', function() {
    $(document).find('#should_update_boolean').val(true);
    return $(document).find('#notices_user_form, #admin_notices_form').submit();
  });

  $(document).on('change', '#notices_user_form #current_page', function() {
    let current_page = $(document).find('#current_page').val();
    const max_page = Number($(document).find('.total-count.total-pages').text().replace(/\D/g, ''));
    if (current_page > max_page) {
      current_page = max_page;
      $(document).find('#current_page').val(current_page);
    }
    return $(document).find('#notices_user_form, #admin_notices_form').submit();
  });

  $(document).on('click', '#admin_notices_form tr.font-weight-bold a[name="view"]', function() {
    const notice_id = $(this).attr('data-target').split('_')[2];
    const row = $(this).parents('.font-weight-bold');
    return mark_notice_read(row, notice_id);
  });

  return mark_notice_read =(row, notice_id) => $.ajax({
    type: 'PATCH',
    url: '/admin/notices/' + notice_id,
    success(response) {
      if (response.success) {
        $(row).removeClass('font-weight-bold');
        const unread_count = $('#admin_notices_form #notice_count').text().replace(/\(|\)/g, '') - 1;
        return $('#admin_notices_form #notice_count').html(`(${unread_count})`);
      } else {
        return alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
      }
    }
  });
});
  