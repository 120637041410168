// Helpfully stolen from http://stackoverflow.com/questions/5203428/inserting-text-after-cursor-position-in-text-areа
jQuery.fn.extend({
  insert_at_caret: function(myValue){
    return this.each(function(i) {
      if (document.selection) {
        //For browsers like Internet Explorer
        this.focus();
        var sel = document.selection.createRange();
        sel.text = myValue;
        this.focus();
      }
      else if (this.selectionStart || this.selectionStart == '0') {
        //For browsers like Firefox and Webkit based
        var startPos = this.selectionStart;
        var endPos = this.selectionEnd;
        var scrollTop = this.scrollTop;
        this.value = this.value.substring(0, startPos)+myValue+this.value.substring(endPos,this.value.length);
        this.focus();
        this.selectionStart = startPos + myValue.length;
        this.selectionEnd = startPos + myValue.length;
        this.scrollTop = scrollTop;
      } else {
        this.value += myValue;
        this.focus();
      }
      $(this).trigger('change');
    });
  }
});

var TextAreaInsertAddition = (function () {
  function setup_events() {
    $(document).on('click', function (event) {
      var clicked_element = $(event.target), destination_textarea = null, insertion_code = '';

      if (clicked_element.data('insert-into') !== undefined) {
        event.preventDefault();
        destination_textarea = $(clicked_element.data('insert-into'));
        insertion_code = clicked_element.data('insert-code');
        if (destination_textarea.prop('tagName') === 'TEXTAREA') {
          destination_textarea.insert_at_caret(' @' + insertion_code + ' ');
        } else {
          alert('The destination is not set to a textarea!');
        }
      }
    });
  }
  return {
    initialize:setup_events
  };
})();

$(function () {
  TextAreaInsertAddition.initialize();
});
