import { Flash } from '../app/flash';

$(function () {
  $('.form-with-spinner').on('click', '.trigger-spinner', event => $('.form-with-spinner .activity-spinner .indeterminate_progress').show());
  $('.form-with-spinner').on('ajax:beforeSend', (evt, xhr, settings) => $('.form-with-spinner .activity-spinner .indeterminate_progress').show());
  $('.form-with-spinner').on('ajax:complete', (evt, xhr, status) => $('.form-with-spinner .activity-spinner .indeterminate_progress').hide());
  $('.form-with-spinner').on('ajax:error', function(evt, xhr, status) {
    $('.form-with-spinner .activity-spinner .indeterminate_progress').hide();
    return Flash.error('There was a problem with your request. Please try again later.');
  });

  return $('.form-with-spinner-and-results').on('ajax:beforeSend', (evt, xhr, settings) => $('#spinnah').show(100)).on('ajax:success', (evt, data, status, xhr) => $('#results').html(xhr.responseText)).on('ajax:complete', (evt, xhr, status) => $('#spinnah').hide(100)).on('ajax:error', function(evt, xhr, status, error) {
    $('.scrolltable').html(xhr.responseText);
    alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
    return $(document).trigger('search:failed');
  });
});
