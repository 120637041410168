$(function() {
  // Open the file input dialog
  $('form').on('click', '#agency_list_file_input_button', event => $('#upload_agency_list_file').trigger('click'));

  // Change the value of the hidden file input
  $('form').on('change', '#upload_agency_list_file', function(event) {
    return $('#agency_list_file_input').val($(this).val());
  });

  // When the user clicks "Validate & Upload", submit the form to the validation route and
  // with an iframe target. Check the results and render the modal dialog with the response.
  // If the response was a success, also render the button to do a full submit.
  $('form').on('click', '#validate-button', function(event) {
    const originalAction = $('#upload-form').attr('action');
    $('#upload-form').attr('target', 'uploadTrg');
    $('#upload-form').attr('action', '/admin/agency_lists_validate');
    $('#uploadTrg').on('load', function() {
      const iFrameContents = this.contentWindow.document.body.innerHTML;
      if (iFrameContents.match(/success/)) {
        $('#validate-feedback').html(`\
  <p>The agency list validation is complete.</p>
  <p><div style="font-weight: bold;">${iFrameContents}</div></p>
  <p>Are you sure you want to update the existing agency information?</p>\
  `
        );
        return $('#upload-confirm-button').show();
      } else {
        return $('#validate-feedback').html('Some agencies were unable to validate. Please check your file and try again.');
      }
    });
    $('#upload-form').submit();
    $('#upload-form').removeAttr('target');
    $('#upload-form').attr('action', originalAction);
    $('#validate-modal').modal();
    $('#upload-confirm-button').hide();
    return event.preventDefault();
  });

  // If the upload buttong is clicked on the dialog, then submit the form.
  return $('#upload-confirm-button').on('click', event => $('#upload-form').submit());
});
  