var ExpandoTable = (function() {
  var part_count=0, tables=[], container_height, container, animation_timer,
    row_height = 33, header_height = 35, current_factor = 0;

  function initialize () {
    container = $('div[data-expando]');
    reweigh();
  }

  function sync_animation (should) {
    if (should) {
      clearInterval(animation_timer);
      animation_timer = setInterval(reweigh, 0);
    } else {
      clearInterval(animation_timer);
      reweigh();
    }
  }

  function reweigh () {
    var bottom_sticky_height = $('.bottom_sticky').height();

    container_height = container.height();

    var results_height = container_height - bottom_sticky_height - 76;

    var factor = 1;

    factor = Math.max(factor, (container_height - (row_height * 3)) / 250);

    factor = Math.floor(factor);

    if (factor !== current_factor) {
      current_factor = factor;
      $('.bottom_sticky .tablescroll').animate({'max-height':((row_height * factor) + header_height)  + 'px'}, {duration:200, complete: reweigh});
      sync_animation();
    }

    $('.results .tablescroll').css({'max-height':results_height + 'px'});

  }

  return {
    initialize: initialize,
    reweigh: reweigh,
    sync_animation: sync_animation
  };
}());
function enable_resizeable_tables(node) {
  var obj = {
    handles:'s',
    // alsoResize:node.find('.tablescroll'),
    minHeight:node.find('.container-header').outerHeight()+node.find('tr th').outerHeight()+(node.find('tr td').outerHeight()*2),
    maxHeight:node.find('.container-header').outerHeight()+node.find('tr th').outerHeight()+(node.find('tr td').outerHeight()*(node.find('tr').length - 1))
  };
  var grid_height = node.find('tr td').outerHeight();
  obj.resize=function(e,ui) {
    if (e.altKey) {
      node.resizable( 'option', 'grid', [ 0, grid_height ] );
    } else {
      node.resizable( 'option', 'grid', null );
    }
    var resize_height = $(this).height();

    if (resize_height != 0) {
      // The timeout is needed to workaround a jquery bug with grids. Namely, dragging down always leaves one row height shy in the inner scroll div unless we recalculate the height dynamically...
      var me = $(this);
      setTimeout(function () {
        var delayed_resize_height = me.height() - 42;
        if (me.find('.footnote').length > 0) {
          delayed_resize_height = delayed_resize_height - me.find('.footnote').outerHeight();
        }
        var tablescroll = me.find('.tablescroll');
        tablescroll.height(delayed_resize_height);
        var scroll_elem = me.find('.scrolltable');
        scroll_elem.height(delayed_resize_height);
      }, 1);

    }
    // scroll_elem.css({'padding-bottom':'42px'});
  };
  obj.stop = obj.resize;
  $(node).resizable(obj);
}
$(function() {
  var nodes = $('.collapsible_data');
  nodes.each(function () {
    var node = $(this);
    node.find('.container-body').css({'height':'auto'});
    node.data('old-height', node.height());
    enable_resizeable_tables(node);
  });

  $(document).on('table_updated', function(evt, xhr, status){
    var node = $('.container-body.collapse.in').parents('.collapsible_data');
    enable_resizeable_tables(node);

    node.find('table.collapsible_headers').find('th').attr('tabindex', '0'); //Enable focus
    node.find('table.collapsible_headers').find('th').attr('aria-hidden', 'false');
  });
});

$(document).on('show', function(e) {
  var node = $(e.target).parents('.collapsible_data');
  if (node.attr('data-old-height')) {
    node.height(node.data('old-height'));
  }
});

$(document).on('hide', function(e) {
  var node = $(e.target).parents('.collapsible_data');
  node.data('old-height', node.height());
  node.css({height:''});
});

$(document).on('hidden', function(e) {
  var node = $(e.target).parents('.collapsible_data');
  try {
    $(node).resizable('destroy');
  }catch(e){
    //Getting errors on this event. resizeable('destroy') is throwing an exception 'some of the time'. 
    //This doesn't seem to matter to functionality. At least on the rx prescription history page.
    //if(window.console) console.log(e); 
  }
});
$(document).on('shown', function(e) {
  var node = $(e.target).parents('.collapsible_data');
  enable_resizeable_tables(node);
});
