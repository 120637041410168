(function($) {
  function initialize_ndc_typeahead() {
    $('input[data-ndc-search-path]').typeahead({
      minLength: 4,
      items: 10,
      dynamic: true,
      emptyTemplate: 'no result for {{query}}',
      templateValue: function() {
        return this.item.display.split(' - ')[0];
      },
      source: {
        ajax: {
          url: '/ndc_suggestions?term={{query}}',
          path: 'ndc_suggestions'
        }
      }
    });
  }

  $(document).on('single_nest_row:row_added', initialize_ndc_typeahead);

  $(function () {
    initialize_ndc_typeahead();
  });

}(jQuery));
