var Validatorian = (function ($) {
  var displayed_error_once = false, have_errors = false;
  $(document).on('submit', function (event) {
    displayed_error_once = false;
    if(have_errors === true){
      event.preventDefault();
      return false;
    }
  });
  $(document).on('ajax:beforeSend', function (event, xhr) {
    displayed_error_once = false;
    if(have_errors === true){
      xhr.abort('validatorian:validation:failed');
      return false;
    }
  });

  $(document).on('validatorian.invalid', function (event) {
    var form = $(event.target);
    if (displayed_error_once === false) {
      //If there is a modal up, hide it before displaying our error message, unless the form is inside the modal -_-
      var backdrop = $('.modal-backdrop');
      if ((form.parents('.modal-backdrop').length + form.parents('.modal-form').length) > 0 ) {
        $('.modal').modal('hide');
      }

      setTimeout(function () {
        alert('You have validation errors. Please check the highlighted fields and try again. Some fields may be missing.');
      },200);
      displayed_error_once = true;
    }
    have_errors = true;
  });
  $(document).on('validatorian.error_cleared', function () {
    if ($(document).find('.validation_error').length > 0) {
      have_errors = true;
    } else {
      have_errors = false;
    }
  });
  function enable_live_validation(node) {
    if (node.data('validatorian-validating') === true) {
      return;
      //we've already ran on this object
    }
    if (node.prop('disabled') == true) {  //form field is disabled. so don't validate.
      return;
    }

    function create_error_node(message) {
      if (message !== '' && message !== undefined && !message.match(/^Error:/)) {
        message = 'Error: ' + message;

        var relative_id = $(node).attr('id');
        if (!relative_id) {
          relative_id = 'fallback_id';
        }
        var new_relationship_identifier = relative_id + Math.random();

        var current_aria_descriptor = $(node).attr('aria-describedby');
        if (!current_aria_descriptor) {
          current_aria_descriptor = '';
        } else {
          current_aria_descriptor = current_aria_descriptor + ' ';
        }
        current_aria_descriptor = current_aria_descriptor + new_relationship_identifier;

        $(node).attr('aria-describedby', current_aria_descriptor);
      }
      return $('<span class="help-block validation_error_message" id="' + new_relationship_identifier + '">' + message + '</span>');
    }


    function get_label_text(node) {
      try {
        var dom_label = $(node).parents('label');
        if (dom_label.length != 0) {
          // text the first text node of the label.
          for (var i = 0; i < dom_label[0].childNodes.length; i++) {
            if (dom_label[0].childNodes[i].nodeType == 3) {
              var text = dom_label[0].childNodes[i].textContent.replace(/(\r\n|\r|\n)/gm, '').trim();
              if (text != '') {
                return text;
              }
            }
          }
        }
        var id_label = $('label[for="' + node.attr('id') + '"]');
        if (id_label.length != 0) {
          var text = id_label.text().replace(/(\r\n|\r|\n)/gm, '').trim();
          return text;
        }

      } catch (e) {
        console.log(e.message);
      }

      return 'indicated';
    }

    var parent_form = node.closest('form');
    parent_form.on('submit', function (event) {
      parent_form.find('*[data-validatorian-validating=true]').each(function () {
        if ($(this).prop('disabled') != true) {
          $(this).data('validation-function')();
        }

      });
      if (parent_form.find('.validation_error').length > 0) {
        if ($(node).prop('disabled') != true && $(node).hasClass('validation_error')) {

          // alert('You have validation errors. Please check the ' + get_label_text(node) + ' field and try again.');
          parent_form.trigger('validatorian.invalid');

        } else {
          var error_count = 0;
          parent_form.find('.validation_error').each(function () {
            var error_node = $(this);
            if (error_node.prop('disabled') !== true) {
              error_count++;
            }
          });
          if (error_count > 0) {
            parent_form.trigger('validatorian.invalid');
          }
        }
      } else {
        parent_form.trigger('validatorian.valid');
      }
    });
    var regex, regex_string = node.data('validates-matching'), allows_blank = node.data('validation-allows-blank');

    if (regex_string !== undefined) {
      if (regex_string.match(/^\//)) {
        // we havea regex literal, let's just eval it since passing a string to the RegExp constructor will escape the slashes :(
        regex = eval(regex_string);
      } else {
        regex = new RegExp(regex_string);
      }
    }


    var validation_failed_message = node.data('validation-failed-message');
    var error_node = create_error_node(validation_failed_message);

    function highlight_node_and_display_error() {
      if (node.prop('disabled') != true) {
        node.addClass('validation_error');
        node.attr('aria-invalid', 'true');
        if (node.siblings('span.validation_error_message').length < 1 && validation_failed_message !== '') {
          var message_node;
          if ($(node).data('validate-message-after')) {
            message_node = $('#' + $(node).data('validate-message-after'));
          } else {
            message_node = node;
          }

          var val_accessible_datepicker_check = $(node).closest('.calendar-datepicker').get(0);
          if ($(val_accessible_datepicker_check).length > 0) {
            $(val_accessible_datepicker_check).after(error_node);
          } else {
            message_node.after(error_node);
          }
        }
      }
    }

    function clear_error() {
      node.removeClass('validation_error');
      node.attr('aria-invalid', 'false');
      error_node.remove();
      node.trigger('validatorian.error_cleared');
    }

    function delayed_validation() {
      if (node.attr('type') === 'radio') {
        if (node.attr('data-validate-required') !== undefined) {
          var form_attributes = parent_form.serializeArray(), radio_name = node.attr('name'), found = false;

          for (var i = 0; i < form_attributes.length; i++) {
            var attribute_object = form_attributes[i];
            if (attribute_object.name === radio_name) {
              found = true;
            }
          }
          $('form input[name="' + radio_name + '"]').each(function () {
            var possibly_valid_node = $(this);
            if (found === false) {
              if (possibly_valid_node.prop('disabled') != true) {
                possibly_valid_node.parent().addClass('validation_error');
                possibly_valid_node.attr('aria-invalid', 'true');
              }
            } else {
              if (possibly_valid_node.prop('disabled') != true) {
                possibly_valid_node.parent().removeClass('validation_error');
                possibly_valid_node.attr('aria-invalid', 'false');
                node.trigger('validatorian.error_cleared');
              }
            }

          });
        }
      } else {
        var value = node.val();
        var matching = value.match(regex);
        var should_validate = (node.attr('data-validate') === 'true' && node.prop('disabled') != true);

        if (should_validate) {

          if ((String(value) === '') && (allows_blank !== undefined)) {
            clear_error();
            return;
          }

          if (!regex.test(value)) {
            highlight_node_and_display_error();
          } else {
            clear_error();
          }

        } else {
          clear_error();
        }

      }

    }

    node.on('blur', function () {
      setTimeout(delayed_validation, 100);
    });
    if (node.data('validate-only-on-blur') != true) {
      node.on('changeDate', function () {
        setTimeout(delayed_validation, 100);
      });
    }
    node.attr('data-validatorian-validating', true);
    node.data('validation-function', delayed_validation);
  } //end enable_live_validation

  $(function () {
    $('*[data-validate="true"]').each(function () {
      enable_live_validation($(this));
    });

  });
  $(document).on('enable_validations', function () {
    $('*[data-validate="true"]').each(function () {
      enable_live_validation($(this));
    });
  });
  $(document).on('shown.bs.popover', function () {
    $('*[data-validate="true"]').each(function () {
      enable_live_validation($(this));
    });
  });
  $(document).ajaxComplete(function (evt, xhr, status) {
    $('*[data-validate="true"]').each(function () {
      enable_live_validation($(this));
    });
  });
})($);
