import { x508 } from '../app/x508';
import { Flash } from '../app/flash';
import { IndeterminateProgress } from '../app/indeterminate_progress';
import { tmpl } from '../blueimp';

export const RxSearchFormAssistant = (function ($) {
  function form_processing_complete(event) {
    var spinner = $('.activity-spinner .indeterminate_progress');
    spinner.hide(100);
    if (typeof(event) !== 'undefined') {
      // This prevents the automatic ajax form that rails creates when deleting a request documentation from triggering a button disable with message.
      if (!$(event.target).hasClass('destroy_request_documentation')) {
        spinner.trigger('RxSearch:indeterminate_progress_indicator:hidden');
      }
    } else {
      spinner.trigger('RxSearch:indeterminate_progress_indicator:hidden');
    }
  }
  return {
    form_processing_complete: form_processing_complete
  };
})(jQuery);

function humanFileSize(bytes, si) {
  var thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
    : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
}

window.humanFileSize = humanFileSize;

function unparam(string) {

  var obj = {};
  var base_url, params, param_string, param_tuples;
  base_url = string.split('?')[0];
  param_string = string.split('?')[1];
  try {
    param_tuples = param_string.split('&');
  } catch (error) {
    return {
      base_url:base_url, obj:{}
    };
  }

  params = {};
  for (var i = 0; i < param_tuples.length; i++) {
    var tuple = param_tuples[i];
    var key = tuple.split('=')[0];
    var value = tuple.split('=')[1];
    params[key] = value;
  }
  return {
    base_url:base_url, obj:params
  };
}

$(document).on('PMPi:request:complete', function () {
  if ($('div#patient_header').length > 0) {
    // then this is our page, yo
    var prescribers, dispensers, dispensations;
    prescribers = $('div.prescribers');
    dispensers = $('div.dispensers');
    dispensations = $('div.results');
    $([prescribers, dispensers, dispensations]).each(function() {
      var source = $(this);
      // prescriber activity request results
      if (source.data('table-id') !== '') {
        source.find('table').load(source.data('url'), function() {
          source.find('.progress').hide();
          $(document).trigger('table_updated');
        });
      }
    });
  }
});

$(document).on('PMPi:request:timeout', function () {
  if ($('div#patient_header').length > 0) {
    // then this is our page, yo
    var prescribers, dispensers, dispensations;
    prescribers = $('div.prescribers');
    dispensers = $('div.dispensers');
    dispensations = $('div.results');
    RxSearchFormAssistant.form_processing_complete();
    $([prescribers, dispensers, dispensations]).each(function() {
      var source = $(this);
      // prescriber activity request results
      if (source.data('table-id') !== '') {
        source.find('.progress').hide();
      }
    });
  }
});

$(function() {
  $('#new_rx_search_request').on('submit', function(event){
    $('#alert-error').remove();
    $('#alert-alert').remove();

    // validate required fields
    const requiredFields = getRequiredFields();
    requiredFields.each(function() { setRequiredFieldState($(this)); });
    const fieldErrors = $('.validation_error');

    if (fieldErrors.length) {
      event.preventDefault();
      fieldErrors.get(0).scrollIntoView();  // scroll to first error
      window.scrollBy( 0, -30 );            // scroll up a bit more
      return false;
    } else {
      return true;
    }
  });

  $('#new_rx_search_request')
    .on('ajax:error', function(evt, xhr, status, error) {
      Flash.error(
        '<b>Could not complete your request because of an error: </b> <p>' +
          error +
          '</p> Please try again later, or contact your administrator if the problem persists.'
      );
    })
    .on('ajax:beforeSend', function(evt, xhr, settings) {
      $('.activity-spinner .indeterminate_progress').show(100);
      setTimeout(function() {
        $('.activity-spinner .indeterminate_progress').hide();
        $('html, body').animate({ scrollTop: 0 }, 'slow');
      }, 500);
    })
    .on('ajax:complete', function(evt, xhr, status) {
      if (
        !(
          xhr['responseText'].indexOf('top.location') >= 0 ||
          xhr['responseText'].indexOf('searchResultsDialogModal') >= 0
        )
      ) {
        RxSearchFormAssistant.form_processing_complete(evt);
      }
    });

  $(document).on('click', '.close-parent-modal', function() {
    $(this)
      .closest('.modal')
      .addClass('hidden');
  });

  $('input[id="rx_search_request_birthdate"]').mask('99/99/9999', {placeholder: 'MM/DD/YYYY'});

  $('#rx_search_request_filter')
    .on('ajax:beforeSend', function(evt, xhr, settings){
      $('#spinnah').show(100);
      $('#criteria_card').empty();
    })
    .on('ajax:success', function(evt, data, status, xhr){
      $('#results').html(xhr.responseText);
    })
    .on('ajax:complete', function(evt, xhr, status) {
      $('#spinnah').hide(100);

      x508.sortedHeaderMessage();
      
      /*
       * This is little brittle.
       * The `sort` class is applied to both original and duplicate headers.
       * We gotta get the one we want.
       * Also, this applies also when a header is clicked with a mouse, which
       * seems less than ideal.  - Tyler Akin, 5/20
       */
      $('.cloned-header a.sort').trigger('focus');
    })
    .on('ajax:error', function(evt, xhr, status, error){
      $('.scrolltable').html(xhr.responseText);

      alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
      $(document).trigger('search:failed');
    });

  $(document).on('click', '#show_selected_criteria_box', function(){
    var selected_box_id = $(this).data('boxToOpen');
    var object_to_find = '.popover-id-' + selected_box_id;

    if ($(document).find(object_to_find).css('display') == 'none') {
      $(document).find('.case-info-popover-view').css('display', 'none');
      $(document).find(object_to_find).css('display', 'block');
    } else if ($(document).find(object_to_find).css('display') == 'block') {
      $(document).find('.case-info-popover-view').css('display', 'none');
    }
  });

  var handleAdvancedOptionsWidgetFocus = (function($) {
    // when widget closes, move focus back the button so user may continue the original tabbing order
    $('#search_options').on('hidden', function() {
      $('#search_option_toggle').trigger('focus');
    });
  })($);
});

$(function(){
  $(document).on('click', '.destroy_request_documentation', function(){
    $(this).next('.documentation_delete_icon').removeClass('hidden');
    $(this).hide();
  });

  $(document).on('ajax:complete', function(e) {
    let theTarget = e.target;
    if (! theTarget.classList.contains('destroy_request_documentation')) {
      return;
    }

    $(e.target).closest('.request-documentation-item').remove();
    $(e.target).closest('.documentation_delete_icon').removeClass('hidden');
    $(e.target).closest('.destroy_request_documentation').hide();
    $('.activity-spinner .indeterminate_progress').hide();
  });

  $('.uploading_multiple_documentation_files').each(function(index){
    $(this).fileupload({
      url: '/request_documentations',
      autoUpload: true,
      showElementClass: 'show',
      formData: {
        'request_documentation_type': $(this).siblings('.request_documentation_type').val(),
        'request_documentation_id': $(this).siblings('.request_documentation_id').val(),
      },
      add: function(e, data) {
        $(document).find('.uploading_multiple_documentation_files').value = '';
        var file;
        file = data.files[0];
        data.context = $(tmpl('template-upload', file));
        var popover_view = $(this).closest('.case-info-popover-view');
        if (popover_view.length == 0) {
          $(document).find('.upload_documentations').append(data.context);
        } else {
          $(popover_view).find('.upload_documentations').append(data.context);
        }

        return data.submit();
      },
      done: function (e, data) {
        $(document).find('.uploading_multiple_documentation_files').value = '';
        $(document).find('.template-upload').remove();

        var file;
        file = data.files[0];
        file['errors'] = data.result.documentation_errors;

        if (file['errors'] == null) {
          file['created_date'] = data.result.DoC;
          file['documentation_url'] = data.result.documentation_url;
          file['documentation_id'] = data.result.documentation_id;
          file['request_id'] = data.result.request_id;
        }

        data.context = $(tmpl('template-download', file));

        var popover_view = $(this).closest('.case-info-popover-view');
        if (popover_view.length == 0) {
          $(document).find('.upload_documentations').append(data.context);
        } else {
          $(popover_view).find('.upload_documentations').append(data.context);
        }
      }
    });
  });
});

$(document).on('click', 'form#multiple_patient_confirmation button[type=submit]', function (event) {
  var button = $(this);
  $('form#multiple_patient_confirmation').on('ajax:beforeSend', function (evt, xhr, message) {
    button.prop('disabled', true);
    button.html('Running Report... ' + IndeterminateProgress.init({white:true}));
  })
    .on('ajax:complete', function (evt, xhr, status) {
      if (!(xhr['responseText'].includes('top.location')) || xhr['responseText'].includes('alert')) {
        button.prop('disabled', false);
        button.html('Run Report');
      }
    });
});

$(document).on('click', 'div.collapsible_data thead th', function(event) {
  if ($('.casper').length > 0) {
    return;
  }

  var current_focus = $( event.target );

  // sak: this handles sorting for all insight reports...
  var should_unwrap = true;
  var th = $(this);
  var should_unwrap_node = th.closest('*[data-sortable-table-should-unwrap]');
  if (should_unwrap_node) {
    should_unwrap = should_unwrap_node.data('sortable-table-should-unwrap');
  }
  var source = th.parents('div.collapsible_data');
  var url = source.data('url');
  var table = th.parents('table');
  var table_id = source.data('table-id');
  if (table_id === undefined) {
    table_id = '';
  }
  var sort_direction = 'asc';
  if (th.hasClass('asc')) {
    sort_direction = 'desc';
  }

  var data_type = source.data('type');
  re_results = /_results$/;

  var pdf_link = $('[data-original-pdf-path]').first();
  pdf_link.data(data_type, th.data('sort-symbol') + ':' + sort_direction);
  href = pdf_link.data('original-pdf-path');
  var param_count = 0;
  for(var field in pdf_link.data()) {
    if(re_results.test(field)) {
      if(param_count) {
        href += '&';
      } else {
        href += '?';
      }
      href += field + '=' + pdf_link.data(field);
      param_count ++;
    }
  }
  pdf_link.attr('href', href);

  var params_obj = unparam(source.data('url'));

  params_obj.obj['sort_symbol'] = th.data('sort-symbol');
  params_obj.obj['sort_direction'] = sort_direction;
  params_obj.obj['table_id'] = table_id;
  params_obj.obj['table_title'] = table.parents('.results').find('a#results_toggle').text();

  source.find('.progress').show();
  if (table.parents('*[data-sort-with-page]').data('sort-with-page')) {
    table = table.parents('.results');
  }

  table.load(params_obj.base_url + '?' + $.param(params_obj.obj), function() {
    source.find('.progress').hide();
    if (should_unwrap) {
      $(this).children(':first').unwrap();
    }

    try{
      //Because the whole table is reloaded, reset the focus and make sure the new headers are keyboard accessible
      var current_focus_text = current_focus.text();
      $(this).find('th').each(function( ) {
        $(this).on('keypress', function(e){
          x508.keyboard_clickable(e);
        }).attr('tabindex', '0');

        if($(this).text() == current_focus_text){
          var el = $(this);
          el.trigger('focus');
        }// fix focus.
      });
    }catch(e){
      console.log(e); //don't crash.
    }
  });
});

//For Section 508 call the click event on an 'Enter' keypress when table header is highlighted.
$(document).on('keypress', 'div.collapsible_data thead th', function(event) {
  x508.keyboard_clickable(event);
});

$(document).on('click', 'form#new_rx_search_request input:checkbox[id^=rx_search_request_search_states_], form#new_rx_search_request input:radio[id^=rx_search_request_search_type_]', function() {
  var formData = $('#new_rx_search_request').serialize();
  $.ajax({
    type: 'GET',
    url: '/rx_search_requests/required_fields',
    cache: false,
    data: formData
  }).done(function(data, status, xhr) {
    var configurable_fields = ['rx_search_request_city', 'rx_search_request_state', 'rx_search_request_postal_code',
      'rx_search_request_ssn', 'rx_search_request_phone_number', 'rx_search_request_dln'];
    $.each(configurable_fields, function(index,value) {
      var selector = '#' + value;
      if($.inArray(value, data) >= 0) {
        if(!$(selector).parent().hasClass('required')) {
          $(selector).parent().addClass('required');
        }
      } else {
        if($(selector).parent().hasClass('required')) {
          $(selector).parent().removeClass('required');
        }
      }
    });
  });
});

var RxSearchRequests = (function ($) {
  var partial_name_checkbox = 'input.partial_search_name[type="checkbox"]';
  var dob_range_select = '.disable_dob_range';

  function hideInterconnectSearch() {
    if (($(partial_name_checkbox + ':checked').length > 0) ||
        ($(dob_range_select).val() > 0)
    ) {
      $('#interconnect_search_block').hide();
      $('#partial-search-interconnect-message').show();
    } else {
      $('#interconnect_search_block').show();
      $('#partial-search-interconnect-message').hide();
    }
  }

  function disableDobRange(){
    var dobRange = $(dob_range_select);

    if ($(partial_name_checkbox + ':checked').length == 2){
      dobRange.val(0);
      dobRange.prop('disabled', true);
    }else{
      dobRange.prop('disabled', false);
    }
  }

  function displayInstructions(){
    var instructionText = $('.partial-search-instructions');
    if ($(partial_name_checkbox + ':checked').length > 0){
      instructionText.show();
    }else{
      instructionText.hide();
    }
  }

  function toggleDobRequired(){
    if ($(partial_name_checkbox + ':checked').length > 0){
      $('.partial-dob-not-required').removeClass('required');
    }else{
      $('.partial-dob-not-required').addClass('required');
    }
  }

  $(function () {
    hideInterconnectSearch();
  });

  $(document).on('change', partial_name_checkbox, function (event) {
    disableDobRange();
    hideInterconnectSearch();
    displayInstructions();
    toggleDobRequired();
    buildRequiredFields();
    setRequiredFieldState($('#rx_search_request_birthdate'), true);
    setRequiredFieldState($('#rx_search_request_first_name'));
    setRequiredFieldState($('#rx_search_request_last_name'));
  });

  $(document).on('change', dob_range_select, function (event) {
    hideInterconnectSearch();
  });

  return {
    hideInterconnectSearch:hideInterconnectSearch
  };
})($);

var HideOrShowSuggestionBox = (function ($) {

  $(document).on('shown.bs.modal', '#multiple_patient_confirmation_modal' , function() {
    disableSuggestionBoxValidation();
  });

  function disableSuggestionBoxValidation() {
    var suggestion_checkbox = 'input#suggestion[type="checkbox"]';

    if ($(suggestion_checkbox + ':checked').length > 0) {
      $('#suggestion_box').attr('data-validate',true);
    } else {
      $('#suggestion_box').attr('data-validate',false);
    }
  }

  $(function () {
    disableSuggestionBoxValidation();
  });
  $(document).on('click', 'input#suggestion[type="checkbox"]', function (event) {
    disableSuggestionBoxValidation();
  });

})($);

var patient_name_checkbox_selector = 'input#patient_name[type="checkbox"]';

$(document).on('change tokenized_search.token_removed', patient_name_checkbox_selector, function (event) {
  var patient_dob_input_field = $('input#patient_dob[type="text"]');
  var patient_dob_datepicker_button = $(patient_dob_input_field).siblings('.calendar-datepicker__toggle');

  if ($(patient_name_checkbox_selector).is(':checked')) {
    patient_dob_input_field.removeAttr('disabled').removeClass('disabled');
    patient_dob_datepicker_button.removeAttr('disabled').removeClass('disabled');
  } else {
    patient_dob_input_field.attr('disabled', true).addClass('disabled');
    patient_dob_datepicker_button.attr('disabled', true).addClass('disabled');
    //Clear out the date of birth control if you disable it so it is not sent.
    patient_dob_input_field.val('');
    //Trigger a change event so the tokenized search field knows it should update the tokens.
    patient_dob_input_field.trigger('change');
  }
});

$(document).on('change', 'select#rx_search_request_peer_reviewer_id', function(event) {
  $(this).attr('required', true);
  $('select#rx_search_request_delegator_id').attr('required', false);
  if ($('select#rx_search_request_delegator_id').length > 0) {
    $('select#rx_search_request_delegator_id option:selected').prop('selected', false);
  }
});

$(document).on('change', 'select#rx_search_request_delegator_id', function(event) {
  $(this).attr('required', true);
  $('select#rx_search_request_peer_reviewer_id').attr('required', false);
  if ($('select#rx_search_request_peer_reviewer_id').length > 0) {
    $('select#rx_search_request_peer_reviewer_id option:selected').prop('selected', false);
  }
});

$(document).on('change', $('#external_requestor_enable_rx_search_request'), function() { buildRequiredFields(); });

$(function() {
  buildRequiredFields();
});

function buildRequiredFields() {
  const fields = getRequiredFields();
  $('#rx_search_request_birthdate').off('blur').mask('99/99/9999', {placeholder: 'MM/DD/YYYY'}); // Reset dob field mask

  fields.each(function() {
    $(this).on('blur', function() { setRequiredFieldState($(this)); });
  });
}

function setRequiredFieldState(selector, initial = false) {
  const fieldLength = selector.val().length;

  if ( // first or last name has length < 3 when 'partial spelling' is checked
    ['rx_search_request_first_name', 'rx_search_request_last_name'].includes( selector.attr('id') ) &&
      fieldLength < 3 &&
      selector.siblings('.partial_checkbox').find('.partial_search_name').eq(0).is(':checked') &&
      !initial
  ) {
    clearFieldError(selector);
    setFieldError(selector, 'Error: Field must be at least 3 characters.');
  } else if (fieldLength == 0 && !initial) {  // blank field
    clearFieldError(selector);
    setFieldError(selector, 'Error: Field is required.');
  } else {  // Clear the error state
    clearFieldError(selector);
  }
}

function getRequiredFields() {
  let requiredFields = $('#new_rx_search_request').find('.required').find('input[type="text"]');

  if ( !$('#rx_search_dob').hasClass('required')){
    requiredFields = requiredFields.filter( $(':not(#rx_search_request_birthdate.dob_date)'));
  }

  if (!$('#external_requestor_enable_rx_search_request').is(':checked')) {
    requiredFields = requiredFields.filter( $(':not(#rx_search_request_external_requestor_attributes_first_name, #rx_search_request_external_requestor_attributes_last_name)'));
  }

  return requiredFields;
}

function clearFieldError(selector) {
  selector.siblings('span[role="alert"]').eq(0).html('');
  selector.removeClass('validation_error');
  selector.attr('aria-invalid', false);
}

function setFieldError(selector, message) {
  selector.siblings('span[role="alert"]').eq(0).html(message);
  selector.siblings('span[role="alert"]').eq(0).addClass('help-block validation_error_message');
  selector.addClass('validation_error');
  selector.attr('aria-invalid', true);
}
