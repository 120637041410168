export const IndeterminateProgress = (function() {
  var size = 'small', visible = 'true', color = '';
  function init (options) {

    var opts = $.extend({
      size:size,
      visible:visible
    }, options);
    size = opts.size;
    visible = opts.visible;
    let white = options.white;
    var display = 'display:inline-block;';
    if (!visible) {
      display = 'display:none;';
    }
    if (white) {
      color = 'white';
    }
    const result = `<i aria-label="Loading results" aria-hidden="true" class="indeterminate_progress ${size} ${color}" style="${display}">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
    <div class="bar4"></div>
    <div class="bar5"></div>
    <div class="bar6"></div>
    <div class="bar7"></div>
    <div class="bar8"></div>
    <div class="bar9"></div>
    <div class="bar10"></div>
    <div class="bar11"></div>
    <div class="bar12"></div>
</i>`;

    return result;
  }

  return {
    init:init
  };
})();

window.IndeterminateProgress = IndeterminateProgress;
