import redPingSrc from '../../../../app/javascript/images/ping/red.png';
import greenPingSrc from '../../../../app/javascript/images/ping/green.png';

$(document).on('click', 'td.status_light', function (event) {
  var clicked = $(this),
    ping_url = clicked.data('ping-url'),
    pmp_name = clicked.data('pmp-name');

  clicked.addClass('pinging');

  $.ajax(ping_url, {
    success: function(data, status, xhr) {
      clicked.removeClass('pinging');
      clicked.attr('title', jQuery.timeago(new Date()));
      clicked.find('img.status_icon').attr('src', greenPingSrc);
      var html = xhr.responseText,
        modal = $('div#ping_status_modal');
      modal.children('.modal-body').html(html);
      modal.find('#ping_status_modal_label').html(pmp_name + '\'s Ping Status');
      modal.modal({show:true});
    },
    error: function(xhr, status, error) {
      clicked.removeClass('pinging');
      clicked.attr('title', 'Could not ping.');
      clicked.find('img.status_icon').attr('src', redPingSrc);
      setTimeout(function() {
        alert('Could not ping \'' + pmp_name + '\'. It may be down or not responding to connections.');
      }, 10);

    }
  });
});