const toggle_form_fields = function(show, hide) {
  $(show).removeClass('hidden');
  $(show).find('input, select').attr('readonly', false);
  $(show).find('.toggle_required').attr('required', true);
  $(hide).addClass('hidden');
  $(hide).find('input, select').attr('readonly', true);
  $(hide).find('input, checkbox').prop('checked', false);
  $(hide).find('input, select').not('input:submit').attr('value', '');
  return $(hide).find('.toggle_required').attr('required', false);
};

$(document).on('click', 'input[data-show]', function() {
  toggle_form_fields($(this).data('show'), $(this).data('hide'));
});
