import '../jquery/jquery.timeago';
import { Flash } from '../app/flash';

var PmpPreferences = (function() {
  function add_popover(input, text) {
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var selector = '';

    for (var i = 0; i < 30; i++) {
      selector += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    let inputId = input.attr('id');
    inputId = $(document.createTextNode(inputId)).text();
    var html = '<div class=\'popover_error2 row align-items-start\'><span class="col-12 col-md-9">' + text + '</span><div class="col-12 col-md-3"><button id="' + inputId + '_close" class="btn btn-link close p-2" role="button" type="button">x</button></div></div>';

    input.popover({
      html:true,
      content:html,
      selector: selector,
      placement:'bottom',
      template:
            '<div class="popover">' +
              '<div class="arrow"></div>' +
                '<div class="popover-inner">' +
                  '<div class="popover-body">' +
        '<p></p>' +
                  '</div>' +
                '</div>' +
            '</div>'
    });

    $(input).on('shown.bs.popover', function () {
      let theCloseButton = $('#' + inputId + '_close');
      $(theCloseButton).on('click keypress', function () {
        $(input).popover('hide');
      });
    });

    $(input).on('hide.bs.popover', function () {
      let theCloseButton = $('#' + inputId + '_close');
      $(theCloseButton).off('click keypress');
    });
  }

  function update_pmp_id() {
    var input = $('input[data-id="pmp_id"]');

    var spinner = input.siblings('.indeterminate_progress');

    add_popover(input, 'Could not find your PMP ID');

    spinner.css({display:'inline-block'});
    $.ajax({type:'POST',
      data: {pmp_id: input.val() },
      url: $(this).data('url')
    }).done(function() {
      input.popover('hide');
    }).always(function() {
      spinner.hide();
    }).fail(function(){
      input.popover('show');
    });
  }

  function refresh_auth_cache() {
    var input = $(this);
    var timestamp = input.parent().siblings('.freshness').children('.timestamp');
    input.attr('disabled', '');

    add_popover(input, 'There was an error refreshing the cache');
    $.ajax({type:'POST', url:input.data('url')}).done(function(data, textStatus, jqXHR) {
      timestamp.replaceWith(data);
      $('abbr.timeago').timeago();
      input.popover('hide');
    }).always(function(){
      input.removeAttr('disabled');
    }).fail(function() {
      input.popover('show');
    });
  }

  function ping_hub() {
    var input = $(this);
    var timestamp = input.parent().siblings('.timestamp');
    input.attr('disabled', '');
    add_popover(input, 'There was an error pinging the hub');
    $.ajax({type:'POST', url:input.data('url')}).done(function(data, textStatus, jqXHR){
      timestamp.siblings('.ping').text(data.ping_time/1000.0 + 'ms');
      timestamp.replaceWith(data.timestamp);
      $('abbr.timeago').timeago();
      timestamp.timeago();
      input.popover('hide');
    }).always(function(){
      input.removeAttr('disabled');
    }).fail(function() {
      input.popover('show');
    });
  }

  function save_credentials() {
    var input = $(this);
    var pmpi_username = $('#pmpi_username').val();
    var pmpi_password = $('#pmpi_password').val();

    add_popover($('#pmpi_username'), 'Username cannot be blank');
    add_popover($('#pmpi_password'), 'Password cannot be blank');

    if(!pmpi_username.length) {
      $('#pmpi_username').popover('show');
      return;
    }

    if(!pmpi_password.length) {
      $('#pmpi_password').popover('show');
      return;
    }

    $('#pmpi_username').popover('hide');
    $('#pmpi_password').popover('hide');

    $.ajax({type:'POST',
      data: {
        username:pmpi_username,
        password:pmpi_password
      },
      url:input.data('url')
    }).done(function() {
      Flash.notice('Your credentials have been successfully saved');
    }).always(function() {

    }).fail(function() {

    });
  }

  function gen_keypair() {
    var input=$(this);
    $.ajax({type:'POST', url:input.data('url')}).done(function(data, textStatus, jqXHR) {
      $('#public_key_console').val(data.public_key);
      $('#private_key_console').val(data.private_key);
      input.popover('hide');
    }).fail(function( jqXHR, textStatus, errorThrown ) {
      add_popover(input, errorThrown + ':<br>' + jqXHR.responseText );
      input.popover('show');
    });
  }

  function dirty_credentials() {
    $('#save_credentials').removeAttr('disabled');
  }

  function clean_credentials() {
    $('#save_credentials').attr('disabled','');
  }

  return {
    update_pmp_id:update_pmp_id,
    refresh_auth_cache:refresh_auth_cache,
    ping_hub:ping_hub,
    save_credentials:save_credentials,
    dirty_credentials:dirty_credentials,
    clean_credentials:clean_credentials,
    gen_keypair:gen_keypair
  };
})();


$(document).on('click', '#update_pmp_id', PmpPreferences.update_pmp_id);
$(document).on('click', '#refresh_cache', PmpPreferences.refresh_auth_cache);
$(document).on('click', '#ping_hub', PmpPreferences.ping_hub);
$(document).on('click', '#save_credentials', PmpPreferences.save_credentials);
$(document).on('click', '#gen_keypair', PmpPreferences.gen_keypair);

$(function() {
  $('abbr.timeago').timeago();
});
