(function () {
  function clear_checkbox(event) {
    $('input[data-agree-every-time]').each(function () {
      var element = $(this);
      if (element.data('agree-every-time') === true) {
        element.attr('checked', false);
      }
    });
  }

  $(document).on('click', function (event) {
    var clicked_node = $(event.target), affected_node = $(clicked_node.data('show-node-when-checked'));
    if (!clicked_node.get(0).tagName.toLowerCase() === 'input'){
      return;
    }
    if (affected_node.length > 0) {
      if (clicked_node.is(':checked')) {
        affected_node.slideDown();
      } else {
        affected_node.slideUp();
      }

    }
  });

  if(window.onpageshow || window.onpageshow === null){
    window.addEventListener('pageshow', clear_checkbox, false);
  } else {
    window.attachEvent('onload', clear_checkbox);
  }
})();

$(function () {
  $('form').on('submit', function (event) {
    var target = $(event.target);
    var protector = target.find('input[data-agreement-required-for]');
    var selector = protector.data('agreement-required-for');
    var protected_forms = $(selector);
    if (protector.length > 0) {
      if (protected_forms.has(event.target)) {
        if (!protector.is(':checked')) {
          alert('You must agree before you submit.');
          event.preventDefault();
          return false;
        } 
      }
    }
  });
});
