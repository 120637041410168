
export const activate_user = function(id) {
  $('#address_card_toolbar').addClass('activated');
};

export const fail_to_activate_user = function (errors) {
  var content = '';
  $.each(errors, function (key, value) {
    content = content + value + ' ';
  });
  var html = '<div class=\'popover_error\'>' + content + '</div>';
  $('#activate_user_button').popover({
    // html:"OH HAI",
    placement: 'top',
    trigger: 'manual',
    title: 'Approval Failed',
    content: html,
    html: true
  }).popover('show');
  // alert("The user '"+user.first_name+"' could not be approved.");
};

export const deactivate_user = function (id) {
  $('#address_card_toolbar').addClass('deactivated');
};

window.activate_user = activate_user;
window.deactivate_user = deactivate_user;
window.fail_to_activate_user = fail_to_activate_user;
