var TableRowDisclosure = (function() {
  var node;
  function init (node) {
    node = $(node);
    node.trigger('click', toggle);

  }
  function toggle (event) {
    var node = $(event.target);
    var target_node = $('#' + node.attr('for'));
    if (node.hasClass('closed')) {
      DisclosureTriangleAnimation.open(node);
    } else {
      DisclosureTriangleAnimation.close(node);
    }
    target_node.toggle();
    return false;
  }
  return {
    initialize:init
  };
})();

$(function(event) {
  $('*[data-row-toggle]').each(function() {
    TableRowDisclosure.initialize(this);
  });
});

$(document).on('click', 'table.collapsable_rows tr', function(event) {
  $('table.collapsable_rows tr').removeClass('selected');
  $(this).addClass('selected');
  var check = $(this).find('input');
  check.attr('checked', true);
  $('.confirm_patient').removeAttr('disabled');
});