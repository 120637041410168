(function ($) {

  function find_checkbox(type) {
    var selector = '#clinical_alert_config_settings_patient_alerts_notification';
    if (type.match('email')) {
      selector = '#clinical_alert_config_settings_email_notification';
    }
    if (type.match('pmp')) {
      selector = '#clinical_alert_config_settings_no_notification';
    }
    return $(selector);
  }

  function count_the_days(form) {
    var selected_button_value = form.find('input[type=radio]:checked').val(),
      result = 0;

    if (selected_button_value !== 'custom') {
      result = parseInt(selected_button_value);
    } else {
      result = parseInt(form.find('input#clinical_alert_config_settings_custom_threshold_days').val());
    }
    return isNaN(result) ? 0 : result;
  }
  function threshold_met(num, days) {
    //Entering a Prescriber or Dispenser value of less than 3 within 30 days, less than 6 within 60 days, less than 9 within 90 days, and less than 18 within 180 days
    if (num * 10 <= days) {
      return true;
    } else {
      return false;
    }

  }
  function threshold_label(days) {
    return Math.round(days / 10);
  }
  function check_false_positive_rate_for_prescriber_dispenser_form(event) {
    var form = $(this),
      prescriber_count = form.find('#clinical_alert_config_settings_prescriber_count').val(),
      pharmacy_count = form.find('#clinical_alert_config_settings_pharmacy_count').val(),
      threshold_delta = Math.min(prescriber_count, pharmacy_count),
      days_count = count_the_days(form),
      onerous_nag = form.find('.onerous_nag');

    if (threshold_delta === 0) {
      threshold_delta = Math.max(prescriber_count, pharmacy_count);
    }

    onerous_nag.find('.threshold_number').html(threshold_label(days_count));
    onerous_nag.find('.threshold_days').html(days_count);

    onerous_nag.toggle(threshold_met(threshold_delta, days_count));
  }

  function check_false_positive_rate_for_mme_form(event) {
    var form = $(this),
      mme_count = parseInt(form.find('#clinical_alert_config_settings_daily_active_mme_threshold').val()),
      onerous_nag = form.find('.onerous_nag');

    onerous_nag.toggle(mme_count < 60);
  }
  $(document).on('change', 'form#prescriber_dispenser_alert_config',
    check_false_positive_rate_for_prescriber_dispenser_form);

  $(document).on('change', 'form#daily_active_mme_config',
    check_false_positive_rate_for_mme_form);

  $(function () {
    $('form#prescriber_dispenser_alert_config').trigger('change');
    $('form#daily_active_mme_config').trigger('change');
    hook_clinical_alerts();
  });
  function patient_alerts() {
    return find_checkbox('patient').attr('checked');
  }
  function email_notification() {
    return find_checkbox('email').attr('checked');
  }
  function pmp_report_only() {
    return find_checkbox('pmp').attr('checked');
  }
  function sync_email_checkbox_state(event) {
    // console.log('syncing');
    var patient_checkbox = find_checkbox('patient'),
      email_checkbox = find_checkbox('email'),
      email_label = email_checkbox.parents('label');
    if (patient_checkbox.is(':checked')) {
      email_checkbox.prop('disabled', false);
      email_label.removeClass('disabled');
    } else {
      email_checkbox.prop('disabled', true);
      email_checkbox.prop('checked', false);
      email_label.addClass('disabled');
    }
  }
  function hook_clinical_alerts() {
    find_checkbox('patient').on('change', sync_email_checkbox_state);
    sync_email_checkbox_state();
  }
})(jQuery);
