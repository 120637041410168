import { IndeterminateProgress } from '../app/indeterminate_progress';

var RemoteIdentifierValidator = (function ($) {
  function instantiate(node) {
    var myself = new Object();
    myself.server_responded = function (data, textStatus, jqXHR) {
      var result = JSON.parse(data);
      switch (result['status']) {
      case 'valid':
        myself.update_status('check', result['message']);
        break;
      case 'invalid':
        myself.update_status('x', result['message']);
        break;
      case 'conditionally_valid':
        myself.update_status('maybe-check', result['message']);
        break;
      case 'last_name_mismatch':
        myself.update_status('maybe-check', result['message']);
        break;
      case 'expired':
        myself.update_status('clock', result['message']);
        break;
      case 'inactive':
        myself.update_status('clock', result['message']);
        break;
      case 'conditionally_active':
        myself.update_status('dash-circle', result['message']);
        break;
      case 'conditionally_inactive':
        myself.update_status('triangle-exclamation', result['message']);
        break;
      case 'not_in_dominus':
        myself.update_status('question', result['message']);
        break;
      default:
        myself.update_status('unknown', 'Status cannot be determined at this time.');
        break;
      }

    };
    myself.server_utoh = function (jqXHR, textStatus, error) {
      myself.update_status('unknown', 'Status cannot be determined at this time.');
    };
    myself.update_status = function (_status, message) {
      var status_node = myself.node.parent().find('.indeterminate_progress');
      status_node.html('');
      status_node.addClass('icon-farm-' + _status);
      status_node.removeClass('indeterminate_progress');
      status_node.removeClass('small');
      status_node.removeClass('large');
      status_node.removeAttr('aria-hidden');
      status_node.attr('title', message);
      status_node.attr('aria-label', message);
      status_node.attr('tabindex', '0');
    };

    myself.setup = function () {
      if (node.data('remote-identifier-validating')) {
        return;
      }
      myself.node = node;
      myself.node.wrap('<div/>');
      var container = myself.node.parent();
      container.prepend(IndeterminateProgress.init({white:false}));
      myself.node.css({
        'padding-left':'3px'
      });
      container.css({
        'margin-left':'2px'
      });

      myself.url = myself.node.data('remote-identifier-url');

      myself.node.data('remote-identifier-validating', true);
    };

    myself.validate = function () {
      if (myself.url) {
        var promise = $.ajax(myself.url);

        promise.done(myself.server_responded);
        promise.fail(myself.server_utoh);
      }
    };

    myself.setup();
    return myself;
  }


  function scan_for_unverified_nodes(event) {
    $('[data-remote-identifier-url]').each(function () {
      var node = $(this),
        instance = instantiate(node);
      instance.validate();

    });
  }

  $(function() {
    scan_for_unverified_nodes();
  });

  $(document).on('ajaxComplete', scan_for_unverified_nodes);

  return {
    scan_for_unverified_nodes:scan_for_unverified_nodes,
    create:instantiate
  };

})(jQuery);
