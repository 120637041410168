$(function(){
  var page_count = $('#user_training').data('page-count');
  var state_value = $('#user_training').data('state-value');
  var current_page = 1;

  if (page_count > 0) {
    if (state_value == 'NM') { $('#previous_user_training_page').remove(); }

    if ($('#user_training').length > 0) {
      $.get('/user_trainings/1', function(e){
        $('#user_training').html(e);
        updatePageNumber(1);
      }).fail(function(){
        $('#user_training').html('<p class="alert alert-danger">Something went wrong!</p>');
      }).always(function(){
        loadImagesFromAWS();
        checkInputsValue();
      });
    }

    $('#user_training_row > #next_user_training_page').on('click', function() {
      showLoadingGif();
      disableButtons();
      current_page += 1;
      if (current_page >= page_count) {
        current_page = page_count;
        $('#next_user_training_page').addClass('d-none');
        $('#complete_training').removeClass('d-none');
      }

      if (current_page > 1) {
        $('#previous_user_training_page').removeClass('d-none');
      }

      loadPageFromAWS(current_page);
    });

    $('#user_training_row > #previous_user_training_page').on('click', function() {
      showLoadingGif();
      disableButtons();
      current_page -= 1;
      if (current_page <= 1) {
        current_page = 1;
        $('#previous_user_training_page').addClass('d-none');
      }

      if (current_page < page_count) {
        $('#next_user_training_page').removeClass('d-none');
        $('#complete_training').addClass('d-none');
      }

      loadPageFromAWS(current_page);
    });
  }

  var showLoadingGif = function() {
    $('#user_training').html('');
    $('.loading-overlay').show();
  };

  var hideLoadingGif = function() {
    $('.loading-overlay').hide();
  };

  var disableButtons = function() {
    $('#next_user_training_page').attr('disabled', true);
    $('#previous_user_training_page').attr('disabled', true);
  };

  var enableButtons = function() {
    $('#next_user_training_page').attr('disabled', false);
    $('#previous_user_training_page').attr('disabled', false);
  };

  var updatePageNumber = function(value) {
    $('#user_training_page_number').text(value);
  };

  var loadPageFromAWS = function(page_number) {
    $.get('/user_trainings/' + page_number, function(e){
      $('#user_training').html(e);
      updatePageNumber(page_number);
    }).fail(function(e){
      current_page -= 1;
      $('#user_training').html('<p class=\'alert alert-danger\'>' + e.statusText + '</p>');
    }).always(function(){
      enableButtons();
      hideLoadingGif();
      loadImagesFromAWS();
      checkInputsValue();
    });
  };

  var loadImagesFromAWS = function() {
    if ($('#user_training').find('.aware-s3-image').length > 0) {
      $('#user_training').find('.aware-s3-image').each(function(){
        var aware_s3_image = $(this);

        $.get('/user_trainings/presentation_image?image_name=' + aware_s3_image.data('s3-src'), function(params){
          var newImage = new Image();
          newImage.src = params['image_path'];
          newImage.classList.add('col-12');
          aware_s3_image.html(newImage);
        });
      });
    }
  };

  var checkInputsValue = function() {
    if ($('.quiz-answer').length > 0) {
      disableButtons();

      $('.quiz-answer input[type="radio"]').on('change', function(){
        if (this.value == 'true') {
          createNotificationFromItem('#success_answer');
        } else if (this.value == 'false') {
          createNotificationFromItem('#wrong_answer');
        }

        if ($('.quiz-answer input:checked[type="radio"][value="false"]').length > 0) {
          disableButtons();
        } else if ($('.quiz-answer input[type="radio"][value="true"]').length == $('.quiz-answer input:checked[type="radio"][value="true"]').length) {
          enableButtons();
        }
      });
    }
  };

  var createNotificationFromItem = function(item_name) {
    var answer_component = $(item_name).clone();
    $(answer_component).removeClass('hidden');
    $('#notification_alerts').append(answer_component);
    setTimeout(function(){
      $(answer_component).remove();
    }, 5000);
  };
});
