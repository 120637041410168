$(function() {
  $('#case_officer_create_form, #case_management_note_create_form, #case_management_note_edit_form, #suspect_alias_name_create_form').on('submit', function(e) {
    $(this).find(':submit').attr('disabled', true);
    return $(this).find('.case-loading').removeClass('hidden');
  });

  $('#case_management_note_create_form').on('ajax:success', function(e, data, status, xhr) {
    $(this).find(':submit').attr('disabled', false);
    $(this).find('.case-loading').addClass('hidden');
    $('.case-comments-list').prepend(xhr.responseText);
    $('#no_case_comment_label').addClass('hidden');
    $('#case_comments_modal').modal('hide');
    $('#note_description').val('');
    return $('#note_character_count').text('2500');
  });

  $('#case_management_note_edit_form').on('ajax:success', function(e, data, status, xhr) {
    $(this).find(':submit').attr('disabled', false);
    $(this).find('.case-loading').addClass('hidden');
    $('#case_comments_edition').modal('hide');
    const div_class = `#case_comment_${data['case_report_note_id']}`;
    return $(div_class).find('.case-note-description').text(data['case_note']);
  });

  $(document).on('click', '.edit-case-comment', function(e) {
    e.preventDefault();
    const comment_id = $(this).data('commentId');
    const report_id = $(this).data('reportId');
    const edition_path = `/case_managements/${report_id}/notes/${comment_id}`;
    const description = $(this).closest('.case-comment-block').find('.case-note-description').text();
    $('#case_management_note_edit_form').attr('action', edition_path);
    $('#note_edition_character_count').text(2500 - description.length);
    $('#edition_note_textarea').val(description);
    return $('#case_comments_edition').modal('show');
  });

  $('#suspect_alias_name_create_form').on('ajax:success', function(e, data, status, xhr) {
    $(this).find(':submit').attr('disabled', false);
    $(this).find('.case-loading').addClass('hidden');
    !(xhr.responseText.includes('$(\'.flash\')')) && $('.suspect-alias-name-list').append(xhr.responseText);
    $('#no_alias_name_label').addClass('hidden');
    $('#alias_name_modal').modal('hide');
    $('#suspect_alias_first_name').val('');
    $('#suspect_alias_last_name').val('');
    return $('#suspect_alias_birthdate').val('');
  });

  $(document).on('ajax:success', '.destroy-alias-name', function(e, data, status, xhr) {
    const case_officer_div = $(document).find(`#case_alias_name_${data['alias_name_id']}`);
    $(case_officer_div).remove();
    const size = $('.suspect-alias-name-list').children().length;
    if (size === 0) { return $('#no_alias_name_label').removeClass('hidden'); }
  });

  $('#case_officer_create_form').on('ajax:success', function(e, data, status, xhr) {
    $(this).find(':submit').attr('disabled', false);
    $(this).find('.case-loading').addClass('hidden');
    $('.case-officers-list').append(xhr.responseText);
    $('#no_case_officer_label').addClass('hidden');
    $('#officer_modal').modal('hide');
    $('#officer_other_case_number').val('');
    return $('#officer_modal_btn').hide();
  });

  return $(document).on('ajax:success', '.destroy-case-officer', function(e, data, status, xhr) {
    const case_officer_div = $(document).find(`#case_officer_${data['officer_id']}`);
    $(case_officer_div).remove();
    const size = $('.case-officers-list').children().length;
    if (size === 0) { $('#no_case_officer_label').removeClass('hidden'); }
    if (data['available_btn'] === true) { return $(document).find('#officer_modal_btn').show(); }
  });
});
  