var SingleClickButton = (function($) {
  function setup() {
    $(document).on('click', '.disable-me-after-click-js', function(event) {
      $(this).attr('disabled', true);
    });
  }
  return {
    setup: setup
  };
})(jQuery);
SingleClickButton.setup();
