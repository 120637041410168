import 'jquery-ujs/src/rails';
import './jquery.flot.min';
import './jquery.flot.categories.min';
import './jquery.flot.stack';
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/plugin';
import 'jquery-ui/ui/disable-selection';
import 'jquery-ui/ui/data';
import 'jquery-ui/ui/effect';
import 'jquery-ui/ui/scroll-parent';
import 'jquery-ui/ui/safe-active-element';
import 'jquery-ui/ui/safe-blur';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/tooltip';
import './jquerybridge';
import 'jquery-typeahead/dist/jquery.typeahead.min';
