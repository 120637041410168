'use strict';
export const EJS = (function($) {
  function EJSView(locals) {
    this.locals = locals;
    this.buffer = [];
  }
  EJSView.prototype.render = function(options) {
    var collection = options['collection'],
      ejs_template = EJSTemplate.named(options['partial']);
    if (typeof(collection) !== 'undefined') {
      for (var i = 0; i < collection.length; i++) {
        this.to_buffer(ejs_template.render(collection[i]));
      }
      return this.result();
    } else {
      return ejs_template.render(options['locals']);
    }

  };
  EJSView.prototype.to_buffer = function (data) {
    this.buffer.push(data);
  };
  EJSView.prototype.result = function () {
    return this.buffer.join('');
  };
  function EJSTemplate(template_name) {
    this.compiled_template = '';
    this.name = template_name;
    EJSTemplate.templates[template_name] = this;
  }
  EJSTemplate.templates = {};
  EJSTemplate.cache_embedded_templates = function () {
    var script_tags = document.getElementsByTagName('script');
    for (var i = 0; i < script_tags.length; i++) {
      var item = script_tags[i];
      if (item.type === 'text/ejs') {
        var t = new EJSTemplate(item.id);
        t.compile(item.innerHTML);
      }
    }
  };
  EJSTemplate.named = function (the_name) {
    return EJSTemplate.templates[the_name];
  },
  EJSTemplate.prototype = {
    add_to_buffer: function (statement, raw, open_ended) {
      if (raw === true) {
        this.compiled_template += '_ejsout = _ejsout.concat(' + statement;
        if (open_ended !== true) {
          this.compiled_template += ');';
        }
      } else {
        this.compiled_template += '_ejsout = _ejsout.concat(\'' + statement;
        if (open_ended !== true) {
          this.compiled_template += '\');';
        }
      }
      return '';
    },
    escape_fragment: function (fragment) {
      var escapes = {
        '\'': '\'',
        '\\': '\\',
        '\r': 'r',
        '\n': 'n',
        '\u2028': 'u2028',
        '\u2029': 'u2029'
      };
      return fragment.replace(/\\|'|\r|\n|\u2028|\u2029/g, function (match) {
        return '\\' + escapes[match];
      });
    },
    compile: function (template_body) {
      var template = this,
        string_position = 0,
        function_stack_index = 0;
      this.compiled_template = 'var _ejsout = new String();';
      template_body.replace(/<%([\s\S]+?)%>/g, function (match, evaluate, offset, string) {
        var before = template.escape_fragment(string.slice(string_position, offset)),
          trimmed = evaluate.trim();
        string_position = offset + match.length;
        template.add_to_buffer(before);
        if (evaluate) {
          //check for a comment
          if (evaluate.slice(0,2) === '//') {
            return;
          }
          //check for interpolation
          if (evaluate[0] === '=') {
            if (trimmed.slice(trimmed.length-1, trimmed.length) === '{') {
              template.add_to_buffer(evaluate.slice(1, evaluate.length), true, true);
              template.compiled_template += 'var _ejsout = "";';
              function_stack_index++;
            } else {
              template.add_to_buffer(evaluate.slice(1, evaluate.length), true, false);
            }

          } else {
            //this is a plain javascript statement that should be executed
            if (trimmed.slice(trimmed.length-2, trimmed.length) === '})') {
              template.compiled_template += trimmed.slice(0, trimmed.length -2);
              template.compiled_template += 'return _ejsout;})';
              function_stack_index--;
              if (function_stack_index === 0) {
                template.compiled_template += ');';
              }
            } else {
              template.compiled_template += evaluate;
            }

          }

        }
      });
      var after = this.escape_fragment(template_body.slice(string_position, template_body.length));
      this.add_to_buffer(after);
      this.compiled_template += 'return _ejsout;';
    },
    pp_compiled_template: function () {
      console.log(this.compiled_template.replace(/;/g, ';\n'));
    },
    render_collection: function (data) {
      var html = '';
      var items = data['collection'];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        item['org_admin'] = data['org_admin'];
        html = html + this.render(item);
      }
      return html;
    },
    render: function (locals) {
      var args = [], values = [], local= null;
      for (local in locals) {
        if (locals.hasOwnProperty(local)) {
          args.push(local);
          values.push(locals[local]);
        }
      }
      var ejs_view = new EJSView(locals),
        compiled_template = this.compiled_template,
        result = '';

      var wrapper = function () {
        var funct = Function(args, compiled_template);
        result = funct.apply(ejs_view, values);
      };
      wrapper();
      return result;
    }
  };

  function setup() {
    EJSTemplate.cache_embedded_templates();
  }
  return {
    View: EJSView,
    Template: EJSTemplate,
    setup: setup
  };
}(jQuery));

$(function() {
  EJS.setup();
});