$(function (event) {
  $('*[data-disable-related-field-if-unchecked]').trigger('sync_related_checkboxes');
});
$(document).on('change sync_related_checkboxes', 'input[type=checkbox]', function (event) {
  var target = $(event.target), related_field = null;
  if (related_field = $(target.data('disable-related-field-if-unchecked'))) {
    if(!target.is(':checked')){
      related_field.removeAttr('checked');
    }
    related_field.prop('disabled', !target.is(':checked'));
       
  }
 
});

$(document).on('input', 'textarea#request_note_note', function(){
  if(this.value.length > 1000) {
    return false;
  }
  $('#request_note_note_left').html(String(1000 - this.value.length) + ' characters left');
});

$(document).on('change', '[data-answer-tag]', function(event) {
  var target = $(event.target);
  $('[name="'+target.data('answer-tag')+'"]').val('');
});

$(document).on('click', 'input[name="sms_notification"][type="checkbox"]', toggle_mobile_number_field);

function toggle_mobile_number_field() {
  if ($('input[name="sms_notification"][type="checkbox"]').is(':checked')) {
    $('div.notification_mobile_number').slideDown('blind');
  } else {
    $('div.notification_mobile_number').slideUp('blind');
  }
}
