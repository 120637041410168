import { IndeterminateProgress } from '../app/indeterminate_progress';

var Popover = (function ($) {
  function RemotePopover(event) {
    var node = $(event.target);
    this.is_open = true;
    this.action_node = node;
    this.disposition = this.action_node.data('disposition');
    if (this.disposition === undefined) {
      this.disposition = 'bottom';
    }
    node.data('popover', this);
    $(document).on('popovers:should_close', this.should_close_event.bind(this));
    $(this.action_node).on('click', this.toggle.bind(this));
    this.initialize_content();
    $('body').append(this.node);
    this.open(event);
    this.update_remote_content();
    event.stopPropagation();
    event.preventDefault();
  }

  RemotePopover.prototype.reposition_content = function () {
    var action_node_position = this.action_node.offset(),
      y_delta = this.action_node.height() + 10,
      x_delta = ((this.node.width() / 2) * -1) + (this.action_node.width() / 2),
      x = action_node_position.left + x_delta,
      y = action_node_position.top + y_delta;

    if (x < 5) x = 5;
    
    this.node.css({
      left: x + 'px',
      top: y + 'px'
    });
  };

  RemotePopover.prototype.toggle = function (event) {
    if (this.is_open) {
      this.close();
    } else {
      this.open(event);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  RemotePopover.prototype.open = function (event) {
    this.action_node.trigger('popovers:should_close');
    this.node.show();
    this.is_open = true;
  };

  RemotePopover.prototype.close = function () {
    this.node.hide();
    this.is_open = false;
  };

  RemotePopover.prototype.should_close_event = function (event) {
    this.close();
  };

  RemotePopover.prototype.initialize_content = function () {
    var spinner = IndeterminateProgress.init({white: false, size: 'large'});
    this.node = $('<div class="popover-container hidden ' + this.disposition + '"><div class="tail"></div><div class="p-content">' + spinner + '</div></div>');
  };

  RemotePopover.prototype.update_remote_content = function () {
    var url = this.action_node.data('popover-url'),
      complete_event = function(xhr, status) {
        var html = xhr.responseText;
        this.node.find('.p-content').html(html);
        this.reposition_content();
      }.bind(this);

    $.ajax(url, {
      complete: complete_event
    });
  };

  function setup() {
    var doc = $(document);
    doc.on('click', '*[data-popover-url]', function (event) {
      var node = $(event.target);
      if (node.data('popover') === undefined) {
        new RemotePopover(event);
      }
    });
    doc.on('click', function () {
      doc.trigger('popovers:should_close');
    });
  }

  return {
    setup: setup
  };
})(jQuery);

Popover.setup();
