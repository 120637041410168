$(function(){
  var checkboxes = $('.end-user-agreement input[type=checkbox]');
  checkboxes.on('change', function(){
    var acceptButton = $('#accept');
    if(checkboxes.length == $('input[type=checkbox]:checked').length){
      acceptButton.attr('disabled', false);
    }else{
      acceptButton.attr('disabled', true);
    }
  });
});
