import { Application } from '../application';
import { HTMLTemplate } from '../app/html_template';

var LiveSearchRows = (function ($) {
  function handle_sort_change(event) {
    var target = $(event.target),
      input = $('input[name=sort]'),
      sort_parameter = target.data('sort-table');
    input.val(sort_parameter);
    input.parent().trigger('change');
    if(sort_parameter.charAt(0) == '-') {
      // change sorting to ascending by removing '-' => 'patient.first_name'
      target.data(sort_parameter.substring(1, sort_parameter.length));
    } else {
      // change sorting to descending by adding '-' => '-patient.first_name'
      target.data('sort-table', '-' + sort_parameter);
    }
  }

  function setup(selector) {
    HTMLTemplate.when_cached(function () {
      $(document).on('click', 'th[data-sort-table]', handle_sort_change);
      $(document).on('ajaxComplete', function (evt, xhr, status) {
        var urls_with_live_search = [
          '/communications/messages/search',
          '/communications/care_notes/search',
          '/admin/communications/messages_and_care_notes/search'
        ];
        if (urls_with_live_search.includes(status.url)) {
          let json = JSON.parse(xhr.responseText);
          let template = HTMLTemplate.instantiate('results_table');
          template.render(json['results']);
          $(document).trigger('pagination:update_metrics', json['pagination_metrics']);
        }

      });
      $('form[data-auto-trigger=true]').trigger('submit');
    });
  }
  return {
    setup: setup
  };
})(jQuery);

$(function () {
  if (Application.current({ controller: 'messages', action: 'index' })) {
    LiveSearchRows.setup('#messages_search_results');
  }
  if (Application.current({ controller: 'care_notes', action: 'index' })) {
    LiveSearchRows.setup('#messages_search_results');
  }
  if (Application.current({ controller: 'messages_and_care_notes', action: 'index' })) {
    LiveSearchRows.setup('#messages_search_results');
  }
});
