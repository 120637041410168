import { XssSanitizer } from './xss_sanitizer';

var PharmacyAnalysisTable = (function () {
  function instantiate(table_node) {
    var me = new Object();
    me.selector = $(table_node);
    me.options = {

    };

    me.unhighlight_row = function (event) {
      me.selector.find('tr').removeClass('highlight');
    };
    me.highlight_row = function (event, row_id) {
      me.selector.find('tr').removeClass('highlight');
      if ($('#highlight_row').is(':checked')) {
        var row = me.selector.find('tr[data-day='+row_id+']');
        row.addClass('highlight');
        row.scrollintoview();
      }

    };

    $(document).on('plot_point:highlighted', me.highlight_row);
    $(document).on('plot_point:unhighlighted', me.unhighlight_row);

    me.setup_external_controls = function () {
      $('#csv_link').on('click', function (event) {
        var quarter = $('select#quarter').val().replaceAll(' ', '%20');
        $(this).attr('href', 
          `${XssSanitizer.sanitize_dom_text($(this).data('base-url'))}?quarter=${XssSanitizer.sanitize_dom_text(quarter)}`
        );
      });
    };

    me.setup_external_controls();

    me.render_table = function () {
      var rows_html = '';
      var json = me.data;
      for (var i = 0; i < json.length; i++) {
        var row = json[i];
        var row_code = '<tr data-day=\'' + i + '\'>';

        if (row.submission_type.toLowerCase() === 'delinquent') {
          row_code = '<tr data-day=\'' + i + '\' class=\'delinquent\'>';
        }

        rows_html += row_code;
        rows_html += '<td>' + (i + 1) + '</td>';
        rows_html += '<td>' + (row.submission_type) + '</td>';
        rows_html += '<td>' + (row.prescription_count) + '</td>';
        rows_html += '<td>' + (row.submitted_on) + '</td>';
        rows_html += '</tr>';
      }

      me.selector.find('tbody').html(rows_html);
    };

    me.update_table_with_json = function (json) {
      me.data = json;
      me.render_table();

    };
    $( document ).ajaxComplete(function( event, xhr, settings ) {
      if ( settings.url.match('pharmacies/analysis_chart')) {
        me.update_table_with_json(JSON.parse(xhr.responseText).pharmacies);
      }
    });

    return me;
  }
  var obj = new Object();
  obj.instantiate = instantiate;
  return obj;
})();

$(function () {
  var table_node = $('#pharmacy_delinquency_table table');
  if (table_node.length > 0) {
    PharmacyAnalysisTable.instantiate(table_node);
  }
});
