// data-no-remote-submit
var AntiAjaxSubmit = (function ($) {
  function disable_ajax_when_submitted_with(node) {
    var parent_node = node.closest('form');

    node.on('click', function (event) {
      if (parent_node.data('remote')) {
        parent_node.removeData('remote');
        parent_node.removeAttr('data-remote');
        setTimeout(function () {
          parent_node.attr('data-remote', true);
        }, 100);
      } 
    });
  }

  $(function () {
    $('*[data-no-remote-submit]').each(function () {
      disable_ajax_when_submitted_with($(this));
    });
  });
  $(document).ajaxComplete(function(evt, xhr, status){
    $('*[data-no-remote-submit]').each(function () {
      disable_ajax_when_submitted_with($(this));
    });
  });
})($);
