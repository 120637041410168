var OfficeDispensationInquiryMessage = function ($) {
  function toggle_message(event) {
    var info_box = $('#office_dispenser_supplementary_information');
    info_box.hide();
    if ($('#user_dispenses_from_office_true').is(':checked')) {
      info_box.show();
    }
  }
  $(function() {
    toggle_message();
  });

  $(document).on('click', 'input[type=radio]', toggle_message);
}($);