import './jquery/jquery_base';
import './jquery/jquery_extensions';
import './menu_adornments';
import './css_sandpaper';
import './bootstrap';
import './blueimp';
import './utilities';
import './common';

// directories
import './app/index';
import './admin/index';
import './rx_search_requests/index';
import './clinical_alerts/index';
import './insight_reports/index';
import './module/index';
import './plugins/index';
import './lib/index';


(function($){
  $.fn.getStyleObject = function(){
    var dom = this.get(0);
    var style;
    var returns = {};
    if(window.getComputedStyle){
      var camelize = function(a,b){
        return b.toUpperCase();
      };
      style = window.getComputedStyle(dom, null);
      for(var i = 0, l = style.length; i < l; i++){
        var prop = style[i];
        var camel = prop.replace(/\-([a-z])/g, camelize);
        var val = style.getPropertyValue(prop);
        returns[camel] = val;
      }
      return returns;
    }
    if(style = dom.currentStyle){
      for(var prop in style){
        try {
          returns[prop] = style[prop];
        } catch(e) {
          // alert(prop + "is not recognized by IE8")
        }
      }
      return returns;
    }
    return this.css();
  };
})(jQuery);

export const Application = (function () {
  var ajax_connection_count = 0;
  var animation_count = 0;
  $(document).ajaxSend(function(e, xhr, options){
    ajax_connection_count = ajax_connection_count + 1;
  });
  $(document).ajaxComplete(function(e, xhr, options) {
    ajax_connection_count = ajax_connection_count - 1;
    if (ajax_connection_count < 0) { // just in case
      ajax_connection_count = 0;
    }
  });
  $(document).on('show hide close slide', function(event) {
    if (event.type === 'hide') {
      if (!$(event.target).is(':visible')) {
        animation_count = animation_count - 1;
      }
    }
    animation_count = animation_count + 1;
  });
  $(document).on('shown hidden closed slid', function(event) {
    animation_count = animation_count - 1;
    if (animation_count < 0) {
      animation_count = 0;
    }
  });
  function prevent_loading_me_in_ajax () {
    // If we load a partial that is rendering a full template, we will have more than one title tag...
    if ($('title').length > 1) {
      location.reload();
    }
  }
  function animation_queue_count() {
    var queue_count = 0;
    $('*').each(function() {
      queue_count = queue_count + $(this).queue('fx').length;
    });
    return queue_count;
  }
  function animating_elements_count() {
    return $(':animated').length;
  }
  function am_I_resting() {
    // He's not resting, he's pining for the fjords!
    var queue_count = animation_queue_count();
    if ((queue_count > 0) || (ajax_connection_count > 0) || (animation_count > 0) || (animating_elements_count() > 0)) {
      return false;
    } else {
      return true;
    }
  }
  function debug_resting() {
    console.log('queue count:', animation_queue_count(),
      'animation count:', animation_count,
      'ajax connection count:', ajax_connection_count);
  }
  function controller() {
    return $('body').data('controller');
  }
  function action() {
    return $('body').data('action-name');
  }
  function matches_controller(_controller) {
    return controller() === _controller;
  }
  function matches_controllers(_controllers) {
    var matches = false;
    for (var i = 0; i < _controllers.length; i++) {
      var controller = _controllers[i];
      matches = matches || matches_controller(controller);
    }
    return matches;
  }
  function matches_action(_action) {
    return action() === _action;
  }
  function current(obj) {
    if (obj.hasOwnProperty('controller') && obj.hasOwnProperty('action')) {
      return matches_controller(obj['controller']) && matches_action(obj['action']);
    }
    if (obj.hasOwnProperty('controller')) {
      return matches_controller(obj['controller']);
    }
    if (obj.hasOwnProperty('action')) {
      return matches_action(obj['action']);
    }
    if (obj.hasOwnProperty('controllers')) {
      return matches_controllers(obj['controllers']);
    }
  }
  return {
    prevent_loading_me_in_ajax: prevent_loading_me_in_ajax,
    am_I_resting: am_I_resting,
    debug: debug_resting,
    current: current,
    controller: controller,
    action: action
  };
}());

$(function () {
  if (navigator.userAgent.indexOf('MSIE 10') > 0) {
    $('.fileinput-button input').on('mousedown',function(event) {
      $(this).trigger('click');
    });
  }
});

function recaptcha_success(){
  $('.punishment-submit').removeAttr('disabled');
}

function recaptcha_expired(){
  $('.punishment-submit').attr('disabled', 'disabled');
}

$(function() {
  $(document).on('click', '.delegate-request-history-modal .close-modal', function(){
    $(this).parents('.delegate-request-history-modal').modal('hide');
  });

  $('#system-wide-message-submit').on('click', function(){
    $('#confirm_system_notification').modal('hide');
    $(this).parents('form').trigger('submit');
  });

  $('#confirm_system_notification').on('show.bs.modal', function (e) {
    $(this).find('.confirm-active-from-date').text($('#system_notification_active_from_date').val());
    $(this).find('.confirm-active-from-hour').text($('#system_notification_active_from_hour').val());
    $(this).find('.confirm-active-to-date').text($('#system_notification_active_to_date').val());
    $(this).find('.confirm-active-to-hour').text($('#system_notification_active_to_hour').val());
    $(this).find('.confirm-subject').text($('#system_notification_subject').val());
    $(this).find('.confirm-message').text($('#system_notification_message').val());

    var loginStatus = $('#system_notification_disable_login').is(':checked') ? 'Disabled' : 'Enabled';
    $(this).find('.confirm-disable-login').text(loginStatus);
  });

  $(document).on('click', '.navigation .nav-dropdown a', function(){
    var token = $( 'input[name*=\'recaptcha_token\']' );

    if(token[0]) {
      $.ajax({
        type: 'POST',
        url: '/verify_recaptcha',
        data: {'recaptcha_token': token[0].value},
        dataType: 'json'
      });
    }
  });

  $(document).on('submit', '.h-captcha-form', function(e){
    e.preventDefault();
    hcaptcha.execute();
  });

  $('.g-recaptcha').attr({'data-callback': 'recaptcha_success', 'data-expired-callback': 'recaptcha_expired', 'data-error-callback': 'recaptcha_expired'});
  $('span.required').not('.no_asterisk').addClass('no_asterisk').append('<span style="display: contents" class="required-indicator">*</span>');
  Application.prevent_loading_me_in_ajax();
  $('.prevent-from-click').on('click', function(e){
    e.stopPropagation();
  });
  // Uncomment this to see when application is busy VIA console
  // setInterval(function() {console.log(Application.am_I_resting())}, 400);
});
