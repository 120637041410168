import { StringInflector } from '../app/string_inflector.js';

export const RemoteSubmission = function (type) {
  var submission_type = type;
  function field_selector(model_name, attribute_name, model_id, use_model_id) {
    use_model_id = typeof(use_model_id) !== 'undefined' ? use_model_id : true;
    var m_name = StringInflector.camelToSnakeCase(model_name);
    var a_name = attribute_name.toLowerCase();
    var selector = '*[name="' + submission_type + '[' + submission_type + '_' + m_name + '][' + a_name + ']"]';
    if (m_name === 'drug') {
      if (model_id) {
        var model_id_selector = use_model_id ? '[data-model-id="' + model_id + '"]' : '';
        selector = '*' + model_id_selector + '[name^="' + submission_type + '[drugs]["]' + '[name$="]['+a_name+']"]';
      }
      else {
        selector = '*[name="' + submission_type + '[drugs][]['+a_name+']"]';
      }
    }
    return selector;
  }
  return {
    field_selector:field_selector
  };
};
