let form_submitted = false;
let fields_parameters_on_load = '';
import { tmpl } from '../blueimp';

$(function() {
  $('.popover_with_content').popover({
    html: true,
    trigger: 'hover',
    content() {
      const id = $(this).data('obj-id');
      const type = $(this).data('obj-type');
      return $(`#popover_content_${type}_${id}`).html();
    }
  });

  $('#mandatory_use_compliance_form').on('submit', () => form_submitted = true);

  $(window).on('load', () => fields_parameters_on_load = $('#mandatory_use_compliance_form').serialize());

  $(window).on('beforeunload', function() {
    if ((fields_parameters_on_load !== $('#mandatory_use_compliance_form').serialize()) && !form_submitted) { return true; }
  });

  $('.schedule-selection').on('click', function() {
    const name = $(this).data('name-value');
    const array_value = $('#schedule_types_selector').val() || [];
    if ($(this).is(':checked')) {
      array_value.push(name);
    } else {
      const index = array_value.indexOf(name.toString());
      if (index > -1) { array_value.splice(index, 1); }
    }

    return $('#schedule_types_selector').val(array_value);
  });

  if ($('#mandatory_compliance_report_setting_reporting_period').val() !== '90') {
    $('#mandatory_compliance_report_setting_reporting_period').prop('disabled', false);
    $('#mandatory_compliance_report_setting_reporting_period_other').prop('checked', true);
  }

  if ($('#mandatory_compliance_report_setting_reporting_period').val() === '90') {
    $('#mandatory_compliance_report_setting_reporting_period').val('');
  }

  $('.reporting-period-radio').on('change', function() {
    if ($(this).val() === 'other') {
      return $('#mandatory_compliance_report_setting_reporting_period').prop('disabled', false);
    } else {
      return $('#mandatory_compliance_report_setting_reporting_period').prop('disabled', true);
    }
  });

  $(document).on('click', '.remove-ndc-record', function(e) {
    e.preventDefault();
    const ndc_id = $(this).data('record-id');
    if (window.confirm('Are you sure?')) {
      if (ndc_id) { $(`#destroy_mandatory_compliance_${ndc_id}`).prop('checked', true); }
      $(this).closest('tr').remove();
      return set_list_description();
    }
  });

  $(document).on('click', '#remove-all-ndc-values-js', function(e) {
    e.preventDefault();
    if (window.confirm('Are you sure?')) {
      $('.remove-ndc-record').each(function() {
        const ndc_id = $(this).data('record-id');
        if (ndc_id) { return $(`#destroy_mandatory_compliance_${ndc_id}`).prop('checked', true); }
      });
      $('.ndcs-value-js').remove();
      return set_list_description();
    }
  });

  $('#add_new_ndc_row').on('click', function(e) {
    e.preventDefault();
    const national_drug_code = $('#new_ndc_value').val();
    if (found_in_existing_table(national_drug_code)) {
      return add_error_message(`This '${national_drug_code}' NDC Number exists in table`);
    } else if (national_drug_code === '') {
      return add_error_message('NDC number required');
    } else {
      return $.ajax('/mandatory_use_compliance/load_ndc', {
        type: 'POST',
        data: { 'national_drug_code' : national_drug_code },
        beforeSend() {
          $('#add_new_ndc_row').addClass('disabled');
          return $('.ndc-number-loading').removeClass('hidden');
        },

        complete(data) {
          $('#add_new_ndc_row').removeClass('disabled');
          return $('.ndc-number-loading').addClass('hidden');
        },

        error(a, b, errorThrown) {
          return alert(errorThrown);
        },

        success(data, textStatus, jqXHR) {
          if (data) {
            $('#new_ndc_value').val('');
            $('#specific_ndc_list').append(data);
            set_list_description();
            return remove_all_errors();
          } else {
            return add_error_message('NDC number not found');
          }
        }
      }
      );
    }
  });

  return $('.ndc_list_file').each(function(index) {
    return $(this).fileupload({
      url: '/mandatory_use_compliance/upload_ndc_list',
      autoUpload: true,
      showElementClass: 'show',
      formData: {
        'ndc_list_type': $(this).siblings('.rndc_list_type').val(),
        'ndc_list_id': $(this).siblings('.ndc_list_id').val()
      },
      add(e, data) {
        $(document).find('.ndc_list_file').value = '';
        let file = undefined;
        file = data.files[0];
        data.context = $(tmpl('template-upload', file));
        const popover_view = $(this).closest('.case-info-popover-view');
        if (popover_view.length === 0) {
          $(document).find('.upload_documentations').append(data.context);
        } else {
          $(popover_view).find('.upload_documentations').append(data.context);
        }
        return data.submit();
      },
      done(e, data) {
        $(document).find('.ndc_list_file').value = '';
        $(document).find('.template-upload').remove();
        $('#specific_ndc_list').append(data.result.ndc_list);
        set_list_description();
        const file = data.files[0];
        file['errors'] = data.result.errors;
        file['created_date'] = data.result.DoC;
        data.context = $(tmpl('template-download', file));
        return $(document).find('.upload_documentations').html(data.context);
      }
    });
  });
});

var found_in_existing_table = function(drug_code) {
  if ($(document).find(`.national-drug-code-${drug_code}`).length > 0) {
    return true;
  } else {
    return false;
  }
};

var set_list_description = function() {
  if ($(document).find('.ndc-number-list > tbody > tr').length > 1) {
    $(document).find('#empty_ndc_list').addClass('hidden');
    return $(document).find('#remove-all-ndc-values-js').removeClass('hidden');
  } else {
    $(document).find('#empty_ndc_list').removeClass('hidden');
    return $(document).find('#remove-all-ndc-values-js').addClass('hidden');
  }
};

var remove_all_errors = (() => {
  let messageContainer = $(document).find('#new-ndc-value-form-alert');
  let messageElement = $(messageContainer).find('#new-ndc-value-form-message');
  if (messageContainer) {
    messageContainer.hide();
  }
  if (messageElement) {
    messageElement.text('');
  }
});

var add_error_message = function(message) {
  let sanitizedMessage = $(document.createTextNode(message));
  let messageContainer = $(document).find('#new-ndc-value-form-alert');
  let messageElement = $(messageContainer).find('#new-ndc-value-form-message');

  remove_all_errors();

  messageContainer.show();
  return messageElement.append(sanitizedMessage);
};
