var PagingScrollView = (function () {
  var view = null, significance_selector = null, container = null,
    page_left_button = null, page_right_button = null,
    marker = null,
    page_left, page_right;

  function initialize (options) {
    view = $(options.view);
    if (view.length > 0) {
      view.addClass('paging-scroll');
      view.wrap('<div class="paging-scroll-container" />');
      container = view.parent();
      container.width(container.width());
      significance_selector = view.data('significance-selector');
      marker = $(significance_selector).first();
      $('<div class="page right" style="display:none" />').insertBefore(view);
      $('<div class="page left" style="display:none" />').insertBefore(view);
      page_left_button = container.children('div.page.left');
      page_right_button = container.children('div.page.right');

      page_left_button.trigger('click', page_left);
      page_right_button.trigger('click', page_right);
      $(significance_selector).first().parent().css({width:''});
      var width = Math.min($('ul.lowest_level_nav').width() + $('ul.crumbs').width() + 32, 930);
      resize_container_to_width(width);
      if (is_overflowing()) {
        show_paging_buttons(true);
      }
     
    }
  }
  function show_paging_buttons (animate) {

    var buttons = {shown:[], hidden:[]};
    // console.log(container.width());
    if (container.width() < 930) {
      buttons.hidden.push(page_left_button);
      buttons.hidden.push(page_right_button);
    } else {
      // console.log("fuck");
      if (view.get(0).scrollLeft > 0) {
        buttons.shown.push(page_left_button);
      }
      if (view.get(0).scrollLeft < 1) {
        buttons.hidden.push(page_left_button);
      }
      // console.log((view.get(0).scrollWidth - view.width() - 1));
      // console.log(view.get(0).scrollLeft);
      if (view.get(0).scrollWidth > (view.width() - 1)) {
        if (view.get(0).scrollLeft < (view.get(0).scrollWidth - view.width() - 1)) {
          buttons.shown.push(page_right_button);
        } else {
          buttons.hidden.push(page_right_button);
        }
      }

    }
    if (animate === true) {
      // $(buttons.shown).fadeIn(100);
      // $(buttons.hidden).fadeOut(100);
      $(buttons.shown).each(function() {
        $(this).show(100);
      });
      $(buttons.hidden).each(function() {
        $(this).hide(100);
      });
    } else {
      // $(buttons.shown).fadeIn(1);
      // $(buttons.hidden).fadeOut(1);
      $(buttons.shown).each(function() {this.show();});
      $(buttons.hidden).each(function() {this.hide();});
      // $(buttons.hidden).hide();
    }
    // console.log(buttons);
  }

  function is_overflowing () {
    if(view.get(0).offsetWidth < view.get(0).scrollWidth){
      return true;
    } else {
      return false;
    }
  }

  function should_scroll_to_elem (elem) {
    var viewportWidth = view.width(),    
      scrollLeft = view.scrollLeft(),

      elementLeft = elem.position().left,
      elementWidth = elem.width(),
      elementRight = elementLeft + elementWidth,

      minLeft = scrollLeft,
      maxLeft = scrollLeft + viewportWidth - 32;
    // console.log(elementLeft, minLeft, elementRight, maxLeft, elementWidth);
    if (elementLeft > minLeft && elementRight < maxLeft) {
      return false;
    }
    if 
    (
      (elementLeft < minLeft && elementRight > minLeft) ||
        (elementLeft < maxLeft && elementRight > maxLeft)
    ) {
      //Partially Visible
      return true;
    } else {
      if 
      (
        (elementLeft < minLeft && elementRight < minLeft) ||
          (elementLeft > maxLeft)
      ) {
        return true;
      }
      return false;
    }
  }
  function page_right (argument) {
    var elem = marker;
    while (elem = elem.next()) {
      if (elem.length < 1) {
        marker = $(significance_selector).last();
        break;
      }
      if (should_scroll_to_elem(elem)) {
        marker = elem;
        break;
      }

    }
    scroll_to_elem(marker);
  }
  function page_left (argument) {
    var elem = marker;
    while (elem = elem.prev()) {
      if (elem.length < 1) {
        marker = $(significance_selector).first();
        break;
      }
      if (should_scroll_to_elem(elem)) {
        marker = elem;
        break;
      }

    }
    scroll_to_elem(marker, 'right');
  }
  function scroll_to_elem (elem, alignment) {
    var left = elem.position().left;
    if (alignment === 'right') {
      left = elem.position().left + elem.width() - (view.width()) + 32;
      // console.log('aligning right');
    }
    view.animate({
      scrollLeft:left
    }, {complete:function() {
      show_paging_buttons(true);
      // console.log($.trim(elem.text()));
      // console.log(view.scrollLeft());
    }});
  }

  function resize_container_to_width (new_width) {
    if (container.width() <= 930) {
      container.css({
        width:new_width
      });
    } 

  }

  
  $(function() {
    PagingScrollView.initialize({
      view: $('*[data-paging-scroll]')
    });
  });

  $(document).on('listWillBeReplaced', function() {
    // console.log('will be replaced');
    // container.width(container.width());
  });
  var smooth_resize_animation_timer;
  $(document).on('listWillAnimateReplace', function(event, object) {

    smooth_resize_animation_timer = setInterval(function() {
      var width = Math.min(object.new_style.width + $('ul.crumbs').width() + 32, 930);
      resize_container_to_width(width);
    },1);
  });

  $(document).on('listReplaced',function() {
    clearInterval(smooth_resize_animation_timer);
    // console.log('replaced');
    // view.width(view.get(0).scrollWidth - 32); 
    view.scrollLeft(0);
    // view.children(':first').css({width:''});
    // container.css({width:''});
    // if (container.width() <= 930) {
    //   // view.children(':first').width(view.get(0).scrollWidth+32); 
    //   var width = Math.min($('ul.lowest_level_nav').width() + $('ul.crumbs').width() + 32, 930);
    //   container.animate({
    //     
    //     width:width
    //   }, {duration:20,complete:function() {
    //     show_paging_buttons();
    //   }});
    // } 
    var width = Math.min($('ul.lowest_level_nav').width() + $('ul.crumbs').width() + 32, 930);
    resize_container_to_width(width);
    show_paging_buttons();
  });

  $(document).on('click', 'a.navigation_item', function (event) {
  });
  return {
    initialize: initialize,
  };
}());