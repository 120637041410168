import './jquery/jquery.maskedinput';
import './jquery/jquery.scrollintoview.min.js';

$(function(event) {
  $.mask.definitions['*'] = '[*0-9]';

  $('input[data-mask-date=\'true\']').mask('99/99/9999', { placeholder: 'MM/DD/YYYY'});
  $('input[data-mask-year=\'true\']').mask('9999', { placeholder: 'YYYY'});
  $('input[data-mask-month=\'true\']').mask('99', { placeholder: 'MM'});

  if(window.location.hash != '') {
    $('a[href=\''+ window.location.hash+'\']').tab('show');
  }
  $(document).on('shown', '[data-scroll-on-show=\'true\']', function() {
    $(this).scrollintoview();
  });
  $(document).on('click', '[data-scroll-top=\'true\']', function(){
    $('#content').scrollintoview();
  });
});
