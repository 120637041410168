class UnsolicitedReportsController {
  constructor() {
    $(document).on('change', '.report-type', () => toggle_fields());
    $(() => toggle_fields());

    var toggle_fields = function() {
      const show_patient_name_fields = $('#report_type_InvestigativeSearchRequest').is(':checked');
      $('#patient-name').toggle(show_patient_name_fields);
      $('#dea-search').toggle(!show_patient_name_fields);
      $('#npi-search').toggle(!show_patient_name_fields);
      return $('#ncpdp-search').toggle(!show_patient_name_fields);
    };
  }
}

const unsolicited_reports_controller = new UnsolicitedReportsController;
  