// And with a flick of the wrist and a flourish of his wand, a puff of smoke appeared.
var Poof = function (args) {
  var poof_image;
  function init(path) {
    poof_image = new Image();
    poof_image.src = path;
  }
  function create_with_options(options) {
    var x, y, width, height;
    x = options.x;
    y = options.y;
    img = poof_image.src;
    var image = new Image();
    image.src = img;
    frame_width = poof_image.width;
    frame_height = frame_width;

    var frames = 5;

    // create node
    var node = $('<div />');
    $('body').append(node);
    node.css({
      position:'absolute',
      background:'url(' + img + ') no-repeat 0 0',
      left:x,
      top:y,
      width:frame_width + 'px',
      height:frame_height + 'px' //not a typo

    });

    var obj = {t:0};
    $(obj).$_animate({
      t:4
    }, {
      duration: 400,
      easing: 'linear',
      step: function(now) {
        var y_position = Math.round(now) * frame_height;
        y_position = y_position * -1;
        node.css({'background-position': '0px '+ y_position + 'px'});
        //         console.log('y pos:', y_position);
        //         console.log(animation, progress, remaining_ms);
      },
      complete: function () {
        node.remove();
      }
    });
    return node;
    // append node to dom

    // animate

    // at end of animation remove

  }

  return {animate_with_options:create_with_options, init:init};
}();

