var CaseDepositionReport = (function($) {
  $(function(){
    $('.case-disposition-select').on('change', function(e){
      var button_id = $(this).data('rightActionsButtons');
      if ($(this).val() == ''){
        $(button_id).find('.approve-link, .deny-link').addClass('disabled');
      } else {
        $(button_id).find('.approve-link, .deny-link').removeClass('disabled');
      }
    });

    $('.choose_audit_form').on('ajax:success', function(e, data){
      $(this).parents('.case-disposition-report-item').hide();
    });

    $(document).on('click', '.approve-link.disabled, .deny-link.disabled', function(e){
      e.preventDefault();
    });
  });
})(jQuery);
