import { Flash } from '../app/flash';

var DataExtracts = (function ($) {
  $(function () {
    // When a user clicks on a download link, log the download.
    $('.data-extract-download').on('click', function(evt) {
      var elem = $(this);
      var nameValue = elem.data('name');
      var linkValue = elem.data('link');
      $.post('/insight_reports/data_extracts/download_log', {
        data: { name: nameValue }
      }).fail(function() {
        Flash.error('Unable to download the file at this time. Please try again later.');
      }).done(function() {
        window.location = linkValue;
      });
    });
  });
})($);