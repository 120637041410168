var Selectable = (function($) {
  function setup() {
    $(document).on('click', '*[data-selectable]', function () {
      var target = $(event.target),
        selectable_name = target.data('selectable'),
        selectable = $('*[data-selectable="' + selectable_name + '"]');
      selectable.removeClass('selected');
      target.addClass('selected');
      $(document).trigger('selectable:changed', selectable_name);
    });
  }
  return {
    setup: setup
  };
}(jQuery));
Selectable.setup();