import { Flash } from '../app/flash';

var PMPiPollingMechanism = (function ($) {
  function instantiate(url) {
    var wait_delta = 1, timer = null;
    $(document).trigger('PMPi:request:queried');
    function timeout_error() {
      Flash.error('Unable to retrieve results from all requested states, try reloading the page at a later date. If problem persists, contact your administrator.');
      clearTimeout(timer);
      $(document).trigger('PMPi:request:timeout');
      toggle_popover(false);
    }
    function completed() {
      clearTimeout(timer);
      $(document).trigger('PMPi:request:complete');
      toggle_popover(false);
    }
    function poll() {
      $.ajax(url).done(function (data) {
        if (data.status === 'complete') {
          completed();
          return;
        }

        // console.log(wait_delta);
        if (wait_delta >= 1.09) {
          wait_delta = Math.floor(wait_delta) * 2;
        } else {
          wait_delta = wait_delta + 0.01;
        }

        if (wait_delta > 32) {
          timeout_error();
        }
        timer = setTimeout(poll, wait_delta * 1000);
      });
    }

    timer = setTimeout(poll, wait_delta * 1000);
    $(document).trigger('PMPi:request:pollIntervalComplete');
  }

  $(function () {
    $('*[data-pmpi-polling-url]').each(function () {
      var url = $(this).data('pmpi-polling-url');
      instantiate(url);
      toggle_popover(read_popover_cookie());
    });

    $(document).on('click', 'button.close_pmpi_popover', function (event) {
      write_popover_cookie(false);
      toggle_popover(false);
    });
  });

  function write_popover_cookie(value) {
    var date = new Date();
    date.setTime(date.getTime() + ((365 * 1000) * 24 * 60 * 60 * 1000)); //1000 years.
    var expires = '; expires=' + date.toGMTString();

    document.cookie = 'pmpi_dummy_mode=' + value + expires + ';';
  }

  function read_popover_cookie() {
    var cookie_string_split = document.cookie.split('pmpi_dummy_mode='), result = true;
    if (cookie_string_split[cookie_string_split.length - 1].charAt(0) === 'f') {
      result = false;
    }
    return result;
  }


  function toggle_popover(show) {
    var close_widget = '<button type="button" class="close close_pmpi_popover" style="float:left;">×</button>';

    var html =  close_widget + '<span style=\'font-size:12px;font-weight:normal;margin-left:6px;\' class=\'awaiting_results_bubble\'>Awaiting results from requested states.</span> ';

    if (show) {
      $('div.progress_container').first().popover({
        placement:'left',
        trigger:'manual',
        // title:"Searching...",
        content:html,
        html:true
      }).popover('show');
    } else {
      $('div.progress_container').first().popover('hide');
    }
  }

  return {
    write_popover_cookie: write_popover_cookie,
    read_popover_cookie:read_popover_cookie
  };
})($);
