import { Application } from '../application';

var MessageStyleNavigationControls = (function ($) {
  var container, metrics_node, from_count, to_count, total_count,
    items_per_page, current_page_index, total_pages, current_page_display,
    previous_button, next_button, per_page_control;
  function calculate_metrics(data) {
    total_count = data['total_items'];
    total_pages = data['total_pages'];
    items_per_page = parseInt(container.find('.per-page-options option:selected').val()) || 1;
    current_page_index = parseInt(current_page_display.val()) || 1;
    from_count = (items_per_page * current_page_index) - items_per_page;
    if (from_count < 0) {
      from_count = 0;
    }
    to_count = from_count + items_per_page;
    if (to_count > total_count) {
      to_count = total_count;
    }
    if (from_count > to_count) {
      from_count = to_count - 1;
    }
  }
  function update_metrics(data) {
    calculate_metrics(data);
    var message = 'Showing ' + (from_count + 1) + ' &ndash; ' + to_count + ' of ' + total_count + ' items';
    metrics_node.html(message);
    container.find('.total-pages').text(total_pages);
    toggle_navigation_buttons();
  }
  function previous_page(event) {
    current_page_index = current_page_index - 1;
    constrain_index_to_bounds();
    update_page_index();
    event.stopPropagation();
    event.preventDefault();
  }
  function next_page(event) {
    current_page_index = current_page_index + 1;
    constrain_index_to_bounds();
    update_page_index();
    event.stopPropagation();
    event.preventDefault();
  }
  function constrain_index_to_bounds() {
    if (current_page_index < 1) {
      current_page_index = 1;
    }
    if (current_page_index > total_pages) {
      if (total_pages > 0) {
        current_page_index = total_pages;
      } else {
        current_page_index = 1;
      }

    }
  }
  function change_page(event) {
    var target = $(event.target), new_value = parseInt(target.val());
    current_page_index = new_value || 1;
    constrain_index_to_bounds();
    event.preventDefault();
    update_page_index();
  }
  function change_per_page(event) {
    event.preventDefault();
    current_page_index = 1;
    update_page_index();
  }
  function toggle_navigation_buttons() {
    previous_button.hide();
    next_button.hide();
    if (current_page_index > 1) {
      previous_button.show();
    }
    if (current_page_index < total_pages) {
      next_button.show();
    }
  }
  function update_page_index() {
    current_page_display.val(current_page_index);
    container.trigger('change');
    toggle_navigation_buttons();
  }
  function init() {
    container = $('*[data-navigation-style="messages"]');
    current_page_display = container.find('#current_page');
    previous_button = container.find('.previous-page-button');
    next_button = container.find('.next-page-button');
    per_page_control = container.find('#items_per_page');
    // setup metrics
    metrics_node = container.children('.details');
    total_count = container.data('navigation-total-items');
    current_page_index = parseInt(current_page_display.val());
    $(document).on('pagination:update_metrics', function (event, data) {
      update_metrics(data);
    });
    $(document).on('selectable:changed', function (event, data) {
      current_page_index = 1;
      update_page_index();
    });

    //setup nav buttons
    total_pages = parseInt(container.find('.total-pages').text());
    $(document).on('click', '*[data-navigation-style="messages"] .previous-page-button', previous_page);
    $(document).on('click', '*[data-navigation-style="messages"] .next-page-button', next_page);

    //setup navigation input field
    current_page_display.on('change', change_page);

    //setup page control dropdown
    per_page_control.on('change', change_per_page);

    //make page consistent
    update_metrics({'total_items': total_count, 'total_pages': total_pages});
  }
  function setup() {
    $(function () {
      if (Application.current({ controller: 'messages', action: 'index' })) {
        init();
      }
      if (Application.current({ controller: 'care_notes', action: 'index' })) {
        init();
      }
      if (Application.current({ controller: 'messages_and_care_notes', action: 'index' })) {
        init();
      }
      if (Application.current({ controller: 'organizations', action: 'index' })) {
        init();
      }
      if (Application.current({ controller: 'users', action: 'index' })) {
        init();
      }
    });
  }
  return {
    setup: setup
  };
})(jQuery);

MessageStyleNavigationControls.setup();
