import { StaticTableHeaders } from '../app/static_table_headers';
import { adulterate_user_row } from './registration.js';
import { adulterate_address_card as admin_adulterate_address_card } from '../admin/registration';

$(function () {
  $('#user_request_filter')
    .on('ajax:beforeSend', function(evt, xhr, settings){
      $('#spinnah').fadeIn();
      $('#address_card_toolbar').remove();
    })
    .on('ajax:success', function(evt, data, status, xhr){
      $('#results').html(xhr.responseText);
    })
    .on('ajax:complete', function(evt, xhr, status){
      $('#spinnah').fadeOut();
    })
    .on('ajax:error', function(evt, xhr, status, error){
      $('.scrolltable').html(xhr.responseText);

      alert('The application experienced a problem processing your request. Please try again. If the problem persists, please contact the Help Desk.');
      $(document).trigger('search:failed', {error:error});
    });
});

function admin_reject_rx_search_request(request_id) {
  adulterate_user_row(request_id, 'rejected');
  admin_adulterate_address_card('rejected');
  $('approve_user_request_button').popover('hide');
}

window.admin_reject_rx_search_request = admin_reject_rx_search_request;

function admin_approve_rx_search_request(request_id, remove_request) {
  if (remove_request) {
    adulterate_user_row(request_id, 'approved');
  }
  admin_adulterate_address_card('approved');
  $('approve_user_request_button').popover('hide');
}

window.admin_approve_rx_search_request = admin_approve_rx_search_request;

function admin_adulterate_request_row(id, class_name) {
  var row = $('#search_result_row_report_id_' + id);
  var cells = row.children('td');
  $(row).animate({
    height: '0px'
  }, {
    duration: '1500',
    complete: function () {
      row.remove();
    }
  });
  $(cells).slideUp(150, function () {
    StaticTableHeaders.tables_need_update();
  });
}

window.admin_adulterate_request_row = admin_adulterate_request_row;

function admin_reject_user_request(request_id) {
  admin_adulterate_request_row(request_id, 'rejected');
  admin_adulterate_address_card('rejected');
  $('approve_user_request_button').popover('hide');
}

window.admin_reject_user_request = admin_reject_user_request;

function admin_approve_user_request(request_id) {
  admin_adulterate_request_row(request_id, 'approved');
  admin_adulterate_address_card('approved');
  $('approve_user_request_button').popover('hide');
}

window.admin_approve_user_request = admin_approve_user_request;

function fail_to_approve_rx_search_request(json) {
  var content = '';
  $.each(errors,function(key, value) {
    content = content + value + ' ';
  });

  var html = '<div class=\'popover_error\'>'+content+'</div>';
  $('#approve_user_request_button').popover({
    // html:"OH HAI",
    placement:'top',
    trigger:'manual',
    title:'Approval Failed',
    content:html,
    html:true
  }).popover('show');

}
