var AjaxFileUpload = (function($) {
  var extra_files = {};
  function setup() {
    $(document).on('file_well:file_dropped', function (event, data) {
      extra_files[data.input_name] = data.file;
    });
    $(document).on('file_well:file_cleared', function (event, data) {
      delete extra_files[data.input_name];
    });
    $(document).on('submit', '*[data-ajax-file-upload]', function (event) {
      event.preventDefault();
      var form = $(event.target).closest('form')[0],
        form_data = new FormData(form);
      for (key in extra_files) {
        if (extra_files.hasOwnProperty(key)) {
          form_data.append(key, extra_files[key]);
        }
      }
      $.ajax({
        url: form.action,
        data: form_data,
        processData: false,
        contentType: false,
        type: 'POST',
        dataType: 'json'
      });
    });
  }

  return {
    setup: setup
  };
}(jQuery));

AjaxFileUpload.setup();