var ColorPicker = (function ($) {
  function color_pickerize(input_node) {
    var node = $(input_node),
      placeholder = node.siblings('.color_picker_placeholder'),
      shell = placeholder.find('.shell'),
      well = node.siblings('.color_well'),
      color = node.val(),
      farb = $.farbtastic(shell);

    farb.linkTo(function (color) {
      well.css({
        background:color
      });
      node.val(color);
      $(node).trigger('color_well:updated', {color:color, node:node});
      $(node).trigger('change');
    });
    farb.setColor(color);
    placeholder.addClass('collapse');

    well.html('');
    well.css({
      display:'block',
      position:'relative',
      width:'52px',
      height:'32px',
      border:'3px solid #fff',
      background:color
    });
    well.wrap('<span/>');
    var well_wrapper = well.parent();
    well_wrapper.css({
      display:'inline-block',
      border:'1px solid #777'
    });

    node.data('farb', farb);
  }

  function auto_picker() {
    $(function () {
      $('*[data-color-select]').each(function () {
        color_pickerize(this);
      });
    });
  }

  $(document).on('click', '[data-color-well-target]', function (event) {
    var link = $(event.target),
      color_well = $(link.data('color-well-target')).data('farb'),
      color = link.data('color-well-color-set');
    color_well.setColor(color);
    event.preventDefault();
  });

  return {
    setup: auto_picker
  };
})(jQuery);

ColorPicker.setup();
