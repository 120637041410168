// Note- this works best for ajax forms. Browsers stop processing javascript
// events after a submit or navigation event occurs if it is not AJAX.
// If you with to enforce a single form submit for regular forms, please
// see unique_form_submission_enforcer.js
var UniqueFormSubmissionMotivator = (function ($) {
  var instance_methods = {
      construct: function (form) {
        this.form = form;
        this.show_nag_event = 'UniqueFormMotivator:submit_disabled';
        var nag_event = this.form.data('encouraging-nag-on-event');
        if (typeof(nag_event) !== 'undefined') {
          this.show_nag_event = nag_event;
        }
        var me = this;
        $(document).on(me.show_nag_event, function (event) {
          me.show_nag();
        });

        this.disable_form_submit();
      }, 
      submit_button: function () {
        return this.form.find(':submit');
      },
      disable_form_submit: function () {
        this.submit_button().prop('disabled', true);
        this.form.trigger('UniqueFormMotivator:submit_disabled');
      },
      enable_form_submit: function () {
        this.submit_button().prop('disabled', false);
        this.hide_nag();
      },
      show_nag: function () {
        /* Get around edge cases where the nag was not removed. */
        if (this.form.find('.uniqueness-nag')) {
          return;
        }

        $(this.submit_button()).before('<div class=\'uniqueness-nag\'><i class=\'fa fa-exclamation-circle\'></i> Please change your search criteria to run a new report.</div>');
      },
      hide_nag: function () {
        this.form.find('.uniqueness-nag').remove();
      }
    },
    class_methods = {
      instantiate: function (arg) {
        var instance = new Object();
        for (let method in instance_methods) {
          if (instance_methods.hasOwnProperty(method)) {
            instance[method] = instance_methods[method];
          }
        }
        instance.construct(arg);
        return instance;
      },
      setup: function () {
        $(document).on('submit', 'form[data-encourage-unique-submissions]', function (event) {
          var form = $(event.target).closest('form'),
            motivator = form.data('motivator');
          if (typeof(motivator) === 'undefined') {
            motivator = UniqueFormSubmissionMotivator.instantiate(form);
            form.data('motivator', motivator);
          }  else {
            //using settimeout ensures form is submitted before disabling
            setTimeout(function () {
              motivator.disable_form_submit();
            }, 100);
          }
        });

        $(document).on('changeDate', function(event){ 
          var target = $(event.target),
            form = target.closest('form'),
            motivator = form.data('motivator');
          if (typeof(motivator) !== 'undefined') {
            motivator.enable_form_submit();
          }
        });

        function enable_input(event) {
          var target = $(event.target),
            form = target.closest('form'),
            motivator = form.data('motivator');
          if (target.data('behaviour') === 'datepicker') {
            return;
          }
          if (typeof(motivator) !== 'undefined') {
            motivator.enable_form_submit();
          } 
        }

        $(document).on('change', 'form[data-encourage-unique-submissions]', enable_input);

        $(document).on('input', 'form[data-encourage-unique-submissions]', enable_input);
      }

    };
  return class_methods;
})(jQuery);

UniqueFormSubmissionMotivator.setup();