export const StringInflector = function() {
  function camelToSnakeCase(key) {
    // firstName -> first_name, isbn10 -> isbn_10, scoresA1 -> scores_a_1
    return key.replace(/([A-Za-z])([0-9])/g, function (snip, char, digit) {
      return char + '_' + digit;
    }).replace(/([A-Z])/g, function (snip) {
      return '_' + snip.toLowerCase();
    }).replace(/^_/g, '');
  }

  return {
    camelToSnakeCase:camelToSnakeCase,
  };
}();
