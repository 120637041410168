function setUpButtons() {
  $('.file_upload_input_button').on('click', function() {
    return $(this).closest('[data-inputgroup="file-upload"]').find('.file_upload_field').trigger('click');
  });
  return $('.file_upload_field').on('change', function() {
    return $(this).closest('[data-inputgroup="file-upload"]').find('.file_upload_file_name').val($(this).val());
  });
}

$(document).on('load turbolinks:load', function() {
  setUpButtons();
});

$(function() {
  setUpButtons();
});

