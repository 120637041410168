var checkbox_selector = 'input[name="selected_patients[]"][type="checkbox"]';

$(function() {
  disable_enable_button();
});

$(document).on('click', checkbox_selector, function (event) {
  disable_enable_button();
});

function disable_enable_button(){
  if ($(checkbox_selector + ':checked').length > 0) {
    $('#run_button').prop('disabled', false);
  } else {
    $('#run_button').prop('disabled', true);
  }
}
