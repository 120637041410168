import { DateConstraints, DatePickerHelper } from '../date_helpers';

var date_picker = function (e) {
  var parentNode = this.parentNode;
  if (!$(parentNode).closest('.calendar-datepicker').length > 0) {
    var initial_date = new Date(); //today
    var field_initial_date = Date.parse($(this).val());
    if (!isNaN(field_initial_date)) {
      initial_date = field_initial_date;
    }
    $(this).datepicker({
      autoclose: true,
      // keyboardNavigation: false,
      assumeNearbyYear: true,
      setDate: initial_date,
    });
  }
  let constraints = new DateConstraints();
  constraints.setDateFormat($(this).data('date-format'));
  constraints.setMinAllowedDate($(this).data('date-start-date'));
  constraints.setMaxAllowedDate($(this).data('date-end-date'));
  $(this).attr('maxlength', constraints.date_format.length);
  $(this).on('blur', function (e) {
    var orig_val = $(this).val();
    var parsed_date = DatePickerHelper.parseDate(
      constraints.date_format,
      orig_val
    );
    var result = DatePickerHelper.enforceMinMaxDate(
      constraints,
      parsed_date
    );
    $(this).val(result);
  });
};

$(document).on('focus', '[data-behaviour~=\'datepicker\']', date_picker);
$(document).on('focus', '[data-behavior~=\'datepicker\']', date_picker);

$(function () {
  $('div.datepicker-date-range').each(function() {
    if (! $(this).find('.calendar-datepicker').length > 0) {
      var input_fields = $(this).find('input');
      input_fields.datepicker({
        autoclose: false,
        // inputs: $(this).find('input'),
        keyboardNavigation: false,
        assumeNearbyYear: true,
        forceParse: false,
        immediateUpdates: true
      });
    }
    let constraints =  new DateConstraints();
    constraints.setDateFormat($(this).data('date-format'));
    constraints.setMinAllowedDate($(this).data('date-start-date') );
    constraints.setMaxAllowedDate($(this).data('date-end-date') );
    var begin_date_field = $(this).find('*[data-date-range-definition="begin"]');
    var end_date_field = $(this).find('*[data-date-range-definition="end"]');
    var begin_date_initial_value = $(begin_date_field).val();
    var end_date_initial_value = $(end_date_field).val();
    // Max length of the date field.
    $(begin_date_field).attr('maxlength', constraints.date_format.length);
    $(end_date_field).attr('maxlength', constraints.date_format.length);
    // Keep track of if the date picker is open and we are leaving focus.
    // we care because if the date was set by the datepicker then it should
    // fall within the correct boundaries but if it was typed we have to check it.
    var begin_date_picker_shown = false;
    $(begin_date_field).on('hide', function(e){ begin_date_picker_shown = false; });
    $(begin_date_field).on('show', function(e){ begin_date_picker_shown = true; });
    $(begin_date_field).on('blur', function (e) {
      if (!begin_date_picker_shown) {
        $(begin_date_field).trigger('final-date-check');
      }
    });
    var end_date_picker_shown = false;
    $(end_date_field).on('hide', function(e){ end_date_picker_shown = false; });
    $(end_date_field).on('show', function(e){ end_date_picker_shown = true; });
    $(end_date_field).on('blur', function(e){
        
      if(! end_date_picker_shown){
        $(end_date_field).trigger('final-date-check');
      }
    });
    $(begin_date_field).on('final-date-check', function (e) {
      var orig_val = $(e.target).val();
      var formatted_date = DatePickerHelper.formatDate(constraints.date_format, orig_val);
      var parsed_date = DatePickerHelper.parseDate(constraints.date_format, formatted_date);
      var result = DatePickerHelper.enforceMinMaxDate(constraints, parsed_date);
      var required = DatePickerHelper.requiredDetection(e.target);
      if(required && result == ''){
        $(e.target).val( begin_date_initial_value );
      }else{
        $(e.target).val( result );
      }
      DatePickerHelper.enforceDateRange(e.target, end_date_field);
      $(e.target).trigger('change');
    });
    $(end_date_field).on('final-date-check', function(e){
      var orig_val = $(e.target).val();
      var formatted_date = DatePickerHelper.formatDate(constraints.date_format, orig_val);
      var parsed_date = DatePickerHelper.parseDate(constraints.date_format,formatted_date);
      var result = DatePickerHelper.enforceMinMaxDate(constraints, parsed_date);
      $(e.target).val(result);
      var required = DatePickerHelper.requiredDetection(e.target);
      if(required && result == ''){
        $(e.target).val( end_date_initial_value );
      }else{
        $(e.target).val( result );
      }
      DatePickerHelper.enforceDateRange(begin_date_field, e.target);
      $(e.target).trigger('change');
    });
    //set the place holder on the input.
    var div_datepicker = $(this);
    $(this).find('input').each(function(){
      var placeholder = $(div_datepicker).attr('placeholder');
      if(placeholder !== undefined) {
        var el_placeholder = $(this).attr('placeholder');
        if(el_placeholder === undefined){
          $(this).attr('placeholder', placeholder);
        }
      }
    });
  });
  $('.dob_date').each(function() {
    var parentNode = this.parentNode;
    if (! $(parentNode).hasClass('calendar-datepicker')) {
      $(this).datepicker({
        autoclose: false,
        keyboardNavigation: false,
        assumeNearbyYear: true,
        forceParse: false,
        immediateUpdates: true
      });
    }
    var input_fields = $(this).find('input');
    var dob_date_field = input_fields.get('context');
    var date_picker_shown = false;
    $(dob_date_field).on('hide', function(e){ date_picker_shown = false; });
    $(dob_date_field).on('show', function(e){ date_picker_shown = true; });
    $(dob_date_field).on('blur', function(e){
      if(! date_picker_shown){
        $(dob_date_field).trigger('final-date-check');
      }
    });
    $(dob_date_field).on('final-date-check', function(e){
      var orig_val = $(e.target).val();
      if (orig_val != '') {
        var formatted_date = DatePickerHelper.formatDate(constraints.date_format, orig_val);
        $(e.target).val(formatted_date);
      }
    });
  });
});
