export const initializeSortable = function() {
  $('[data-behavior=\'sortable\']').sortable({
    stop: function(e, ui) {
      $.ajax({
        url: ui.item.data('url'),
        type: 'PATCH',
        data: { sequence: (ui.item.index() + 1), rerender_list: false }
      });
    }
  });

  $('[data-behavior=\'local-sortable\']').sortable();
};

$(function () {
  initializeSortable();
});

window.initializeSortable = initializeSortable;
