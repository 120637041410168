$(document).on('click', '*[data-sortable] thead th.sortable', function() {
  //Sorting for rx_search_requests
  var th = $(this);
  var source = th.parents('[data-sortable]');
  var table = th.parents('table');
  var url = source.data('url');
  var spinner = $(source.data('spinner'));
  var sort_direction = 'asc';
  if (th.hasClass('asc')) {
    sort_direction = 'desc';
  }
  var form = th.parents('form');
  $('#sort_symbol').val(th.data('sort-symbol'));
  $('#sort_direction').val(sort_direction);
  var primary_submit_button = form.find('.btn-primary').not('.remove-token');
  
  if (primary_submit_button.length > 0) {
    primary_submit_button.trigger('click');
  } else {
    //There is server side code that checks the name of the buttons used to submit.
    //If the only button is a pdf or csv button, then when you sort a column,
    //the column data renders in one of those two formats instead of html.
    //To fix this, let's create a new submit button and ensure we are using that one.
    var magic_submit_button = $('#__magic_submit_button');
    if (magic_submit_button.length === 0) {
      form.append($('<input type=\'submit\' value=\'\' style=\'display:none;\' id=\'__magic_submit_button\' />'));
      magic_submit_button = $('#__magic_submit_button');
    }
    magic_submit_button.trigger('click');
  }
});
