(function() {
  $(document).on('click', 'a[data-interior-tab]', function (event) {
    var link = $(event.target),
      tab_to_activate = $(link.attr('href')),
      text_area = $(link.data('text-field'));

    if (text_area.length > 0 && $(text_area)[0].value == '') {
      tab_to_activate = $(link.data('link-for-empty'));
    }

    link.parents('.tab-content').children('.tab-pane').removeClass('active');
    tab_to_activate.addClass('active');
  });
}());
